import React from "react";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Hidden, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 0, 0),
  },
}));

const CustomButton = withStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0, 3, 3, 0),
    padding: theme.spacing(1, 2, 1, 3),
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[700],
    },
  },
}))(Button);

const SidebarToggleButton = (props) => {
  const { toggleSidebar, isCallInProgress } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Hidden lgUp={true && !isCallInProgress}>
        <CustomButton
          size="small"
          startIcon={<MenuIcon />}
          onClick={toggleSidebar}
        >
          {t("SidebarToggleButton")}
        </CustomButton>
      </Hidden>
    </div>
  );
};

export default SidebarToggleButton;
