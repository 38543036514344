import React from "react";

// Components
import Row from "./components/Row/Row";

import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const FilesTable = (props) => {
  const { allFiles, setSelectedAttachment, setSelectedMp4Attachment } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "1px" }} />
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("TitleDateOfBirth")}</TableCell>
            <TableCell>{t("PhoneNumber")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allFiles
            ?.slice(0)
            .reverse()
            .map((patient) => (
              <Row
                {...{
                  patient,
                  setSelectedAttachment,
                  setSelectedMp4Attachment,
                }}
                key={patient.id}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilesTable;
