import React, { useEffect } from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper } from "@mui/material";

// Components
import { LatestClinics, Tasks } from "./components";
import { ClinicBookings } from "components";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  useEffect(() => {
    // console.log(">>> Device detect", {
    //   isMobile,
    //   isMobileOnly,
    //   isBrowser,
    //   isSafari,
    //   isMobileSafari,
    //   isIOS,
    // });

    if (!("Notification" in window)) return;
    Promise.resolve(Notification.requestPermission()).then((permission) => {
      console.log("[Notification permission]", permission);
    });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item lg={6} xs={12}>
          <Paper>
            <LatestClinics />
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Paper>
            <ClinicBookings />
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Tasks />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
