import React, { useState, useContext, useEffect } from "react";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { ClinicOptions } from "components";

// API
import { apiPost, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

// Country codes
import { countryCodes } from "common/countryCodes";

// Contexts
import { AccountContext } from "contexts/AccountProvider";

// Snackbar
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ClinicDefaults = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [accountData] = useContext(AccountContext);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!accountData?.clinicDefaults) return;

    setFormState({
      country: accountData.clinicDefaults.country,
      expiration: accountData.clinicDefaults.expiration,
    });
  }, [accountData]);

  const [formState, setFormState] = useState({
    expiration: "",
    country: "",
  });

  const handleChange = async (e) => {
    try {
      setFormState((formState) => ({
        ...formState,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      }));

      const response = await apiPost(endpoints.account.settings, {
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });

      if (response.status === 200) {
        enqueueSnackbar("Settings saved successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Unable to save the settings", {
          variant: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("There was a problem savings your settings", {
        variant: "error",
      });
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)} elevation={0}>
      <CardHeader
        title={t("MenuClinicDefaults")}
        subheader={t("SettingsClinicDefaultsSubheader")}
      />
      <Divider />
      <CardContent>
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginBottom: "1rem" }}
        >
          <Grid item>
            <Typography>{t("DefaultExpiration")}</Typography>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="expiration">{t("Expiration")}</InputLabel>
              <Select
                label={t("Expiration")}
                labelId="expiration"
                id="expiration"
                name="expiration"
                value={formState.expiration || ""}
                onChange={handleChange}
              >
                <MenuItem value={"H24"}>{t("24Hours")}</MenuItem>
                <MenuItem value={"H72"}>{t("72Hours")}</MenuItem>
                <MenuItem value={"H168"}>{t("1Week")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginBottom: "1rem" }}
        >
          <Grid item>
            <Typography>{t("DefaultCountryCode")}</Typography>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="country-label">
                {t("DefaultCountryCode")}
              </InputLabel>
              <Select
                label={t("DefaultCountryCode")}
                labelId="country-label"
                id="country"
                name="country"
                value={formState.country || ""}
                onChange={handleChange}
              >
                {countryCodes.map((country) => {
                  return (
                    <MenuItem value={country.code} key={country.code}>
                      {`${country.name} ${country.dial_code}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <ClinicOptions
          callback={async (res) => {
            try {
              const response = await apiPost(endpoints.account.settings, res);

              if (response.status === 200) {
                enqueueSnackbar("Settings saved successfully", {
                  variant: "success",
                });
              } else {
                enqueueSnackbar("Unable to save the settings", {
                  variant: "warning",
                });
              }
            } catch (err) {
              console.error(err);
              enqueueSnackbar("There was a problem savings your settings", {
                variant: "error",
              });
            }
          }}
          showExtraOptions={false}
        />
      </CardContent>
    </Card>
  );
};

ClinicDefaults.propTypes = {
  className: PropTypes.string,
};

export default ClinicDefaults;
