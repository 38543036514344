import React, { useState, useContext, useEffect } from "react";

// Utils
import PropTypes from "prop-types";

// API
import { endpoints, apiGet } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Switch,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  OutlinedInput,
} from "@mui/material";
import Alert from '@mui/material/Alert';

// Contexts
import { AccountContext } from "contexts/AccountProvider";

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ClinicOptions = (props) => {
  const { switchItems, callback, clinic, showExtraOptions } = props;

  const classes = useStyles();

  const [accountData] = useContext(AccountContext);

  const [formState, setFormState] = useState({
    audioMessagesEnabled: false,
    promptForDoB: false,
    promptForPhoneNumber: false,
    availabilityProfileId: "",
    clinicServicePriceId: "",
    isPublished: false,
  });

  // Prefill the state with clinic properties
  useEffect(() => {
    if (!clinic) return;

    setFormState({
      audioMessagesEnabled: clinic.audioMessagesEnabled || false,
      promptForDoB: clinic.promptForDoB || false,
      promptForPhoneNumber: clinic.promptForPhoneNumber || false,
      availabilityProfileId: clinic.availabilityProfileId || "",
      clinicServicePriceId: clinic.service?.id || "",
      isPublished: clinic.isPublished || false,
    });
  }, [clinic]);

  // Set the state the same as user's clinic defaults
  useEffect(() => {
    // Prioritise clinic properties over user's clinic defaults
    // So if clinic object is there, ignore user's clinic defaults
    if (clinic || !accountData?.clinicDefaults) return;

    setFormState((prevState) => ({
      ...prevState,

      audioMessagesEnabled:
        accountData.clinicDefaults.audioMessagesEnabled || false,
      promptForDoB: accountData.clinicDefaults.promptForDoB || false,
      promptForPhoneNumber:
        accountData.clinicDefaults.promptForPhoneNumber || false,
      availabilityProfileId:
        accountData.clinicDefaults.availabilityProfileId || "",
    }));
  }, [accountData]);

  // Retrieving and setting availability profiles upon first render
  const [availabilityProfiles, setAvailabilityProfiles] = useState([]);
  useEffect(() => {
    apiGet(endpoints.clinic.availabilityProfile)
      .then((res) => {
        setAvailabilityProfiles(res.data);
      })
      .catch(console.error);
  }, []);

  // Retrieving and setting stripe products upon first render
  const [clinicServices, setClinicServices] = useState([]);
  useEffect(() => {
    if (accountData?.stripe?.receivePayment?.onBoardingCompleted)
      apiGet(endpoints.stripe.products)
        .then((res) => {
          setClinicServices(res.data);
        })
        .catch(console.error);
  }, []);

  const handleChange = (e) => {
    console.log(e)
    setFormState((formState) => ({
      ...formState,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));

    callback({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <>
      {/* Clinic check-in settings */}
      <Box my={3}>
        <Box mb={2}>
          <Typography variant="h6">{"Check-in Settings"}</Typography>
        </Box>
        <FormGroup>
          {switchItems.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={formState[item.name] || false}
                    onChange={handleChange}
                    name={item.name}
                    inputProps={{ "aria-label": item.label }}
                  />
                }
                label={item.label}
                // labelPlacement="start"
                key={index}
              />
            );
          })}
        </FormGroup>
      </Box>
      <Box my={3}>
        <Box mb={2}>
          <Typography variant="h6">{"Availability Profile"}</Typography>
        </Box>
        {!accountData?.timezone && (
          <Box my={2}>
            <Alert severity="error">
              {"You need to set up your timezone first."}
            </Alert>
          </Box>
        )}
        <FormControl
          className={classes.formControl}
          variant="outlined"
          disabled={!accountData?.timezone}
          style={{
            width: "100%",
          }}
        >
          <InputLabel id="availability-profile-label">
            {"Availability Profile"}
          </InputLabel>
          <Select
            labelId="availability-profile-label"
            id="availability-profile"
            label="Availability Profile"
            name="availabilityProfileId"
            value={formState.availabilityProfileId}
            onChange={handleChange}
            style={{
              width: "100%",
            }}
          >
            {availabilityProfiles?.map((profile) => {
              return (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      {showExtraOptions && clinic.term === "Long" && (
        <>
          <Box my={2}>
            <Box mb={2}>
              <Typography variant="h6">{"Paid Booking Service"}</Typography>
            </Box>

            {!accountData?.stripe?.receivePayment?.onBoardingCompleted ? (
              <Box my={2}>
                <Alert severity="warning">
                  {
                    "Please set up your Payment Service in Account Settings first."
                  }
                </Alert>
              </Box>
            ) : (
              formState.clinicServicePriceId && (
                <Box my={2}>
                  <Alert severity="info">
                    {"This clinic will only be accessible with an invitation."}
                  </Alert>
                </Box>
              )
            )}

            <FormControl
              className={classes.formControl}
              disabled={
                !accountData?.stripe?.receivePayment?.onBoardingCompleted
              }
              variant="outlined"
              style={{
                width: "100%",
              }}
            >
              <InputLabel shrink id="clinic-service-label">
                {"Select a service"}
              </InputLabel>
              <Select
                labelId="clinic-service-label"
                id="clinic-service"
                label="Select a service"
                name="clinicServicePriceId"
                value={formState.clinicServicePriceId}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                displayEmpty
                input={<OutlinedInput notched label="Select a service" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {clinicServices?.map((service) => {
                  return (
                    <MenuItem key={service.id} value={service.id}>
                      {`${service.name} (${service.price} ${service.currency})`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {/* Toggle publish paid clinic */}
          <Box my={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.isPublished}
                    onChange={handleChange}
                    name="isPublished"
                    inputProps={{ "aria-label": "publish clinic" }}
                    disabled={!formState.clinicServicePriceId}
                  />
                }
                label="Publish Clinic"
              />
            </FormGroup>
          </Box>
        </>
      )}
    </>
  );
};

ClinicOptions.defaultProps = {
  switchItems: [
    { label: "Waiting Room Audio", name: "audioMessagesEnabled" },
    { label: "Require Date of Birth", name: "promptForDoB" },
    { label: "Require Phone Number", name: "promptForPhoneNumber" },
  ],
  callback: (res) => console.log(res),
  showExtraOptions: true,
};

ClinicOptions.propTypes = {
  className: PropTypes.string,
};

export default ClinicOptions;
