import React, { useState } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Components
import { InviteCliniciansComponent } from "components";

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  list: {
    marginLeft: "1rem",
  },
  listItem: {
    padding: "2px",
  },
}));

const includedOptions = [
  "Unlimited Video Calling",
  "Unlimited Waiting Rooms",
  "HD Group Calls",
  "Verified Clinician Profile",
  "Unlimited Pre-appointment Questionnaires (coming soon)",
  "Meeting History (Coming soon)",
];

const includedOptionsAll = [
  "HIPPA/GDPR compliant",
  "Cybersecurity Essentials, NHS DSPT, DCB0129",
  "HD Video Calls",
  "End-to-End Encryption",
  "Fully Insured",
  "Secure Data Center",
  "2-Factor Authentication",
  "Personalised URL",
  "No App Downloads",
  "Upload Files and Photos",
  "Screen Sharing",
  "Browser Notifications",
  "Text Chat with Patient",
  "Live Chat Support",
];

const WhatsIncluded = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h5">
            {"What's included in the Clinician Pro package?"}
          </Typography>
        </Grid>
        <Grid item>
          <List className={classes.list}>
            {includedOptions.map((option, index) => {
              return (
                <ListItem className={classes.listItem} key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlineRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={option} />

                  {/* <Typography variant="body1">{option}</Typography> */}
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item>
          <Typography variant="h5">
            {"Included in all our plans:"}
          </Typography>
        </Grid>
        <Grid item>
          <List className={classes.list}>
            {includedOptionsAll.map((option, index) => {
              return (
                <ListItem className={classes.listItem} key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlineRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={option} />

                  {/* <Typography variant="body1">{option}</Typography> */}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WhatsIncluded;
