import React, { useState } from "react";

// Utils
import { useSnackbar } from "notistack";

// Components
import Builder from "@medicalchain/medicalchain-react-form-builder";

// API
import { apiPost, apiPut, endpoints } from "api";

// React Json Forms
import Form from "@rjsf/material-ui";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2, 3),
  },
}));

const QuestionnaireBuilder = (props) => {
  const { labels, selectedQuestionnaire, handleHideBuilder } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const questSchema =
    selectedQuestionnaire && JSON.parse(selectedQuestionnaire.formSchema);
  const questUiSchema =
    selectedQuestionnaire && JSON.parse(selectedQuestionnaire.uiSchema);

  const initSchema = {
    title: "",
    description: "",
    type: "object",
    required: [],
    properties: {},
  };
  const initUiSchema = {
    "ui:order": [],
  };

  // State
  const [schema, setSchema] = useState(questSchema ? questSchema : initSchema);
  const [uiSchema, setUiSchema] = useState(
    questUiSchema ? questUiSchema : initUiSchema,
  );
  const [formData, setFormData] = useState({});

  const handleCreateQuestionnaire = async () => {
    try {
      const response = await apiPost(endpoints.clinic.questCreate, {
        formSchema: schema,
        uiSchema,
      });

      if (response.status === 200) {
        enqueueSnackbar("Questionnaire saved successfully", {
          variant: "success",
        });

        setSchema(initSchema);
        setUiSchema(initUiSchema);
        handleHideBuilder();
      } else {
        enqueueSnackbar("Unable to save the questionnaire", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("There is a problem saving the questionnaire", {
        variant: "error",
      });
      console.error("Problem saving questionnaire:", err);
    }
  };

  const handleUpdateQuestionnaire = async () => {
    try {
      const response = await apiPut(
        `${endpoints.clinic.questUpdate}${selectedQuestionnaire.id}`,
        {
          formSchema: schema,
          uiSchema,
        },
      );

      if (response.status === 200) {
        enqueueSnackbar("Questionnaire updated successfully", {
          variant: "success",
        });

        setSchema(initSchema);
        setUiSchema(initUiSchema);
        handleHideBuilder();
      } else {
        enqueueSnackbar("Unable to update the questionnaire", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("There is a problem updating the questionnaire", {
        variant: "error",
      });
      console.error("Problem updating questionnaire:", err);
    }
  };

  return <>
    <Box mb={4}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <IconButton onClick={handleHideBuilder} size="large">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {labels.questionnaireBuilderTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveOutlinedIcon />}
                onClick={
                  selectedQuestionnaire
                    ? handleUpdateQuestionnaire
                    : handleCreateQuestionnaire
                }
                disabled={!schema.title}
              >
                {selectedQuestionnaire
                  ? labels.questionnaireUpdate
                  : labels.questionnaireSave}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

    <Grid container spacing={2}>
      {/* Builder */}
      <Grid item xs={6}>
        <Box>
          <Paper className={classes.paper}>
            <Builder
              {...{
                languageOptions: labels,
                schema: questSchema,
                uiSchema: questUiSchema,
                onChange: (data) => {
                  setSchema(data.schema);
                  setUiSchema(data.uiSchema);
                },
              }}
            />
          </Paper>
        </Box>
      </Grid>

      {/* Preview */}
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Box mb={2}>
            <Typography variant="h5">
              {labels.questionnairePreviewLabel}
            </Typography>
          </Box>

          <Form
            schema={schema}
            uiSchema={uiSchema}
            onChange={(questionnaire) => setFormData(questionnaire.formData)}
            formData={formData}
            onSubmit={(res) => console.log(res)}
          />
        </Paper>
      </Grid>
    </Grid>
  </>;
};

export default QuestionnaireBuilder;
