import React from "react";

// Utils
import PropTypes from "prop-types";

// Material UI
import { Grid, Card, CardContent, Typography } from "@mui/material";

const AddClinic = ({ children }) => {
  return (
    <Grid item style={{ width: "100%" }}>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item>{children}</Grid>
            <Grid item>
              <Typography variant="button">Add a clinic</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

AddClinic.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AddClinic;
