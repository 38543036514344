import React, { useState } from "react";

// Utils
import { useSnackbar } from "notistack";

// API
import { apiPost, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const ProfileClaim = (props) => {
  const { match, history } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    values: {
      handle: match.params.claim_id,
      givenName: "",
      familyName: "",
      phoneNumber: "",
    },
  });

  const handleChange = (e) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    }));
  };

  const handleSubmit = () => {
    apiPost(endpoints.profile.claim, formState.values)
      .then((res) => {
        if (res.status == 200) {
          enqueueSnackbar("Profile claim submitted successfully", {
            variant: "success",
          });
          history.push("/");
        } else {
          enqueueSnackbar("Unable to submit profile claim request", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("There is a problem submitting profile claim request", {
          variant: "error",
        });
        console.error(err);
      });
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {"Claim your profile"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {"Please provide your details below to claim your profile"}
          </Typography>
        </Grid>

        <Grid
          container
          direction="column"
          spacing={2}
          xs={4}
          style={{ marginTop: 33, marginBottom: 33 }}
        >
          <Grid item>
            <TextField
              label="Profile handle"
              name="handle"
              variant="outlined"
              value={formState.values.handle}
              disabled
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              label="First name"
              name="givenName"
              variant="outlined"
              value={formState.values.givenName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              label="Last name"
              name="familyName"
              variant="outlined"
              value={formState.values.familyName}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              label="Phone number"
              name="phoneNumber"
              variant="outlined"
              value={formState.values.phoneNumber}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit request
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileClaim;
