import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Chip } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { IClinicQuestionnaire } from "types";

const useStyles = makeStyles((theme) => ({
  chip: {
    color: theme.palette.getContrastText(lightGreen[200]),
    backgroundColor: lightGreen[200],
    border: "1px solid",
    borderColor: lightGreen[400],
  },
}));

interface IProps {
  questionnaires: IClinicQuestionnaire[];
}

const ClinicAssignedQuestionnaires = (props: IProps) => {
  const { questionnaires } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <Typography variant="button" color="textSecondary">
          Assigned questionnaires:
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        {questionnaires.map((quest) => {
          const label =
            typeof quest?.formSchema == "string" &&
            JSON.parse(quest.formSchema);

          return (
            <Grid item key={quest.id}>
              <Chip
                label={label?.title || "-"}
                size="small"
                className={classes.chip}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ClinicAssignedQuestionnaires;
