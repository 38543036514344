import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Link } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body2">
        Copyright &copy; 2022 MyClinic.com Ltd. All rights reserved.
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
