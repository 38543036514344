import React, { useState, useEffect, useContext } from "react";

// Contexts
import { AccountContext } from "contexts";

// Components
import {
  ProfileImageAndCover,
  ProfileActionButtons,
  ProfileTitleAndName,
  ProfileSingleFieldEditor,
  PropItem,
  NotEditable,
} from "./components";

// API
import { apiGet, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Box, Paper } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: 128,
  },
}));

const ProfileEdit = () => {
  const classes = useStyles();

  const [accountData, setAccountData] = useContext(AccountContext);

  // Get profile data on first render
  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () =>
    apiGet(endpoints.profile.all)
      .then((res) => {
        // Append profile data to account data provider for global access
        setAccountData((prevState) => ({ ...prevState, profile: res.data }));
      })
      .catch(console.error);

  const [profile, setProfile] = useState(null);
  // When accountData.profile changes, update profile state
  useEffect(() => {
    if (accountData?.profile) setProfile(accountData.profile);
  }, [accountData?.profile]);

  return (
    <Box className={classes.root}>
      {accountData?.isProfileEditable && profile && (
        <>
          {/* Page header */}
          <Box mb={3}>
            <Typography variant="h4">{"Profile Edit"}</Typography>
            <Typography color="textSecondary">
              {"Change or modify your profile details"}
            </Typography>
          </Box>

          {/* Profile image & banner */}
          <Box>
            <ProfileImageAndCover {...{ profile }} />
          </Box>

          {/* Profile Action Buttons */}
          <Box my={1}>
            <ProfileActionButtons {...{ isPublished: profile.isPublished }} />
          </Box>

          {/* Profile content */}
          <Grid container className={classes.content}>
            <Grid item xs={12}>
              <ProfileTitleAndName {...{ profile, callback: getProfileData }} />
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={1}>
                <ProfileSingleFieldEditor
                  {...{
                    profile,
                    title: "Intro",
                    fieldName: "shortBio",
                    callback: getProfileData,
                  }}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={1}>
                <ProfileSingleFieldEditor
                  {...{
                    profile,
                    title: "About",
                    fieldName: "longBio",
                    multiline: true,
                    callback: getProfileData,
                  }}
                />
              </Paper>
            </Grid>

            {/* Qualifications section */}
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={1}>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <PropItem
                      {...{
                        propName: "qualifications",
                        propArray: profile.props?.qualifications,
                        propType: "qual",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <PropItem
                      {...{
                        propName: "licenses",
                        propArray: profile.props?.licenses,
                        propType: "qual",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <PropItem
                      {...{
                        propName: "memberships",
                        propArray: profile.props?.memberships,
                        propType: "qual",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Treatments section */}
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={1}>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <PropItem
                      {...{
                        propName: "treatments",
                        propArray: profile.props?.treatments,
                        propType: "treat",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <PropItem
                      {...{
                        propName: "clinicalInterests",
                        propArray: profile.props?.clinicalInterests,
                        propType: "treat",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <PropItem
                      {...{
                        propName: "specialities",
                        propArray: profile.props?.specialities,
                        propType: "treat",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Location Selector */}
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={1}>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <PropItem
                      {...{
                        propName: "locations",
                        propArray: profile?.locations,
                        propType: "loc",
                        callback: getProfileData,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      {/* Show this if profile is not editable */}
      {accountData?.isProfileEditable == false && <NotEditable />}
    </Box>
  );
};

export default ProfileEdit;
