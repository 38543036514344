import React, { useState, useEffect } from "react";

// Components
import HeaderWithEdit from "./HeaderWithEdit";
import ActionButtons from "./ActionButtons";

// API
import { apiPatch, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(5),
  },
  formControl: { width: "100%" },
  editButton: {
    opacity: 0,
  },
}));

const ProfileTitleAndName = ({ profile, callback }) => {
  const classes = useStyles();

  useEffect(() => {
    setFormState({
      values: {
        title: profile.title ? profile.title : "",
        firstName: profile.firstName ? profile.firstName : "",
        lastName: profile.lastName ? profile.lastName : "",
      },
    });
  }, [profile]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      title: "",
      firstName: "",
      lastName: "",
    },
  });

  const handleChange = (e) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    }));
  };

  const handleSave = () => {
    apiPatch(endpoints.profile.all, formState.values)
      .then((res) => {
        console.log(res);
        setIsEditMode(false);
        callback();
      })
      .catch(console.error);
  };

  const handleClick = () => {
    setIsEditMode(true);
  };

  const handleClose = () => {
    setIsEditMode(false);
    setFormState({
      values: {
        title: profile.title,
        firstName: profile.firstName,
        lastName: profile.lastName,
      },
    });
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <HeaderWithEdit
            {...{
              title:
                formState.values.firstName || formState.values.lastName
                  ? `${formState.values.title} ${formState.values.firstName} ${formState.values.lastName}`
                  : "Add name",
              variant: "h4",
              tooltip: "Edit name and title",
              handleClick,
            }}
          />
        </Grid>

        {isEditMode && (
          <Grid item container direction="column" justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={2}>
              <FormControl className={classes.formControl} variant="outlined">
                <InputLabel id="changeTitle">{"Title"}</InputLabel>
                <Select
                  labelId="changeTitle"
                  label="Title"
                  name="title"
                  value={formState.values.title}
                  onChange={handleChange}
                >
                  <MenuItem value={"Mr"}>{"Mr"}</MenuItem>
                  <MenuItem value={"Ms"}>{"Ms"}</MenuItem>
                  <MenuItem value={"Miss"}>{"Miss"}</MenuItem>
                  <MenuItem value={"Mrs"}>{"Mrs"}</MenuItem>
                  <MenuItem value={"Dr"}>{"Dr"}</MenuItem>
                  <MenuItem value={"Prof"}>{"Prof"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                label={"First name"}
                name="firstName"
                value={formState.values.firstName}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                label={"Last name"}
                name="lastName"
                value={formState.values.lastName}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item>
              <ActionButtons
                {...{
                  handleSave,
                  handleClose,
                  active:
                    !!formState.values.firstName && !!formState.values.lastName,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
export default ProfileTitleAndName;
