import React, { useContext } from "react";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

// Contexts
import { AccountContext, ClinicsContext } from "contexts";

// Router
import { Link as RouterLink, withRouter } from "react-router-dom";

// Snackbar
import { useSnackbar } from "notistack";

// API
import { apiPost, endpoints } from "api";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import { Drawer, Grid, Button } from "@mui/material";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";

//SVGs
import IconDashbord from "icons/svg/IconDashbord";
import IconManageClinics from "icons/svg/IconManageClinics";
import IconProfileSettings from "icons/svg/IconProfileSettings";
import IconAccountSettings from "icons/svg/IconAccountSettings";
import IconUpgrade from "icons/svg/IconUpgrade";
import IconInviteClinicians from "icons/svg/IconInviteClinicians";
import IconTourGuide from "icons/svg/IconTourGuide";
import IconHelp from "icons/svg/IconHelp";
import IconLogout from "icons/svg/IconLogout";
import IconMessaging from "icons/svg/IconMessaging";

// Components
import { Profile, SidebarNav } from "./components";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    // [theme.breakpoints.up("lg")]: {
    //   height: "calc(100% - 64px)",
    // },
  },
  root: {
    // background: `linear-gradient(0deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    // backgroundColor: "#e8e8e8",
    // display: "flex",
    // flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    // paddingTop: 64,
    // borderRight: "1px solid #c1c3c5",
  },
  nav: {
    margin: theme.spacing(1, 0),
  },
  actionButton: {
    // backgroundColor: "#e8e8e8",
    color: "#222222",
    fontSize: "1rem",
  },
}));

const Sidebar = (props) => {
  const {
    history,
    open,
    variant,
    onClose,
    className,
    setOpenClickAwayConfirmation,
    isClickAwayAllowed,
    // eslint-disable-next-line
    staticContext,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [accountData, setAccountData] = useContext(AccountContext);
  const [, setClinicsList] = useContext(ClinicsContext);

  const pages = [
    {
      title: `${t("Dashboard")}`,
      href: "/dashboard",
      icon: <IconDashbord />,
    },
    {
      title: `${t("ManageClinics")}`,
      href: "/clinics-manager",
      icon: <IconManageClinics />,
    },
    {
      title: `${t("Messaging")}`,
      href: "/Messaging",
      icon: <IconMessaging />,
    },
    {
      title: `${t("Questionnaires")}`,
      href: "/questionnaire",
      icon: <AssignmentRoundedIcon />,
    },
    {
      title: `${t("MenuProfile")}`,
      href: "/profile-edit",
      icon: <IconProfileSettings />,
    },
    {
      title: `${t("MenuAccount")}`,
      href: "/account",
      icon: <IconAccountSettings />,
    },
  ];

  const handleOpenClickAwayConfirmation = () => {
    if (isClickAwayAllowed) return;
    setOpenClickAwayConfirmation(true);
  };

  // Logout
  const handleLogout = async () => {
    try {
      const response = await apiPost(endpoints.account.logout);
      if (response.status === 200) {
        enqueueSnackbar("Logged out successfully", { variant: "success" });
      } else {
        enqueueSnackbar("Problem logging out", { variant: "error" });
      }
      setClinicsList(null);
      setAccountData(null);
      sessionStorage.removeItem("orgId");
      history.push("/sign-in");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Drawer {...{ classes: { paper: classes.drawer }, variant, open, onClose }}>
      <Grid container alignItems="center" justifyContent="center"></Grid>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        {...rest}
        className={clsx(classes.root, className)}
        wrap="nowrap"
      >
        {/* Section 1 */}
        <Grid item>
          <Grid container justifyContent="center" spacing={2}>
            {/* Logo */}
            <Grid item>
              <RouterLink to="/dashboard">
                <img
                  alt="Logo"
                  src="/images/logos/logo_white.png"
                  height="64"
                />
              </RouterLink>
            </Grid>

            <Grid item>
              <Grid container justifyContent="center">
                {/* Profile name */}
                <Grid item>
                  <Profile
                    {...{ isClickAwayAllowed }}
                    onClick={handleOpenClickAwayConfirmation}
                  />
                </Grid>

                {/* Nav buttons */}
                <Grid item>
                  <SidebarNav
                    {...{
                      className: classes.nav,
                      pages,
                      isClickAwayAllowed,
                      onClose,
                    }}
                    onClick={handleOpenClickAwayConfirmation}
                  />
                  {accountData?.stripe?.status !== "active" && (
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<IconUpgrade />}
                      style={{ width: "100%" }}
                      component={RouterLink}
                      to="/free-upgrade"
                    >
                      <p>
                        {"Get free upgrade "}
                        <sup>{"pro"}</sup>
                      </p>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Section 2 */}
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Button
                className={classes.actionButton}
                startIcon={<IconInviteClinicians />}
                component={RouterLink}
                to="/invite-clinicians"
              >
                {"Invite Clinicians"}
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={classes.actionButton}
                startIcon={<IconTourGuide />}
                href="/dashboard?product_tour_id=216996"
              >
                {"Tour Guide"}
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={classes.actionButton}
                startIcon={<IconHelp />}
                target="_blank"
                href="https://help.myclinic.com/en"
              >
                {"Help"}
              </Button>
            </Grid>

            <Grid item>
              <Button
                className={classes.actionButton}
                startIcon={<IconLogout />}
                onClick={handleLogout}
                style={{ marginTop: "0.75rem" }}
              >
                {t("LogOutButton")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default withRouter(Sidebar);
