import { useMutation, useQuery } from "react-query";
import { Client } from "./client";
import { HTTPStatus } from "./api-new.interface";

interface GetNonceReq {
  address: string;
}

interface VerifyWalletReq {
  address: string;
  signature: string;
}

const endpoint = {
  getNonce: "/misc/wallets/{address}",
  verifySig: "/misc/wallets/{address}/verification",
  timzones: "/misc/tz",
};

/***************
 * API Promise *
 ***************/

// merge implmentation into hook function and call
// that function from this one to remove code dupe
export const promisePostGetNonce = async ({ address }: GetNonceReq) => {
  try {
    const url = endpoint.getNonce.replace("{address}", address);
    const { data } = await Client.instance.post(url, {});
    return data || "OK";
  } catch (e) {
    throw "Could not get nonce";
  }
};

// merge implmentation into hook function and call
// that function from this one to remove code dupe
export const promisePostVerifySig = async ({
  address,
  signature,
}: VerifyWalletReq) => {
  try {
    const url = endpoint.verifySig.replace("{address}", address);
    const { data } = await Client.instance.post(url, { signature });
    return data || "OK";
  } catch (e) {
    throw "Could not get nonce";
  }
};

/***************
 * API Hooks   *
 ***************/

export const usePostGetNonce = () => {
  return useMutation(async ({ address }: GetNonceReq) => {
    try {
      const url = endpoint.getNonce.replace("{address}", address);
      const { data } = await Client.instance.post(url, {});
      return data || "OK";
    } catch (e) {
      throw "Could not get nonce";
    }
  });
};

export const usePostVerifySig = () => {
  return useMutation(async ({ address }: VerifyWalletReq) => {
    try {
      const url = endpoint.verifySig.replace("{address}", address);
      const { data } = await Client.instance.post(url, {});
      return data || "OK";
    } catch (e) {
      throw "Unable to verify wallet";
    }
  });
};

export const useGetTimezones = () => {
  return useQuery("timezones", async () => {
    try {
      const { data, status } = await Client.instance.get(endpoint.timzones);
      if (status !== HTTPStatus.OK) throw "";
      return data;
    } catch {
      throw "Unable to get timezones";
    }
  });
};
