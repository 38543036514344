import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

/*
<Route> render: func (inline wrapping/composing)
This is explained here https://reactrouter.com/web/api/Route/render-func
You can spread routeProps to make them available to your rendered Component
More detailed explanation: https://simonsmith.io/reusing-layouts-in-react-router-4#creating-a-default-layout
Route's "render" prop will be called when the route matches
The "rest" parameter contains every prop passed except for layout & component
"matchProps" which is passed to the "render" prop contains our layout & component
*/

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    title,
    location,
    ...rest
  } = props;

  useEffect(() => {
    if (!title) return;
    document.title = `${title} - ${document.title}`;

    return () => {
      document.title = process.env.REACT_APP_WEBSITE_NAME;
    };
  }, [location]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
