import React, { useState, useEffect } from "react";

// Stripe
import { createCheckoutSession } from "common/stripeFunctions";

// I18n
import { useTranslation } from "react-i18next";

// Components
import { InviteCliniciansComponent } from "components";

import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Typography, Paper, Button } from "@mui/material";
import { apiGet, apiPost, endpoints } from "api";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const UpgradeImmediately = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [price, setPrice] = useState(0);

  useEffect(() => {
    apiGet(endpoints.misc.pricing)
      .then((res) => setPrice(res.data.displayPriceYearly))
      .catch(console.error);
  }, []);

  return (
    <Paper className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">{"Upgrade Immediately"}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5">{price} / year</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={createCheckoutSession}
              >
                {"Upgrade"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UpgradeImmediately;
