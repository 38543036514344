import React, { useState } from "react";

// Components
import { ConfirmDialog } from "components";
import { QuestionnaireAssign } from "views/Questionnaire/components";

// API
import { apiDelete, endpoints } from "api";

// Utils
import { useSnackbar } from "notistack";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToPhotosRoundedIcon from "@mui/icons-material/AddToPhotosRounded";

// Date
import { format } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const QuestionnaireRow = (props) => {
  const {
    questionnaire,
    getAllQuestionnaires,
    handleShowPreview,
    handleShowBuilder,
  } = props;

  const schema = JSON.parse(questionnaire.formSchema);
  const title = schema.title;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
  };

  const handleDelete = async () => {
    try {
      const response = await apiDelete(
        `${endpoints.clinic.questDelete}${questionnaire.id}`,
      );
      if (response.status === 200) {
        enqueueSnackbar("Questionnaire deleted successfully", {
          variant: "success",
        });

        // Update the list
        getAllQuestionnaires();
      } else {
        enqueueSnackbar("Unable to delete the questionnaire", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("There is a problem deleting the questionnaire", {
        variant: "error",
      });
      console.error("Problem deleting questionnaire:", err);
    }
  };

  const handleCloseDialog = () => {
    setShowDeleteDialog(false);
  };

  return <>
    <QuestionnaireAssign
      {...{
        questionnaireId: questionnaire.id,
        questionnaireName: title,
        open: openAssignModal,
        handleClose: handleCloseAssignModal,
      }}
    />

    <ConfirmDialog
      {...{
        title: `${t("QuestionnaireDeleteConfirmTitle")} ${title}`,
        content: `${t("QuestionnaireDeleteConfirmContent")}`,
        open: showDeleteDialog,
        handleClose: handleCloseDialog,
        handleDelete,
      }}
    />

    <TableRow className={classes.root} style={{ cursor: "pointer" }}>
      <TableCell
        component="th"
        scope="row"
        onClick={() => handleShowPreview(questionnaire)}
      >
        <Typography>{title}</Typography>
      </TableCell>

      <TableCell onClick={() => handleShowPreview(questionnaire)}>
        <Typography>
          {format(new Date(questionnaire.created), "PPp", {
            locale: supportedLocales[i18n.language],
          })}
        </Typography>
      </TableCell>

      <TableCell>
        <Grid container>
          {/* <Grid item>
            <Tooltip title={t("QuestionnaireShareTooltip")} arrow>
              <IconButton
                aria-label="share"
                color="primary"
                onClick={() => console.log("Share button clicked")}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}

          <Grid item>
            <Tooltip title={t("QuestionnaireEditTooltip")} arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleShowBuilder(questionnaire)}
                size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={t("QuestionnaireAssignTooltip")} arrow>
              <IconButton aria-label="assign" onClick={() => setOpenAssignModal(true)} size="large">
                <AddToPhotosRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={t("QuestionnaireDeleteTooltip")} arrow>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => setShowDeleteDialog(true)}
                size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  </>;
};

export default QuestionnaireRow;
