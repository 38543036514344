import {
  arSA,
  enUS,
  enGB,
  es,
  fr,
  it,
  ja,
  ko,
  nl,
  pt,
  vi,
  zhCN,
  zhTW,
} from "date-fns/locale";

export const supportedLocales = {
  arSA,
  enUS,
  enGB,
  es,
  fr,
  it,
  ja,
  ko,
  nl,
  pt,
  vi,
  zhCN,
  zhTW,
};
