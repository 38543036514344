import React, { createContext, useState } from "react";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatState, setChatState] = useState({});
  const [chatMessageCounter, setChatMessageCounter] = useState({});

  return (
    <ChatContext.Provider
      value={[
        chatState,
        setChatState,
        chatMessageCounter,
        setChatMessageCounter,
      ]}
    >
      {children}
    </ChatContext.Provider>
  );
};
