import React, { useState, useEffect } from "react";

// Utils
import PropTypes from "prop-types";
import { log } from "helpers";

// Components
import { replacePeerTrack } from "common/videoCallModule";

// Material UI
import { IconButton, CircularProgress } from "@mui/material";
import FlipCameraIosOutlinedIcon from "@mui/icons-material/FlipCameraIosOutlined";

const VideoCallFlipCamera = (props) => {
  const { className, peers, selfVideo, setLogs } = props;

  const [pending, setPending] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);

  useEffect(() => {
    if (!selfVideo.current.srcObject) return;

    const track = selfVideo.current?.srcObject?.getVideoTracks()[0];
    const currentFacingMode = track?.getSettings().facingMode;
    currentFacingMode ? setIsAvailable(true) : setIsAvailable(false);
    // eslint-disable-next-line
  }, [selfVideo.current?.srcObject]);

  const flipCamera = async () => {
    try {
      setPending(true);

      const videoElement = selfVideo.current;
      const stream = videoElement.srcObject;
      let track = stream.getVideoTracks()[0];

      const currentFacingMode = track.getSettings().facingMode;

      if (!currentFacingMode) throw new Error("Facing mode not available");

      track.stop();

      const constraint = {
        video: {
          facingMode: {
            exact: currentFacingMode === "user" ? "environment" : "user",
          },
        },
      };

      const newStream = await navigator.mediaDevices.getUserMedia(constraint);
      const newTrack = newStream.getVideoTracks()[0];

      replacePeerTrack(peers, track, newTrack, stream);
      stream.removeTrack(track);
      stream.addTrack(newTrack);
      videoElement.load();
      videoElement.play();

      setPending(false);
    } catch (err) {
      setLogs((prevState) => [...prevState, `Error: ${err.message}`]);
      log({
        type: "err",
        text: "[Media -> Flip camera] Error flipping camera:",
        params: err.message,
      });

      setPending(false);
    }
  };

  return <>
    {isAvailable && (
      <IconButton
        className={className}
        color="default"
        aria-label="flip camera"
        onClick={flipCamera}
        disabled={pending}
        size="large">
        {pending ? (
          <CircularProgress size={24} />
        ) : (
          <FlipCameraIosOutlinedIcon />
        )}
      </IconButton>
    )}
  </>;
};

VideoCallFlipCamera.propTypes = {
  className: PropTypes.string,
  // localStream: PropTypes.object.isRequired,
  // setLocalStream: PropTypes.func.isRequired,
};

export default VideoCallFlipCamera;
