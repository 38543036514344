import React, { useState } from "react";

// Router
import { Link as RouterLink } from "react-router-dom";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar } from "@mui/material";
// import LanguageIcon from "@mui/icons-material/Language";

// Components
import { TopbarLanguagePopover } from "components";

// I18n
// import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: theme.palette.background.appBar },
  flexGrow: {
    flexGrow: 1,
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  // const { t } = useTranslation();

  // Language popover
  const [showLanguageAnchorEl, setShowLanguageAnchorEl] = useState(null);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logos/logo_white.png" height="64" />
        </RouterLink>
        <div className={classes.flexGrow} />

        {/* Language popover starts */}
        <TopbarLanguagePopover
          {...{ showLanguageAnchorEl, setShowLanguageAnchorEl }}
        />
        {/* Language popover ends */}

        {/* <Tooltip title={t("MenuLanguage")} arrow>
          <IconButton
            color="inherit"
            onClick={(event) => {
              setShowLanguageAnchorEl(event.currentTarget);
            }}
          >
            <LanguageIcon />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
