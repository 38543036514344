import { log } from "helpers";

const apiPatch = async (endpoint, payload) => {
  const API_ENDPOINT = `${process.env.REACT_APP_API_DOMAIN}${endpoint}`;
  const body = JSON.stringify(payload);
  let response = null;
  let data = null;

  const orgId = sessionStorage.getItem("orgId");

  const headers = new Headers({
    "Content-Type": "application/merge-patch+json",
    ...(orgId && { "X-OrganisationID": orgId }),
  });

  try {
    response = await fetch(API_ENDPOINT, {
      method: "PATCH",
      credentials: "include",
      headers,
      body,
    });

    try {
      data = await response.json();
    } catch (error) {
      // console.log(`[${endpoint}] No data provided from server`);
    }

    const resObject = { status: response && response.status, data };
    log({ text: `[${endpoint}]`, params: [resObject] });
    return resObject;
  } catch (err) {
    log({
      type: "err",
      text: `[${endpoint}]`,
      params: [response && response.status, err.message],
    });
    return null;
  }
};

export default apiPatch;
