import React, { useState, useContext } from "react";

// Utils
import { useSnackbar } from "notistack";
import { IClinic, IPatient } from "types";

// Contexts
import { ClinicsContext } from "contexts";

import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Badge,
  IconButton,
  Tooltip,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import AttachmentIcon from "@mui/icons-material/Attachment";

// Components
import { StatusBullet, FramerModal, ClinicSelectDropDown } from "components";

// Date
import { format, formatDistance } from "date-fns";

// Animation
import { motion } from "framer-motion";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";
import { apiPost } from "api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actionButtons: {
    "& >*": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface IProps {
  clinicInfo: IClinic | null;
  patientsList: { [key: string]: IPatient[] };
  handleSelectedPatient: (
    event: React.MouseEvent,
    patient: IPatient,
    valueProp?: number,
  ) => void;
  handleCallConnect: (patient: IPatient) => void;
  showDetails: boolean;
}

const PatientsTable = (props: IProps) => {
  const {
    clinicInfo,
    patientsList,
    handleSelectedPatient,
    handleCallConnect,
    showDetails,
  } = props;

  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openMoveRoomModal, setOpenMoveRoomModal] = useState(false);
  // const [clinicsList, setClinicsList] = useContext(ClinicsContext);
  const [waitingRoomTransitions, setWaitingRoomTransitions] = useState<{
    show: boolean;
    patient: IPatient | null;
  }>({
    show: false,
    patient: null,
  });

  const handleShowPatientTransitions = (patient: IPatient) => {
    setWaitingRoomTransitions({
      show: true,
      patient,
    });
  };

  const handleHidePatientTransitions = () => {
    setWaitingRoomTransitions({
      show: false,
      patient: null,
    });
  };

  return <>
    {/* Waiting room transitions modal */}
    <FramerModal
      {...{
        open: waitingRoomTransitions.show,
        handleClose: handleHidePatientTransitions,
      }}
    >
      <Box mb={2}>
        <Typography variant="h5" gutterBottom>
          {`${waitingRoomTransitions.patient?.name} - Patient Pathways`}
        </Typography>
        <Divider />
      </Box>
      {waitingRoomTransitions.patient?.waitingRoomsVisited.map(
        (visitedRoom, index) => {
          return (
            <Box key={visitedRoom.timestamp}>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography>
                    {`${index === 0 ? "Joined" : "Switched to"} ${
                      visitedRoom.name ? visitedRoom.name : "Clinic"
                    } ${formatDistance(
                      new Date(visitedRoom.timestamp),
                      new Date(),
                      {
                        addSuffix: true,
                        includeSeconds: true,
                        // @ts-ignore
                        locale: supportedLocales[i18n.language],
                      },
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        },
      )}
      <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={1}>
        <Button onClick={handleHidePatientTransitions} color="secondary">
          {"Close"}
        </Button>
      </Grid>
    </FramerModal>
    {/* End of waiting room transitions modal */}

    <TableContainer className={classes.root} component={Paper}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>{t("PatientName")}</TableCell>
            {!showDetails && clinicInfo?.promptForDoB && (
              <TableCell>{t("TitleDateOfBirth")}</TableCell>
            )}
            {!showDetails && clinicInfo?.promptForPhoneNumber && (
              <TableCell>{t("PhoneNumber")}</TableCell>
            )}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {clinicInfo &&
            patientsList[clinicInfo.id]?.map((patient: IPatient) => (
              <TableRow key={patient.id}>
                <TableCell
                  onClick={(e) => handleSelectedPatient(e, patient)}
                  component="th"
                  scope="row"
                  style={{ verticalAlign: "top", cursor: "pointer" }}
                >
                  <Typography variant="body1" gutterBottom>
                    <motion.span
                      animate={{ opacity: 0 }}
                      transition={{
                        yoyo: Infinity,
                        duration: 1,
                        ease: "easeIn",
                      }}
                    >
                      <StatusBullet
                        size="sm"
                        color={patient.patientOnline ? "success" : "warning"}
                        className={classes.status}
                      />
                    </motion.span>
                    {patient.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "grey" }}
                    gutterBottom
                  >
                    {`${t("TitleJoined")} ${formatDistance(
                      new Date(patient.patientJoined),
                      new Date(),
                      {
                        addSuffix: true,
                        includeSeconds: true,
                        // @ts-ignore
                        locale: supportedLocales[i18n.language],
                      },
                    )}`}
                  </Typography>
                </TableCell>

                {!showDetails && clinicInfo.promptForDoB && (
                  <TableCell
                    onClick={(e) => handleSelectedPatient(e, patient)}
                    style={{ verticalAlign: "top", cursor: "pointer" }}
                  >
                    {format(new Date(patient.doB), "P", {
                      // @ts-ignore
                      locale: supportedLocales[i18n.language],
                    })}
                  </TableCell>
                )}

                {!showDetails && clinicInfo.promptForPhoneNumber && (
                  <TableCell
                    onClick={(e) => handleSelectedPatient(e, patient)}
                    style={{ verticalAlign: "top", cursor: "pointer" }}
                  >
                    {patient.phoneNumber}
                  </TableCell>
                )}

                {/* Action buttons */}
                <TableCell
                  className={classes.actionButtons}
                  style={{ verticalAlign: "top" }}
                >
                  <Grid container spacing={1}>
                    {patient.attachments && patient.attachments.length > 0 && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<AttachmentIcon />}
                          onClick={(e) =>
                            handleSelectedPatient(e, patient, 1)
                          }
                        >
                          {`${t("ClinicViewAttachments")} (${
                            patient.attachments.length
                          })`}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<VideoCallIcon />}
                        disabled={patient.patientOnline ? false : true}
                        onClick={() => handleCallConnect(patient)}
                      >
                        {t("Connect")}
                      </Button>
                    </Grid>

                    {sessionStorage.getItem("orgId") && (
                      <Grid item>
                        <Button
                          onClick={() => setOpenMoveRoomModal(true)}
                          variant="contained"
                          color="primary"
                        >
                          {"Change room"}
                        </Button>

                        {/* Move patient between clinics modal - to be extracted */}
                        <FramerModal
                          {...{
                            open: openMoveRoomModal,
                            handleClose: () => setOpenMoveRoomModal(false),
                          }}
                        >
                          <Grid container direction="column" spacing={3}>
                            <Grid item>
                              <Typography>
                                {
                                  "Move the patient to another clinic's waiting room"
                                }
                              </Typography>
                            </Grid>
                            <Grid item>
                              <ClinicSelectDropDown
                                {...{
                                  callback: (selectedClinic: string) => {
                                    apiPost(
                                      `/clinic/patients/${patient.id}/move/${selectedClinic}`,
                                    )
                                      .then(() =>
                                        enqueueSnackbar(
                                          "Room changed successfully.",
                                          {
                                            variant: "success",
                                          },
                                        ),
                                      )
                                      .catch(console.error);

                                    setOpenMoveRoomModal(false);
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                onClick={() => {
                                  setOpenMoveRoomModal(false);
                                }}
                                variant="contained"
                                color="primary"
                              >
                                {"Close"}
                              </Button>
                            </Grid>
                          </Grid>
                        </FramerModal>
                        {/* End of move patient modal */}
                      </Grid>
                    )}

                    {patient.waitingRoomsVisited?.length > 1 &&
                      sessionStorage.getItem("orgId") && (
                        <Grid item>
                          <Button
                            onClick={() =>
                              handleShowPatientTransitions(patient)
                            }
                            variant="contained"
                            color="primary"
                          >
                            {"Patient Pathways"}
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>;
};

export default PatientsTable;
