export const authenticatorLinks = {
  "Microsoft Authenticator": [
    {
      title: "Windows Phone",
      href: "https://go.microsoft.com/fwlink/?Linkid=825071",
    },
    {
      title: "Android",
      href: "https://go.microsoft.com/fwlink/?Linkid=825072",
    },
    {
      title: "iOS",
      href: "https://go.microsoft.com/fwlink/?Linkid=825073",
    },
  ],
  "Google Authenticator": [
    {
      title: "Android",
      href: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en",
    },
    {
      title: "iOS",
      href: "https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8",
    },
  ],
};
