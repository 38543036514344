import React, { createContext, useState, useEffect, useContext } from "react";

// Hooks
import { usePrevious } from "hooks";

// Contexts
import { NotificationsContext } from "contexts";

// I18n
import { useTranslation } from "react-i18next";

export const PatientsContext = createContext();

export const PatientsProvider = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  // Context
  const [, setNotificationsState, , setNotificationsCount] = useContext(
    NotificationsContext,
  );

  const [patientsList, setPatientsList] = useState(null);

  const prev = usePrevious(patientsList);

  const notify = (clinic, patient) => {
    setNotificationsCount((prevState) => prevState + 1);
    setNotificationsState((prevState) => [
      ...prevState,
      {
        notifier: "PatientsProvider",
        title: `${t("NotificationTitle")}`,
        message: `${t("NotificationText")}`,
        link: `/clinic/${clinic}`,
        time: new Date(),
      },
    ]);

    // Send browser notification
    if (Notification?.permission === "granted") {
      // eslint-disable-next-line
      const notification = new Notification(`${t("NotificationTitle")}`, {
        body: `${t("NotificationText")}`,
      });

      // TODO: Work this out
      // notification.onclick = (e) => {
      //   e.preventDefault();
      //   history.push("/");
      //   // history.push(`/clinic/${clinic}`);
      //   // window.location.href = `${window.location.host}/clinic/${clinic}`;
      // };
    }
  };

  useEffect(() => {
    if (!patientsList) return;
    if (prev) {
      // Is it the first time app running?
      // patientsList[clinic] VERSUS prev[clinic]
      for (const clinic in patientsList) {
        // console.log(patientsList[clinic]); // Result for each clinic: [{...}, {...}]

        // Compare the patient ids in the current and prev patients array, notify if patient is online
        patientsList[clinic].forEach((patient) => {
          if (
            prev[clinic] &&
            !prev[clinic].find((obj) => obj.id === patient.id) &&
            patient.patientOnline
          ) {
            if ("Notification" in window) notify(clinic, patient);
          }
        });
      }
    } else {
      for (const clinic in patientsList) {
        patientsList[clinic].forEach((patient) => {
          if (patient.patientOnline) notify(clinic, patient);
        });
      }
    }
    // eslint-disable-next-line
  }, [patientsList]);

  return (
    <PatientsContext.Provider value={[patientsList, setPatientsList]}>
      {children}
    </PatientsContext.Provider>
  );
};
