import React, { useState, useEffect } from "react";

// Utils
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { log } from "helpers";

// Components
import {
  getDevices,
  monitorDevices,
  replacePeerTrack,
  addPeerTrack,
} from "common/videoCallModule";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3),
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "10",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  wrapper: {
    padding: theme.spacing(1),
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // borderRadius: "0.5rem",
    // overflow: "hidden",
    maxWidth: 600,
  },
}));

const VideoCallSettings = (props) => {
  const { peers, selfVideo, handleClose } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [devices, setDevices] = useState(null);
  useEffect(() => {
    (async () => {
      const result = await getDevices();
      setDevices(result);
      monitorDevices(monitorDevicesCallback);
    })();
  }, []);

  const monitorDevicesCallback = (data) => {
    setDevices(data);
  };

  const [formState, setFormState] = useState({});

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClickedOutside = (e) => {
    if (e.target === e.currentTarget) handleClose();
  };

  const setAudioInputDevice = async (id) => {
    try {
      if (!selfVideo.current.srcObject) throw new Error("Missing stream");
      //if ([("default", "communications")].includes(id))
      //  throw new Error("Default or communications device");

      const videoElement = selfVideo.current;
      const stream = videoElement.srcObject;
      let track = stream.getAudioTracks()[0];
      const isEnabled = track.enabled;

      track.stop();

      const constraint = {
        audio: { deviceId: { exact: id } },
      };

      const newStream = await navigator.mediaDevices.getUserMedia(constraint);
      const newTrack = newStream.getAudioTracks()[0];

      if (isEnabled !== undefined) newTrack.enabled = isEnabled;

      replacePeerTrack(peers, track, newTrack, stream);
      stream.removeTrack(track);
      stream.addTrack(newTrack);
      videoElement.load();
      videoElement.play();

      enqueueSnackbar("Audio input changed successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Problem changing audio input", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "error",
      });

      log({
        type: "err",
        text: "[Media -> Audio input]",
        params: err.message,
      });
    }
  };

  const setVideoInputDevice = async (id) => {
    try {
      if (!selfVideo.current.srcObject) throw new Error("Missing stream");
      //if ([("default", "communications")].includes(id))
      //  throw new Error("Default or communications device");

      const videoElement = selfVideo.current;
      const stream = videoElement.srcObject;

      if (stream.getVideoTracks().length == 0) {
        const constraint = {
          video: { deviceId: { exact: id } },
        };

        const newStream = await navigator.mediaDevices.getUserMedia(constraint);
        const newTrack = newStream.getVideoTracks()[0];

        addPeerTrack(peers, newTrack, stream);
        stream.addTrack(newTrack);
      } else {
        let track = stream.getVideoTracks()[0];
        const isEnabled = track.enabled;

        track.stop();

        const constraint = {
          video: { deviceId: { exact: id } },
        };

        const newStream = await navigator.mediaDevices.getUserMedia(constraint);
        const newTrack = newStream.getVideoTracks()[0];

        if (isEnabled !== undefined) newTrack.enabled = isEnabled;

        replacePeerTrack(peers, track, newTrack, stream);
        stream.removeTrack(track);
        stream.addTrack(newTrack);
      }

      videoElement.load();
      videoElement.play();

      enqueueSnackbar("Video input changed successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Problem changing video input", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "error",
      });

      log({
        type: "err",
        text: "[Media -> Video input]",
        params: err.message,
      });
    }
  };

  const setAudioOutputDevice = (id) => {
    try {
      //if ([("default", "communications")].includes(id))
      //  throw new Error("Default or communications device");

      const allVideoElements = document.querySelectorAll("video");

      allVideoElements.forEach(async (el) => {
        try {
          if (el.sinkId !== undefined) {
            log({
              text: `[Media -> Audio output] Current sinkId: [${el.sinkId}]`,
            });
            await el.setSinkId(id);
            log({
              text: `[Media -> Audio output] sinkId set: [${el.sinkId}]`,
            });
          } else {
            throw new Error("Couldn't perform setSinkId()");
          }
        } catch (err) {
          log({
            type: "err",
            text: "[Media -> Audio output]",
            params: err.message,
          });
        }
      });

      enqueueSnackbar("Audio output changed successfully", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Problem changing audio output", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "error",
      });

      log({
        type: "err",
        text: "[Media -> Audio output]",
        params: err.message,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const { audioinput, videoinput, audiooutput } = e.target;

      if (audioinput?.value) setAudioInputDevice(audioinput.value);
      if (videoinput?.value) setVideoInputDevice(videoinput.value);
      if (audiooutput?.value) setAudioOutputDevice(audiooutput.value);

      handleClose();
    } catch (err) {
      log({ type: err, params: err.message });
    }
  };

  return (
    <Box className={classes.root} onClick={handleClickedOutside}>
      <Card className={classes.wrapper}>
        <CardHeader
          title="Call settings"
          subheader="Change your device settings"
        />
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="audioinput">
                    {"Change audio input"}
                  </InputLabel>
                  <Select
                    label={"Change audio input"}
                    labelId="audioinput"
                    id="audioinput"
                    name="audioinput"
                    value={
                      formState.audioinput ||
                      // devices?.audioinput[0]?.deviceId ||
                      ""
                    }
                    onChange={handleChange}
                  >
                    {devices?.audioinput?.map((device, index) => (
                      <MenuItem value={device.deviceId} key={index}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="videoinput">
                    {"Change video input"}
                  </InputLabel>
                  <Select
                    label={"Change video input"}
                    labelId="videoinput"
                    id="videoinput"
                    name="videoinput"
                    value={
                      formState.videoinput ||
                      // devices?.videoinput[0]?.deviceId ||
                      ""
                    }
                    onChange={handleChange}
                  >
                    {devices?.videoinput?.map((device, index) => (
                      <MenuItem value={device.deviceId} key={index}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="audiooutput">
                    {"Change audio output"}
                  </InputLabel>
                  <Select
                    label={"Change audio output"}
                    labelId="audiooutput"
                    id="audiooutput"
                    name="audiooutput"
                    value={
                      formState.audiooutput ||
                      // devices?.audiooutput[0]?.deviceId ||
                      ""
                    }
                    onChange={handleChange}
                  >
                    {devices?.audiooutput?.map((device, index) => (
                      <MenuItem value={device.deviceId} key={index}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Button size="small" onClick={handleClose}>
              Close
            </Button>
            <Button size="small" type="submit">
              Set
            </Button>
          </CardActions>
        </form>
      </Card>
    </Box>
  );
};

VideoCallSettings.propTypes = {
  peers: PropTypes.object.isRequired,
  selfVideo: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default VideoCallSettings;
