import React, { useContext } from "react";

// Utils
import { useSnackbar } from "notistack";

// Components
import { FramerModal } from "components";

// API
import { apiPost, apiDelete } from "api";

// Contexts
import { ClinicsContext } from "contexts";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  Grid,
  Divider,
  Button,
  Switch,
} from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  clinicName: {
    fontWeight: 600,
  },
}));

const QuestionnaireAssign = (props) => {
  const { questionnaireId, questionnaireName, open, handleClose } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Contexts
  const [clinicsList] = useContext(ClinicsContext);

  const handleChange = async (e, clinicId, isAssigned, isRequired) => {
    const addEndpoint = `/clinic/${clinicId}/questionnaires`;
    const removeEndpoint = `/clinic/${clinicId}/questionnaires/${questionnaireId}`;

    try {
      if (e.target.checked) {
        await apiPost(addEndpoint, {
          questionnaireId,
          required: isRequired,
        });
      } else {
        await apiDelete(removeEndpoint);
      }
    } catch (err) {
      enqueueSnackbar("Problem assigning questionnaire", {
        variant: "error",
      });
      console.error(err);
    }
  };

  return <>
    <FramerModal {...{ open, handleClose }}>
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        wrap="nowrap"
        spacing={3}
      >
        <Grid item>
          <Typography variant="h5">
            {`${t("QuestionnaireAssignTitle")} - ${questionnaireName}`}
          </Typography>
          <Typography>
            {
              "Assign the questionnaire to the clinics to be filled in by the patient after check-in"
            }
          </Typography>
        </Grid>

        <Grid item>
          {clinicsList?.map((clinic) => {
            const { id: clinicId, name, questionnaires } = clinic;
            const isAssigned = questionnaires.some(
              (item) => item.id === questionnaireId,
            );
            const isRequired =
              isAssigned &&
              questionnaires.filter((item) => item.id === questionnaireId)[0][
                "required"
              ];

            return (
              <Box key={clinicId}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item>
                    <Typography className={classes.clinicName}>
                      {name}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Switch
                          defaultChecked={isAssigned}
                          name="status"
                          onChange={(e) =>
                            handleChange(e, clinicId, isRequired)
                          }
                          inputProps={{
                            "aria-label": "assign questionnaire",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            );
          })}
        </Grid>

        <Grid item>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                {t("Done")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FramerModal>
  </>;
};

export default QuestionnaireAssign;
