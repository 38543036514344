import React, { useState, useContext } from "react";

// Contexts
import { AccountContext } from "contexts";

// Components
import ProfileImageUploader from "./ProfileImageUploader";

// API
import { endpoints, apiPatch, apiGet } from "api";

// MUI
import {
  Button,
  Menu,
  MenuItem,
  Icon,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";

interface IProps {
  anchor: {
    el: any;
    type: "photo" | "cover";
  };
  setAnchor: React.Dispatch<
    React.SetStateAction<{
      el: any;
      type: null | "photo" | "cover";
    }>
  >;
}

export default function ButtonWithMenu(props: IProps) {
  const { anchor, setAnchor } = props;
  const [uploader, setUploader] = useState({
    show: false,
    endpoint: "",
  });

  const [accountData, setAccountData] = useContext(AccountContext);

  const handleUpload = () => {
    const destination = {
      photo: endpoints.profile.attachmentPhoto,
      cover: endpoints.profile.attachmentCover,
    };

    setUploader({
      show: true,
      endpoint: destination[anchor.type],
    });
  };

  const handleRemove = () => {
    const types = {
      photo: "profileImageUrl",
      cover: "bgImageUrl",
    };

    apiPatch(endpoints.profile.all, {
      [types[anchor.type]]: "",
    })
      .then(getProfileData)
      .catch(console.error);
  };

  const handleClose = () => {
    setAnchor({
      el: null,
      type: null,
    });
  };

  const getProfileData = () =>
    apiGet(endpoints.profile.all)
      .then((res: any) => {
        setAccountData((prevState: any) => ({
          ...prevState,
          profile: res.data,
        }));
      })
      .catch(console.error);

  return (
    <div>
      {uploader.show && (
        <ProfileImageUploader
          {...{
            uploader,
            setUploader,
            callback: getProfileData,
          }}
        />
      )}

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchor.el}
        keepMounted
        open={Boolean(anchor.el)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleUpload();
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Icon>upload</Icon>
            </Grid>
            <Grid item>
              <Typography>Upload new</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleRemove();
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Icon>delete</Icon>
            </Grid>
            <Grid item>
              <Typography>Remove photo</Typography>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  );
}
