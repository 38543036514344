import React, { useState } from "react";

// Utils
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  TableRow,
  TableCell,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Link,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";

// Date
import { format } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > *": {
      borderBottom: "unset",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const Row = (props) => {
  const { patient, setSelectedAttachment, setSelectedMp4Attachment } = props;

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [openCollapse, setOpenCollapse] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onClick={() => setOpenCollapse((prevState) => !prevState)}
        style={{ cursor: "pointer" }}
      >
        <TableCell>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: openCollapse,
            })}
            aria-label="expand row"
            size="small"
          >
            <ExpandMoreOutlinedIcon />
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" style={{ verticalAlign: "top" }}>
          <Typography variant="body1">{patient.name}</Typography>
        </TableCell>

        <TableCell style={{ verticalAlign: "top" }}>
          <Typography variant="body1">
            {format(new Date(patient.doB), "P", {
              locale: supportedLocales[i18n.language],
            })}
          </Typography>
        </TableCell>

        <TableCell style={{ verticalAlign: "top" }}>
          <Typography variant="body1">{patient.phoneNumber}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ verticalAlign: "top" }} colSpan={4}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Box ml={8}>
                {patient?.attachments?.map((attachment, index) => {
                  const { name, fileUrl, fileID } = attachment;
                  const fileExtension = name.split(".").pop();
                  return (
                    <div key={fileID}>
                      {["png", "jpg", "jpeg"].includes(fileExtension) && (
                        <Link
                          onClick={() =>
                            setSelectedAttachment({
                              fileIndex: index,
                              fileName: name,
                              fileUrl,
                              fileType: "image",
                            })
                          }
                        >
                          <ListItem button className={classes.nested}>
                            <ListItemIcon>
                              <AttachmentOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={name} />
                          </ListItem>
                        </Link>
                      )}

                      {["pdf", "mov"].includes(fileExtension) && (
                        <Link component="a" href={fileUrl} target="_blank">
                          <ListItem button className={classes.nested}>
                            <ListItemIcon>
                              <AttachmentOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={name} />
                          </ListItem>
                        </Link>
                      )}

                      {["mp4"].includes(fileExtension) && (
                        <Link
                          onClick={() =>
                            setSelectedMp4Attachment({
                              fileUrl,
                            })
                          }
                        >
                          <ListItem button className={classes.nested}>
                            <ListItemIcon>
                              <AttachmentOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={name} />
                          </ListItem>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </Box>
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
