import React, { useState, useContext, useEffect } from "react";

// Utils
import PropTypes from "prop-types";

// Contexts
import { AccountContext } from "contexts";

// Time
import { format, formatISO } from "date-fns";

// API
import { apiGet, apiPost, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Icon,
  Tooltip,
  Box,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import TextsmsIcon from "@mui/icons-material/Textsms";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

// Modal
import { FramerModal } from "components";

// Animation
import { motion, AnimatePresence } from "framer-motion";

// Snackbar
import { useSnackbar } from "notistack";

//  Date & time picker
import { DateTimePicker } from "@material-ui/pickers";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: { whiteSpace: "nowrap" },
  form: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

// Framer Motion animation
const invitationComponent = {
  hidden: { y: -100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.1 },
  },
};

export const InviteToRoom2 = (props) => {
  const { roomId, showCallInviteModal, setShowCallInviteModal } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const [accountData] = useContext(AccountContext);

  const [defaultCountryCode, setDefaultCountryCode] = useState("");

  // const [open, setOpen] = useState(false);
  const [supportedSmsCountries, setSupportedSmsCountries] = useState([]);
  const [invitationMethod, setInvitationMethod] = useState({ 0: "email" });
  const [numberOfInvitations, setNumberOfInvitations] = useState(1);
  const [invitations, setInvitations] = useState({
    0: {},
  });

  const [roomURL, setRoomURL] = useState(null);
  // Add the formState here to collect textField data to post to invite by sms/email endpoint

  // useEffect(() => {
  //   if (!roomId) return;
  //   getInvCodeByRoomID();
  //   // eslint-disable-next-line
  // }, [roomId]);

  const copyToClipboard = async () => {
    try {
      const response = await apiGet(
        `${endpoints.clinic.roomURL1}${roomId}${endpoints.clinic.roomURL2}`,
      );

      if (response.status === 200 && response.data) {
        await navigator.clipboard.writeText(response.data);
        enqueueSnackbar("Invitation link copied successfully", {
          variant: "info",
        });
      } else {
        enqueueSnackbar("There's a problem getting invitation URL", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
      enqueueSnackbar("Failed to copy", { variant: "error" });
    }
  };

  useEffect(() => {
    // Get all supported countries
    apiGet(endpoints.config.smsCountries)
      .then((res) => setSupportedSmsCountries(res.data))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (!accountData) return;
    getDefaultCountryCode();
  }, [accountData]);

  const getDefaultCountryCode = async () => {
    try {
      const response = await apiGet(endpoints.account.countries);
      const listOfAllCountries = response.data;

      for (let i = 0; i < listOfAllCountries.length; i++) {
        const country = listOfAllCountries[i];
        if (country.isoCode === accountData.clinicDefaults.country) {
          setDefaultCountryCode(country.countryCode);
          return;
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInvitationMethod = (e, value, index) => {
    if (value !== null) {
      if (value === "phone") {
        // Extract one pass the rest
        const { emailAddress, ...rest } = invitations[index];

        setInvitations((prevState) => ({
          ...prevState,
          [index]: { ...rest },
        }));

        // TODO: Conditionally delete the items below
        // invitations[index].email
        // (invitations[index].phone, invitations[index].countryCode);
      } else {
        const { phoneNumber, prefix, ...rest } = invitations[index];

        setInvitations((prevState) => ({
          ...prevState,
          [index]: { ...rest },
        }));
      }

      setInvitationMethod((prevState) => ({
        ...prevState,
        [index]: value,
      }));
      return;
    }
    return;
  };

  const handleClose = () => {
    setShowCallInviteModal(false);
    // Clearing all states on close
    setInvitationMethod({ 0: "email" });
    setInvitations({ 0: {} });
    setNumberOfInvitations(1);
  };

  const handleChange = (e, index) => {
    setInvitations((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleInvitePatient = async (e) => {
    e.preventDefault();
    try {
      handleClose();
      let payload = invitations[0];

      if (payload.phoneNumber) {
        if (!payload.prefix) {
          payload.prefix = defaultCountryCode;
        }
      }

      const response = await apiPost(
        `${endpoints.clinic.roomInvite1}${roomId}${endpoints.clinic.roomInvite2}`,
        payload,
      );
      if (response.status === 200) {
        enqueueSnackbar("Invitation sent successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Unable to send the invitation", {
          variant: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("There was a problem sending invitation", {
        variant: "error",
      });
    }
  };

  // More about this here: https://stackoverflow.com/a/57458475/9221167
  const handleIncrementInvitations = () => {
    // Make a new invitation in the state
    setInvitations((prevState) => ({
      ...prevState,
      [numberOfInvitations]: {},
    }));

    // Make a new invitation method in the state
    setInvitationMethod((prevState) => ({
      ...prevState,
      [numberOfInvitations]: "email",
    }));

    setNumberOfInvitations((prevState) => prevState + 1);
  };

  const handleDateTimePicker = (expectedJoinDate, index) => {
    setInvitations((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        expectedJoinDate: formatISO(new Date(expectedJoinDate)),
      },
    }));
  };

  return (
    <FramerModal {...{ open: showCallInviteModal, handleClose }}>
      <Typography variant="h5" gutterBottom>
        {"Room Invitation"}
      </Typography>
      <Divider />
      <Box mt={3} mb={3}>
        <Typography gutterBottom>
          You can invite up to 2 other people to join the call with your patient
          either by sharing the link or sending an email or SMS invitation.
        </Typography>
      </Box>

      <Button
        // variant="contained"
        color="secondary"
        aria-label="copy invitation link"
        startIcon={<FileCopyIcon />}
        onClick={copyToClipboard}
      >
        Copy invitation link
      </Button>

      <Box mt={3} mb={3}>
        <Typography gutterBottom>Or send an invitation:</Typography>
      </Box>

      <form className={classes.form} onSubmit={handleInvitePatient}>
        {/* {Array(numberOfInvitations).fill( */}
        {Array.from({ length: numberOfInvitations }, (_, index) => (
          <AnimatePresence key={index}>
            <motion.div
              variants={invitationComponent}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <Grid container spacing={1}>
                <Grid item>
                  <TextField
                    fullWidth
                    label={t("PatientFullName")}
                    name="operatorName"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e, index)}
                    // value={invitations[index].operatorName}
                    style={{
                      width: "25ch",
                    }}
                    required
                  />
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    value={invitationMethod[index]}
                    size="large"
                    exclusive
                    onChange={(e, value) =>
                      handleInvitationMethod(e, value, index)
                    }
                    aria-label="invitation method"
                  >
                    <Tooltip title={t("InvitePatientViaEmail")} arrow>
                      <ToggleButton value="email" aria-label="email">
                        <AlternateEmailIcon />
                      </ToggleButton>
                    </Tooltip>

                    <Tooltip
                      title={
                        accountData?.features?.sms
                          ? t("InvitePatientViaSMS")
                          : t("AccountUpgradeToUseFeature")
                      }
                      arrow
                    >
                      {accountData?.features?.sms ? (
                        <ToggleButton
                          value="phone"
                          aria-label="phone"
                          disabled={false}
                        >
                          <TextsmsIcon />
                        </ToggleButton>
                      ) : (
                        // Without surrounding div the disabled button will not trigger tooltip
                        <Box>
                          <ToggleButton
                            value="phone"
                            aria-label="phone"
                            disabled={true}
                            size="large"
                          >
                            <TextsmsIcon />
                          </ToggleButton>
                        </Box>
                      )}
                    </Tooltip>
                  </ToggleButtonGroup>
                </Grid>

                {invitationMethod[index] === "phone" ? (
                  <>
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel id="prefix-label">
                          {t("InvitationCountryCode")}
                        </InputLabel>
                        <Select
                          labelId="prefix-label"
                          id="prefix"
                          value={
                            invitations[index].prefix ||
                            defaultCountryCode ||
                            ""
                          }
                          name="prefix"
                          onChange={(e) => handleChange(e, index)}
                          label={t("InvitationCountryCode")}
                          style={{
                            width: "15ch",
                          }}
                        >
                          {supportedSmsCountries.map((countryCode) => {
                            return (
                              <MenuItem value={countryCode} key={countryCode}>
                                {countryCode}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label={t("PatientPhone")}
                        name="phoneNumber"
                        type="text"
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)}
                        value={invitations[index].phoneNumber || ""}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <TextField
                      fullWidth
                      label={t("PatientEmail")}
                      name="emailAddress"
                      type="text"
                      variant="outlined"
                      onChange={(e) => handleChange(e, index)}
                      value={invitations[index].emailAddress || ""}
                    />
                  </Grid>
                )}
              </Grid>
            </motion.div>
          </AnimatePresence>
        ))}

        <Grid container justifyContent="flex-end" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<Icon>send</Icon>}
            >
              {t("PatientInviteButton")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FramerModal>
  );
};

InviteToRoom2.propTypes = {
  roomId: PropTypes.string.isRequired,
  // variant: PropTypes.number,
  // color: PropTypes.string,
};

InviteToRoom2.defaultProps = {
  // variant: 1,
  // color: "primary",
};

// export default InviteToRoom2;
