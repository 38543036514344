import axios from "axios";

export class Client {
  static instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN || "",
    withCredentials: true,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
      ...(sessionStorage.getItem("orgId") && {
        "X-OrganisationID": sessionStorage.getItem("orgId"),
      }),
    },
  });
}
