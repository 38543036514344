import React, { useState, useEffect } from "react";

// Utils
import { useSnackbar } from "notistack";
import validate from "validate.js";

// API
import { apiPost, endpoints } from "api";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(5, 3),
    padding: theme.spacing(4),
    border: "1px solid #e6e6e6",
    borderRadius: "1rem",
    maxWidth: 512,
  },
  formControl: {
    width: "100%",
  },
}));

const schema = {
  title: {
    presence: { allowEmpty: false, message: "is required" },
  },
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  phoneNumber: {
    // numericality: true,
    // presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   maximum: 32,
    // },
  },
};

const ClinicianInvitationForm = (props) => {
  const { callback } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const { t } = useTranslation();

  const initialFormData = {
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  };

  const [formState, setFormState] = useState(initialFormData);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await apiPost(
        endpoints.account.referralInvite,
        formState.values
      );

      if (response?.status === 200) {
        setFormState(initialFormData);

        enqueueSnackbar("Invitation sent successfully", {
          variant: "success",
        });

        callback();
      } else {
        enqueueSnackbar("Problem sending invitation", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("Problem sending invitation", {
        variant: "error",
      });

      console.error(err);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">{"Send an invitation"}</Typography>
        </Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            required
            className={classes.formControl}
            error={hasError("title")}
          >
            <InputLabel id="title">{"Title"}</InputLabel>
            <Select
              label="Title"
              labelId="title"
              id="title"
              name="title"
              value={formState.values.title || ""}
              onChange={handleChange}
            >
              <MenuItem value="Mr">Mr</MenuItem>
              <MenuItem value="Ms">Ms</MenuItem>
              <MenuItem value="Mrs">Mrs</MenuItem>
            </Select>

            {hasError("title") && (
              <FormHelperText id="title">
                {formState.errors.title}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item>
          <TextField
            name="firstName"
            label={"First Name"}
            error={hasError("firstName")}
            helperText={
              hasError("firstName")
                ? formState.errors.firstName.map((err, index) => {
                    return <li key={index}>{err}</li>;
                  })
                : null
            }
            type="text"
            variant="outlined"
            value={formState.values.firstName || ""}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        <Grid item>
          <TextField
            name="lastName"
            label={"Last Name"}
            error={hasError("lastName")}
            helperText={
              hasError("lastName")
                ? formState.errors.lastName.map((err, index) => {
                    return <li key={index}>{err}</li>;
                  })
                : null
            }
            type="text"
            variant="outlined"
            value={formState.values.lastName || ""}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        <Grid item>
          <TextField
            name="email"
            label={"Email"}
            error={hasError("email")}
            helperText={
              hasError("email")
                ? formState.errors.email.map((err, index) => {
                    return <li key={index}>{err}</li>;
                  })
                : null
            }
            type="text"
            variant="outlined"
            value={formState.values.email || ""}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>

        <Grid item>
          <TextField
            name="phoneNumber"
            label={"Phone number"}
            error={hasError("phoneNumber")}
            helperText={
              hasError("phoneNumber")
                ? formState.errors.phoneNumber.map((err, index) => {
                    return <li key={index}>{err}</li>;
                  })
                : null
            }
            type="text"
            variant="outlined"
            value={formState.values.phoneNumber || ""}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={!formState.isValid}
          >
            {"Send invitation"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClinicianInvitationForm;
