import React from "react";

// Utils
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Typography, LinearProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    color: "white",
  },
  progressBar: {
    width: "100%",
    height: "0.5rem",
  },
}));

const WaitingForClinician = (props) => {
  const { text, isClinicianOnline } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      className={classes.root}
    >
      <Grid item container alignItems="center" justifyContent="center" xs={11}>
        <Box p={3} m={3}>
          <Typography variant="h6">{text}</Typography>
        </Box>
      </Grid>

      <Grid item container alignItems="center" justifyContent="center">
        <LinearProgress
          className={classes.progressBar}
          color={isClinicianOnline ? "secondary" : "primary"}
        />
      </Grid>
    </Grid>
  );
};

WaitingForClinician.propTypes = {
  isClinicianOnline: PropTypes.bool,
};

WaitingForClinician.defaultProps = {
  isClinicianOnline: false,
};

export default WaitingForClinician;
