import React, { useState } from "react";

// API
import { apiPatch, endpoints } from "api";

// MUI
import { Grid, FormControlLabel, Switch } from "@mui/material";

const ProfileActionButtons = ({ isPublished }) => {
  const [state, setState] = useState({
    isPublished,
  });

  const handleChange = (e) => {
    const fieldName = e.target.name;

    setState((prevState) => ({
      ...prevState,
      [fieldName]: e.target.checked,
    }));

    apiPatch(endpoints.profile.all, { [fieldName]: e.target.checked })
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          [fieldName]: res.data[fieldName],
        }));
      })
      .catch(console.error);
  };

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={state.isPublished}
              onChange={handleChange}
              name="isPublished"
              inputProps={{ "aria-label": "isPublished" }}
            />
          }
          label="Published"
        />
      </Grid>
    </Grid>
  );
};

ProfileActionButtons.defaultProps = {
  isPublished: false,
};
export default ProfileActionButtons;
