import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IProps {
  openPatientEndCallConfirm: boolean;
  handlePatientEndCallSubmit: () => void;
  handlePatientEndCallCancel: () => void;
}

const PatientEndCallDialog = (props: IProps) => {
  const {
    openPatientEndCallConfirm,
    handlePatientEndCallSubmit,
    handlePatientEndCallCancel,
  } = props;

  return (
    <div>
      <Dialog
        open={openPatientEndCallConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePatientEndCallCancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"End Call"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This operation will disconnect you from this call. Are you sure you
            want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePatientEndCallCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePatientEndCallSubmit} color="primary">
            End call
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatientEndCallDialog;
