import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { CommonDialog } from "../../Common/CommonDialog";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { default as QRCode } from "qrcode.react";
import { authenticatorLinks } from "./2fa.app.links";
import { ActivateContainer, LinksContainer } from "./TwoFactorAut.styles";
import { LinksSection, DisableContainer } from "./TwoFactorAut.styles";
import TextField from "@mui/material/TextField";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

export enum View {
  ACTIVATE = "ACTIVATE",
  DISABLE = "DISABLE",
}

interface ChangePasswordFormProps {
  onActivate: (event: IFormInput) => void;
  onDisable: (event: IFormInput) => void;
  sharedKey: string | undefined;
  authenticatorUri: string | undefined;
  view: View;
}

interface IFormInput {
  code: string;
}

const schema = Joi.object<IFormInput>({
  code: Joi.string().required(),
});

export const TwoFactorAuthForm = ({
  sharedKey = "",
  authenticatorUri,
  onActivate,
  onDisable,
  view = View.ACTIVATE,
}: ChangePasswordFormProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { register, handleSubmit, reset, formState } = useForm<IFormInput>({
    resolver: joiResolver(schema),
  });

  // resets for feilds on dialog close
  useEffect(() => reset(), [open]);

  const submit: SubmitHandler<IFormInput> = (data) => {
    if (view === View.ACTIVATE) onActivate(data);
    if (view === View.DISABLE) onDisable(data);
    setOpen(false);
    reset();
  };

  return (
    <CommonDialog
      title="Two Factor Autentcation"
      onSubmit={handleSubmit(submit)}
      buttonText={view === View.ACTIVATE ? "Set up" : "Disable"}
      {...{ open, setOpen }}
    >
      <>
        {view === View.ACTIVATE && (
          <ActivateContainer>
            <Typography>{t("EnableAuthenticator1")}</Typography>
            <Typography> {t("EnableAuthenticator2")}</Typography>
            <AuthLinks />
            <Typography>
              {t("EnableAuthenticator3")} {t("EnableAuthenticator4")}
            </Typography>
            <Typography>
              <b>{sharedKey.toUpperCase()}</b>
            </Typography>
            <QRCode value={authenticatorUri || ""} size={128} />
            <Typography>{t("EnableAuthenticator5")}</Typography>
          </ActivateContainer>
        )}

        {view === View.DISABLE && (
          <DisableContainer>
            <Typography variant="h6">{t("DisableAuthenticator1")}</Typography>
            <Typography variant="body1">
              {t("DisableAuthenticator2")} {t("DisableAuthenticator3")}
            </Typography>
          </DisableContainer>
        )}

        {/* same field is needed for both views */}
        <TextField
          {...register("code")}
          label={formState?.errors?.code?.message || t("AuthenticatorCode")}
          error={!!formState?.errors?.code}
        />
      </>
    </CommonDialog>
  );
};

const AuthLinks = () => {
  return (
    <LinksContainer>
      {Object.entries(authenticatorLinks).map((app, k) => {
        return (
          <LinksSection key={k}>
            <Typography>
              <b>{app[0]}</b>
            </Typography>
            {app[1].map(({ title, href }, k) => (
              <a key={k} href={href} target="_blank" rel="noreferrer">
                <Typography> {title}</Typography>
              </a>
            ))}
          </LinksSection>
        );
      })}
    </LinksContainer>
  );
};
