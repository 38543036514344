import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Tooltip, IconButton, Icon } from "@mui/material";
import { pink, grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    // opacity: 0,
    backgroundColor: grey[100],
    color: grey[500],
    "&:hover": {
      color: grey[600],
      backgroundColor: grey[200],
      // transition: "ease-in-out 0.33s",
    },
  },
  header: {
    marginBottom: theme.spacing(1),
    // "&:hover $editButton": {
    //   transition: "ease-in-out 0.33s",
    //   opacity: 1,
    // },
  },
}));

const HeaderWithEdit = ({ title, variant, tooltip, handleClick, isAdding }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="center"
      className={classes.header}
    >
      <Grid item>
        <Typography variant={variant ? variant : "h4"}>{title}</Typography>
      </Grid>

      <Grid item>
        <Tooltip
          title={
            tooltip ? tooltip : isAdding ? `Add ${title}` : `Edit ${title}`
          }
          arrow
        >
          <IconButton
            className={classes.iconButton}
            size="medium"
            onClick={handleClick}
          >
            {isAdding ? <Icon>add</Icon> : <Icon>edit</Icon>}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

HeaderWithEdit.defaultProps = {
  isAdding: false,
};

export default HeaderWithEdit;
