import React, { useState, useEffect } from "react";

// API
import { apiGet, endpoints } from "api";

// MUI
import {
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Icon,
  Typography,
} from "@mui/material";
import { Autocomplete } from '@mui/material';

//  Date picker
import { DatePicker } from "@material-ui/pickers";

const QualEditorFields = ({
  propName,
  editorData,
  handleSave,
  handleDelete,
}) => {
  const itemLabels = {
    qualifications: "Qualification",
    licenses: "License",
    memberships: "Membership",
    treatments: "Treatment",
    clinicalInterests: "Clinical Interest",
    specialities: "Speciality",
  };

  const defaultValues = {
    name: "",
    issuer: "",
    country: "",
    state: "",
    number: "",
    url: "",
    issueDate: null,
    expirationDate: null,
  };
  const [formData, setFormData] = useState(
    editorData ? editorData : defaultValues,
  );

  // Fetching options for autocomplete
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  useEffect(() => {
    apiGet(endpoints.profile.list[propName])
      .then((res) => {
        setAutocompleteOptions(res.data);
      })
      .catch(console.error);
  }, []);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeCheckboxDate = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value === true ? null : new Date(),
    }));
  };

  const handleChangeDate = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeAutocomplete = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      spacing={2}
      style={{ maxWidth: 500 }}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>{`${
          editorData ? "Edit" : "Add"
        } a ${itemLabels[propName]}`}</Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          freeSolo
          fullWidth
          options={autocompleteOptions}
          // value={formData.name}
          defaultValue={formData.name || ""}
          onChange={(_, value) => handleChangeAutocomplete("name", value)}
          onInputChange={(_, value) => handleChangeAutocomplete("name", value)}
          renderInput={(params) => (
            // Wrapping TextField in a form to deactivate autocomplete
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <TextField
                {...params}
                label={itemLabels[propName]}
                variant="outlined"
                required
              />
            </form>
          )}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Issuer"
          name="issuer"
          value={formData.issuer}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Country"
          name="country"
          value={formData.country}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="State"
          name="state"
          value={formData.state}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Number"
          name="number"
          value={formData.number}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="URL"
          name="url"
          value={formData.url}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="issueDate"
                checked={!formData.issueDate}
                onChange={(e, value) =>
                  handleChangeCheckboxDate(e.target.name, value)
                }
                color="primary"
              />
            }
            label="No Issue Date"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="expirationDate"
                checked={!formData.expirationDate}
                onChange={(e, value) =>
                  handleChangeCheckboxDate(e.target.name, value)
                }
                color="primary"
              />
            }
            label="No Expiration Date"
          />
        </FormGroup>
      </Grid>

      {formData.issueDate && (
        <Grid item>
          <DatePicker
            variant="inline"
            openTo="year"
            format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            autoOk
            inputVariant="outlined"
            label="Issue date"
            value={formData.issueDate}
            onChange={(value) => {
              handleChangeDate("issueDate", value);
            }}
          />
        </Grid>
      )}

      {formData.expirationDate && (
        <Grid item>
          <DatePicker
            variant="inline"
            openTo="year"
            format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            autoOk
            inputVariant="outlined"
            label="Expiration date"
            value={formData.expirationDate}
            onChange={(value) => {
              handleChangeDate("expirationDate", value);
            }}
          />
        </Grid>
      )}

      {/* Actions */}
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1rem" }}
        spacing={3}
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Icon>delete</Icon>}
            disabled={!editorData}
            onClick={() => handleDelete(editorData.id)}
          >
            {"Delete"}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            disabled={!formData.name}
            onClick={() => {
              handleSave(formData);
            }}
          >
            {"Save"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QualEditorFields;
