import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Client } from "./client";
import { HTTPStatus } from "./api-new.interface";

interface ChangeEmailReq {
  email: string;
}

interface ChangePasswordReq {
  oldPassword: string;
  newPassword: string;
}

interface GetEnableMfsRes {
  authenticatorUri: string;
  sharedKey: string;
}

interface PostMFAReq {
  code: string;
}

interface PostSettingsRes {
  region?: string;
  expiration?: string;
  timezone?: string;
  country?: string;
  audioMessagesEnabled?: boolean;
  promptForDoB?: boolean;
  promptForPhoneNumber?: boolean;
  availabilityProfileId?: string;
  cryptoSetting?: {
    walletVerificationId: string;
    enableCryptoPayment: boolean;
  };
}

interface ConfirmEmailProps {
  id: string;
  code: string;
}

const endpoint = {
  changeEmail: "/account/changeemail",
  confirmEmail: "/account/confirmemailchange",
  changePassword: "/account/changepassword",
  getMFA: "/account/enablemfa",
  postMFA: "/account/enablemfa",
  postDisableMFA: "/account/disablemfa",
  settings: "/account/settings",
  logout: "/account/logout",
};

/***************
 * API Promise *
 ***************/

export const promisePostSettings = async (params: PostSettingsRes) => {
  try {
    const { data } = await Client.instance.post(endpoint.settings, params);
    return data || "OK";
  } catch (e) {
    throw "Could not update settings";
  }
};

export const promisePostLogout = async () => {
  try {
    const { data } = await Client.instance.post(endpoint.logout, {});
    return data || "OK";
  } catch (e) {
    throw "Could not logout";
  }
};

/***************
 * API Hooks   *
 ***************/

export const usePostChangeEmail = () => {
  return useMutation(async ({ email }: ChangeEmailReq) => {
    try {
      const { data } = await Client.instance.post(endpoint.changeEmail, {
        NewEmail: email,
      });
      return data || "OK";
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e.response?.status;
        if (status === HTTPStatus.BAD_REQUEST)
          throw "Invalid new email address";
        if (status === HTTPStatus.FORBIDDEN)
          throw "The new email is already in use";
        if (status === HTTPStatus.BAD_REQUEST)
          throw "Invalid new email address";
      }

      throw "Internal server error";
    }
  });
};

export const usePostChangePassword = () => {
  return useMutation(async (form: ChangePasswordReq) => {
    try {
      const changePassword = endpoint.changePassword;
      const { data } = await Client.instance.post(changePassword, form);
      return data || "OK";
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // Backend error response is terrible
        // not error handling properly until its sorted
        if (typeof e.response?.data?.detail === "string") {
          throw e.response?.data?.detail;
        }
      }

      throw "Internal server error";
    }
  });
};

export const usePostMFA = () => {
  return useMutation(async (form: PostMFAReq) => {
    try {
      const { data } = await Client.instance.post(endpoint.postMFA, form);
      return data || "OK";
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (typeof e.response?.data?.detail === "string") {
          throw e.response?.data?.detail;
        }

        throw "Could not enable MFA";
      }

      throw "Internal server error";
    }
  });
};

export const usePostDisableMFA = () => {
  return useMutation(async (form: PostMFAReq) => {
    try {
      const { data } = await Client.instance.post(
        endpoint.postDisableMFA,
        form
      );
      return data || "OK";
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (typeof e.response?.data?.detail === "string") {
          throw e.response?.data?.detail;
        }

        throw "Could not disable MFA";
      }

      throw "Internal server error";
    }
  });
};

export const useGetMFACode = () => {
  return useQuery<GetEnableMfsRes>("MFACode", async () => {
    try {
      const { data, status } = await Client.instance.get(endpoint.getMFA);
      if (status !== HTTPStatus.OK) throw "";
      return data;
    } catch {
      throw "Unable to get MFA code";
    }
  });
};

export const usePostSettings = () => {
  return useMutation(async (form: PostSettingsRes) => {
    try {
      const { data, status } = await Client.instance.post(
        endpoint.settings,
        form
      );
      if (status !== HTTPStatus.OK) throw "";
      return data;
    } catch {
      throw "Unable to update settings";
    }
  });
};

export const usePostLogout = () => {
  return useMutation(async () => {
    try {
      const { data, status } = await Client.instance.post(endpoint.logout);
      if (status !== HTTPStatus.OK) throw "";
      return data || "OK";
    } catch {
      throw "Unable to logout";
    }
  });
};

export const confirmEmailChange = () => {
  return useMutation(async (form: ConfirmEmailProps) => {
    try {
      const { data, status } = await Client.instance.post(
        endpoint.confirmEmail,
        form
      );
      if (status !== HTTPStatus.OK) throw "";
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (typeof e.response?.data?.detail === "string") {
          throw e.response?.data?.detail;
        }
      }
      throw "Unable to change email, please go try again";
    }
  });
};
