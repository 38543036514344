import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";

export const Container = styled(Grid)(({ theme }) => ({
  height: "100%",
}));

export const ImageContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
}));

export const Image = styled("div")(({ theme }) => ({
  //@ts-ignore
  backgroundColor: theme.palette.neutral,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: "url(/images/auth.webp)",
  // backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

export const Body = styled(Grid)(({ theme }) => ({}));

export const BodySection = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBototm: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const Form = styled("div")(({ theme }) => ({
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 100,
  flexBasis: 700,
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
