import React from "react";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

// Date & Time
import { formatDistance } from "date-fns";

import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

const useStyles = makeStyles((theme) => ({
  root: {},
  svgIcon: {
    fontSize: "0.5rem",
    width: "100%",
    height: "100%",
    color: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    strokeWidth: 1,
  },
  svgIconContainer: {
    marginRight: 5,
    paddingTop: "4px !important",
    paddingLeft: "0px !important",
    paddingBottom: "4px !important",
    border: "2px solid",
    borderRadius: "50%",
    borderColor: theme.palette.primary.main,
    width: "1.75rem",
    height: "1.75rem",
  },
}));

const ClinicTerm = (props) => {
  const { clinicTerm, clinicExpiration } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid item className={classes.svgIconContainer}>
        {clinicTerm !== "Permanent" ? (
          <HourglassEmptyIcon color="action" className={classes.svgIcon} />
        ) : (
          <DoneIcon color="action" className={classes.svgIcon} />
        )}
      </Grid>

      <Grid item>
        {clinicTerm === "Short" ? (
          <Typography color="textSecondary">
            {`${t("ClinicExpires")} ${formatDistance(
              new Date(clinicExpiration),
              new Date(),
              {
                addSuffix: true,
                locale: supportedLocales[i18n.language],
              }
            )}`}
          </Typography>
        ) : clinicTerm === "Long" ? (
          <Typography color="textSecondary">{"No Expiration"}</Typography>
        ) : (
          <Typography color="textSecondary">
            {t("ClinicTermPermanent")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

ClinicTerm.propTypes = {
  clinicTerm: PropTypes.string.isRequired,
  // clinicExpiration: PropTypes.string.isRequired
};

ClinicTerm.defaultProps = {
  clinicTerm: "",
  clinicExpiration: null,
};

export default ClinicTerm;
