import React from "react";

// Components
import Unspecified from "./Unspecified";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Icon,
  Link,
  Grid,
  IconButton,
  Paper,
  Box,
} from "@mui/material";
import { grey } from "@mui/material/colors";

// Date
import { format } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: grey[50],
    maxWidth: 400,
  },
  iconButton: {
    color: grey[400],
    "&:hover": {
      color: grey[500],
      backgroundColor: "unset",
    },
  },
}));

const QualContent = ({ data, propName, handleOpenEditor, handleReorder }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const propIcons = {
    qualifications: "school",
    licenses: "shield",
    memberships: "badge",
  };

  return (
    <Grid container direction="column" spacing={3}>
      {data.length > 0 ? (
        data
          .sort((a, b) => a.order - b.order)
          .map((prop, index) => {
            return (
              <Grid item xs={12} key={prop.id}>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Grid item xs={9}>
                      <List key={prop.id}>
                        <ListItem
                        // alignItems="flex-start"
                        // divider={index < data.length - 1} // Exclude the last item
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <Icon>{propIcons[propName]}</Icon>
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText>
                            <Grid container direction="column">
                              <Grid item>
                                <Typography color="textSecondary">
                                  <strong>
                                    {prop.url ? (
                                      <Link
                                        href={prop.url}
                                        target="_blank"
                                        rel="noopener"
                                        color="textSecondary"
                                      >
                                        {prop.name}
                                      </Link>
                                    ) : (
                                      prop.name
                                    )}
                                  </strong>
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textSecondary"
                                  style={{ display: "block" }}
                                >
                                  {prop.issuer ? prop.issuer : ""}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textSecondary"
                                  style={{ display: "block" }}
                                >
                                  {`${prop.state ? `${prop.state}, ` : ""}${
                                    prop.country ? prop.country : ""
                                  }`}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textSecondary"
                                  style={{ display: "block" }}
                                >
                                  {prop.number ? `Number: ${prop.number}` : ""}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textSecondary"
                                  style={{ display: "block" }}
                                >
                                  {prop.issueDate
                                    ? `Issue date: ${format(
                                        new Date(prop.issueDate),
                                        "MMM y",
                                        {
                                          locale:
                                            supportedLocales[i18n.language],
                                        },
                                      )}`
                                    : ""}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  color="textSecondary"
                                  style={{ display: "block" }}
                                >
                                  {prop.expirationDate
                                    ? `Expiration date: ${format(
                                        new Date(prop.expirationDate),
                                        "MMM y",
                                        {
                                          locale:
                                            supportedLocales[i18n.language],
                                        },
                                      )}`
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Grid>

                    {/* Action buttons */}
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      wrap="nowrap"
                      xs={3}
                    >
                      {index != 0 && (
                        <Grid item>
                          <IconButton
                            size="small"
                            className={classes.iconButton}
                            onClick={() => handleReorder(index, index - 1)}
                          >
                            <Icon>arrow_upward</Icon>
                          </IconButton>
                        </Grid>
                      )}

                      {index < data.length - 1 && (
                        <Grid item>
                          <IconButton
                            size="small"
                            className={classes.iconButton}
                            onClick={() => handleReorder(index, index + 1)}
                          >
                            <Icon>arrow_downward</Icon>
                          </IconButton>
                        </Grid>
                      )}

                      <Grid item>
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          onClick={() => handleOpenEditor(prop)}
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })
      ) : (
        <Unspecified />
      )}
    </Grid>
  );
};

export default QualContent;
