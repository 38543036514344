import React, { useState, useEffect } from "react";

// API
import { apiPost, endpoints, authMessages } from "api";

// Router
import { withRouter } from "react-router-dom";

// Utils
import PropTypes from "prop-types";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { GoogleLogin } from "react-google-login";
import { log } from "helpers";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Button,
  TextField,
  FormHelperText,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// I18n
import { useTranslation } from "react-i18next";

const schema = {
  email: {
    // presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  code: {
    // numericality: true,
    // presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  password: {
    // presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 6,
      maximum: 100,
    },
  },
  // policy: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   checked: true,
  // },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.webp)",
    // backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    background: "rgba(232, 62, 140, 0.9)",
    borderRadius: "0.5rem",
    padding: theme.spacing(3, 4),
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 100,
    flexBasis: 700,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    // marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignUp = (props) => {
  const { history } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [step, setStep] = useState(1);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [isAvailable, setIsAvailable] = useState({
    email: true,
    handle: true,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    if (["email", "handle"].includes(event.target.name))
      checkAvailability(event.target.name, event.target.value);

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response = null;

      switch (step) {
        case 1:
          // Send the new email only
          response = await apiPost(endpoints.account.simpleSignUp, {
            emailAddress: formState.values.email,
          });

          if (response.status === 200) {
            enqueueSnackbar("Check your email for verification code", {
              variant: "success",
            });
            setStep(2);
          } else {
            enqueueSnackbar("Invalid email", {
              variant: "error",
            });
          }

          break;

        case 2:
          // Send the new email along with the verification code
          response = await apiPost(endpoints.account.simpleSignUp, {
            emailAddress: formState.values.email,
            code: formState.values.code,
          });

          if (response.status === 200) {
            enqueueSnackbar("Code verified", {
              variant: "success",
            });
            setStep(3);
          } else {
            enqueueSnackbar("Invalid code", {
              variant: "error",
            });
          }

          break;
        case 3:
          // Change the password from "" to a new password
          response = await apiPost(endpoints.account.changePassword, {
            oldPassword: "",
            newPassword: formState.values.password,
          });

          if (response.status === 200) {
            enqueueSnackbar("Password updated successfully", {
              variant: "success",
            });
            history.push("/dashboard");
          } else {
            enqueueSnackbar("Couldn't update password", {
              variant: "error",
            });
          }
          break;

        default:
          break;
      }
    } catch (err) {
      enqueueSnackbar("Problem signing up", {
        variant: "error",
      });
      console.error(err);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleBack = () => {
    history.goBack();
  };

  const checkAvailability = (target, value) => {
    if (value === "") return;

    apiPost(
      `${
        target === "email"
          ? endpoints.account.checkEmail
          : endpoints.account.checkHandle
      }${value}`
    )
      .then((res) => setIsAvailable({ ...isAvailable, [target]: res.data }))
      .catch((err) => console.error(err));
  };

  const availabilityHelper = {
    email: {
      EmailInUse: "This email address is already registered.",
    },
    handle: {
      HandleInUse: "The link is already in use.",
      InvalidHandle: "The link is not valid.",
    },
  };

  // Google auth
  const gSignUpSuccess = async (token) => {
    log({ text: "[Google Auth] Sign up successful", params: [token] });

    try {
      const response = await apiPost(endpoints.account.gAuth, {
        idToken: token.tokenId,
      });
      if (response.status === 200 && response.data) {
        log({ text: "[App] Signed up with Google" });
        enqueueSnackbar(authMessages[response.status].message, {
          variant: authMessages[response.status].variant,
        });
        history.push("/dashboard");
      } else {
        enqueueSnackbar(authMessages.default.message, {
          variant: authMessages.default.variant,
        });

        throw new Error("Failed to authenticate with Google token");
      }
    } catch (err) {
      log({
        type: "err",
        text: "[Google Auth -> Sign up]",
        params: err.message,
      });
    }
  };

  const gSignUpFail = () => {
    log({ type: "err", text: "[Google Auth] Failed signing up with Google" });
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            {/* <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h3">
                MyClinic
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Powered by Medicalchain
                </Typography>
              </div>
            </div> */}
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack} size="large">
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Typography className={classes.title} variant="h2" gutterBottom>
                  {t("RegisterTitle")}
                </Typography>
                {step === 1 && (
                  <>
                    {/* Google sign in */}
                    <Box mt={5} mb={5}>
                      <Typography variant="h5" gutterBottom>
                        {t("AccountSignUpWithGoogleAccount")}
                      </Typography>

                      <GoogleLogin
                        className={classes.gButton}
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText={t("AccountSignUpWithGoogleButton")}
                        onSuccess={(token) => gSignUpSuccess(token)}
                        onFailure={gSignUpFail}
                        cookiePolicy={"single_host_origin"}
                      />
                    </Box>

                    <Typography variant="h5" gutterBottom>
                      {t("AccountUseOwnEmail")}
                    </Typography>

                    <TextField
                      name="email"
                      className={classes.textField}
                      label={t("EmailAddress")}
                      error={hasError("email") || isAvailable.email !== true}
                      fullWidth
                      helperText={
                        hasError("email") ? formState.errors.email : null
                      }
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ""}
                      variant="outlined"
                      required
                    />
                    <FormHelperText error>
                      {!hasError("email") &&
                        isAvailable.email !== true &&
                        availabilityHelper.email[isAvailable.email]}
                    </FormHelperText>
                  </>
                )}
                {step === 2 && (
                  <TextField
                    name="code"
                    className={classes.textField}
                    label={t("AccountSignUPCode")}
                    error={hasError("code")}
                    fullWidth
                    helperText={
                      hasError("code") ? formState.errors.code[0] : null
                    }
                    onChange={handleChange}
                    type="text"
                    value={formState.values.code || ""}
                    variant="outlined"
                    required
                  />
                )}
                {step === 3 && (
                  <TextField
                    name="password"
                    className={classes.textField}
                    label={t("Password")}
                    error={hasError("password")}
                    fullWidth
                    helperText={
                      hasError("password") ? formState.errors.password[0] : null
                    }
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                    variant="outlined"
                    required
                  />
                )}
                {/* <div className={classes.policy}>
                  <Checkbox
                    name="policy"
                    className={classes.policyCheckbox}
                    checked={formState.values.policy || false}
                    color="primary"
                    onChange={handleChange}
                    required
                  />
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {t("AccountSignUpT&C1")}{" "}
                    <Link
                      href="https://myclinic.com/terms-for-providers/"
                      target="_blank"
                      color="primary"
                      // component={RouterLink}
                      // to="#"
                      underline="always"
                      variant="body1"
                    >
                      {t("AccountSignUpT&C2")}
                    </Link>
                  </Typography>
                </div>
                {hasError("policy") && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )} */}
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid || isAvailable.email !== true}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t("CreateAccount")}
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignUp);
