import React, { createContext, useState } from "react";

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notificationsState, setNotificationsState] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(
    notificationsState.length,
  );

  return (
    <NotificationsContext.Provider
      value={[
        notificationsState,
        setNotificationsState,
        notificationsCount,
        setNotificationsCount,
      ]}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
