import React from "react";

// Utils
//  eslint-disable-next-line
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Paper, IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
    overflow: "unset",
  },
  heading: {
    fontWeight: "bold",
  },
  headingContainer: {
    padding: "20px 16px 20px 30px",
  },
  iconContainer: {
    width: "3rem",
    display: "flex",
    justifyContent: "center",
  },
  headingActionContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ProfileSection = ({
  heading,
  headingAction,
  headingActionComponent: HeadingActionComponent,
  headingActionComponentProps,
  headingActionAriaLabel,
  renderChildren,
}) => {
  const classes = useStyles();
  const { isIcon = true, ...restHeadingActionComponentProps } =
    headingActionComponentProps || {};

  const headingActionComp = () => {
    if (!isIcon) {
      return (
        <HeadingActionComponent
          onClick={headingAction}
          {...restHeadingActionComponentProps}
        />
      );
    }

    return (
      <div className={classes.iconContainer}>
        <IconButton
          onClick={headingAction}
          aria-label={headingActionAriaLabel}
          size="small"
        >
          <HeadingActionComponent {...restHeadingActionComponentProps} />
        </IconButton>
      </div>
    );
  };

  return (
    <Paper variant="outlined" className={classes.headingContainer}>
      <Grid item style={{ width: "100%" }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={6}>
            <Typography className={classes.heading} variant="button">
              {heading}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.headingActionContainer}>
            {HeadingActionComponent && headingActionComp()}
          </Grid>
        </Grid>
        {renderChildren && renderChildren()}
      </Grid>
    </Paper>
  );
};

ProfileSection.propTypes = {
  // children: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node,
  // ]),
};

export default ProfileSection;
