import React from "react";

// Components
import Unspecified from "./Unspecified";

// MUI
import { Grid, Icon, Chip } from "@mui/material";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

const LocContent = ({ data, handleOpenEditor }) => {
  return (
    <Grid container spacing={1}>
      {data?.length > 0 ? (
        data?.map((loc) => {
          const map = loc.map && JSON.parse(loc.map);
          return (
            <Grid item key={loc.id}>
              <Chip
                icon={<LocationOnRoundedIcon />}
                label={map?.formatted_address || ""}
                variant="outlined"
                deleteIcon={<Icon>edit</Icon>}
                style={{
                  border: "2px solid",
                  borderColor: "#eeeeee",
                  padding: "0 0.5rem",
                }}
                onDelete={() => handleOpenEditor(loc)}
              />
            </Grid>
          );
        })
      ) : (
        <Unspecified />
      )}
    </Grid>
  );
};

export default LocContent;
