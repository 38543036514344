import React, { useContext } from "react";

// Routing
import { withRouter } from "react-router-dom";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

// Context
import { AccountContext } from "contexts";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid, FormControl, Select, MenuItem } from "@mui/material";

const boxShadow =
  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    // marginTop: theme.spacing(3),
  },
  name: {
    // color: theme.palette.white,
  },
  avatar: {
    width: 120,
    height: 120,
    margin: theme.spacing(1, 1, 2, 1),
    color: theme.palette.white,
    backgroundColor: theme.palette.white,
    boxShadow,
  },
  avatarIcon: {
    width: "120%",
    height: "120%",
    backgroundColor: theme.palette.white,
  },
  select: {
    "&:before": {
      borderColor: "rgba(0,0,0,0)",
    },
    "&:hover": {
      borderColor: "rgba(0,0,0,0)",
    },
    "&:after": {
      borderColor: "rgba(0,0,0,0)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "rgba(0,0,0,0)",
    },
  },
  iconSelect: {
    fill: "grey",
    stroke: "grey",
    strokeWidth: 2,
    // fontSize: "3rem",
  },
}));

const Profile = (props) => {
  const {
    className,
    isClickAwayAllowed,
    history,
    // eslint-disable-next-line
    staticContext,
    ...rest
  } = props;
  const classes = useStyles();

  const [accountData] = useContext(AccountContext);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/* <Avatar
        className={classes.avatar}
        component={isClickAwayAllowed ? RouterLink : "div"}
        to="/profile"
      >
        {accountData?.photoUrl ? (
          <img alt="profile photo" src={accountData.photoUrl} height="120" />
        ) : (
          <AccountCircleIcon className={classes.avatarIcon} color="disabled" />
        )}
      </Avatar> */}
      {!accountData?.organisations ||
      accountData?.organisations?.length === 0 ? (
        <Typography className={classes.name} variant="h6">
          {accountData?.fullName && `${accountData.fullName}`}
        </Typography>
      ) : (
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <FormControl style={{ width: "100%" }}>
              {/* <InputLabel id="organisation" shrink={false}>
              {!sessionStorage.getItem("orgId") && accountData?.fullName}
            </InputLabel> */}
              <Select
                variant="standard"
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.iconSelect,
                  },
                }}
                label={"Organisation"}
                labelId="organisation"
                id="organisation"
                name="organisation"
                value={sessionStorage.getItem("orgId") || "main"}
                onChange={(e) => {
                  e.target.value !== "main"
                    ? sessionStorage.setItem("orgId", e.target.value)
                    : sessionStorage.removeItem("orgId");

                  history.push(`/dashboard`);
                }}
                style={{ fontWeight: "500" }}
              >
                <MenuItem value={"main"}>
                  <Typography variant="h6">{accountData?.fullName}</Typography>
                </MenuItem>
                {accountData?.organisations?.map((org) => (
                  <MenuItem value={org.id} key={org.id}>
                    <Typography variant="h6">{org.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  isClickAwayAllowed: PropTypes.bool,
};

export default withRouter(Profile);
