import React, { useState } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Components
import { InviteCliniciansComponent } from "components";

import makeStyles from '@mui/styles/makeStyles';
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const InviteClinicians = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const labels = {
    title: "Invite Clinicians",
    text:
      "Do you have colleagues or friends that can benefit from MyClinic? Why not invite them to the MyClinic experience.",
  };

  return (
    <div className={classes.root}>
      <InviteCliniciansComponent {...{ ...labels }} />
    </div>
  );
};

export default InviteClinicians;
