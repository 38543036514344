import React from "react";

// Material components
import { SvgIcon } from "@mui/material";

// Minifying SVGs: https://jakearchibald.github.io/svgomg/
export default function IconMessaging(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M10.688 95.156C80.958 154.667 204.26 259.365 240.5 292.01c4.865 4.406 10.083 6.646 15.5 6.646 5.406 0 10.615-2.219 15.469-6.604 36.271-32.677 159.573-137.385 229.844-196.896 4.375-3.698 5.042-10.198 1.5-14.719C494.625 69.99 482.417 64 469.333 64H42.667c-13.083 0-25.292 5.99-33.479 16.438-3.542 4.52-2.875 11.02 1.5 14.718zM505.813 127.406a10.618 10.618 0 00-11.375 1.542c-46.021 39.01-106.656 90.552-152.385 129.885a10.654 10.654 0 00-3.708 8.271 10.644 10.644 0 004 8.135c42.49 34.031 106.521 80.844 152.76 114.115a10.626 10.626 0 006.229 2.01c1.667 0 3.333-.385 4.865-1.177a10.66 10.66 0 005.802-9.49V137.083a10.663 10.663 0 00-6.188-9.677zM16.896 389.354c46.25-33.271 110.292-80.083 152.771-114.115a10.646 10.646 0 004-8.135 10.654 10.654 0 00-3.708-8.271C124.229 219.5 63.583 167.958 17.563 128.948a10.674 10.674 0 00-11.375-1.542A10.66 10.66 0 000 137.083v243.615c0 4 2.24 7.667 5.802 9.49a10.566 10.566 0 004.865 1.177 10.62 10.62 0 006.229-2.011z" />
        <path d="M498.927 418.375c-44.656-31.948-126.917-91.51-176.021-131.365-4-3.26-9.792-3.156-13.729.24-9.635 8.406-17.698 15.49-23.417 20.635-17.563 15.854-41.938 15.854-59.542-.021-5.698-5.135-13.76-12.24-23.396-20.615-3.906-3.417-9.708-3.521-13.719-.24-48.938 39.719-131.292 99.354-176.021 131.365a10.703 10.703 0 00-4.406 7.604 10.67 10.67 0 002.802 8.333C19.552 443.01 30.927 448 42.667 448h426.667c11.74 0 23.104-4.99 31.198-13.688a10.676 10.676 0 002.802-8.323 10.73 10.73 0 00-4.407-7.614z" />
      </svg>
    </SvgIcon>
  );
}
