import React from "react";

// Utils
import clsx from "clsx";
import Linkify from "react-linkify";

// Time
import { format } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: "1rem",
    overflowWrap: "anywhere",
    overflow: "hidden",
  },
  actionButtons: {
    opacity: "0",
    transition: "ease 0.2s",
  },
  hoveredListItem: {
    transition: "ease 0.2s",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.075)",
    },
    "&:hover $actionButtons": {
      opacity: "1",
    },
  },
}));

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);

const MessageBody = (props) => {
  const { message } = props;
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <Grid
      container
      className={clsx(classes.root, classes.hoveredListItem)}
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body1" display="block" color="primary">
                  {message.sender}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  &#9702;
                  {`  ${format(new Date(message.created), "PPp", {
                    locale: supportedLocales[i18n.language],
                  })}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          gutterBottom
          style={{
            whiteSpace: "pre-line",
          }}
        >
          <Linkify componentDecorator={componentDecorator}>
            {message.message}
          </Linkify>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MessageBody;
