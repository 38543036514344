import React, { forwardRef, useContext } from "react";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

// Components
import { ClinicSelectDropDown } from "components";

// Routing
import { NavLink as RouterLink, withRouter } from "react-router-dom";

// Contexts
import { ClinicsContext, GlobalContext, MessagingContext } from "contexts";

import makeStyles from '@mui/styles/makeStyles';
import {
  List,
  ListItem,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
  Badge,
} from "@mui/material";
import IconClinic from "icons/svg/IconClinic";

const useStyles = makeStyles((theme) => ({
  root: { width: "200px" },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#adadad",
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: "#222222",
    margin: "3px",
    padding: theme.spacing(1),
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  icon: {
    color: "#222222",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    backgroundColor: theme.palette.white,
    color: "#334bfa",
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: "#334bfa",
    },
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
}));

// React docs https://reactjs.org/docs/forwarding-refs.html
// eslint-disable-next-line
const CustomRouterLink = forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink activeClassName={classes.active} {...props} />
    </div>
  );
});

const SidebarNav = (props) => {
  const {
    history,
    pages,
    className,
    onClose,
    isClickAwayAllowed,
    // eslint-disable-next-line
    staticContext,
    ...rest
  } = props;
  const classes = useStyles();

  // Contexts
  const [clinicsList] = useContext(ClinicsContext);
  const { setGlobalSettings } = useContext(GlobalContext);
  const [messageCount] = useContext(MessagingContext);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem style={{ paddingLeft: 10 }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={2} style={{ paddingTop: 22 }}>
            <div className={classes.icon}>
              <IconClinic />
            </div>
          </Grid>
          <Grid item xs={10}>
            <ClinicSelectDropDown
              {...{
                callback: (currentClinic) => {
                  setGlobalSettings((prevState) => ({
                    ...prevState,
                    currentClinic,
                  }));
                  onClose();
                  history.push(`/clinic/${currentClinic}`);
                },
              }}
            />
          </Grid>
        </Grid>
      </ListItem>

      <Divider className={classes.divider} />

      {pages?.map((page) => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
          onClick={onClose}
        >
          <Button
            className={classes.button}
            component={isClickAwayAllowed ? CustomRouterLink : "div"}
            to={page.href}
          >
            <Box className={classes.icon}>{page.icon}</Box>
            <Badge
              color="secondary"
              badgeContent={page.title === "Messaging" ? messageCount : 0}
            >
              {page.title}
            </Badge>
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default withRouter(SidebarNav);
