import React from "react";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {},
  clinicCodeText: {
    color: "white",
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px 0 0 5px",
  },
  clinicCodeCode: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white,
    borderRadius: "0 5px 5px 0",
    textTransform: "uppercase",
  },
  clinicCodeCommon: {
    padding: theme.spacing(1, 1.5),
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const ClinicCode = (props) => {
  const { clinicCode } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <Typography
          className={clsx(classes.clinicCodeText, classes.clinicCodeCommon)}
          variant="button"
          align="center"
          gutterBottom
        >
          {`${t("ClinicCode")} `}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={clsx(classes.clinicCodeCode, classes.clinicCodeCommon)}
          variant="button"
          align="center"
          gutterBottom
        >
          {clinicCode}
        </Typography>
      </Grid>
    </Grid>
  );
};

ClinicCode.propTypes = {
  clinicCode: PropTypes.string.isRequired,
};

ClinicCode.defaultProps = {
  clinicCode: "",
};

export default ClinicCode;
