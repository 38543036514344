import React from "react";

// MUI
import { Grid, Button, Icon } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const ActionButtons = ({ handleAddNew, handleSave, handleClose, active }) => {
  return (
    <Grid container direction="column" spacing={1} style={{ width: 300 }}>
      {handleAddNew && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddRoundedIcon />}
            onClick={handleAddNew}
            style={{ width: "100%" }}
          >
            {"Add new"}
          </Button>
        </Grid>
      )}

      <Grid item container justifyContent="space-between" xs={12}>
        <Grid item style={{ width: "48%" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            onClick={handleSave}
            style={{ width: "100%" }}
            disabled={!active}
          >
            {"Save"}
          </Button>
        </Grid>

        <Grid item style={{ width: "48%" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{ width: "100%" }}
          >
            {"Cancel"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActionButtons.defaultProps = {
  active: true,
};

export default ActionButtons;
