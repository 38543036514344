import React, { useEffect, useState } from "react";

// API
import { endpoints, apiPost } from "api";

import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle } from '@mui/material';

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(3, 5),
    // "& > * + *": {
    //   marginTop: theme.spacing(2),
    // },
  },
}));

const ConfirmEmail = (props) => {
  const { history } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const id = params.get("userId");
    const code = params.get("code");
    getConfirmation({ id, code });
    //  eslint-disable-next-line
  }, []);

  const [state, setState] = useState(null);

  const getConfirmation = async (params) => {
    const response = await apiPost(endpoints.account.confirmEmail, params);
    if (response.status === 200) {
      setState(true);
      setTimeout(() => {
        history.push("/sign-in");
      }, 5000);
    } else {
      setState(false);
    }
  };
  return (
    <div className={classes.root}>
      {state === false && (
        <Alert severity="error">
          <AlertTitle>{t("ConfirmEmailTitle")}</AlertTitle>
          {t("ConfirmEmailError")}
        </Alert>
      )}
      {state === true && (
        <Alert severity="success">
          <AlertTitle>{t("ConfirmEmailTitle")}</AlertTitle>
          {t("ConfirmEmailSuccess")}
        </Alert>
      )}
    </div>
  );
};

export default ConfirmEmail;
