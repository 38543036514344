import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useForm, SubmitHandler } from "react-hook-form";
import { CommonDialog } from "../../Common/CommonDialog";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

interface ChangePasswordFormProps {
  onSubmit: (event: IFormInput) => void;
}

interface IFormInput {
  email: string;
}

const schema = Joi.object<IFormInput>({
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "net"] },
  }),
});

export const ChangeEmailForm = ({ onSubmit }: ChangePasswordFormProps) => {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset, formState } = useForm<IFormInput>({
    resolver: joiResolver(schema),
  });

  // resets for feilds on dialog close
  useEffect(() => reset(), [open]);

  const submit: SubmitHandler<IFormInput> = (data) => {
    onSubmit(data);
    setOpen(false);
    reset();
  };

  return (
    <CommonDialog
      title="Change Email"
      subtitle="When you change your email you will be autmatically logged out"
      onSubmit={handleSubmit(submit)}
      buttonText="Change Email"
      {...{ open, setOpen }}
    >
      <TextField
        label={formState?.errors?.email?.message || "Email"}
        {...register("email")}
        error={!!formState?.errors?.email}
      />
    </CommonDialog>
  );
};
