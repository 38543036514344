import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// API
import { apiGet, apiPost, endpoints } from "api";

// Contexts
import { AccountContext } from "contexts";

// Snackbar
import { useSnackbar } from "notistack";

import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Region = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [accountData] = useContext(AccountContext);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");

  useEffect(() => {
    if (!accountData) return;
    setSelectedRegion(accountData.region);
    setSelectedTimezone(accountData.timezone ? accountData.timezone : "");
  }, [accountData]);

  const [timezoneOptions, setTimezoneOptions] = useState([]);
  useEffect(() => {
    apiGet(endpoints.misc.timezone)
      .then((res) => setTimezoneOptions(res.data))
      .catch();
  }, []);

  const handleRegionChange = async (e) => {
    try {
      setSelectedRegion(e.target.value);

      const response = await apiPost(endpoints.account.region, {
        region: e.target.value,
      });

      if (response.status === 200) {
        enqueueSnackbar("Region changed successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Unable to change the region", {
          variant: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("There was a problem changing region", {
        variant: "error",
      });
    }
  };

  const handleChangeTimezone = (e, value) => {
    if (value === null) return;

    setSelectedTimezone(value);

    apiPost(endpoints.account.settings, { timezone: value })
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Timezone changed successfully", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("There was a problem changing timezone", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("There was a problem changing timezone", {
          variant: "error",
        });
      });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)} elevation={0}>
      <CardHeader
        title={t("Region & Timezone")}
        subheader={t("Change your default region and timezone")}
      />
      <Divider />
      <CardContent>
        <Grid container direction="column" spacing={3} wrap="wrap">
          <Grid item>
            <FormControl variant="outlined" style={{ width: 300 }}>
              <Select
                label={"Region"}
                labelId="region"
                id="region"
                name="region"
                value={selectedRegion}
                onChange={handleRegionChange}
              >
                <MenuItem value="Europe">Europe</MenuItem>
                <MenuItem value="NorthAmerica">North America</MenuItem>
                <MenuItem value="AsiaPacific">Asia & Pacific</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Autocomplete
              options={timezoneOptions}
              style={{ width: 300 }}
              onChange={handleChangeTimezone}
              value={selectedTimezone}
              renderInput={(params) => (
                <TextField {...params} label="Timezone" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Region.propTypes = {
  className: PropTypes.string,
};

export default Region;
