import React, { createContext, useState } from "react";

export const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {
  // const [messageList, setMessageList] = useState([]);
  const [messageCount, setMessageCount] = useState(0);

  return (
    <MessagingContext.Provider value={[messageCount, setMessageCount]}>
      {children}
    </MessagingContext.Provider>
  );
};
