import React, { useState } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Components
import { InviteCliniciansComponent } from "components";
import UpgradeImmediately from "./components/UpgradeImmediately";
import WhatsIncluded from "./components/WhatsIncluded";

import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Typography, Paper, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const GetFreeUpgrade = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const labels = {
    title: "Get a free upgrade",
    text:
      "Invite your friends and colleagues and if three of them join MyClinic you will receive a free upgrade to our Clinician Pro account for one year.",
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="column" justifyContent="center" spacing={2}>
            <Grid item>
              <InviteCliniciansComponent
                {...{ ...labels, showProgressBar: true }}
              />
            </Grid>
            <Grid item>
              <Typography align="center" variant="h5">
                {"Or"}
              </Typography>
            </Grid>
            <Grid item>
              <UpgradeImmediately />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <WhatsIncluded />
        </Grid>
      </Grid>
    </div>
  );
};

export default GetFreeUpgrade;
