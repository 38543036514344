const endpoints = {
  clinic: {
    open: "/clinic/open",
    close: "/clinic/close",
    create: "/clinic",
    data: "/clinic",
    patients: "/clinic/patients/",
    invitations: "/clinic/invitations?code=",
    files: "/clinic/files/",
    createInvitation: "/clinic/invitations",
    delete: "/clinic/",
    settings: "/clinic/settings",
    callFeedback: "/clinic/feedback",
    roomURL1: "/clinic/rooms/", // Concat roomId at the end
    roomURL2: "/invite/url", // Comes after roomId
    roomInvite1: "/clinic/rooms/", // Concat roomId at the end
    roomInvite2: "/invite", // Comes after roomId
    roomJoinInvCode: "/clinic/rooms/join?InvitationCode=",
    roomJoinRoomID: "/clinic/rooms/join?RoomId=",
    questCreate: "/clinic/questionnaires",
    questRetrieve: "/clinic/questionnaires",
    questDelete: "/clinic/questionnaires/",
    questUpdate: "/clinic/questionnaires/",
    testBot: "/clinic/rooms/join/test/",
    availabilityProfile: "/clinic/availabilityprofiles",
    messages: "/clinic/messages",
  },
  account: {
    login: "/account/login",
    logout: "/account/logout",
    register: "/account/register",
    data: "/account",
    changeEmail: "/account/changeemail",
    changePassword: "/account/changepassword",
    forgotPassword: "/account/forgotpassword",
    getMFA: "/account/enablemfa",
    performMFA: "/account/mfa",
    enableMFA: "/account/enablemfa",
    disableMFA: "/account/disablemfa",
    profile: "/account/info",
    confirmEmail: "/account/confirmemail",
    checkEmail: "/account/checkemail?Email=",
    checkHandle: "/account/checkhandle?Handle=",
    gAuth: "/account/googlesignin",
    simpleSignUp: "/account/registersimple",
    country: "/account/country",
    region: "/account/region",
    handle: "/account/handle",
    primaryClinicName: "/account/primaryclinicname",
    countries: "/account/countries",
    regions: "/account/regions",
    settings: "/account/settings",
    referralStatus: "/account/referral/status",
    referralInvite: "/account/referral/invite",
    getPrice: "/account/pricing",
  },
  patient: {
    checkIn: "/patient/checkin",
    infoClinicCode: "/patient/info?cc=",
    submitQuestData: "/patient/questionnaires/",
    questionnairesThatNeedToBeFilledIn: "/patient/questionnaires/",
    submitQuestionnaireAnswers: "/patient/questionnaires",
  },
  chargebee: {
    hostedPage: "/chargebee/hostedpage",
    portal: "/chargebee/portalsession",
  },
  config: {
    smsCountries: "/config/smscountries",
    patientBaseUrl: "/config/patientbaseurl",
  },
  profile: {
    all: "/profile",
    publish: "/profile/publish",
    email: "/profile/email",
    personalDetails: "/profile/personaldetails",
    biography: "/profile/biography",
    clinicalInterest: "/profile/clinicalinterest",
    treatment: "/profile/treatment",
    qualification: "/profile/qualification",
    membership: "/profile/membership",
    license: "/profile/license",
    clinic: "/profile/clinic",
    remove: "/profile/remove",
    attachmentPhoto: "/profile/attachment/photo",
    attachmentCover: "/profile/attachment/banner",
    list: {
      qualifications: "/profile/list/qualifications",
      licenses: "/profile/list/licensenames",
      memberships: "/profile/list/memberships",
      titles: "/profile/list/titles",
      treatments: "/profile/list/treatmentsinterests",
      clinicalInterests: "/profile/list/treatmentsinterests",
      specialities: "/profile/list/treatmentsinterests",
    },
    claim: "/profile/claimprofile",
  },
  bookings: {
    get: "/bookings",
  },
  stripe: {
    getKey: "/stripe/setup",
    checkout: "/stripe/create-checkout-session",
    manage: "/stripe/customer-portal",
    onboard: "/stripe/onboard-payment/link",
    check: "/stripe/onboard-payment/check",
    products: "/stripe/onboard-payment/products",
  },
  misc: {
    pricing: "/misc/pricing",
    timezone: "/misc/tz",
  },
};

export default endpoints;
