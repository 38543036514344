import React, { useState, useEffect, useContext, useRef } from "react";

// Hooks
import { usePrevious } from "hooks";

// Router
import { withRouter } from "react-router-dom";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSnackbar, SnackbarProvider } from "notistack";

// Contexts
import { CallContext, PatientsContext, ClinicsContext } from "contexts";

//Components
import { Sidebar, Topbar, Footer } from "./components";
import { ConfirmClickAway } from "./components/Sidebar/components";
import { SidebarToggleButton } from "components";
import SnackMessage from "components/OnlinePatientNotifier/OnlinePatientNotifier";

// Material UI
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery } from "@mui/material";
import { Typography, Box, Button, Badge, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   paddingTop: 64,
    // },
  },
  shiftContentDown: {
    // paddingTop: 64,
  },
  shiftContentRight: {
    paddingLeft: 240,
  },
  content: {
    backgroundColor: theme.palette.white,
    height: "inherit",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

const action = (key) => (
  <>
    <Button
      onClick={() => {
        alert(`I belong to snackbar with key ${key}`);
      }}
    >
      Show
    </Button>
    <Button
      onClick={() => {
        // this.props.closeSnackbar(key);
      }}
    >
      Dismiss
    </Button>
  </>
);

const Notifier = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [patientsList] = useContext(PatientsContext);
  const [clinicsList, setClinicsList] = useContext(ClinicsContext);

  const prev = usePrevious(patientsList);

  useEffect(() => {
    if (!patientsList) return;
    if (prev) {
      for (const clinic in patientsList) {
        patientsList[clinic]?.forEach((patient) => {
          if (
            prev[clinic] &&
            !prev[clinic].find((oldPatient) => oldPatient.id === patient.id) &&
            patient.patientOnline
          ) {
            notify(clinic, patientsList[clinic].length);
          } else if (
            prev[clinic] &&
            prev[clinic].find((oldPatient) => {
              if (oldPatient.id === patient.id) {
                return oldPatient.patientOnline !== patient.patientOnline;
              }
            })
          ) {
            const currentOnlinePatients = patientsList[clinic]?.filter(
              (patient) => patient.patientOnline === true,
            );
            if (currentOnlinePatients.length > 0) {
              notify(clinic, currentOnlinePatients.length);
            } else {
              closeSnackbar(clinic);
            }
          }
        });
      }
    } else {
      for (const clinic in patientsList) {
        patientsList[clinic]?.forEach((patient) => {
          if (patient.patientOnline) {
            notify(clinic, patientsList[clinic].length);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [patientsList]);

  const notify = (clinic, patientsNumber) => {
    const currentClinic = clinicsList.filter(
      (existingClinic) => existingClinic.id === clinic,
    );

    closeSnackbar(clinic);
    setTimeout(() => {
      enqueueSnackbar(
        `There ${
          patientsNumber === 1
            ? `is ${patientsNumber} patient`
            : `are ${patientsNumber} patients`
        } waiting in ${currentClinic[0].name}.`,
        {
          persist: true,
          preventDuplicate: true,
          key: clinic,
        },
      );
    }, 1000);
  };

  return <div></div>;
};

const Main = (props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  // Refs
  const snackMessageRef = useRef(null);

  // Contexts
  const [patientsList] = useContext(PatientsContext);
  const { callStatus, setCallStatus } = useContext(CallContext);
  const { isCallInProgress, isClickAwayAllowed } = callStatus;

  const [openSidebar, setOpenSidebar] = useState(false);

  const [openClickAwayConfirmation, setOpenClickAwayConfirmation] =
    useState(false);

  const shouldSidebarOpen = isDesktop && !isCallInProgress ? true : openSidebar;

  const toggleSidebar = () => {
    setOpenSidebar((prevState) => !prevState);
  };

  const handleCloseClickAwayConfirmation = () => {
    setOpenClickAwayConfirmation(false);
  };

  const handleAllowClickAway = () => {
    setCallStatus((prevState) => ({
      ...prevState,
      isClickAwayAllowed: true,
    }));
    handleCloseClickAwayConfirmation();
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContentDown]: true,
        [classes.shiftContentRight]: isDesktop && !isCallInProgress,
      })}
    >
      {/* <Topbar {...{ toggleSidebar, isCallInProgress }} /> */}

      <Sidebar
        {...{
          variant: isDesktop && !isCallInProgress ? "persistent" : "temporary",
          open: shouldSidebarOpen,
          setOpenClickAwayConfirmation,
          onClose: toggleSidebar,
          isClickAwayAllowed,
        }}
      />

      <ConfirmClickAway
        {...{
          open: openClickAwayConfirmation,
          handleClose: handleCloseClickAwayConfirmation,
          handleAllowClickAway,
        }}
      />

      <SidebarToggleButton {...{ toggleSidebar, isCallInProgress }} />

      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        content={(key, message) => <SnackMessage {...{ id: key, message }} />}
      >
        <Notifier />
      </SnackbarProvider>
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
