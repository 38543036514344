import React from "react";

// Components
import Unspecified from "./Unspecified";

// MUI
import { Grid, Chip, Icon, Tooltip, Typography } from "@mui/material";

const TreatContent = ({ data, propName, handleOpenEditor }) => {
  const chipColor = {
    treatments: "#c5e5ff",
    clinicalInterests: "#cbfac1",
    specialities: "#efc5ff",
  };

  return (
    <Grid container spacing={1}>
      {data.length > 0 ? (
        data.map((prop) => {
          return (
            <Grid item key={prop.id}>
              <Tooltip
                title={
                  prop.country || prop.code || prop.description ? (
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption">
                          {prop.country || ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {prop.code || ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {prop.description || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )
                }
                aria-label="info"
                arrow
              >
                <Chip
                  variant="outlined"
                  deleteIcon={<Icon>edit</Icon>}
                  label={prop.name}
                  style={{
                    border: "2px solid",
                    borderColor: chipColor[propName],
                    padding: "0 0.5rem",
                  }}
                  onDelete={() => handleOpenEditor(prop)}
                />
              </Tooltip>
            </Grid>
          );
        })
      ) : (
        <Unspecified />
      )}
    </Grid>
  );
};

export default TreatContent;
