import React, { useState } from "react";

// React Json Forms
import Form from "@rjsf/material-ui";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2, 3),
  },
}));

const QuestionnairePreview = (props) => {
  const {
    labels,
    handleShowBuilder,
    handleHidePreview,
    selectedQuestionnaire,
  } = props;
  const classes = useStyles();

  const schema =
    selectedQuestionnaire && JSON.parse(selectedQuestionnaire.formSchema);
  const uiSchema =
    selectedQuestionnaire && JSON.parse(selectedQuestionnaire.uiSchema);

  const [formData, setFormData] = useState({});

  return <>
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <IconButton onClick={handleHidePreview} size="large">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {labels.questionnairePreviewTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditRoundedIcon />}
                onClick={() => handleShowBuilder(selectedQuestionnaire)}
              >
                {labels.questionnairePreviewEditButton}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

    <Box mt={4}>
      <Paper className={classes.paper}>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          onChange={(questionnaire) => setFormData(questionnaire.formData)}
          formData={formData}
          onSubmit={(res) => console.log(res)}
        />
      </Paper>
    </Box>
  </>;
};

export default QuestionnairePreview;
