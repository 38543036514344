import React, { useState, useEffect } from "react";

// API
import { apiGet, apiPost, endpoints } from "api";

// I18n
import { useTranslation } from "react-i18next";

// Components
import ClinicianInvitationForm from "./components/ClinicianInvitationForm";

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Paper,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const InviteCliniciansComponent = (props) => {
  const { title, text, showProgressBar } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [referralStatus, setReferralStatus] = useState({
    referralInvitesSent: 0,
    referralInvitesJoined: 0,
  });

  useEffect(() => {
    getReferralStatus();
  }, []);

  const getReferralStatus = async () => {
    try {
      const response = await apiPost(endpoints.account.referralStatus);

      if (response?.status === 200 && response.data) {
        setReferralStatus(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const callback = () => {
    getReferralStatus();
  };

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" justifyContent="center" spacing={4}>
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            {(showProgressBar || referralStatus?.referralInvitesSent > 0) && (
            <Grid item>
              <Typography>{`You have invited ${referralStatus?.referralInvitesSent} people, ${referralStatus?.referralInvitesJoined} of which have joined MyClinic`}</Typography>
            </Grid>
            )}
            {showProgressBar && (
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      color="secondary"
                      value={
                        referralStatus?.referralInvitesJoined < 3
                          ? (referralStatus.referralInvitesJoined * 100) / 3
                          : 100
                        }
                      style={{ height: 10 }}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{referralStatus?.referralInvitesJoined} / 3</Typography>
                  </Box>
                </Box>              
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <ClinicianInvitationForm {...{ callback }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

InviteCliniciansComponent.defaultProps = {
  showProgressBar: false,
};

export default InviteCliniciansComponent;
