import React, { useContext } from "react";

// Utils
import { useCookies } from "react-cookie";

// Contexts
import { GlobalContext } from "contexts";

// Material UI
import { Popover, Typography, List, ListItem } from "@mui/material";

const TopbarThemePopover = (props) => {
  const { showThemeAnchorEl, setShowThemeAnchorEl } = props;

  const [, setCookie] = useCookies(["theme"]);

  const { globalSettings, setGlobalSettings } = useContext(GlobalContext);

  const handleClose = () => {
    setShowThemeAnchorEl(null);
  };

  const open = Boolean(showThemeAnchorEl);

  return (
    <Popover
      open={open}
      anchorEl={showThemeAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List>
        {Object.keys(globalSettings.allThemes).map((theme) => {
          return (
            <ListItem
              button
              onClick={() => {
                setGlobalSettings((prevState) => ({
                  ...prevState,
                  theme: globalSettings.allThemes[theme],
                }));
                setCookie("theme", theme, {
                  path: "/",
                });
                handleClose();
              }}
              key={theme}
            >
              <Typography variant="button">{theme}</Typography>
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
};

export default TopbarThemePopover;
