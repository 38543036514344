import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation Data
// import arSA from "./resources/ar/data";
import enUS from "./resources/en/data";
import es from "./resources/es-ES/data";
import fr from "./resources/fr/data";
import it from "./resources/it/data";
import ja from "./resources/ja/data";
import ko from "./resources/ko/data";
import nl from "./resources/nl/data";
import pt from "./resources/pt-PT/data";
// import vi from "./resources/vi/data";
import zhCN from "./resources/zh-CN/data";
import zhTW from "./resources/zh-TW/data";

const parseResource = (langResource) => ({ translation: langResource });

const resources = {
  // arSA: parseResource(arSA),
  enUS: parseResource(enUS),
  enGB: parseResource(enUS),
  //es: parseResource(es),
  //fr: parseResource(fr),
  //it: parseResource(it),
  //ja: parseResource(ja),
  //ko: parseResource(ko),
  //nl: parseResource(nl),
  //pt: parseResource(pt),
  // vi: parseResource(vi),
  //zhCN: parseResource(zhCN),
  //zhTW: parseResource(zhTW),
};

export const languageCodesAndNames = {
  // arSA: "Arabic",
  enUS: "English (US)",
  enGB: "English (UK)",
  //es: "Español",
  //fr: "Français",
  //it: "Italiano",
  //ja: "日本語",
  //ko: "한국어",
  //nl: "Nederlands",
  //pt: "Português",
  // vi: "Vietnamese",
  //zhCN: "中文(中国)",
  //zhTW: "中文(台灣)",
};

// Options for the languge detector
const options = {
  order: ["cookie", "navigator"], // order and from where user language should be detected
  lookupCookie: "language", // keys or params to lookup language from
  caches: ["cookie"], // cache user language on
};

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng: "enGB",
    detection: options,
    resources,
    supportedLngs: Object.keys(languageCodesAndNames),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
