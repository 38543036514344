import { Client } from "./client";
import { HTTPStatus } from "./api-new.interface";
import { useQuery } from "react-query";
/***************
 * API Promise *
 ***************/

const endpoint = {
  getKey: "/stripe/setup",
  checkout: "/stripe/create-checkout-session",
  manage: "/stripe/customer-portal",
  onboard: "/stripe/onboard-payment/link",
  check: "/stripe/onboard-payment/check",
  products: "/stripe/onboard-payment/products",
};

export const promiseGetOnboardLink = async () => {
  try {
    const { data } = await Client.instance.get(endpoint.onboard);
    return data || "OK";
  } catch (e) {
    throw "Could start onboard process";
  }
};

/***************
 * API Hooks   *
 ***************/

export const useGetStripCheck = () => {
  return useQuery("stripcheck", async () => {
    try {
      const { data, status } = await Client.instance.get(endpoint.check);
      if (status !== HTTPStatus.OK) throw "";
      return data;
    } catch {
      throw "Stripe is not set up";
    }
  });
};
