import React, { useState, useEffect, useContext, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

// Utils
import { isMobile } from "react-device-detect";
import clsx from "clsx";

// Lightbox
import "react-awesome-lightbox/build/style.css";
// @ts-ignore
import { default as Lightbox } from "react-awesome-lightbox";

// Video player
import "./Clinic.scss";
// @ts-ignore
import ModalVideo from "react-modal-video";

// Contexts
import {
  PatientsContext,
  ClinicsContext,
  FullscreenContext,
  CallContext,
  GlobalContext,
} from "contexts";

// API
import { apiGet, apiPost, endpoints } from "api";

// Components
import {
  PatientDetails,
  PatientsTable,
  InvitationsTable,
  FilesTable,
} from "./components";
import alerts from "common/alertMessages";
import {
  ClinicInvite,
  VideoCall,
  CallFeedback,
  CopyClinicLink,
  ClinicCode,
  NothingToShow,
  Alert,
  InviteToRoom2,
  CustomModal,
} from "components";

// I18n
import { useTranslation } from "react-i18next";

// Material UI
import { styled } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  Button,
  Badge,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
} from "@mui/material";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import IconAttachment from "icons/svg/IconAttachment";
import IconInvitation from "icons/svg/IconInvitation";
import IconWaitingRoom from "icons/svg/IconWaitingRoom";
import {
  IClinic,
  IFileAttachment,
  IPatient,
  IQuestionnaireResponse,
  IQuestionnaireResult,
} from "types";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2),
    // minHeight: "65vh",
    // height: "inherit",
    // overflow: "hidden",
  },
  topElementContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },
  detailsContainer: {
    width: "fit-content !important",
  },
  breadcrumbIcon: {
    marginTop: "2px",
    fontSize: "1rem",
    color: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    strokeWidth: 4,
  },
  breadcrumb: {
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  tab: {
    // padding: theme.spacing(0.5, 2),
    fontSize: "0.9rem",
  },
  activeTab: {
    borderBottom: "2px solid #e83e8c",
    borderRadius: "unset",
    // backgroundColor: theme.palette.primary.main,
    transition: "ease 0.2s",
    color: "#e83e8c",
  },
  badge: {
    // right: -3,
    // top: 13,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: "0 4px",
  },
}));

const FlexContainer = styled(Box)({
  width: "inherit",
  height: "inherit",
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
});

const FlexItemContainer = styled(Box)({
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const FlexItemRightSidePanelContainer = styled(Box)({
  // marginLeft: "auto",
  marginLeft: 5,
  overflow: "hidden",
  minWidth: "fit-content",
  border: "1px solid #eeeeee",
});

interface IProps extends RouteComponentProps<any> {
  history: any;
  match: any;
}

const Clinic = (props: IProps) => {
  const { history, match } = props;
  const clinicId = match.params.clinic_id;

  const classes = useStyles();
  const { t } = useTranslation();

  // Context
  const [FullScreen, isFullscreen] = useContext(FullscreenContext);
  const [clinicsList] = useContext(ClinicsContext);
  const [patientsList] = useContext(PatientsContext);
  const { callStatus, setCallStatus } = useContext(CallContext);
  const { setGlobalSettings } = useContext(GlobalContext);

  const [clinicInfo, setClinicInfo] = useState<IClinic | null>(null);
  const [isClinicOpen, setIsClinicOpen] = useState(null);
  const [patientId, setPatientId] = useState<string | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [allInvitations, setAllInvitations] = useState([]);
  const [allFiles, setAllFiles] = useState([]);

  const [isCallInProgress, setIsCallInProgress] = useState(false);
  const [showWaitingRoom, setShowWaitingRoom] = useState(true);
  const [showInvitations, setShowInvitations] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showCall, setShowCall] = useState(false);

  // Details pane
  const [isChatMounted, setIsChatMounted] = useState(false);
  const [selectedPatient, setSelectedPatient] =
    useState<(IPatient & { valueProp?: number }) | null>(null);
  const [showDetails, setShowDetails] = useState(false);

  // Show CallFeedback
  const [showCallFeedback, setShowCallFeedback] = useState(false);

  // Attachments
  const [selectedAttachment, setSelectedAttachment] =
    useState<IFileAttachment | null>(null);
  const [selectedMp4Attachment, setSelectedMp4Attachment] =
    useState<{
      fileUrl: string;
    } | null>(null);

  // Invite third party
  const [showCallInviteModal, setShowCallInviteModal] = useState(false);

  // Questionnaire result modal view
  const [questionResult, setQuestionResult] = useState<{
    open: boolean;
    selected: IQuestionnaireResult | null;
  }>({
    open: false,
    selected: null,
  });

  const clinicUpdate = useMemo(() => clinicInfo, [clinicInfo?.name]);

  useEffect(() => {
    if (!clinicUpdate) return;
    document.title = `${clinicUpdate.name} - ${document.title}`;

    return () => {
      document.title = process.env.REACT_APP_WEBSITE_NAME || "";
    };
  }, [clinicUpdate]);

  useEffect(() => {
    setGlobalSettings((prevState) => ({
      ...prevState,
      currentClinic: clinicId,
    }));
    return () => {
      setGlobalSettings((prevState) => ({
        ...prevState,
        currentClinic: null,
      }));
    };
  }, []);

  useEffect(() => {
    getClinicInfo();
    // eslint-disable-next-line
  }, [clinicsList]);

  const getClinicInfo = async () => {
    if (Array.isArray(clinicsList)) {
      const selectedClinic = clinicsList.filter(
        (clinic) => clinic.id === clinicId
      );
      if (selectedClinic[0]) {
        getAllInvitations(selectedClinic[0].code);
        getAllFiles(selectedClinic[0].id);
        setClinicInfo(selectedClinic[0]);
        setIsClinicOpen(selectedClinic[0].isOpen);
      } else {
        history.push("/dashboard");
      }
    }
  };

  const getAllInvitations = async (clinicCode: string) => {
    try {
      const response = await apiGet(endpoints.clinic.invitations, clinicCode);
      if (response && response.status === 200 && response.data)
        setAllInvitations(response.data);
    } catch (err) {
      console.error("Problem getting invitations:", err);
    }
  };

  const getAllFiles = async (clinicId: string) => {
    try {
      const response = await apiPost(`${endpoints.clinic.files}${clinicId}`);
      if (response && response.status === 200 && response.data)
        setAllFiles(response.data);
    } catch (err) {
      console.error("Problem getting files:", err);
    }
  };

  const handleSelectedPatient = (
    e: React.MouseEvent,
    patient: IPatient,
    valueProp = 0
  ) => {
    e.preventDefault();

    // Clicking on the same patient unmounts patient details component
    if (
      selectedPatient &&
      selectedPatient.id === patient.id &&
      valueProp === 0
    ) {
      setShowDetails(false);
      setSelectedPatient(null);
    } else {
      setSelectedPatient({ ...patient, valueProp });
      setShowDetails(true);
    }
  };

  const handleShowInvitations = () => {
    setShowWaitingRoom(false);
    setShowInvitations(true);
    setShowFiles(false);

    // Avoid right side details pane to show
    setShowDetails(false);
    setSelectedPatient(null);
  };

  const handleShowFiles = () => {
    setShowWaitingRoom(false);
    setShowInvitations(false);
    setShowFiles(true);

    // Avoid right side details pane to show
    setShowDetails(false);
    setSelectedPatient(null);
  };

  const handleShowWaitingRoom = () => {
    setShowWaitingRoom(true);
    setShowInvitations(false);
    setShowFiles(false);
  };

  // This is the open/close feature that is temporarily disabled
  // eslint-disable-next-line
  // const toggleOpen = async () => {
  //   if (isClinicOpen) {
  //     try {
  //       const response = await apiPost(endpoints.clinic.close, {
  //         clinicId: clinicInfo.id,
  //       });
  //       if (response.status === 200) setIsClinicOpen(false);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   } else {
  //     try {
  //       const response = await apiPost(endpoints.clinic.open, {
  //         clinicId: clinicInfo.id,
  //         hours: 0,
  //       });
  //       if (response.status === 200) setIsClinicOpen(true);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  // };

  // Prop for PatientsTable, gets patient obj from there
  const handleCallConnect = (patient: IPatient) => {
    // Prop for call feedback component
    setPatientId(patient.id);

    // Prop for video call component
    setRoomId(patient.roomId);

    // Unmount waiting room table component
    setShowWaitingRoom(false);

    // Mount video call component
    setShowCall(true);
    setIsCallInProgress(true);

    // Mount patient details component
    !isMobile ? setShowDetails(true) : setShowDetails(false);
    setSelectedPatient(patient);

    // if (isMobile) isFullscreen.enter();
  };

  const callConnectCallback = () => {
    console.log("[Call Connect Callback] Clinician call connected.");
  };

  const callDisconnectCallback = () => {
    // If in full screen, exit
    if (isFullscreen.active) isFullscreen.exit();

    // Unmount video call component
    setShowCall(false);
    setIsCallInProgress(false);

    // Clear selected patient -> unmounts patient details component
    setShowDetails(false);
    setSelectedPatient(null);

    // Unmount the chat component in patient details pane (prop)
    setIsChatMounted(false);

    // Mount call feedback component
    if (!callStatus.noUserMedia) {
      // Show call feedback if the call ends normally
      setShowCallFeedback(true);
    } else {
      // If the call fails with no detected device

      // Mount waiting room table component
      handleShowWaitingRoom();

      // Reset the state
      setCallStatus((prevState) => ({
        ...prevState,
        noUserMedia: false,
      }));
    }
  };

  const callFeedbackCallback = () => {
    // Unmount call feedback component
    setShowCallFeedback(false);

    // Mount waiting room table component
    handleShowWaitingRoom();
  };

  return (
    <Box className={classes.root}>
      <Box mb={3}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          direction={"row"}
          spacing={5}
          style={{ marginBottom: "0.75rem" }}
        >
          {/* Clinic name, code & term */}
          <Grid item>
            <Grid container alignItems="center" direction={"row"} spacing={4}>
              {/* Clinic name */}
              <Grid item>
                <Badge
                  color={isClinicOpen ? "secondary" : "primary"}
                  className={classes.badge}
                  // badgeContent={isClinicOpen ? `${t("Open")}` : `${t("Closed")}`}
                >
                  <Typography
                    variant="h4"
                    style={{
                      // margin: "0rem 1rem 0 0",
                      textTransform: "capitalize",
                    }}
                  >
                    {clinicInfo?.name}
                  </Typography>
                </Badge>
              </Grid>

              {/* Clinic term */}
              {!isCallInProgress && !showCallFeedback && (
                <Grid item>
                  <ClinicCode {...{ clinicCode: clinicInfo?.code }} />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={3}>
              {/* Clinic code  */}
              {!isCallInProgress && !showCallFeedback && (
                <>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <CopyClinicLink
                          {...{ clinicURL: clinicInfo?.clinicURL }}
                        />
                      </Grid>

                      <Grid item>
                        {clinicInfo && (
                          <ClinicInvite {...{ clinic: clinicInfo }} />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Clinic action buttons */}
        {!isCallInProgress && !showCallFeedback && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            {/* <Grid item>
              <Box className={classes.breadcrumb}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <NavigateNextOutlinedIcon
                      className={classes.breadcrumbIcon}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="button"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {(showWaitingRoom && `${t("ClinicWaitingRoom")}`) ||
                        (showInvitations && `${t("ClinicInvitations")}`) ||
                        (showFiles && `${t("ClinicAttachments")}`)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}

            <Grid item>
              <Grid container spacing={5}>
                <Grid item>
                  <Badge
                    className={classes.badge}
                    badgeContent={
                      patientsList && patientsList[clinicId]?.length
                    }
                    color="secondary"
                  >
                    <Button
                      className={clsx(classes.tab, {
                        [classes.activeTab]: showWaitingRoom,
                      })}
                      variant="text"
                      size="small"
                      onClick={handleShowWaitingRoom}>
                      {t("ClinicWaitingRoom")}
                    </Button>
                  </Badge>
                </Grid>

                <Grid item>
                  <Badge
                    className={classes.badge}
                    badgeContent={allInvitations?.length}
                    color="secondary"
                  >
                    <Button
                      className={clsx(classes.tab, {
                        [classes.activeTab]: showInvitations,
                      })}
                      variant="text"
                      size="small"
                      onClick={handleShowInvitations}>
                      {t("ClinicInvitations")}
                    </Button>
                  </Badge>
                </Grid>

                <Grid item>
                  <Badge badgeContent={allFiles?.length} color="secondary">
                    <Button
                      className={clsx(classes.tab, {
                        [classes.activeTab]: showFiles,
                      })}
                      variant="text"
                      size="small"
                      onClick={handleShowFiles}>
                      {t("ClinicAttachments")}
                    </Button>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>

      {isCallInProgress && (
        <Box mb={2}>
          <Alert {...{ alert: alerts.checkPatientId }} />
        </Box>
      )}

      <FullScreen handle={isFullscreen}>
        <FlexContainer>
          {showWaitingRoom && (
            <FlexItemContainer>
              {patientsList &&
              patientsList[clinicId] &&
              patientsList[clinicId].length > 0 ? (
                <PatientsTable
                  {...{
                    clinicInfo,
                    patientsList,
                    handleSelectedPatient,
                    handleCallConnect,
                    showDetails,
                  }}
                />
              ) : (
                <NothingToShow
                  {...{
                    text: t("ClinicNoPatientWaiting"),
                    icon: <IconWaitingRoom />,
                  }}
                />
              )}
            </FlexItemContainer>
          )}

          {showFiles && (
            <FlexItemContainer>
              {allFiles.length > 0 ? (
                <FilesTable
                  {...{
                    allFiles,
                    setSelectedAttachment,
                    setSelectedMp4Attachment,
                  }}
                />
              ) : (
                <NothingToShow
                  {...{
                    text: t("ClinicNoFilesYet"),
                    icon: <IconAttachment />,
                  }}
                />
              )}
            </FlexItemContainer>
          )}

          {showInvitations && (
            <FlexItemContainer>
              {allInvitations.length > 0 ? (
                <InvitationsTable {...{ allInvitations }} />
              ) : (
                <NothingToShow
                  {...{
                    text: t("ClinicNoInvitationsYet"),
                    icon: <IconInvitation />,
                  }}
                />
              )}
            </FlexItemContainer>
          )}

          {showCall && isCallInProgress && roomId && (
            <FlexItemContainer
              // style={{ height: !isFullscreen.active && "600px" }}
              style={{ minWidth: 400 }}
            >
              <VideoCall
                {...{
                  // clinicId,
                  isClinician: true,
                  roomId,
                  patientId,
                  // showDetails,
                  setShowCallInviteModal,
                  setShowDetails,
                  callConnectCallback,
                  callDisconnectCallback,
                  setIsChatMounted,
                }}
              />
            </FlexItemContainer>
          )}

          {showCallFeedback && (
            <CallFeedback {...{ patientId, callFeedbackCallback }} />
          )}

          {clinicInfo &&
            selectedPatient &&
            patientsList &&
            patientsList[clinicId] &&
            patientsList[clinicId].length > 0 && (
              <FlexItemRightSidePanelContainer
                style={{
                  display: showDetails ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                <PatientDetails
                  {...{
                    clinicInfo,
                    roomId: roomId || "",
                    selectedPatient,
                    setSelectedAttachment,
                    setSelectedMp4Attachment,
                    isChatMounted,
                    setQuestionResult,
                  }}
                />
              </FlexItemRightSidePanelContainer>
            )}
          {selectedAttachment && (
            <Lightbox
              image={selectedAttachment.fileUrl}
              title={selectedAttachment.fileName}
              onClose={() => setSelectedAttachment(null)}
            />
          )}
          {selectedMp4Attachment && (
            <ModalVideo
              channel="custom"
              url={selectedMp4Attachment.fileUrl}
              isOpen={selectedMp4Attachment}
              onClose={() => setSelectedMp4Attachment(null)}
              autoplay
            />
          )}
          {showCallInviteModal && roomId && (
            <InviteToRoom2
              {...{ roomId, showCallInviteModal, setShowCallInviteModal }}
            />
          )}
        </FlexContainer>
        {/* Show selected questionnaire result */}
        {questionResult.open && (
          <CustomModal
            {...{
              handleClose: () =>
                setQuestionResult({ open: false, selected: null }),
            }}
            // style={{ padding: "1rem" }}
          >
            <CardHeader
              title={questionResult.selected?.name}
              subheader={"Patient's response"}
            />
            <Divider />
            <CardContent>
              {questionResult.selected?.responses?.map(
                (response: IQuestionnaireResponse, i: number) => {
                  return (
                    <Box p={1} key={i}>
                      <Typography>
                        <strong>{`${i + 1}. ${response.question}`}</strong>
                      </Typography>
                      <Typography>
                        {Array.isArray(response.answer) ? (
                          <ul>
                            {response.answer.map((option, i) => {
                              return <li key={i}>{option}</li>;
                            })}
                          </ul>
                        ) : (
                          response.answer
                        )}
                      </Typography>
                    </Box>
                  );
                }
              )}
            </CardContent>

            <CardActions>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() =>
                  setQuestionResult({ open: false, selected: null })
                }
              >
                {"Close"}
              </Button>
            </CardActions>
          </CustomModal>
        )}
      </FullScreen>
    </Box>
  );
};

export default Clinic;
