import React, { useState, useEffect } from "react";

// API
import { apiGet, endpoints } from "api";

// MUI
import { Grid, TextField, Button, Icon, Typography } from "@mui/material";
import { Autocomplete } from '@mui/material';

const TreatEditorFields = ({
  propName,
  editorData,
  handleSave,
  handleDelete,
}) => {
  const itemLabels = {
    qualifications: "Qualification",
    licenses: "License",
    memberships: "Membership",
    treatments: "Treatment",
    clinicalInterests: "Clinical Interest",
    specialities: "Speciality",
  };

  const defaultValues = {
    name: "",
    issuer: "",
    country: "",
    state: "",
    number: "",
    url: "",
    issueDate: null,
    expirationDate: null,
  };
  const [formData, setFormData] = useState(
    editorData ? editorData : defaultValues,
  );

  // Fetching options for autocomplete
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  useEffect(() => {
    apiGet(endpoints.profile.list[propName])
      .then((res) => {
        setAutocompleteOptions(res.data);
      })
      .catch(console.error);
  }, []);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeAutocomplete = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      spacing={2}
      style={{ maxWidth: 500 }}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>{`${
          editorData ? "Edit" : "Add"
        } a ${itemLabels[propName]}`}</Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          freeSolo
          fullWidth
          options={autocompleteOptions}
          // value={formData.name}
          defaultValue={formData.name || ""}
          onChange={(_, value) => handleChangeAutocomplete("name", value)}
          onInputChange={(_, value) => handleChangeAutocomplete("name", value)}
          renderInput={(params) => (
            // Wrapping TextField in a form to deactivate autocomplete
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <TextField
                {...params}
                label={itemLabels[propName]}
                variant="outlined"
                required
              />
            </form>
          )}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Country"
          name="country"
          value={formData.country}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Code"
          name="code"
          value={formData.code}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <TextField
          variant="outlined"
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
      </Grid>

      {/* Actions */}
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1rem" }}
        spacing={3}
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Icon>delete</Icon>}
            disabled={!editorData}
            onClick={() => handleDelete(editorData.id)}
          >
            {"Delete"}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            disabled={!formData.name}
            onClick={() => {
              handleSave(formData);
            }}
          >
            {"Save"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TreatEditorFields;
