import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";

// Utils
import PropTypes from "prop-types";

// File uploader
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import "common/uploaderStyles.css";

// Contexts
import { FullscreenContext, ChatContext } from "contexts";

// Components
import { patientId, sendChatMessage } from "common/videoCallModule";

// Utils
import clsx from "clsx";
import Linkify from "react-linkify";

// Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import {
  TextField,
  Typography,
  Grid,
  Box,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import SendIcon from "@mui/icons-material/Send";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AttachmentRoundedIcon from "@mui/icons-material/AttachmentRounded";

const boxShadow =
  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";

const useStyles = makeStyles((theme) => ({
  rootMobile: {
    // padding: theme.spacing(3),
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "10",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  rootDesktop: {
    width: "100%",
    height: "100%",
  },
  wrapperDesktop: {
    width: "100%",
    height: "100%",
    background: "white",
  },
  wrapperMobile: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // borderRadius: "0.5rem",
    // overflow: "hidden",
    maxWidth: 400,
    background: "white",
  },
  header: {
    padding: theme.spacing(1, 2),
    color: theme.palette.white,
    backgroundColor: "white",
    borderBottom: "solid 0.1px grey",
    zIndex: 1,
  },
  bodyMobile: {
    height: 200,
    padding: theme.spacing(2),
    // minHeight: 200,
    // maxHeight: 400,
    backgroundColor: theme.palette.white,
    overflow: "auto",
    // zIndex: "-1",
  },
  bodyDesktop: {
    height: 300,
    // maxHeight: 300,
    // maxWidth: "fit-content",
    padding: theme.spacing(2),
    // backgroundColor: theme.palette.white,
    overflow: "auto",
    // zIndex: "-1",
  },
  bodyDesktopFullscreen: {
    height: "calc(100vh - 368px)",
    // height: 500,
    padding: theme.spacing(2),
    // backgroundColor: theme.palette.white,
    overflow: "auto",
    // zIndex: "-1",
  },
  messageContainer: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 1.5),
  },
  localMessage: {
    backgroundColor: "#FAFAFA",
    width: "fit-content",
    maxWidth: "75%",
    marginLeft: "auto",
    borderRadius: "1rem 0 1rem 1rem",
    overflowWrap: "anywhere",
  },
  remoteMessage: {
    backgroundColor: theme.palette.background.default,
    width: "fit-content",
    maxWidth: "75%",
    marginRight: "auto",
    borderRadius: "0 1rem 1rem 1rem",
    overflowWrap: "anywhere",
  },
  actionBar: {
    height: "100%",
    padding: theme.spacing(1, 1),
    backgroundColor: "#FAFAFA",
  },
  customDropWrapper: {
    backgroundColor: theme.palette.background.default,
    "& .e-upload .e-file-select-wrap": {
      display: "none",
    },
  },
  uploader: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    overflow: "auto",
  },
  displayUploader: {
    // display: "block",
    // height: "inherit",
    zIndex: 1,
    opacity: 100,
    transition: "ease 0.25s",
  },
  hideUploader: {
    // display: "none",
    // height: 0,
    zIndex: -1,
    opacity: 0,
    transition: "ease 0.25s",
  },
}));

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);

const Chat = (props) => {
  const { isClinician, roomId, handleCloseChatWindow } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  // Context
  const [, isFullscreen] = useContext(FullscreenContext);
  const [chatState, setChatState, , setChatMessageCounter] =
    useContext(ChatContext);

  const dropAreaRef = useRef(null);
  const messageField = useRef(null);
  const messageBodyEnd = useRef(null);

  // States
  const [showUploader, setShowUploader] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);

  useEffect(() => {
    const listener = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        e.stopPropagation();
        handleChatMessageSubmit();
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    messageBodyEnd.current.scrollIntoView({ behavior: "smooth" });
  }, [chatState]);

  const handleClickedOutside = (e) => {
    if (e.target === e.currentTarget) handleCloseChatWindow();
  };

  const createTimestampForChatMsg = () => {
    // TODO: Allow different locales
    const locale = "en-GB";
    return new Date().toLocaleString(locale, {
      hour: "numeric",
      minute: "numeric",
      // hour12: true,
    });
  };

  const handleChatMessageSubmit = async () => {
    const type = "text";
    const body = messageField.current.value;

    messageField.current.value = "";
    messageField.current.focus();

    setChatState((prevState) => ({
      ...prevState,
      [roomId]: [
        ...prevState[roomId],
        {
          type,
          author: "local",
          body,
          time: createTimestampForChatMsg(),
        },
      ],
    }));

    setChatMessageCounter((prevState) => ({
      ...prevState,
      [roomId]: 0,
    }));

    const message = JSON.stringify({ type, body });

    await sendChatMessage(message, roomId);
  };

  const handleSendFiles = async ({ name, fileUrl }) => {
    const type = "file";
    const encodedFileUrl = window.btoa(fileUrl);
    const body = { name, fileUrl: encodedFileUrl };

    setChatState((prevState) => ({
      ...prevState,
      [roomId]: [
        ...prevState[roomId],
        {
          type,
          author: "local",
          body,
          time: createTimestampForChatMsg(),
        },
      ],
    }));

    setChatMessageCounter((prevState) => ({
      ...prevState,
      [roomId]: 0,
    }));

    const message = JSON.stringify({
      type,
      body,
    });

    await sendChatMessage(message, roomId);
  };

  // UPLOADER FUNCS
  const asyncSettings = {
    saveUrl: `${process.env.REACT_APP_API_DOMAIN}/patient/attachment2`,
    removeUrl: `${process.env.REACT_APP_API_DOMAIN}/patient/attachment2`,
  };

  // const onBeforeUpload = (args) => {
  //   console.log("[onBeforeUpload]", args);
  // };

  const onUploading = (args, PatientID) => {
    console.log("[onUploading]", args);
    args.customFormData = [{ PatientID }];
  };

  const handleCloseUpload = () => {
    console.log("[onClose]");
    const clearAllBtn = document.getElementsByClassName("e-file-clear-btn")[0];
    if (clearAllBtn) clearAllBtn.click();

    setShowUploader(false);
    setAttachedFiles([]);
  };

  const onUploadSuccess = (args) => {
    const response = JSON.parse(args?.e?.target?.response);
    const data = response?.[0];
    const { name, fileUrl } = data;

    if (args.operation === "upload") {
      // Add the file to the state to send it to the chat later onActionComplete
      setAttachedFiles((prevState) => [...prevState, { name, fileUrl }]);

      console.log("[onUploadSuccess] Operation: upload", data);
    } else if (args.operation === "remove") {
      console.log("[onUploadSuccess] Operation: remove", args);
    }
  };

  const onUploadFailure = (args) => {
    console.log("[onUploadFailure]", args);
  };

  const onRemoving = (args, PatientID) => {
    console.log("[onRemoving]", args);
    args.customFormData = [{ PatientID }];
  };

  const onActionComplete = () => {
    console.log("[onActionComplete]");
    attachedFiles.forEach((file) => {
      console.log(">>> File", file);
      const { name, fileUrl } = file;
      handleSendFiles({ name, fileUrl });
    });

    handleCloseUpload();
  };

  return (
    <Box
      className={clsx({
        [classes.rootMobile]: !isClinician,
        // [classes.rootDesktop]: isClinician,
      })}
      onClick={handleClickedOutside}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={clsx({
          [classes.wrapperMobile]: !isClinician,
          [classes.wrapperDesktop]: isClinician,
        })}
        style={{ position: "relative", overflow: "hidden" }}
      >
        {/* Chat header in mobile mode */}
        {!isClinician && (
          <Grid item className={classes.header}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="body1">
                  {t("ChatTitleMyClinic")}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="close"
                  onClick={handleCloseChatWindow}
                  size="small"
                  sx={{ backgroundColor: "white" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Chat header in desktop mode */}
        {isClinician && (
          <Grid
            item
            className={classes.header}
            style={{
              backgroundColor: "#FAFAFA",
              borderTop: "1px solid #eeeeee",
            }}
          >
            <Typography variant="button" color="primary">
              {t("ChatTitleWithPatient")}
            </Typography>
          </Grid>
        )}

        {/* Chat body starts */}
        <Grid
          item
          className={clsx({
            [classes.bodyMobile]: !isClinician,
            [classes.bodyDesktop]: isClinician && !isFullscreen.active,
            [classes.bodyDesktopFullscreen]: isClinician && isFullscreen.active,
          })}
          ref={dropAreaRef}
        >
          {chatState[roomId] &&
            chatState[roomId].map((message, index) => {
              return (
                <Box key={index}>
                  {message.type === "text" && (
                    <Grid
                      container
                      className={clsx(classes.messageContainer, {
                        [classes.localMessage]: message.author === "local",
                        [classes.remoteMessage]: message.author !== "local",
                      })}
                      direction="column"
                      alignItems={
                        message.author === "local" ? "flex-end" : "flex-start"
                      }
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="primary"
                          style={{ textTransform: "capitalize" }}
                        >
                          {message.author !== "local" && message.author}
                        </Typography>
                      </Grid>
                      <Grid item style={{ maxWidth: 300 }}>
                        <Typography variant="body2" gutterBottom>
                          <Linkify componentDecorator={componentDecorator}>
                            {message.body}
                          </Linkify>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          {message.time}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {message.type === "file" && (
                    <Grid
                      container
                      className={clsx(classes.messageContainer, {
                        [classes.localMessage]: message.author === "local",
                        [classes.remoteMessage]: message.author !== "local",
                      })}
                      direction="column"
                      alignItems={
                        message.author === "local" ? "flex-end" : "flex-start"
                      }
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          color="primary"
                          style={{ textTransform: "capitalize" }}
                        >
                          {message.author !== "local" && message.author}
                        </Typography>
                      </Grid>
                      <Grid item style={{ maxWidth: 300 }}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                          wrap="nowrap"
                        >
                          <Grid item>
                            <AttachmentRoundedIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" gutterBottom>
                              <Link
                                component="a"
                                href={window.atob(message.body.fileUrl)}
                                target="_blank"
                                download
                              >
                                {message.body.name}
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          {message.time}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              );
            })}
          <div ref={messageBodyEnd} />
        </Grid>
        {/* Chat body ends */}

        {/* Chat action bar starts */}
        <Grid item className={classes.actionBar}>
          {/* <form onSubmit={handleSubmit}> */}
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            spacing={1}
          >
            <Grid item>
              <IconButton
                color="secondary"
                aria-label="attach files"
                onClick={() => {
                  document
                    .getElementsByClassName("e-file-select-wrap")[0]
                    .querySelector("button")
                    .click();
                }}
                size="large"
              >
                <AttachFileRoundedIcon style={{ transform: "rotate(33deg)" }} />
              </IconButton>
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <TextField
                name="messageField"
                variant="standard"
                placeholder={t("ChatTypeMessagePlaceholder")}
                // value={textFieldValue}
                // onChange={handleChange}
                multiline={true}
                rows={1}
                maxRows={3}
                size="small"
                fullWidth={true}
                inputRef={messageField}
                // autoFocus // This causes a bug in tabs
              />
            </Grid>

            <Grid item>
              <IconButton
                type="submit"
                color="secondary"
                aria-label="close"
                // style={{ backgroundColor: "rgba(0, 222, 182, 0.1)" }}
                onClick={handleChatMessageSubmit}
                size="large"
              >
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* </form> */}
        </Grid>
        {/* Chat action bar ends */}

        {/* Chat upload starts */}
        <Grid item>
          <Grid
            container
            direction="column"
            className={clsx(classes.uploader, {
              [classes.displayUploader]: showUploader,
              [classes.hideUploader]: !showUploader,
            })}
            wrap="nowrap"
          >
            <Grid item style={{ textAlign: "right" }}>
              <IconButton
                color="secondary"
                aria-label="close"
                onClick={handleCloseUpload}
                size="large"
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              style={{
                backgroundColor: "#F4F6F8",
              }}
            >
              <UploaderComponent
                type="file"
                id="uploadFiles"
                dropArea={dropAreaRef.current}
                maxFileSize={15000000}
                allowedExtensions={".png, .jpg, .jpeg, .pdf, .mp4, .mov"}
                asyncSettings={asyncSettings}
                autoUpload={false}
                buttons={{
                  browse: "Add Files",
                  clear: "Cancel",
                  upload: "Send Files",
                }}
                selected={(args) => {
                  console.log("[onSelected]", args);
                  setShowUploader(true);
                }}
                clearing={(args) => {
                  console.log("[onClearing]", args);
                  setShowUploader(false);
                  setAttachedFiles([]);
                }}
                uploading={(args) => onUploading(args, patientId)}
                success={onUploadSuccess}
                failure={onUploadFailure}
                removing={(args) => onRemoving(args, patientId)}
                actionComplete={onActionComplete}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

Chat.propTypes = {
  roomId: PropTypes.string.isRequired,
  isClinician: PropTypes.bool.isRequired,
  handleCloseChatWindow: PropTypes.func,
};

Chat.defaultProps = {
  isClinician: false,
  handleCloseChatWindow: PropTypes.func,
};

export default Chat;
