import React, { useState } from "react";

// API
import { apiPost } from "api";

// MUI
import { Box } from "@mui/material";

// Drop zone
import { DropzoneDialogBase } from "material-ui-dropzone";

const ProfileImageUploader = ({ uploader, setUploader, callback }) => {
  const [images, setImages] = useState([]);

  const handleAdd = (filesArray) => {
    setImages(filesArray);
  };

  const handleSave = () => {
    apiPost(uploader.endpoint, {
      uploadFile: images[0].data,
    })
      .then(() => {
        callback();
        handleClose();
      })
      .catch(console.error);
  };

  const handleDelete = () => {
    setImages([]);
  };

  const handleClose = () => {
    setUploader({
      show: false,
      endpoint: null,
    });
  };

  return (
    <Box>
      <DropzoneDialogBase
        open={true}
        fileObjects={images}
        acceptedFiles={["image/jpeg", "image/png"]}
        showPreviews={true}
        // showAlerts={false}
        filesLimit={1}
        maxFileSize={5000000}
        onAdd={handleAdd}
        onSave={handleSave}
        onDelete={handleDelete}
        onClose={handleClose}
      />
    </Box>
  );
};

export default ProfileImageUploader;
