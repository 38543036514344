import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

interface Props {
  status: STATUS;
  onboardingComplete?: boolean;
  onSetup: () => void;
  onManageProduct: () => void;
}

export enum STATUS {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
}

export const StripeSetup = ({ onManageProduct, status, onSetup }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {status === STATUS.COMPLETE && (
        <Button variant="outlined" onClick={onManageProduct}>
          Manage Products
        </Button>
      )}

      {status === STATUS.INCOMPLETE && (
        <Button variant="outlined" onClick={onSetup}>
          Setup Stripe portal
        </Button>
      )}
    </div>
  );
};
