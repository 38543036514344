import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

interface DialogProps {
  children: React.ReactElement | React.ReactElement[];
  onSubmit: () => void;
  title: string;
  subtitle?: string;
  buttonText?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommonDialog = ({
  children,
  buttonText = "Open",
  title,
  subtitle,
  open,
  setOpen,
  onSubmit,
}: DialogProps) => {
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const submit = () => onSubmit();

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle id="alert-dialog-title">
          <Typography>{title}</Typography>
        </DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <Typography variant="caption">{subtitle}</Typography>
        </DialogContent>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={submit}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
