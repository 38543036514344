import { styled } from "@mui/system";

export const ActivateContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  "& > p": {
    marginBottom: "20px",
  },
  "& > div": {
    marginBottom: "20px",
  },
  "& > canvas": {
    marginBottom: "20px",
  },
}));

export const LinksContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  "& > p": {
    marginBottom: "10px",
    marginTop: "20px",
  },
  "& > a:last-child": {
    marginBottom: "20px",
  },
}));

export const LinksSection = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
}));

export const DisableContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  "& > p": {
    marginBottom: "20px",
  },
  "& > h6": {
    marginBottom: "20px",
  },
}));
