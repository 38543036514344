import React from "react";

// Routing
import { BrowserRouter as Router } from "react-router-dom";

// Components
import Main from "./Main";

// Styles
import "./assets/scss/index.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// Providers
import {
  GlobalProvider,
  PatientsProvider,
  ClinicsProvider,
  AccountProvider,
  FullscreenProvider,
  LanguageProvider,
  ChatProvider,
  NotificationsProvider,
  CallProvider,
  MessagingProvider,
} from "contexts";
import { CookiesProvider } from "react-cookie";

// Validators
import validate from "validate.js";
import validators from "./common/validators";
import { QueryClient, QueryClientProvider } from "react-query";

validate.validators = {
  ...validate.validators,
  ...validators,
};

const queryClient = new QueryClient();

const App = (): React.ReactElement => {
  return (
    <Router>
      <CookiesProvider>
        <GlobalProvider>
          <QueryClientProvider client={queryClient}>
            <CallProvider>
              <NotificationsProvider>
                <AccountProvider>
                  <ClinicsProvider>
                    <PatientsProvider>
                      <LanguageProvider>
                        <MessagingProvider>
                          <ChatProvider>
                            <FullscreenProvider>
                              <Main />
                            </FullscreenProvider>
                          </ChatProvider>
                        </MessagingProvider>
                      </LanguageProvider>
                    </PatientsProvider>
                  </ClinicsProvider>
                </AccountProvider>
              </NotificationsProvider>
            </CallProvider>
          </QueryClientProvider>
        </GlobalProvider>
      </CookiesProvider>
    </Router>
  );
};

export default App;
