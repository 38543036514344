import { styled } from "@mui/system";

export const Container = styled("div")(({ theme }) => ({
  width: "100%",
  "& > div": {
    padding: "20px",
  },
  "& > div:not(:last-child)": {
    borderBottom: `solid ${theme.palette.grey["500"]} 0.5px`,
  },
}));

export const Cell = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});
