import React, { useState, useContext } from "react";

// API
import { apiGet, apiPost, endpoints } from "api";

// Components
import {
  ClinicsToolbar,
  ClinicCard,
  AddClinic,
  CreateClinicForm,
} from "./components";
import { FramerModal, UpgradeAccountCreateClinic } from "components";

// Contexts
import { ClinicsContext, AccountContext } from "contexts";

// Utils
import { useSnackbar } from "notistack";

// Time
import { formatISO } from "date-fns";

import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Button,
  IconButton,
  Grow,
  Typography,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2),
  },
}));

const ClinicsManager = ({ history }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [clinicsList, setClinicsList] = useContext(ClinicsContext);
  const [accountData] = useContext(AccountContext);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (data) => {
    setClinicsList(data);
  };

  const handleCreateClinic = async (data) => {
    try {
      // Render the clinic preview to the DOM prior to API call
      // const daysToExpiration = {
      //   H24: 1,
      //   H72: 3,
      //   H168: 7,
      // };

      // const newClinicPreview = {
      //   id: "",
      //   name: data.name,
      //   logo: "",
      //   audioMessagesEnabled: data.audioMessagesEnabled,
      //   code: "",
      //   status: "Active",
      //   expiration: formatISO(
      //     new Date().setDate(
      //       new Date().getDate() + daysToExpiration[data.expirationOption],
      //     ),
      //   ),
      //   openingIntervals: null,
      //   isOpen: true,
      // };

      // setClinicsList((prevState) => [...prevState, newClinicPreview]);

      setOpen(false);

      // Make an API call
      const response = await apiPost(endpoints.clinic.create, data);
      if (response.status === 200) {
        try {
          const res = await apiGet(endpoints.clinic.data);
          if (res.status === 200) {
            if (res.data) handleUpdate(res.data);
            enqueueSnackbar("Clinic created successfully", {
              variant: "success",
            });
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        enqueueSnackbar("There's a problem creating clinic", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Couldn't create the clinic", {
        variant: "error",
      });
    }
  };

  const clickedUpgradeCallback = () => {
    handleClose();
  };

  return (
    <div className={classes.root}>
      <FramerModal {...{ open, handleClose }}>
        {accountData?.features?.createClinics ? (
          <CreateClinicForm {...{ handleCreateClinic }} />
        ) : (
          <UpgradeAccountCreateClinic {...{ clickedUpgradeCallback }} />
        )}
      </FramerModal>

      <ClinicsToolbar>
        <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/availability-profiles")}
            >
              {"Availability profiles"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<AddRoundedIcon />}
              color="primary"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              {t("CreateClinic")}
            </Button>
          </Grid>
        </Grid>
      </ClinicsToolbar>

      <Grid container spacing={3}>
        {clinicsList && clinicsList.length > 0 ? (
          clinicsList.map((clinic, index) => (
            <Grow in={true} timeout={(index + 1) * 250} key={clinic.id}>
              <Grid item lg={4} md={6} xs={12}>
                <ClinicCard
                  clinic={clinic}
                  allClinics={clinicsList}
                  handleUpdate={handleUpdate}
                />
              </Grid>
            </Grow>
          ))
        ) : (
          <AddClinic>
            <IconButton
              aria-label="add"
              color="secondary"
              onClick={() => setOpen(true)}
              size="large">
              <AddIcon style={{ fontSize: "8rem" }} />
            </IconButton>
          </AddClinic>
        )}
      </Grid>
    </div>
  );
};

export default ClinicsManager;
