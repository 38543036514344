import React, { createContext, useState } from "react";

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [accountData, setAccountData] = useState(null);

  return (
    <AccountContext.Provider value={[accountData, setAccountData]}>
      {children}
    </AccountContext.Provider>
  );
};
