import React, { useState, useEffect } from "react";

// API
import { apiGet, endpoints } from "api";

// Components
import { NothingToShow } from "components";
import QuestionnaireRow from "./components/QuestionnaireRow/QuestionnaireRow";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Badge,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const QuestionnaireTable = (props) => {
  const { labels, handleShowBuilder, handleShowPreview } = props;
  const classes = useStyles();

  const [allQuestionnaires, setAllQuestionnaires] = useState(null);

  useEffect(() => {
    getAllQuestionnaires();
  }, []);

  const getAllQuestionnaires = async () => {
    try {
      const response = await apiGet(`${endpoints.clinic.questRetrieve}`);
      if (response.status === 200 && response.data)
        setAllQuestionnaires(response.data);
    } catch (err) {
      console.error("Problem getting questionnaires:", err);
    }
  };

  return <>
    <Grid container justifyContent="space-between">
      <Grid item>
        <Badge badgeContent={"BETA"} color="secondary">
          <Typography variant="h4">
            {labels.questionnaireTableTitle}
          </Typography>
        </Badge>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleShowBuilder()}
          startIcon={<AddOutlinedIcon />}
        >
          {labels.questionnaireTableCreateNewButton}
        </Button>
      </Grid>
    </Grid>

    {allQuestionnaires?.length > 0 && (
      <TableContainer className={classes.table}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>{labels.questionnaireTableColumnName}</TableCell>
              <TableCell>{labels.questionnaireTableColumnCreated}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {allQuestionnaires.map((questionnaire) => (
              <QuestionnaireRow
                {...{
                  questionnaire,
                  getAllQuestionnaires,
                  handleShowPreview,
                  handleShowBuilder,
                }}
                key={questionnaire.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
    {allQuestionnaires?.length === 0 && (
      <Grid container justifyContent="center">
        <Grid item>
          <NothingToShow />
        </Grid>
      </Grid>
    )}
  </>;
};

export default QuestionnaireTable;
