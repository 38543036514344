import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useForm, SubmitHandler } from "react-hook-form";
import { CommonDialog } from "../../Common/CommonDialog";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Alert } from "@material-ui/lab";

interface ChangePasswordFormProps {
  onSubmit: (event: IFormInput) => void;
}

interface IFormInput {
  newPassword: string;
  oldPassword: string;
}

const schema = Joi.object<IFormInput>({
  newPassword: Joi.string().min(8).required().messages({
    "string.min": `New Password must be at least 8 characters long`,
  }),
  oldPassword: Joi.string().required(),
});

export const ChangePasswordForm = ({ onSubmit }: ChangePasswordFormProps) => {
  const [open, setOpen] = useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);

  const { register, handleSubmit, reset, formState } = useForm<IFormInput>({
    resolver: joiResolver(schema),
  });

  // resets for feilds on dialog close
  useEffect(() => reset(), [open]);

  const submit: SubmitHandler<IFormInput> = (data) => {
    onSubmit(data);
    setOpen(false);
    reset();
  };

  return (
    <CommonDialog
      title="Change Password"
      subtitle="When you change your password you will be autmatically logged out"
      onSubmit={handleSubmit(submit)}
      buttonText="Change password"
      {...{ open, setOpen }}
    >
      <FormControl sx={{ mb: 2, width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          Old Password
        </InputLabel>
        <OutlinedInput
          {...register("oldPassword")}
          id="outlined-adornment-password"
          type={showOldPassword ? "text" : "password"}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowOldPassword(!showOldPassword)}
                onMouseDown={() => setShowOldPassword(!showOldPassword)}
                edge="end"
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Old Password"
        />
      </FormControl>

      <FormControl sx={{ mb: 1, width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          New Password
        </InputLabel>
        <OutlinedInput
          {...register("newPassword", {
            minLength: {
              value: 8,
              message: "Password must be atleast 8 characters long",
            },
          })}
          id="outlined-adornment-password"
          type={showNewPassword ? "text" : "password"}
          fullWidth
          error={formState?.errors?.newPassword ? true : false}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPassword(!showNewPassword)}
                onMouseDown={() => setShowNewPassword(!showNewPassword)}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="New Password"
        />
      </FormControl>
      <ErrorDisplay message={formState?.errors?.newPassword?.message} />
    </CommonDialog>
  );
};

function ErrorDisplay({ message }: any) {
  if (!message) return null;
  return <Alert severity="error">{message}</Alert>;
}
