import React from "react";

// Material components
import { SvgIcon } from "@mui/material";

// Minifying SVGs: https://jakearchibald.github.io/svgomg/
export default function IconWaitingRoom(props) {
  return (
    <SvgIcon {...props}>
        <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <circle cx="256" cy="108.236" r="108.236" fill="#eb5569"></circle>
      <circle cx="256" cy="108.236" r="80.647" fill="#fdd1d5"></circle>
      <path fill="#5a5a5a" d="M76.165 416.9h24.906v82.647H76.165z"></path>
      <path
        fill="#838383"
        d="M34.655 499.547c0-6.878 5.575-12.453 12.453-12.453h83.021c6.878 0 12.453 5.575 12.453 12.453 0 6.878-5.575 12.453-12.453 12.453H47.108c-6.878 0-12.453-5.575-12.453-12.453z"
      ></path>
      <path fill="#5a5a5a" d="M243.547 416.9h24.906v82.647h-24.906z"></path>
      <path
        fill="#838383"
        d="M202.037 499.547c0-6.878 5.575-12.453 12.453-12.453h83.021c6.878 0 12.453 5.575 12.453 12.453 0 6.878-5.575 12.453-12.453 12.453H214.49c-6.878 0-12.453-5.575-12.453-12.453z"
      ></path>
      <path fill="#5a5a5a" d="M410.929 416.9h24.906v82.647h-24.906z"></path>
      <path
        fill="#838383"
        d="M369.418 499.547c0-6.878 5.575-12.453 12.453-12.453h83.021c6.878 0 12.453 5.575 12.453 12.453 0 6.878-5.575 12.453-12.453 12.453h-83.021c-6.877 0-12.453-5.575-12.453-12.453z"
      ></path>
      <path
        fill="#444"
        d="M76.165 423.908h24.906v38.548H76.165zM243.547 423.908h24.906v38.548h-24.906zM410.929 423.908h24.906v38.548h-24.906z"
      ></path>
      <path
        fill="#08a9f1"
        d="M158.868 397.626H18.369v-89.524c0-28.153 22.822-50.975 50.975-50.975h38.548c28.153 0 50.975 22.822 50.975 50.975v89.524z"
      ></path>
      <path
        fill="#01c0fa"
        d="M4.928 428.566v-23.332a7.608 7.608 0 017.608-7.608h152.165a7.608 7.608 0 017.608 7.608v23.332a7.608 7.608 0 01-7.608 7.608H12.536a7.607 7.607 0 01-7.608-7.608z"
      ></path>
      <path
        fill="#08a9f1"
        d="M326.25 397.626h-140.5v-89.524c0-28.153 22.822-50.975 50.975-50.975h38.549c28.153 0 50.975 22.822 50.975 50.975v89.524z"
      ></path>
      <path
        fill="#01c0fa"
        d="M172.309 428.566v-23.332a7.608 7.608 0 017.608-7.608h152.165a7.608 7.608 0 017.608 7.608v23.332a7.608 7.608 0 01-7.608 7.608H179.917a7.608 7.608 0 01-7.608-7.608z"
      ></path>
      <path
        fill="#08a9f1"
        d="M493.631 397.626H353.132v-89.524c0-28.153 22.822-50.975 50.975-50.975h38.548c28.153 0 50.975 22.822 50.975 50.975v89.524z"
      ></path>
      <path
        fill="#01c0fa"
        d="M339.691 428.566v-23.332a7.608 7.608 0 017.608-7.608h152.165a7.608 7.608 0 017.608 7.608v23.332a7.608 7.608 0 01-7.608 7.608H347.299a7.607 7.607 0 01-7.608-7.608z"
      ></path>
      <path
        fill="#e53950"
        d="M256 0c-4.672 0-9.277.298-13.795.875 53.192 6.796 94.442 52.352 94.442 107.361s-41.25 100.565-94.442 107.361c4.518.577 9.122.875 13.795.875 59.682 0 108.236-48.554 108.236-108.236S315.682 0 256 0z"
      ></path>
      <path
        fill="#0290cf"
        d="M107.893 257.127H75.77c28.153 0 50.975 22.822 50.975 50.975v89.524h32.122v-89.524c.001-28.153-22.821-50.975-50.974-50.975zM275.274 257.127h-32.122c28.153 0 50.975 22.822 50.975 50.975v89.524h32.122v-89.524c.001-28.153-22.822-50.975-50.975-50.975zM442.656 257.127h-32.122c28.153 0 50.975 22.822 50.975 50.975v89.524h32.122v-89.524c0-28.153-22.822-50.975-50.975-50.975z"
      ></path>
      <path
        fill="#08a9f1"
        d="M164.701 397.626h-32.122a7.608 7.608 0 017.608 7.608v23.332a7.608 7.608 0 01-7.608 7.608h32.122a7.608 7.608 0 007.608-7.608v-23.332a7.608 7.608 0 00-7.608-7.608zM332.082 397.626H299.96a7.608 7.608 0 017.608 7.608v23.332a7.608 7.608 0 01-7.608 7.608h32.122a7.608 7.608 0 007.608-7.608v-23.332a7.607 7.607 0 00-7.608-7.608zM499.464 397.626h-32.122a7.608 7.608 0 017.608 7.608v23.332a7.608 7.608 0 01-7.608 7.608h32.122a7.608 7.608 0 007.608-7.608v-23.332a7.608 7.608 0 00-7.608-7.608z"
      ></path>
      <path
        fill="#384949"
        d="M287.869 115.963H256a7.725 7.725 0 01-7.726-7.726V66.901a7.726 7.726 0 1115.452 0v33.609h24.143a7.725 7.725 0 017.726 7.726 7.725 7.725 0 01-7.726 7.727z"
      ></path>
      <g fill="#eef5f6">
        <path d="M175.702 115.597l.015.001h14.56a7.725 7.725 0 007.726-7.726 7.725 7.725 0 00-7.726-7.726h-14.52a81.624 81.624 0 00-.405 8.091c.001 2.482.13 4.934.35 7.36zM256 165.867a7.725 7.725 0 00-7.726 7.726v14.56c0 .119.013.236.018.354 2.538.241 5.107.376 7.708.376s5.17-.135 7.708-.376c.005-.118.018-.234.018-.354v-14.56a7.725 7.725 0 00-7.726-7.726zM321.722 100.145a7.725 7.725 0 00-7.726 7.726 7.725 7.725 0 007.726 7.726h14.56l.015-.001c.22-2.426.349-4.878.349-7.361 0-2.731-.14-5.43-.405-8.091h-14.519zM256 27.589c-2.628 0-5.224.136-7.788.382v14.266a7.726 7.726 0 1015.452 0V27.961a81.551 81.551 0 00-7.664-.372z"></path>
      </g>
    </svg>
    </SvgIcon>
  );
}