import React from "react";

// MUI
import { Typography, Box } from "@mui/material";
import { Alert } from '@mui/material';

const NotEditable = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h4">{"Profile Edit"}</Typography>
        <Typography color="textSecondary">
          {"Change or modify your profile details"}
        </Typography>
      </Box>

      <Box>
        <Alert severity="warning">
          You cannot edit your profile at this moment.
        </Alert>
      </Box>
    </>
  );
};

export default NotEditable;
