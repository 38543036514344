import React, { useState, useEffect } from "react";

// Utils
import { useSnackbar } from "notistack";

// Components
import HeaderWithEdit from "./HeaderWithEdit";
import QualContent from "./QualContent";
import QualEditorFields from "./QualEditorFields";
import TreatContent from "./TreatContent";
import TreatEditorFields from "./TreatEditorFields";
import LocContent from "./LocContent";
import LocEditorFields from "./LocEditorFields";

// API
import { apiPatch, endpoints } from "api";

// MUI
import { Grid } from "@mui/material";
import { FramerModal } from "components";

const PropItem = ({ propName, propArray, propType, callback }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState([]);

  const [open, setOpen] = useState(false);
  const [editorData, setEditorData] = useState(null);

  // Update state when propArray changes
  useEffect(() => {
    // Check if data is array, then populate
    if (Array.isArray(propArray)) {
      setFormState(propArray);
    }
  }, [propArray]);

  const handleOpenEditor = (data) => {
    setEditorData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditorData(null);
  };

  const handleSubmit = (payload, notify = true) => {
    apiPatch(endpoints.profile.all, payload)
      .then((res) => {
        if (res.status === 200) {
          if (notify)
            enqueueSnackbar("Profile updated successfully", {
              variant: "success",
            });
          handleClose();
          callback();
        } else {
          if (notify)
            enqueueSnackbar("Unable to update the profile", {
              variant: "error",
            });
        }
      })
      .catch((err) => {
        if (notify)
          enqueueSnackbar("Problem updating profile", {
            variant: "error",
          });
        console.error(err);
      });
  };

  const handleDelete = (id) => {
    let payload;

    const remaining = formState.filter((item) => item.id != id);
    const orderedRemaining = ensureOrder(remaining);

    if (propType == "loc") {
      payload = {
        [propName]: orderedRemaining,
      };
    } else {
      payload = {
        props: { [propName]: orderedRemaining },
      };
    }

    handleSubmit(payload);
  };

  const handleSaveLocation = (data) => {
    let payload;

    // Location map must be stringified
    if (data.map) {
      data.map = JSON.stringify(data.map);
    }

    if (editorData) {
      const otherProps = formState.filter((item) => item.id != editorData.id);

      payload = { [propName]: [...otherProps, { ...data }] };
    } else {
      payload = { [propName]: [...formState, { ...data }] };
    }

    handleSubmit(payload);
  };

  const handleSave = (data) => {
    let payload;

    if (editorData) {
      // If we're editing an item
      const otherProps = formState.filter((item) => item.id != editorData.id);
      payload = {
        props: { [propName]: [...otherProps, { ...data }] },
      };
    } else {
      // If we're adding an item
      payload = {
        props: {
          [propName]: [
            ...formState,
            {
              ...data,
              // Adding order for the newly created items
              order: formState.length,
            },
          ],
        },
      };
    }

    handleSubmit(payload);
  };

  const ensureOrder = (arr) => {
    return arr.map((el, i) => {
      el.order = i;
      return el;
    });
  };

  const handleReorder = (index, toIndex) => {
    const arr = [...formState];
    const el = arr[index];

    arr.splice(index, 1);
    arr.splice(toIndex, 0, el);

    const newOrder = ensureOrder(arr);

    setFormState(newOrder);

    const payload = {
      props: {
        [propName]: newOrder,
      },
    };

    handleSubmit(payload, false);
  };

  const titleLabels = {
    qualifications: "Qualifications",
    licenses: "Licenses",
    memberships: "Memberships",
    treatments: "Treatments",
    clinicalInterests: "Clinical Interests",
    specialities: "Specialities",
    locations: "Locations",
  };

  return (
    <>
      {/* Modal to add new items */}
      <FramerModal {...{ open, handleClose }}>
        {propType === "qual" && (
          <QualEditorFields
            {...{
              propName,
              editorData,
              handleSave,
              handleDelete,
            }}
          />
        )}

        {propType === "treat" && (
          <TreatEditorFields
            {...{
              propName,
              editorData,
              handleSave,
              handleDelete,
            }}
          />
        )}

        {propType === "loc" && (
          <LocEditorFields
            {...{
              propName,
              editorData,
              handleSave: handleSaveLocation,
              handleDelete,
            }}
          />
        )}
      </FramerModal>

      <Grid container spacing={1}>
        {/* Header */}
        <Grid item xs={12}>
          <HeaderWithEdit
            {...{
              title: titleLabels[propName],
              handleClick: () => setOpen(true),
              isAdding: true,
            }}
          />
        </Grid>

        {/* Rendering existing items */}
        {propType === "qual" && (
          <Grid item xs={12}>
            <QualContent
              {...{
                data: formState,
                propName,
                handleOpenEditor,
                handleReorder,
              }}
            />
          </Grid>
        )}

        {propType === "treat" && (
          <Grid item xs={12}>
            <TreatContent
              {...{
                data: formState,
                propName,
                handleOpenEditor,
              }}
            />
          </Grid>
        )}

        {propType === "loc" && (
          <Grid item xs={12}>
            <LocContent
              {...{
                data: formState,
                propName,
                handleOpenEditor,
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PropItem;
