import React, { useState, useEffect } from "react";

// Time
import { differenceInMilliseconds } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";

// Material UI
import { Typography, Grid, Box, Tooltip } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";

const VideoCallTime = () => {
  const { t } = useTranslation();

  const [callTime, setCallTime] = useState({
    callStartTime: new Date(),
    inCallTime: "00:00",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = differenceInMilliseconds(
        new Date(),
        callTime.callStartTime,
      );
      setCallTime((prevState) => ({
        ...prevState,
        inCallTime: new Date(duration).toISOString().slice(14, 19),
      }));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Tooltip title={t("CallActionsCallTime")} arrow>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            <TimerIcon />
          </Grid>
          <Grid item>
            <Typography variant="button">{callTime.inCallTime}</Typography>
          </Grid>
        </Grid>
      </Tooltip>
    </Box>
  );
};

export default VideoCallTime;
