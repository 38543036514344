import { useQuery } from "react-query";
import { Client } from "./client";
import { HTTPStatus } from "./api-new.interface";

interface GetEnableMfsRes {
  authenticatorUri: string;
  sharedKey: string;
}

const endpoint = {
    availabilityProfile: "/clinic/availabilityprofiles",
};

/***************
 * API Hooks   *
 ***************/

export const useGetAvaliabilityProfile = () => {
  return useQuery("avalibilityProfile", async () => {
    try {
      const availabilityProfile = endpoint.availabilityProfile
      const { data, status } = await Client.instance.get(availabilityProfile);
      if (status !== HTTPStatus.OK) throw "";
      return data;
    } catch {
      throw "Unable to Avalibility profiles";
    }
  });
};
