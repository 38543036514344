const authMessages = {
  200: {
    message: "Logged in successfully",
    variant: "success",
  },
  401: {
    InvalidUsernamePassword: {
      message: "Email or password not recognized",
      variant: "error",
    },
    MFARequired: {
      message: "Multi-Factor Authentication is required",
      variant: "warning",
    },
    MFAFailed: {
      message: "MFA failed",
      variant: "error",
    },
    LockedOut: {
      message: "Your account is temporarily locked",
      variant: "error",
    },
  },
  default: {
    message: "There is a problem signing in",
    variant: "error",
  },
};

export default authMessages;
