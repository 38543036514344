import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Alert } from "@mui/material";

interface Option {
  value: string;
  label: string;
}

interface Props {
  onChange: (data: string) => void;
  options: Option[];
  defaultValue: string;
  disabled?: boolean;
  disabledText?: string;
}

export const SingleSelectForm = ({
  onChange,
  options = [],
  defaultValue,
  disabled,
  disabledText,
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(
    defaultValue || options[0]?.value || ""
  );

  useEffect(() => setSelected(defaultValue), [defaultValue]);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    onChange(value);
    setSelected(value);
  };

  return (
    <>
      {!disabled && (
        <FormControl fullWidth sx={{ minWidth: "200px" }}>
          <Select
            onChange={handleChange}
            value={selected}
            fullWidth
            size="small"
          >
            {options.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {disabled && <Alert severity="warning">{disabledText}</Alert>}
    </>
  );
};
