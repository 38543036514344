import React, { useState, useEffect } from "react";

// API
import { apiGet, apiPost, endpoints } from "api";

// Router
import { withRouter } from "react-router-dom";

// Utils
import PropTypes from "prop-types";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

// File uploader
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import "common/uploaderStyles.css";
import {
  asyncSettings,
  onActionComplete,
  onUploadSuccess,
  onUploading,
  onRemoving,
  onUploadFailure,
} from "common/uploaderModule";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 4,
      maximum: 32,
    },
  },
  phoneNumber: {
    // numericality: true,
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.webp)",
    // backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    background: "rgba(232, 62, 140, 0.9)",
    borderRadius: "0.5rem",
    padding: theme.spacing(3, 4),
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    // paddingBototm: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& > *": {
      marginTop: theme.spacing(1),
    },
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 100,
    flexBasis: 700,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  formControl: {
    flexBasis: `30%`,
  },
  datePickerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

const makeRange = (start, end) =>
  Array.from({ length: end - start }, (v, k) => k + start);

const CheckIn = (props) => {
  const { history } = props;
  const code = props.match.params.clinic_code;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      id: uuidv4(),
      code,
      invitationCode: "",
    },
    touched: {},
    errors: {},
  });

  const [isClinicOpen, setIsClinicOpen] = useState(true);

  useEffect(() => {
    const listener = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }
      // else if (e.code === "Escape") {
      //   handleCloseChatWindow();
      // }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line
  }, [formState.values]);

  useEffect(() => {
    getPatientAndClinicInfo();
    // eslint-disable-next-line
  }, []);

  const getPatientAndClinicInfo = async () => {
    try {
      const response = await apiGet(endpoints.patient.infoClinicCode, code);
      if (response.status === 200 && response.data) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            id: response.data.id,
          },
        }));

        // TODO: Change the object destination in case it changes in backend
        setIsClinicOpen(response.data.clinicOpeningDetails.isOpen);
      } else {
        console.error("Unable to get patient info");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await apiPost(
        endpoints.patient.checkIn,
        formState.values
      );
      if (response.status === 200 && response.data) {
        enqueueSnackbar("Check in successful", {
          variant: "success",
        });
        history.push(`/c/${response.data.roomId}/${response.data.patientID}`);
      } else {
        enqueueSnackbar("Problem checking in", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("Problem checking in", {
        variant: "error",
      });
      console.error(err);
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            {/* <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h3">
                MyClinic
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Powered by Medicalchain
                </Typography>
              </div>
            </div> */}
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              {/* <Alert {...{ alert: alerts.patientInformation }} />
              <Alert {...{ alert: alerts.improveYourExperience }} />
              <Alert {...{ alert: alerts.photoOrMedicalReportUpload }} /> */}
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Typography className={classes.title} variant="h2" gutterBottom>
                  Patient Check-in
                </Typography>
                <Typography
                  color={isClinicOpen ? "textSecondary" : "primary"}
                  gutterBottom
                >
                  {isClinicOpen
                    ? "Please enter your details to check into the clinic."
                    : "The clinic is closed for now. You'll be able to check-in after it's open again."}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("name")}
                  fullWidth
                  helperText={
                    hasError("name") ? formState.errors.name[0] : null
                  }
                  label="Full name"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ""}
                  variant="outlined"
                  required
                  disabled={!isClinicOpen}
                />
                <div
                  className={classes.datePickerContainer}
                  style={{ display: "flex" }}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required
                    disabled={!isClinicOpen}
                  >
                    <InputLabel id="select-outlined-label">Day</InputLabel>
                    <Select
                      labelId="select-outlined-label"
                      id="select-outlined"
                      value={formState.values.doB_Day || ""}
                      name="doB_Day"
                      onChange={handleChange}
                      label="doB_Day"
                    >
                      {makeRange(1, 32).map((i) => (
                        <MenuItem value={`${i}`} key={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required
                    disabled={!isClinicOpen}
                  >
                    <InputLabel id="select-outlined-label">Month</InputLabel>
                    <Select
                      labelId="select-outlined-label"
                      id="select-outlined"
                      value={formState.values.doB_Month || ""}
                      name="doB_Month"
                      onChange={handleChange}
                      label="doB_Month"
                    >
                      {makeRange(1, 13).map((i) => (
                        <MenuItem value={`${i}`} key={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required
                    disabled={!isClinicOpen}
                  >
                    <InputLabel id="select-outlined-label">Year</InputLabel>
                    <Select
                      labelId="select-outlined-label"
                      id="select-outlined"
                      value={formState.values.doB_Year || ""}
                      name="doB_Year"
                      onChange={handleChange}
                      label="doB_Year"
                    >
                      {makeRange(
                        new Date().getFullYear() - 100,
                        new Date().getFullYear() + 1
                      )
                        .reverse()
                        .map((i) => (
                          <MenuItem value={`${i}`} key={i}>
                            {i}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  className={classes.textField}
                  error={hasError("phoneNumber")}
                  fullWidth
                  helperText={
                    hasError("phoneNumber")
                      ? formState.errors.phoneNumber[0]
                      : null
                  }
                  label="Phone number"
                  name="phoneNumber"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phoneNumber || ""}
                  variant="outlined"
                  required
                  style={{ marginBottom: "1rem" }}
                  disabled={!isClinicOpen}
                />
                <UploaderComponent
                  type="file"
                  id="uploadFiles"
                  maxFileSize={15000000}
                  allowedExtensions={".png, .jpg, .jpeg, .pdf, .mp4, .mov"}
                  asyncSettings={asyncSettings}
                  actionComplete={onActionComplete}
                  success={onUploadSuccess}
                  failure={onUploadFailure}
                  uploading={(args) => onUploading(args, formState.values.id)}
                  removing={(args) => onRemoving(args, formState.values.id)}
                  autoUpload={true}
                  enabled={isClinicOpen}
                />
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={!formState.isValid || !isClinicOpen}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Check in now
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

CheckIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(CheckIn);
