import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Container } from "./AccountSubscription.styles";

export enum SubscriptionView {
  "ACTIVE" = "ACTIVE",
  "INACTIVE" = "INACTIVE",
}

interface RegionProps {
  onClick: () => void;
  subState: string;
  trialEndDate: string;
}

export const AccountSubscription = ({
  subState,
  trialEndDate,
  onClick,
}: RegionProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Button variant="outlined" onClick={onClick}>
        {subState === SubscriptionView.ACTIVE && t("ManageSubscription")}
        {subState === SubscriptionView.INACTIVE && t("Upgrade to Premium")}
      </Button>
      {subState === SubscriptionView.ACTIVE && trialEndDate && (
        <p>{`(Ends on ${trialEndDate})`}</p>
      )}
    </Container>
  );
};
