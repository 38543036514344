import { log } from "helpers";

const apiDelete = async (endpoint, param = "") => {
  const API_ENDPOINT = `${
    sessionStorage.getItem("endpointPath") || process.env.REACT_APP_API_DOMAIN
  }${endpoint}${param}`;
  let response = null;
  let data = null;

  const orgId = sessionStorage.getItem("orgId");

  const headers = new Headers({
    "Content-Type": "application/json",
    ...(orgId && { "X-OrganisationID": orgId }),
  });

  try {
    response = await fetch(API_ENDPOINT, {
      method: "DELETE",
      credentials: "include",
      headers,
    });

    try {
      data = await response.json();
    } catch (err) {
      // console.log(`[${endpoint}] No data provided from server`);
    }

    const resObject = {
      status: response && response.status,
      data,
      ...(param && { param }),
    };
    log({ text: `[${endpoint}]`, params: [resObject] });
    return resObject;
  } catch (err) {
    log({
      type: "err",
      text: `[${endpoint}${param}]`,
      params: [response && response.status, err.message],
    });
    return null;
  }
};

export default apiDelete;
