import React, { useState } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Components
import {
  QuestionnaireTable,
  QuestionnaireBuilder,
  QuestionnairePreview,
} from "./components";

import makeStyles from '@mui/styles/makeStyles';
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const Questionnaire = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const labels = {
    questionnaireTableTitle: t("Questionnaires"),
    questionnaireTableCreateNewButton: t("QuestionnaireCreateNew"),
    questionnaireTableColumnName: t("QuestionnaireTableName"),
    questionnaireTableColumnCreated: t("QuestionnaireTableCreated"),
    questionnairePreviewTitle: t("QuestionnairePreviewTitle"),
    questionnairePreviewEditButton: t("QuestionnaireEdit"),
    questionnaireBuilderTitle: t("QuestionnaireBuilder"),
    // questionnaireDetailsTitle: t("QuestionnaireDetails"),
    questionnaireInputDescriptionLabel: "Option",
    questionnaireDetailsTitle: "",
    questionnaireAddQuestions: t("QuestionnaireQuestionsAdd"),
    questionnaireTitleLabel: t("QuestionnaireTitle"),
    questionnaireDescriptionLabel: t("QuestionnaireDescription"),
    questionnairePreviewLabel: t("QuestionnairePreview"),
    textFieldQuestion: t("QuestionnaireQuestionsTextField"),
    selectionQuestion: t("QuestionnaireQuestionsSelection"),
    checkboxQuestion: t("QuestionnaireQuestionsCheckbox"),
    questionTitleLabel: t("QuestionnaireQuestionsTitle"),
    addOptionLabel: t("QuestionnaireQuestionAddOption"),
    addOptionTitleLabel: t("QuestionnaireQuestionAddOptionTitle"),
    questionnaireUpdate: t("QuestionnaireUpdate"),
    questionnaireSave: t("QuestionnaireSave"),
    questionContainerTitle: t("QuestionnaireQuestionContainerTitle"),
    questionContainerRequiredLabel: t(
      "QuestionnaireQuestionContainerRequiredLabel",
    ),
  };

  const [showTable, setShowTable] = useState(true);
  const [showBuilder, setShowBuilder] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);

  const handleShowBuilder = (questionnaire = null) => {
    setShowTable(false);
    setShowPreview(false);
    setSelectedQuestionnaire(questionnaire);
    setShowBuilder(true);
  };

  const handleHideBuilder = () => {
    setShowTable(true);
    setShowPreview(false);
    setShowBuilder(false);
  };

  const handleShowPreview = (questionnaire) => {
    setShowTable(false);
    setSelectedQuestionnaire(questionnaire);
    setShowPreview(true);
  };

  const handleHidePreview = () => {
    setShowTable(true);
    setShowPreview(false);
  };

  return (
    <Box className={classes.root}>
      {showTable && (
        <QuestionnaireTable
          {...{
            labels,
            handleShowBuilder,
            handleShowPreview,
            handleHideBuilder,
          }}
        />
      )}

      {showBuilder && (
        <QuestionnaireBuilder
          {...{
            labels,
            selectedQuestionnaire,
            handleHideBuilder,
          }}
        />
      )}

      {showPreview && (
        <QuestionnairePreview
          {...{
            labels,
            handleShowBuilder,
            handleHidePreview,
            selectedQuestionnaire,
          }}
        />
      )}
    </Box>
  );
};

export default Questionnaire;
