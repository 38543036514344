const alerts = {
  patientInformation: {
    type: "info",
    title: "Patient Information",
    message:
      "Please check that you have joined the correct ‘virtual waiting room’ and when the video consultation begins, to satisfy yourself that you are speaking with the correct and expected health professional before divulging any sensitive data to them.",
  },
  improveYourExperience: {
    type: "info",
    title: "Improve Your Experience",
    message:
      "We recommend you use a strong Wifi connection and close any unused applications to ensure the best quality call with the clinician.",
  },
  photoOrMedicalReportUpload: {
    type: "info",
    title: "Photo or Medical Report Upload",
    message:
      "If you have an image or medical report you wish for the clinician to review before your appointment please upload these by using the 'Upload' option on this page.",
  },
  checkPatientId: {
    type: "warning",
    title: "Identity check",
    message:
      "Please ensure you verify the patient's identity before starting the consultation.",
  },
};

export default alerts;
