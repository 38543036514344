import React, { useState, useContext, useEffect } from "react";

// Routing
import { withRouter } from "react-router-dom";

// SignalR
import { signalRConnection, patientId, roomId } from "common/videoCallModule";

// Contexts
import { FullscreenContext, AccountContext, ChatContext } from "contexts";

// Components
import VideoCallTime from "../VideoCallTime";
import VideoCallFlipCamera from "../VideoCallFlipCamera";
import PatientEndCallDialog from "../PatientEndCallDialog";
import { FramerModal, ClinicSelectDropDown } from "components";

// import { toggleMic, toggleCamera } from "common/videoCallModule";

// Utils
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { useSnackbar } from "notistack";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  IconButton,
  Badge,
  Grid,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import CallEndIcon from "@mui/icons-material/CallEnd";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
// import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TransferWithinAStationRoundedIcon from "@mui/icons-material/TransferWithinAStationRounded";
import { apiPost } from "api";

const boxShadow =
  "0px 2px 4px -1px rgba(0,0,0,0.05), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 8px 0px rgba(0,0,0,0.03)";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.white,
    backgroundColor: theme.palette.white,
    // color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2),
  },
  iconButton: {
    border: "1px solid WhiteSmoke",
    // color: theme.palette.white,
    backgroundColor: "white !important",
    boxShadow,
  },
}));

const VideoCallActions = (props) => {
  const {
    history,
    peers,
    selfVideo,
    roomId,
    isClinician,
    isSharingScreen,
    openChatWindow,
    turnOffScreensharing,
    turnOnScreensharing,
    setShowCallInviteModal,
    setShowDetails,
    handleOpenInviteToRoom,
    handleOpenCallSettings,
    endCall,
    setLogs,
    remoteScreensharingStream,
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // const { t } = useTranslation();

  // CONTEXTS
  const [, isFullscreen] = useContext(FullscreenContext);
  const [accountData] = useContext(AccountContext);
  const [, , chatMessageCounter, setChatMessageCounter] =
    useContext(ChatContext);

  // STATES
  const [isMicOn, setIsMicOn] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [openMoveRoomModal, setOpenMoveRoomModal] = useState(false);
  const [openPatientEndCallConfirm, setOpenPatientEndCallConfirm] =
    useState(false);

  useEffect(() => {
    if (!signalRConnection) return;
    signalRConnection.on("EndCallRejoin", (res) => {
      console.log(">>> Received EndCallRejoin", res);

      signalRConnection
        .invoke("EndCall", patientId)
        .then(() => {
          window.location.assign(
            `http://${window.location.host}/c/${res.roomId}/${patientId}`
          );
        })
        .catch(console.error);
    });
  }, [signalRConnection]);

  const handlePatientEndCallSubmit = () => {
    setOpenPatientEndCallConfirm(false);
    endCall(true);
  };

  const handlePatientEndCallCancel = () => {
    setOpenPatientEndCallConfirm(false);
  };

  return (
    <Box className={classes.root}>
      <PatientEndCallDialog
        {...{
          openPatientEndCallConfirm,
          handlePatientEndCallSubmit,
          handlePatientEndCallCancel,
        }}
      />

      <Grid
        container
        alignItems="center"
        justifyContent={!isMobile ? "space-between" : "center"}
        spacing={1}
        wrap="nowrap"
      >
        {isClinician && !isMobile && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <VideoCallTime />
          </Grid>
        )}
        {!isClinician && !isMobile && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          ></Grid>
        )}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          xs={12}
          wrap="nowrap"
        >
          <Grid item>
            <Tooltip title={"Toggle microphone"} arrow>
              <IconButton
                className={classes.iconButton}
                color={isMicOn ? "secondary" : "primary"}
                aria-label="toggle microphone"
                sx={{ backgroundColor: "white !important" }}
                onClick={() => {
                  selfVideo.current.srcObject.getAudioTracks().forEach((i) => {
                    if (i.readyState === "live") {
                      i.enabled = !isMicOn;
                      console.log(
                        `Mic is ${i.enabled ? "enabled" : "disabled"}`
                      );
                    }
                  });
                  setIsMicOn((prevState) => !prevState);
                }}
                size="large"
              >
                {isMicOn ? <MicOutlinedIcon /> : <MicOffOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </Grid>

          {selfVideo.current?.srcObject?.getVideoTracks().length > 0 && (
            <Grid item>
              <Tooltip title={"Toggle camera"} arrow>
                <IconButton
                  className={classes.iconButton}
                  color={isCameraOn ? "secondary" : "primary"}
                  aria-label="toggle camera"
                  onClick={() => {
                    selfVideo.current.srcObject
                      .getVideoTracks()
                      .forEach((i) => {
                        if (i.readyState === "live") {
                          i.enabled = !isCameraOn;
                          console.log(
                            `Cam is ${i.enabled ? "enabled" : "disabled"}`
                          );
                        }
                      });
                    setIsCameraOn((prevState) => !prevState);
                  }}
                  size="large"
                >
                  {isCameraOn ? (
                    <VideocamOutlinedIcon />
                  ) : (
                    <VideocamOffOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {!isClinician && (
            <Grid item>
              <Tooltip title={"Chat"} arrow>
                <IconButton
                  color="secondary"
                  aria-label="chat"
                  onClick={() => {
                    setChatMessageCounter((prevState) => ({
                      ...prevState,
                      [roomId]: 0,
                    }));
                    openChatWindow();
                  }}
                  className={classes.iconButton}
                  size="large"
                >
                  <Badge
                    color="primary"
                    badgeContent={
                      chatMessageCounter[roomId]
                        ? chatMessageCounter[roomId]
                        : 0
                    }
                  >
                    <ChatOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {
            <Grid item>
              <VideoCallFlipCamera
                {...{
                  className: classes.iconButton,
                  peers,
                  selfVideo,
                  setLogs,
                }}
              />
            </Grid>
          }

          {/* Screen sharing - Not available on mobile */}
          {!isMobile && (
            <Grid item>
              <Tooltip
                title={
                  !remoteScreensharingStream
                    ? "Toggle screensharing"
                    : "Only one person can share screen at a time"
                }
                arrow
              >
                <Box>
                  <IconButton
                    disabled={remoteScreensharingStream}
                    color={isSharingScreen ? "secondary" : "default"}
                    aria-label="screensharing"
                    onClick={
                      isSharingScreen
                        ? () => turnOffScreensharing()
                        : () => turnOnScreensharing()
                    }
                    className={classes.iconButton}
                    size="large"
                  >
                    <ScreenShareOutlinedIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Tooltip title={"End call"} arrow>
              <IconButton
                color="primary"
                aria-label="end call"
                onClick={() => {
                  if (isClinician) {
                    endCall(true);
                  } else {
                    setOpenPatientEndCallConfirm(true);
                  }
                }}
                className={classes.iconButton}
                size="large"
              >
                <CallEndIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          {isClinician && sessionStorage.getItem("orgId") && (
            <>
              <Grid item>
                <Tooltip title={"Change room"} arrow>
                  <IconButton
                    color="primary"
                    aria-label="end call"
                    onClick={() => setOpenMoveRoomModal(true)}
                    className={classes.iconButton}
                    size="large"
                  >
                    <TransferWithinAStationRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <FramerModal
                {...{
                  open: openMoveRoomModal,
                  handleClose: () => setOpenMoveRoomModal(false),
                }}
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Typography>
                      {"Move the patient to another clinic's waiting room"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ClinicSelectDropDown
                      {...{
                        callback: (selectedClinic) => {
                          setOpenMoveRoomModal(false);

                          signalRConnection
                            .invoke("EndCallRejoin", patientId)
                            .then(() => {
                              console.log(">>> Invoked EndCallRejoin");
                              apiPost(
                                `/clinic/patients/${patientId}/move/${selectedClinic}`
                              )
                                .then(() => {
                                  enqueueSnackbar(
                                    "Room changed successfully.",
                                    {
                                      variant: "success",
                                    }
                                  );

                                  endCall(true);
                                })
                                .catch(console.error);
                            })
                            .catch(console.error);
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setOpenMoveRoomModal(false);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {"Close"}
                    </Button>
                  </Grid>
                </Grid>
              </FramerModal>
            </>
          )}

          {isClinician && !isMobile && (
            <Grid item>
              <Tooltip title={"Patient info & Chat"} arrow>
                <IconButton
                  color="default"
                  aria-label="info"
                  onClick={() => {
                    setChatMessageCounter((prevState) => ({
                      ...prevState,
                      [roomId]: 0,
                    }));
                    setShowDetails((prevState) => !prevState);
                  }}
                  className={classes.iconButton}
                  size="large"
                  sx={{ backgroundColor: "white" }}
                >
                  <Badge
                    color="primary"
                    badgeContent={
                      chatMessageCounter[roomId]
                        ? chatMessageCounter[roomId]
                        : 0
                    }
                  >
                    <InfoOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {isClinician && accountData?.features?.multiPartyVideo && (
            <Grid item>
              <Tooltip title={"Invite more participants"} arrow>
                <IconButton
                  color="secondary"
                  aria-label="invite"
                  onClick={() => setShowCallInviteModal(true)}
                  className={classes.iconButton}
                  size="large"
                  sx={{ backgroundColor: "white" }}
                >
                  <GroupAddOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Grid item>
            <Tooltip title={"Toggle fullscreen"} arrow>
              <IconButton
                color="default"
                aria-label="fullscreen"
                onClick={() =>
                  isFullscreen.active
                    ? isFullscreen.exit()
                    : isFullscreen.enter()
                }
                className={classes.iconButton}
                size="large"
                sx={{ backgroundColor: "white" }}
              >
                {isFullscreen.active ? (
                  <FullscreenExitOutlinedIcon />
                ) : (
                  <FullscreenOutlinedIcon />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <Tooltip title={"Video & audio settings"} arrow>
              <IconButton
                color="default"
                aria-label="call settings"
                onClick={handleOpenCallSettings}
                size="large"
                sx={{ backgroundColor: "white" }}
              >
                <SettingsOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

VideoCallActions.propTypes = {
  roomId: PropTypes.string.isRequired,
  isClinician: PropTypes.bool,
  isSharingScreen: PropTypes.bool,
  openChatWindow: PropTypes.func,
  turnOffScreensharing: PropTypes.func,
  turnOnScreensharing: PropTypes.func,
  setShowDetails: PropTypes.func,
  handleOpenInviteToRoom: PropTypes.func,
  handleOpenCallSettings: PropTypes.func,
  endCall: PropTypes.func,
};

VideoCallActions.defaultProps = {
  isClinician: false,
};

export default withRouter(VideoCallActions);
