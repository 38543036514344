import { log } from "helpers";

const apiPost = async (endpoint, payload) => {
  const API_ENDPOINT = `${
    sessionStorage.getItem("endpointPath") || process.env.REACT_APP_API_DOMAIN
  }${endpoint}`;
  const body = JSON.stringify(payload);
  let response = null;
  let data = null;

  const orgId = sessionStorage.getItem("orgId");

  const headers = new Headers({
    "Content-Type": "application/json",
    ...(orgId && { "X-OrganisationID": orgId }),
  });

  try {
    response = await fetch(API_ENDPOINT, {
      method: "POST",
      credentials: "include",
      headers,
      body,
    });

    try {
      data = await response.json();
    } catch (err) {
      // console.log(`[${endpoint}] No data provided from server`);
    }

    const resObject = { status: response && response.status, data };

    const ignoredEndpoints = ["/clinic/files"];

    if (
      !ignoredEndpoints.includes(endpoint) &&
      !endpoint.includes(ignoredEndpoints)
    )
      log({ text: `[${endpoint}]`, params: [resObject], sLog: false });
    return resObject;
  } catch (err) {
    log({
      type: "err",
      text: `[${endpoint}]`,
      params: [response && response.status, err.message],
    });
    return null;
  }
};

export default apiPost;
