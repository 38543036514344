import React, { useState } from "react";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography,
  Switch,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Notifications = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(true);
  return (
    <Card {...rest} className={clsx(classes.root, className)} elevation={0}>
      <CardHeader
        title={t("Notifications")}
        subheader={t("SettingsNotificationsSubheader")}
      />
      <Divider />
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body1">
              {t("SettingsNotificationsPatientJoinsWaitingRoom")}
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={isChecked}
              onChange={() => setIsChecked((prevState) => !prevState)}
              name="patientNotification"
              inputProps={{ "aria-label": "online patient notification" }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string,
};

export default Notifications;
