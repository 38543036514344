import React from "react";

// Stripe
import { createCheckoutSession } from "common/stripeFunctions";

// Material UI
import { Grid, Typography, Button } from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const UpgradeAccountCreateClinic = (props) => {
  const { clickedUpgradeCallback } = props;

  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>{t("AccountUpgradeToUseFeature")}</Typography>
      </Grid>
      <Grid item onClick={clickedUpgradeCallback}>
        <Button
          variant="contained"
          color="primary"
          onClick={createCheckoutSession}
        >
          {t("Upgrade to Premium")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UpgradeAccountCreateClinic;
