import React, { useState, useContext } from "react";

// Utils
import classnames from "classnames";
import { useSnackbar, SnackbarContent } from "notistack";

// Router
import { Link as RouterLink } from "react-router-dom";

// Contexts
import { PatientsContext, CallContext } from "contexts";

import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Box,
  Paper,
  Collapse,
  Typography,
  Card,
  CardActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const boxShadow =
  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "344px !important",
    },
    boxShadow,
  },
  card: {
    backgroundColor: "#fef3e5",
    border: "1px solid #fb8c00",
    width: "100%",
  },
  snackbarMessage: {
    fontWeight: "bold",
    color: "#643900",
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
  },
  icons: {
    marginLeft: "auto",
  },
  iconButton: {
    color: "#643900",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  collapse: {
    padding: theme.spacing(1, 2),
  },
}));

// eslint-disable-next-line
const OnlinePatientNotifier = React.forwardRef((props, ref) => {
  const { id, message } = props;
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  // Contexts
  const [patientsList, setPatientsList] = useContext(PatientsContext);
  const { callStatus, setCallStatus } = useContext(CallContext);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDismiss = () => {
    closeSnackbar(id);
  };

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              component={!callStatus.isCallInProgress ? RouterLink : "div"}
              to={`/clinic/${id}`}
              onClick={handleDismiss}
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="subtitle2"
                className={classes.snackbarMessage}
              >
                {message}
              </Typography>
            </Grid>

            <Grid item>
              <div className={classes.icons}>
                <IconButton
                  className={classnames(classes.iconButton, {
                    [classes.expandOpen]: expanded,
                  })}
                  size="small"
                  aria-label="Show more"
                  onClick={handleExpandClick}
                >
                  <ExpandMoreIcon />
                </IconButton>

                <IconButton
                  className={classes.iconButton}
                  size="small"
                  onClick={handleDismiss}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            {patientsList[id] &&
              patientsList[id].map((patient) => {
                if (patient.patientOnline)
                  return (
                    <Box key={patient.id}>
                      <Typography variant="subtitle2" gutterBottom>
                        {patient.name}
                      </Typography>
                    </Box>
                  );
              })}
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default OnlinePatientNotifier;
