import React from "react";

// Utils
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";

// Animation
import { motion, AnimatePresence } from "framer-motion";
import { Grid, Icon, IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalWrapper: {
    outline: "none",
    zIndex: 2,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0.5rem",
    padding: theme.spacing(2, 3),
    maxWidth: "90vw",
  },
}));

// Framer Motion animation
const modalVariant = {
  hidden: { y: -100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.1 },
  },
};

const FramerModal = (props) => {
  const { open, handleClose, children } = props;
  const classes = useStyles();

  return (
    <AnimatePresence>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <motion.div
          className={classes.modalWrapper}
          variants={modalVariant}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Paper className={classes.paper}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
            ></Grid>
            {children}
          </Paper>
        </motion.div>
      </Modal>
    </AnimatePresence>
  );
};

FramerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FramerModal;
