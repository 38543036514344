import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";

// Components
import { Region, ClinicDefaults } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <ClinicDefaults />
        </Grid>
        <Grid item md={6} xs={12}>
          <Region />
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;

/**
 * Unused components, not sure why they were commmented out
 * import { Language, Notifications } from "./components";
 */
