import { Typography } from "@mui/material";
import React from "react";
import { Cell, Container } from "./AccountTable.styles";
import Grid from "@mui/material/Grid";

interface ButtonProps {
  children: React.ReactElement<RowProps> | React.ReactElement<RowProps>[];
}

interface RowProps {
  title: string;
  subTitle: string;
  children: React.ReactNode;
}

export const AccountTable = ({ children }: ButtonProps) => {
  return (
    <Container>
      {React.Children.map(
        children,
        (child: React.ReactElement<RowProps>) => child
      )}
    </Container>
  );
};

export const AccountTableRow = ({ title, subTitle, children }: RowProps) => {
  return (
    <Grid container justifyContent="space-between">
      <Cell>
        <Typography sx={{ maxWidth: "400px" }}>{title}</Typography>
        <Typography variant="caption" sx={{ maxWidth: "400px" }}>
          {subTitle}
        </Typography>
      </Cell>
      <Cell> {children} </Cell>
    </Grid>
  );
};
