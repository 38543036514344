import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Alert as MuAlert, AlertTitle } from '@mui/material';
import { IconButton, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Alert = (props) => {
  const { alert } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <MuAlert
          severity={alert.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </MuAlert>
      </Collapse>
    </div>
  );
};

export default Alert;
