import React, { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

interface Option {
  name: string;
  label: string;
  value: boolean;
}

interface Res {
  [x: string]: boolean;
}

interface Props {
  onChange: (data: Res) => void;
  options: Option[];
}

export const SingleSwitchForm = ({ onChange, options = [] }: Props) => {
  const [state, setState] = React.useState<Res>({});

  useEffect(() => {
    // fixes bug with load time before render
    if (Object.keys(state).length === 0) {
      setState(
        options.reduce((a, v) => ({ ...a, [v.name as any]: v.value }), {})
      );
    }
  }, [options]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setState({ ...state, [name]: checked });
    onChange({ [name]: checked });
  };

  return (
    <FormGroup>
      {options?.map((o) => (
        <FormControlLabel
          key={o.name}
          control={<Switch />}
          label={o.label}
          checked={state[o.name]}
          name={o.name}
          //@ts-ignore
          onChange={handleChange}
        />
      ))}
    </FormGroup>
  );
};
