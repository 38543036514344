import React from "react";

// // Material UI
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const PublishToggle = ({ onChange, value }) => {
  return (
    <ToggleButtonGroup
      value={value}
      size="large"
      exclusive
      onChange={(e, value) => {
        if (value !== null) {
          onChange(value);
        }
      }}
    >
      <ToggleButton value={true}>Published</ToggleButton>
      <ToggleButton value={false}>Unpublished</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PublishToggle;
