import React from "react";

// Material components
import { SvgIcon } from "@mui/material";

// Minifying SVGs: https://jakearchibald.github.io/svgomg/
export default function IconClinic(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.004 14.004">
        <path d="M7.002 2.923c-.472 0-.917.461-1.252.796L1.59 7.881v4.771c0 .522.424.946.947.946h8.932a.947.947 0 00.946-.946V7.881L8.254 3.719c-.334-.335-.78-.796-1.252-.796zm2.887 7.04H7.974v1.915H6.03V9.963H4.114V8.02H6.03V6.104h1.944V8.02h1.915v1.943z" />
        <path d="M13.779 5.947L9.061 1.232C7.96.131 6.044.131 4.941 1.232L.226 5.947a.77.77 0 000 1.089c.29.29.797.29 1.087 0l4.716-4.717c.52-.52 1.426-.52 1.945 0l4.716 4.717a.773.773 0 001.089 0c.3-.302.3-.788 0-1.089z" />
      </svg>
    </SvgIcon>
  );
}
