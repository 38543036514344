import React from "react";

// Utils
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

import makeStyles from '@mui/styles/makeStyles';
import { Button, Tooltip, IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: { whiteSpace: "nowrap" },
}));

const CopyClinicLink = (props) => {
  const { clinicURL, variant, color } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copyToClipboard = async (clinicURL) => {
    try {
      await navigator.clipboard.writeText(`${clinicURL}`);
      enqueueSnackbar("Clinic link copied successfully", { variant: "info" });
    } catch (err) {
      enqueueSnackbar("Failed to copy", { variant: "error" });
      console.error("Failed to copy: ", err);
    }
  };

  return <>
    {variant === 1 && (
      <Button
        className={classes.button}
        variant="contained"
        color={color}
        size="small"
        startIcon={<FileCopyIcon />}
        onClick={() => copyToClipboard(clinicURL)}
      >
        {t("ClinicCopyLink")}
      </Button>
    )}
    {variant === 2 && (
      <Tooltip title={t("ClinicCopyLink")} arrow>
        <IconButton
          color={color}
          aria-label={t("ClinicCopyLink")}
          onClick={() => copyToClipboard(clinicURL)}
          size="large">
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    )}
  </>;
};

CopyClinicLink.propTypes = {
  clinicURL: PropTypes.string.isRequired,
  variant: PropTypes.number,
  color: PropTypes.string,
};
CopyClinicLink.defaultProps = {
  clinicURL: "",
  variant: 1,
  color: "secondary",
};

export default CopyClinicLink;
