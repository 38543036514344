import React, { createContext, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [languageState, setLanguageState] = useState("");

  return (
    <LanguageContext.Provider value={[languageState, setLanguageState]}>
      {children}
    </LanguageContext.Provider>
  );
};
