import React, { useState, useContext, useEffect } from "react";

// Components
import { ClinicInviteForm } from "./components";

// Utils
import PropTypes from "prop-types";

// Contexts
import { AccountContext } from "contexts";

// Time
import { format } from "date-fns";

// API
import { apiGet, apiPost, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Tooltip,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Modal
import { FramerModal } from "components";

// Animation
import { motion, AnimatePresence } from "framer-motion";

// Snackbar
import { useSnackbar } from "notistack";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: { whiteSpace: "nowrap" },
  form: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

// Framer Motion animation
const invitationComponent = {
  hidden: { y: -100, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.1 },
  },
};

const ClinicInvite = (props) => {
  const { clinic, variant, color, patient } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const [accountData] = useContext(AccountContext);
  const [open, setOpen] = useState(false);

  const invitationInstance = {
    type: "email",
    fullName: "",
    email: "",
    countryCode: "",
    phone: "",
    expectedJoinDate: new Date().toISOString(),
  };

  // If patient prop is passed (to send invitation for booking request)
  const patientInvitation = patient
    ? {
        type: "email",
        fullName: patient.name || "NO name",
        email: patient.email || "",
        countryCode: "",
        phone: "", // Cannot use this now as phoneNumber is coming with country code from booking request data
        expectedJoinDate: new Date().toISOString(),
      }
    : null;
  const [allInvitations, setAllInvitations] = useState(
    patientInvitation ? [patientInvitation] : [invitationInstance],
  );

  const [supportedSmsCountries, setSupportedSmsCountries] = useState([]);
  const [defaultCountryCode, setDefaultCountryCode] = useState("");

  // Get supported countries for SMS
  useEffect(() => {
    if (!open) return;

    apiGet(endpoints.config.smsCountries)
      .then((res) => setSupportedSmsCountries(res.data))
      .catch((err) => console.error(err));
  }, [open]);

  // Get default country code form accountData.clinicDefaults
  useEffect(() => {
    if ((!accountData, !open)) return;
    getDefaultCountryCode();
  }, [accountData, open]);

  const getDefaultCountryCode = async () => {
    try {
      const response = await apiGet(endpoints.account.countries);
      const listOfAllCountries = response.data;

      for (let i = 0; i < listOfAllCountries.length; i++) {
        const country = listOfAllCountries[i];
        if (country.isoCode === accountData.clinicDefaults.country) {
          setDefaultCountryCode(country.countryCode);
          return;
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddInvitation = () => {
    setAllInvitations((prevState) => [...prevState, invitationInstance]);
  };

  const handleRemoveInvitation = (index) => {
    const remainingInvitations = allInvitations.filter(
      (_, idx) => idx != index,
    );
    setAllInvitations(remainingInvitations);
  };

  const handleClose = () => {
    setOpen(false);
    setAllInvitations([invitationInstance]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleClose();

    // Map the invitations based on their type
    const mappedInvitations = allInvitations.map((i) => {
      if (i.type == "email") {
        const { type, countryCode, phone, ...rest } = i;
        return { ...rest };
      }
      if (i.type == "phone") {
        const { type, email, ...rest } = i;
        return { ...rest };
      }
    });

    const payload = {
      clinicCode: clinic.code,
      invitations: mappedInvitations,
    };

    try {
      const response = await apiPost(
        endpoints.clinic.createInvitation,
        payload,
      );
      if (response.status === 200) {
        enqueueSnackbar("Invitations sent successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Unable to send invitations", {
          variant: "warning",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Server error", {
        variant: "error",
      });
    }
  };

  return <>
    {/* Button variants */}
    {variant === 1 && (
      <Button
        data-cy="invite-patients-btn"
        className={classes.button}
        variant="contained"
        color={color}
        size="small"
        startIcon={<AddRoundedIcon />}
        onClick={() => setOpen(true)}
        disabled={new Date() > new Date(clinic.expiration)}
      >
        {new Date() > new Date(clinic.expiration)
          ? "Expired clinic"
          : patient
          ? "Send booking invitation"
          : t("InvitePatients")}
      </Button>
    )}

    {variant === 2 && (
      <Tooltip title={t("InvitePatients")} arrow>
        <IconButton
          color={color}
          aria-label="invite"
          onClick={() => setOpen(true)}
          disabled={new Date() > new Date(clinic.expiration)}
          size="large">
          <AddRoundedIcon />
        </IconButton>
      </Tooltip>
    )}

    {/* Main modal */}
    <FramerModal {...{ open, handleClose }}>
      <Typography variant="h5" style={{ textTransform: "capitalize" }}>
        {`${clinic.name} - ${t("InvitePatients")}`}
      </Typography>
      <Typography>{t("InvitePatientsModalTitle")}</Typography>
      {clinic.term !== "Permanent" && (
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {`${t("Expiration")} ${format(new Date(clinic.expiration), "PPp", {
            locale: supportedLocales[i18n.language],
          })}`}
        </Typography>
      )}
      <Divider />
      <form className={classes.form} onSubmit={handleSubmit}>
        {allInvitations.map((invitation, index) => {
          return (
            <AnimatePresence key={invitation.expectedJoinDate}>
              <motion.div
                variants={invitationComponent}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <ClinicInviteForm
                  {...{
                    allInvitations,
                    setAllInvitations,
                    invitation,
                    index,
                    accountData,
                    clinicExpiration: clinic.expiration,
                    defaultCountryCode,
                    supportedSmsCountries,
                    handleRemoveInvitation,
                  }}
                />
              </motion.div>
            </AnimatePresence>
          );
        })}

        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddCircleIcon />}
              onClick={handleAddInvitation}
              disabled={!!patient}
            >
              {t("InvitationAddPatient")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              data-cy="invitation-submit"
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<Icon>send</Icon>}
            >
              {t("PatientInviteButton")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FramerModal>
  </>;
};

ClinicInvite.propTypes = {
  clinic: PropTypes.object.isRequired,
  variant: PropTypes.number,
  color: PropTypes.string,
};

ClinicInvite.defaultProps = {
  variant: 1,
  color: "primary",
};

export default ClinicInvite;
