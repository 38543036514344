import React, { useContext } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Contexts
import { PatientsContext, ClinicsContext } from "contexts";

// Components
import { CopyClinicLink, ClinicInvite } from "components";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

// Router
import { Link as RouterLink } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  Link,
  Badge,
  Grid,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import IconClinic from "icons/svg/IconClinic";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    // height: "inherit",
    position: "relative",
  },
  cardContent: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  leftBorder: {
    borderLeft: "3px solid rgba(255, 255, 255, 0)",
    transition: "ease 0.2s",
  },
  actionButtons: {
    opacity: "0",
    transition: "ease 0.2s",
  },
  hoveredListItem: {
    transition: "ease 0.2s",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    "&:hover $actionButtons": {
      opacity: "1",
    },
    "&:hover $leftBorder": {
      backgroundColor: "rgba(255, 255, 255, 0)",
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));

const LatestClinics = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [clinicsList] = useContext(ClinicsContext);
  const [patientsList] = useContext(PatientsContext);

  const getOnlinePatientsNumber = (clinicId) => {
    if (
      patientsList &&
      patientsList[clinicId] &&
      patientsList[clinicId].length > 0
    )
      return patientsList[clinicId].length;
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)} elevation={0}>
      <CardHeader
        title={t("Clinics")}
        // subheader={`You have ${
        //   clinicsList && clinicsList.length > 0 ? clinicsList.length : 0
        // } active clinic(s)`}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <List data-cy="dashboard-clinics-list">
          {/* Rendering clinics */}
          {clinicsList && clinicsList.length > 0 ? (
            clinicsList.map((clinic, index) => (
              <ListItem
                className={classes.hoveredListItem}
                // divider={index < clinicsList.length - 1}
                key={clinic.id}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  {/* List item content */}
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="center">
                      <ListItem
                        className={classes.leftBorder}
                        button
                        component={RouterLink}
                        to={`/clinic/${clinic.id}`}
                      >
                        <ListItemAvatar>
                          <IconClinic />
                        </ListItemAvatar>

                        <Badge
                          color="secondary"
                          badgeContent={getOnlinePatientsNumber(clinic.id)}
                        >
                          <Typography
                            variant="body1"
                            style={{ marginRight: "0.75rem" }}
                          >
                            {clinic.name}
                          </Typography>
                        </Badge>
                      </ListItem>
                    </Grid>
                  </Grid>

                  {/* List item actions */}
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      spacing={1}
                      className={classes.actionButtons}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <CopyClinicLink
                          {...{
                            clinicURL: clinic.clinicURL,
                            variant: 2,
                            color: "secondary",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <ClinicInvite
                          {...{ clinic, variant: 2, color: "secondary" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography variant="body1">
                {t("NoClinics1")}
                <Link component={RouterLink} to="/clinics-manager">
                  {` ${t("NoClinics2")} `}
                </Link>
                {t("NoClinics3")}
              </Typography>
            </ListItem>
          )}
        </List>
      </CardContent>
      {/* <Divider /> */}
      <CardActions className={classes.cardActions}>
        <Button
          component={RouterLink}
          to="/clinics-manager"
          color="primary"
          size="small"
          variant="text"
        >
          {t("ManageClinics")}
          <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestClinics.propTypes = {
  className: PropTypes.string,
};

export default LatestClinics;
