import React, { useState } from "react";

// API
import { apiPost, endpoints } from "api";

// Utils
import { useSnackbar } from "notistack";

import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Rating from '@mui/material/Rating';

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const CallFeedback = (props) => {
  const { patientId, callFeedbackCallback } = props;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  // On hover starte
  const [hover, setHover] = useState(-1);

  const [feedbackState, setFeedbackState] = useState({
    patientID: patientId,
    rating: 5,
    options: [],
    notes: "",
  });

  const ratingLabels = {
    1: `${t("RatingLabel1")}`,
    2: `${t("RatingLabel2")}`,
    3: `${t("RatingLabel3")}`,
    4: `${t("RatingLabel4")}`,
    5: `${t("RatingLabel5")}`,
  };

  const ratingOptions = {
    0: `${t("RatingCouldntHearPatient")}`,
    1: `${t("RatingPatientCouldntHearMe")}`,
    2: `${t("RatingCouldntSeePatient")}`,
    3: `${t("RatingPatientCouldntSeeMe")}`,
    4: `${t("RatingOther")}`,
  };

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setFeedbackState({
        ...feedbackState,
        options: event.target.checked
          ? [...feedbackState.options, event.target.name]
          : feedbackState.options.filter((item) => item !== event.target.name),
      });
      return;
    }

    setFeedbackState({
      ...feedbackState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    callFeedbackCallback();

    try {
      const response = await apiPost(
        endpoints.clinic.callFeedback,
        feedbackState,
      );

      if (response.status === 200) {
        enqueueSnackbar("Thanks for submitting your feedback.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Unable to submit feedback.", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Problem submitting feedback.", {
        variant: "error",
      });
    }
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Grid item>
            <Typography component="legend">{t("RateCall")}</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              // spacing={2}
            >
              <Grid item>
                <Rating
                  name="rating"
                  size="large"
                  value={feedbackState.rating}
                  defaultValue={5}
                  onChange={(e, newValue) =>
                    setFeedbackState({
                      ...feedbackState,
                      options:
                        newValue >= 3 || !newValue
                          ? []
                          : [...feedbackState.options],
                      rating: newValue,
                    })
                  }
                  onChangeActive={(e, newHover) => {
                    setHover(newHover);
                  }}
                />
              </Grid>

              {/* Rating text guide */}
              <Grid item>
                <Typography>
                  {ratingLabels[hover !== -1 ? hover : feedbackState.rating]}
                </Typography>
              </Grid>

              {/* Rating options */}
              {feedbackState.rating < 3 && feedbackState.rating !== null && (
                <Grid item>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={ratingOptions[0]}
                            onChange={handleChange}
                          />
                        }
                        label={t("RatingCouldntHearPatient")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={ratingOptions[1]}
                            onChange={handleChange}
                          />
                        }
                        label={t("RatingPatientCouldntHearMe")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={ratingOptions[2]}
                            onChange={handleChange}
                          />
                        }
                        label={t("RatingCouldntSeePatient")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={ratingOptions[3]}
                            onChange={handleChange}
                          />
                        }
                        label={t("RatingPatientCouldntSeeMe")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={ratingOptions[4]}
                            onChange={handleChange}
                          />
                        }
                        label={t("RatingOther")}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ minWidth: 320 }}>
            <TextField
              name="notes"
              placeholder={t("RatingPlaceholderComments")}
              // label="Note"
              variant="outlined"
              type="string"
              size="medium"
              fullWidth
              multiline={true}
              rows={3}
              value={feedbackState.notes}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={callFeedbackCallback}
                >
                  {t("Close")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!feedbackState.rating}
                >
                  {t("SubmitFeedback")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CallFeedback;
