import React, { useState, useEffect, useContext } from "react";

// Contexts
import { AccountContext } from "contexts";

// I18n
import { useTranslation } from "react-i18next";

// Components
import { GenericFormModal } from "components";

// Utils
import { useSnackbar } from "notistack";

// APIs
import { apiGet, endpoints } from "api";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

// Router
import { Link as RouterLink } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
  Link,
  Paper,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    // height: "inherit",
  },
  cardContent: {
    padding: 0,
  },
}));

const Tasks = ({ className, taskCount }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [selectOptions, setSelectOptions] = useState({
    titles: [],
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [profileData, setProfileData] = useState();

  // Contexts
  const [accountData, setAccountData] = useContext(AccountContext);

  const getSelectOptions = async (endpoint) => {
    try {
      const response = await apiGet(endpoint);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getProfileData = async () => {
    try {
      const response = await apiGet(endpoints.profile.all);

      if (response.status === 200 && response.data) {
        setProfileData({
          loaded: true,
          ...response.data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // NOTE: This is just a callback func to update Account Data in the context
  // Not to be run on mount as the data already exists
  const getAccountData = async () => {
    try {
      const response = await apiGet(endpoints.account.data);

      if (response.status === 200 && response.data) {
        setAccountData((prevState) => ({ ...prevState, ...response.data }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const getAllSelectOptions = async () => {
      const titles = await getSelectOptions(endpoints.profile.list.titles);
      const countries = await getSelectOptions(endpoints.account.countries);
      const regions = await getSelectOptions(endpoints.account.regions);

      setSelectOptions({
        titles,
        countries,
        regions,
      });
    };

    getAllSelectOptions();
    // getAccountData();
    getProfileData();
  }, []);

  const setFormModal = (formContent) => {
    setModalContent(formContent);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const PasswordTask = () => (
    <Typography variant="body1">
      {` ${t("You have not created a password, ")} `}
      <Link
        href="#"
        onClick={() => {
          setFormModal({
            formTitle: "Set Password",
            acceptLabel: "Save Changes",
            endpoint: endpoints.account.changePassword,
            closeLabel: "Cancel",
            successMessage: "Password updated",
            fieldArray: [
              {
                name: "newPassword",
                label: "New password *",
                type: "input",
                textType: "password",
              },
              {
                name: "confirmPassword",
                label: "Confirm new password *",
                type: "input",
                textType: "password",
              },
            ],
            schema: {},
          });
        }}
      >
        {` ${t("click here to create one.")} `}
      </Link>
    </Typography>
  );

  const RegionTask = () => (
    <Typography variant="body1">
      {` ${t("You have not set your region, ")} `}
      <Link
        href="#"
        onClick={() => {
          setFormModal({
            formTitle: "Update Region",
            acceptLabel: "Save Changes",
            endpoint: endpoints.account.region,
            closeLabel: "Cancel",
            successMessage: "Region updated",
            fieldArray: [
              {
                name: "region",
                label: "Region",
                type: "select",
                options: Object.values(selectOptions.regions).map((region) => ({
                  value: region,
                  label: region,
                })),
              },
            ],
            schema: {},
          });
        }}
      >
        {` ${t("click here to set one.")} `}
      </Link>
    </Typography>
  );

  const PersonalUrlTask = () => (
    <Typography variant="body1">
      {` ${t("You have not set a personal url, ")} `}
      <Link
        href="#"
        onClick={() => {
          setFormModal({
            formTitle: "Update Personal Url",
            acceptLabel: "Save Changes",
            endpoint: endpoints.account.handle,
            closeLabel: "Cancel",
            successMessage: "Personal Url updated",
            processError: (err) => {
              switch (err) {
                case "InvalidHandle":
                  enqueueSnackbar("Invalid Handle", { variant: "error" });
                  return;
                case "HandleExists":
                  enqueueSnackbar("Handle Exists", { variant: "error" });
                  return;
                default:
                  enqueueSnackbar(err, { variant: "error" });
                  return;
              }
            },
            fieldArray: [
              {
                name: "handle",
                label: "Personal Url",
                type: "apiValidateInput",
                prefix:
                  window.location.hostname !== "localhost"
                    ? `${process.env.REACT_APP_MAIN_URL}/`
                    : "https://dev.myclinic.com/",
              },
            ],
            schema: {},
          });
        }}
      >
        {` ${t("click here to set one.")} `}
      </Link>
    </Typography>
  );

  const NameTask = () => (
    <Typography variant="body1">
      {` ${t("You have not set your name, ")} `}
      <Link
        href="#"
        onClick={() => {
          setFormModal({
            formTitle: "Update Personal Details",
            acceptLabel: "Save Changes",
            endpoint: endpoints.profile.personalDetails,
            closeLabel: "Cancel",
            successMessage: "Personal Details updated",
            fieldArray: [
              {
                name: "title",
                label: "Title",
                type: "select",
                options: Object.values(selectOptions.titles).map((title) => ({
                  value: title,
                  label: title,
                })),
              },
              {
                name: "firstName",
                label: "First Name",
                type: "input",
              },
              {
                name: "lastName",
                label: "Last Name",
                type: "input",
              },
            ],
            schema: {},
          });
        }}
      >
        {` ${t("click here to set it.")} `}
      </Link>
    </Typography>
  );

  const ClinicNameTask = () => (
    <Typography variant="body1">
      {`${t("You have not set your primary clinic name, ")}`}
      <Link
        href="#"
        onClick={() => {
          setFormModal({
            formTitle: "Set Clinic Name",
            acceptLabel: "Save Changes",
            endpoint: endpoints.account.primaryClinicName,
            closeLabel: "Cancel",
            successMessage: "Clinic name updated successfully",
            fieldArray: [
              {
                name: "name",
                label: "Clinic Name *",
                type: "input",
              },
            ],
            schema: {},
          });
        }}
      >
        {`${t("click here to set it.")}`}
      </Link>
    </Typography>
  );

  const taskList = [
    accountData && !accountData?.hasPassword && PasswordTask,
    accountData && !accountData?.fullName && NameTask,
    accountData && !accountData?.region && RegionTask,
    profileData && !profileData?.profileUrl && PersonalUrlTask,
    accountData && !accountData?.hasPrimaryClinicName && ClinicNameTask,
    // true && PasswordTask,
    // true && NameTask,
    // true && CountryTask,
    // true && RegionTask,
    // true && PersonalUrlTask,
  ].filter((e) => e);

  const taskListCount = taskList.length;

  if (taskCount) {
    taskCount(taskListCount);
  }

  if (taskListCount === 0) {
    return null;
  }

  return (
    <Paper>
      <Card className={clsx(classes.root, className)} elevation={0}>
        <CardHeader title={t("Tasks")} />
        <Divider />
        <CardContent className={classes.cardContent}>
          <List>
            {taskList.map((Task, i) => (
              <ListItem key={i}>
                <Task />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      <GenericFormModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        formContent={modalContent}
        onSuccess={() => {
          getProfileData();
          getAccountData();
        }}
      />
    </Paper>
  );
};

Tasks.propTypes = {
  className: PropTypes.string,
  taskCount: PropTypes.func,
};

export default Tasks;
