import React from "react";

// Material components
import { SvgIcon } from "@mui/material";

// Minifying SVGs: https://jakearchibald.github.io/svgomg/
export default function IconUpgrade(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M256 0C114.512 0 0 114.497 0 256c0 141.488 114.497 256 256 256 141.488 0 256-114.497 256-256C512 114.512 397.503 0 256 0zm95.276 274.753h-47.917v101.688c0 9.425-7.641 17.067-17.067 17.067h-60.584c-9.425 0-17.067-7.641-17.067-17.067V274.753h-47.918c-14.214 0-22.165-16.4-13.456-27.565l95.276-122.128c6.836-8.761 20.093-8.742 26.913 0l95.276 122.128c8.712 11.167.758 27.565-13.456 27.565z" />
      </svg>
    </SvgIcon>
  );
}
