import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

const ConfirmClickAway = ({ open, handleClose, handleAllowClickAway }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Caution"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            "You are currently on a call. If you click away, your call will be disconnected. Are you sure you want to proceed?"
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleAllowClickAway} color="primary" autoFocus>
          {"Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmClickAway;
