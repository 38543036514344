import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Typography, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: grey[50],
    margin: theme.spacing(1, 0),
    width: "100%",
    maxWidth: 400,
    padding: theme.spacing(1),
  },
}));

const Unspecified = ({ text }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography>
        <i>{text}</i>
      </Typography>
    </Paper>
  );
};

Unspecified.defaultProps = {
  text: "Not specified yet.",
};

export default Unspecified;
