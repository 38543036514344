import React, { useState, useContext } from "react";

// Contexts
import { NotificationsContext, AccountContext } from "contexts";

// Router
import { Link as RouterLink, withRouter } from "react-router-dom";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

// Components
import { TopbarLanguagePopover, TopbarThemePopover } from "components";

import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Popover,
  Grid,
  Typography,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
// import LanguageIcon from "@mui/icons-material/Language";
import InputIcon from "@mui/icons-material/Input";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";

// Snackbar
import { useSnackbar } from "notistack";

// API
import { apiPost, endpoints } from "api";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

// Date
import { formatDistance } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: theme.palette.background.appBar },
  flexGrow: {
    flexGrow: 1,
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const {
    className,
    toggleSidebar,
    isCallInProgress,
    history,
    staticContext,
    ...rest
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  // Contexts
  const [
    notificationsState,
    ,
    notificationsCount,
    setNotificationsCount,
  ] = useContext(NotificationsContext);

  const [, setAccountData] = useContext(AccountContext);

  // Language popover
  const [showLanguageAnchorEl, setShowLanguageAnchorEl] = useState(null);

  // Theme popover
  const [showThemeAnchorEl, setShowThemeAnchorEl] = useState(null);

  // Notifications popover
  const [showNotificationsAnchorEl, setShowNotificationsAnchorEl] = useState(
    null,
  );

  const handleNotificationsClick = (event) => {
    if (notificationsState.length > 0)
      setShowNotificationsAnchorEl(event.currentTarget);
    setNotificationsCount(0);
  };

  const handleNotificationsClose = () => {
    setShowNotificationsAnchorEl(null);
  };

  const openNotifications = Boolean(showNotificationsAnchorEl);

  // Logout
  const handleLogout = async () => {
    try {
      const response = await apiPost(endpoints.account.logout);
      if (response.status === 200) {
        enqueueSnackbar("Logged out successfully", { variant: "success" });
      } else {
        enqueueSnackbar("Problem logging out", { variant: "error" });
      }
      setAccountData(null);
      history.push("/sign-in");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/dashboard">
          <img alt="Logo" src="/images/logos/logo_white.png" height="64" />
        </RouterLink>
        <div className={classes.flexGrow} />

        {/* Language popover starts */}
        <TopbarLanguagePopover
          {...{ showLanguageAnchorEl, setShowLanguageAnchorEl }}
        />
        {/* Language popover ends */}

        {/* Theme popover starts */}
        <TopbarThemePopover {...{ showThemeAnchorEl, setShowThemeAnchorEl }} />

        {/* Theme popover ends */}

        {/* Notifications popover starts */}
        <Popover
          open={openNotifications}
          anchorEl={showNotificationsAnchorEl}
          onClose={handleNotificationsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            {notificationsState.map((item) => {
              return (
                <ListItem
                  button
                  component={RouterLink}
                  to={item.link}
                  onClick={handleNotificationsClose}
                  key={item.message}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="button">{item.title}</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="body2">{item.message}</Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="caption"
                        style={{ color: "grey" }}
                        gutterBottom
                      >
                        {formatDistance(item.time, new Date(), {
                          addSuffix: true,
                          includeSeconds: true,
                          locale: supportedLocales[i18n.language],
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </Popover>
        {/* Notifications popover ends */}

        {/* <Hidden xsDown> */}
        <Tooltip title={t("Notifications")} arrow>
          <IconButton color="inherit" onClick={handleNotificationsClick} size="large">
            <Badge badgeContent={notificationsCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>

        <Tooltip title={t("ThemeChange")} arrow>
          <IconButton
            color="inherit"
            onClick={(event) => {
              setShowThemeAnchorEl(event.currentTarget);
            }}
            size="large">
            <PaletteOutlinedIcon />
          </IconButton>
        </Tooltip>

        {/* <Tooltip title={t("MenuLanguage")} arrow>
          <IconButton
            color="inherit"
            onClick={(event) => {
              setShowLanguageAnchorEl(event.currentTarget);
            }}
          >
            <LanguageIcon />
          </IconButton>
        </Tooltip> */}

        <Tooltip title={t("MenuLogout")} arrow>
          <IconButton
            onClick={handleLogout}
            className={classes.actionButton}
            color="inherit"
            component={RouterLink}
            to="/sign-in"
            size="large">
            <InputIcon />
          </IconButton>
        </Tooltip>

        {/* </Hidden> */}

        <Hidden lgUp={true && !isCallInProgress}>
          <IconButton color="inherit" onClick={toggleSidebar} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  toggleSidebar: PropTypes.func,
};

export default withRouter(Topbar);
