import React from "react";

// Material components
import { SvgIcon } from "@mui/material";

// Minifying SVGs: https://jakearchibald.github.io/svgomg/
export default function IconInvitation(props) {
  return (
    <SvgIcon {...props}>
        <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -51 512 511"
    >
      <path
        fill="#7acefa"
        d="M146.063 403.594l360-399.336-354.684 272.086zm0 0"
      ></path>
      <path
        fill="#a3defe"
        d="M15.918 206.879l275.785 171.437c26.281 16.34 60.942 5.586 73.36-22.761L510.597 23.277c5.578-12.738-6.395-26.136-19.676-22.02L23.734 146.06c-27.586 8.55-32.343 45.57-7.816 60.82zm0 0"
      ></path>
      <path
        fill="#a3defe"
        d="M15.918 206.879l275.785 171.437c26.281 16.34 60.942 5.586 73.36-22.761L510.597 23.277c5.578-12.738-6.395-26.136-19.676-22.02L23.734 146.06c-27.586 8.55-32.343 45.57-7.816 60.82zm0 0"
      ></path>
      <path
        fill="#7acefa"
        d="M23.734 146.059c-27.586 8.55-32.343 45.57-7.816 60.82L193.031 316.98c-66.554-62.824-76.535-139.777-32.539-213.308zm0 0"
      ></path>
      <path
        fill="#8eafe2"
        d="M59.672 234.078l59.187 165.203c5.465 15.25 27.657 13.168 30.188-2.828l15.394-97.246L506.063 4.257zm0 0"
      ></path>
      <path
        fill="#6a96d7"
        d="M59.672 234.078l59.187 165.203c5.465 15.25 27.657 13.168 30.188-2.828l15.394-97.246L506.063 4.257zm0 0"
      ></path>
    </svg>
    </SvgIcon>
  );
}