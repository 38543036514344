import React, { useState, useEffect } from "react";

// Components
import HeaderWithEdit from "./HeaderWithEdit";
import ActionButtons from "./ActionButtons";
import Unspecified from "./Unspecified";

// API
import { apiPatch, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper, TextField, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: 128,
  },
}));

const ProfileSingleFieldEditor = ({
  profile,
  title,
  fieldName,
  multiline,
  callback,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!profile) return;

    setFormState({ values: { [fieldName]: profile[fieldName] } });
  }, [profile]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      [fieldName]: "",
    },
  });

  const handleChange = (e) => {

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    }));
  };

  const handleSave = () => {
    apiPatch(endpoints.profile.all, formState.values)
      .then((res) => {
        console.log(res);
        setIsEditMode(false);
        callback();
      })
      .catch(console.error);
  };

  const handleClick = () => {
    setIsEditMode(true);
  };

  const handleClose = () => {
    setIsEditMode(false);
    setFormState({ values: { [fieldName]: profile[fieldName] } });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <HeaderWithEdit
          {...{
            title,
            handleClick,
          }}
        />
      </Grid>

      {isEditMode ? (
        <Grid item container direction="column" justifyContent="center" spacing={3}>
          <Grid item style={{ maxWidth: 500 }}>
            <TextField
              variant="outlined"
              multiline={multiline}
              rows={3}
              fullWidth
              label={title}
              name={fieldName}
              value={formState.values[fieldName]}
              onChange={handleChange}
            />
          </Grid>

          <Grid item>
            <ActionButtons
              {...{
                handleSave,
                handleClose,
                active: !!formState.values[fieldName],
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          {formState.values[fieldName] ? (
            <Typography>{formState.values[fieldName]}</Typography>
          ) : (
            <Unspecified />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ProfileSingleFieldEditor;
