import React, { useContext, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { AccountContext } from "contexts";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import { countryCodes } from "common/countryCodes";
import { useHistory } from "react-router-dom";

import { AccountTable, AccountTableRow } from "@components/Common/AccountTable";
import { ChangePasswordForm } from "@components/Forms/ChangePassword";
import { ChangeEmailForm } from "@components/Forms/ChangeEmail";
import { TwoFactorAuthForm, View } from "@components/Forms/TwoFactorAuth";
import { AccountSubscription } from "@components/Forms/AccountSubscription";
import { SubscriptionView } from "@components/Forms/AccountSubscription";
import { StripeSetup, STATUS } from "@components/Forms/StripeSetup";
import { SingleSelectForm } from "@components/Forms/SingleSelectForm";
import { SingleSwitchForm } from "@components/Forms/SingleSwitch";
import { CryptoPaymentsButton } from "@components/Forms/CryptoPayments";
import { CryptoView } from "@components/Forms/CryptoPayments";

import { promisePostGetNonce, promisePostVerifySig } from "api-new/misc";
import { useGetTimezones } from "api-new/misc";
import { usePostDisableMFA } from "api-new/account";
import { promisePostLogout, usePostChangeEmail } from "api-new/account";
import { usePostMFA } from "api-new/account";
import { usePostSettings } from "api-new/account";
import { usePostChangePassword } from "api-new/account";
import { promisePostSettings } from "api-new/account";
import { useGetMFACode } from "api-new/account";
import { promiseGetOnboardLink } from "api-new/stripe";
import { useGetStripCheck } from "api-new/stripe";
import { useGetAvaliabilityProfile } from "api-new/clinic";

import { createCheckoutSession } from "common/stripeFunctions";
import { createPortalSession } from "common/stripeFunctions";
import { convertTrialString } from "common/stripeFunctions";
import { useState } from "react";

/**
 * reset and prevent duplicate on error should be unecassery
 * last dev has whole app re-rendrering on polling on root level
 * so is necassery untill issues is fixed
 */
const ChangeEmail = () => {
  const [accountData] = useContext(AccountContext);
  if (accountData?.isSSOSignIn) return null;

  const { mutate, error, reset, isSuccess } = usePostChangeEmail();
  if (error) displayError(error, reset);
  if (isSuccess) displaySuccess("Email Changed", reset);
  return (
    <AccountTableRow title="Change Email" subTitle="Change your email">
      <ChangeEmailForm onSubmit={(d) => mutate(d)} />
    </AccountTableRow>
  );
};

const ChangePassword = () => {
  const history = useHistory();
  const [accountData] = useContext(AccountContext);
  const { mutate, error, reset, isSuccess } = usePostChangePassword();
  if (error) displayError(error, reset);

  if (accountData?.isSSOSignIn) return null;

  if (isSuccess) {
    displaySuccess("Password Changed", reset);
    promisePostLogout().then(() => history.push("/sign-in"));
  }

  return (
    <AccountTableRow title="Change Password" subTitle="Change your password">
      <ChangePasswordForm onSubmit={(d) => mutate(d)} />
    </AccountTableRow>
  );
};

const TwoFactorAuth = () => {
  const [account] = useContext(AccountContext);
  const [active, setActive] = useState(false);
  const { data } = useGetMFACode();

  const {
    mutate: enable,
    isSuccess: enableSuccess,
    error: enableError,
    reset: enableReset,
  } = usePostMFA();

  const {
    mutate: disable,
    isSuccess: disableSuccess,
    error: disableError,
    reset: disableReset,
  } = usePostDisableMFA();

  useEffect(() => {
    setActive(account?.twoFactorEnabled);
  }, [account]);

  if (enableError) displayError(enableError, enableReset);
  if (disableError) displayError(disableError, disableReset);

  if (enableSuccess) {
    setActive(true);
    displaySuccess("MFA Enabled", enableReset);
  }

  if (disableSuccess) {
    setActive(false);
    displaySuccess("MFA Disabled", disableReset);
  }

  return (
    <AccountTableRow
      title="Two-factor Authentication"
      subTitle="Add two factor authentication to your account for extra security"
    >
      <TwoFactorAuthForm
        view={active ? View.DISABLE : View.ACTIVATE}
        sharedKey={data?.sharedKey}
        authenticatorUri={data?.authenticatorUri}
        onActivate={(d) => enable(d)}
        onDisable={(d) => disable(d)}
      />
    </AccountTableRow>
  );
};

const Reigon = () => {
  const [accountData] = useContext(AccountContext);
  const { mutate, error, isSuccess, reset } = usePostSettings();
  if (error) displayError(error, reset);
  if (isSuccess) displaySuccess("Settings updated", reset);

  return (
    <AccountTableRow title="Region" subTitle="Change your default region">
      <SingleSelectForm
        defaultValue={accountData?.region}
        onChange={(region) => mutate({ region })}
        options={[
          { value: "Europe", label: "Europe" },
          { value: "NorthAmerica", label: "North America" },
          { value: "AsiaPacific", label: "Asia Pacific" },
        ]}
      />
    </AccountTableRow>
  );
};

const TimeZone = () => {
  const [accountData] = useContext(AccountContext);
  //prettier-ignore
  const { mutate, error: errPost, isSuccess, reset: resetPost } = usePostSettings();
  const { data, error } = useGetTimezones();
  if (error) displayError(error);
  if (errPost) displayError(errPost);
  if (isSuccess) {
    // Quick fix user account context is not updated on settings change
    // so this componant cannot access new state unless parant holds state
    // storing state in parent is uneeded, context should update on settings
    // update success. this can be achived using a
    // 1. using reducer
    // 2. pooling or websocket
    // 3. react query, fore example invalidate cache on success
    window.location.reload();
    displaySuccess("Settings updated", resetPost);
  }

  return (
    <AccountTableRow title="Time Zone" subTitle="Change your default time zone">
      <SingleSelectForm
        defaultValue={accountData?.timezone}
        onChange={(timezone) => mutate({ timezone })}
        options={data?.map((tz: any) => ({
          value: tz,
          label: tz.replace(/([A-Z])/g, " $1").trim(),
        }))}
      />
    </AccountTableRow>
  );
};

const Subscription = () => {
  const [accountData] = useContext(AccountContext);
  const [view, setView] = useState(SubscriptionView.INACTIVE);
  const [stripeActive, setStripeActive] = useState(false);
  const dateEnd = convertTrialString(accountData?.stripe?.trialEnd);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const status =
      accountData?.stripe?.status || urlParams.get("status") === "success";
    const trialEnd = accountData?.stripe?.trialEnd;
    const stripActive = status && trialEnd;

    setView(status ? SubscriptionView.ACTIVE : SubscriptionView.INACTIVE);
    setStripeActive(status);
  }, [accountData]);

  function callStripe() {
    if (stripeActive) createPortalSession();
    if (!stripeActive) createCheckoutSession();
  }

  return (
    <AccountTableRow
      title="Subscription"
      subTitle="Manage MyClinic.com Subscription"
    >
      <AccountSubscription
        subState={view}
        onClick={callStripe}
        trialEndDate={dateEnd}
      />
    </AccountTableRow>
  );
};

const PayedAppointments = () => {
  const { isSuccess } = useGetStripCheck();
  const [accountData] = useContext(AccountContext);
  const [status, setStatus] = useState(STATUS.INCOMPLETE);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const isComplete =
      accountData?.stripe?.receivePayment?.onBoardingCompleted ||
      urlParams.get("onboarding") === "complete";

    if (isComplete) setStatus(STATUS.COMPLETE);
    if (!isComplete) setStatus(STATUS.INCOMPLETE);
  }, [accountData]);

  useEffect(() => {
    if (isSuccess) setStatus(STATUS.COMPLETE);
  }, [isSuccess]);

  async function onSetup() {
    try {
      const link = await promiseGetOnboardLink();
      window.open(link);
    } catch (e) {
      displayError(e);
    }
  }

  function onManage() {
    window.open("https://dashboard.stripe.com/products?active=true");
  }

  return (
    <AccountTableRow
      title="Paid appointments"
      subTitle="Stripe portal must be set up to use paid appointments service"
    >
      <StripeSetup
        status={status}
        onSetup={onSetup}
        onManageProduct={onManage}
      />
    </AccountTableRow>
  );
};

const EthereumKeys = () => {
  const [view, setView] = useState(CryptoView.DISABLED);
  const [account] = useContext(AccountContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const wallet = account?.wallet?.ethereumWallet;

    const urlParams = new URLSearchParams(window.location.search);

    const stripeSetup =
      account?.stripe?.receivePayment?.onBoardingCompleted ||
      urlParams.get("onboarding") === "complete";

    if (!stripeSetup) return;
    if (wallet) setView(CryptoView.DISPLAY_KEY);
    if (!wallet) setView(CryptoView.ADD_KEY);
  }, [account]);

  async function addKey() {
    // @ts-ignore
    if (typeof window.ethereum === "undefined") {
      // prettier-ignore
      const err = "MetaMask is not installed, please install it from your browsers extension store";
      enqueueSnackbar(err, {
        variant: "error",
        preventDuplicate: true,
      });
    }

    //@ts-ignore
    if (typeof window.ethereum !== "undefined") {
      //@ts-ignore
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      try {
        const nonce = await promisePostGetNonce({ address });
        const signature = await signer.signMessage(nonce);
        const sigId = await promisePostVerifySig({ address, signature });

        await promisePostSettings({
          cryptoSetting: {
            walletVerificationId: sigId,
            enableCryptoPayment: true,
          },
        });

        // TODO: Quick fix user account context is not updated on settings change
        // so this componant cannot access new state unless parant holds state
        // storing state in parent is uneeded, context should update on settings
        // update success. this can be achived using a
        // 1. using reducer
        // 2. pooling or websocket
        // 3. react query, fore example invalidate cache on success
        window.location.reload();

        enqueueSnackbar("Wallet added", {
          variant: "success",
          preventDuplicate: true,
        });
      } catch (e) {
        enqueueSnackbar(e as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    }
  }

  return (
    <AccountTableRow
      title="Crypto payments"
      subTitle="Add an Ethereum wallet to accept crypto payments, We currently only allow payment in MTN (other major cryptos coming soon)"
    >
      <CryptoPaymentsButton
        onAddETHKey={addKey}
        view={view}
        wallet={account?.wallet?.ethereumWallet}
      />
    </AccountTableRow>
  );
};

const DefaultExperation = () => {
  const [accountData] = useContext(AccountContext);
  const { mutate, error, isSuccess, reset } = usePostSettings();
  const { t } = useTranslation();

  if (error) displayError(error, reset);
  if (isSuccess) displaySuccess("Settings updated", reset);

  return (
    <AccountTableRow
      title="Default Experation"
      subTitle="Default Expiration for new Clinics"
    >
      <SingleSelectForm
        defaultValue={accountData?.clinicDefaults?.expiration}
        onChange={(expiration) => mutate({ expiration })}
        options={[
          { value: "H24", label: t("24Hours") },
          { value: "H72", label: t("72Hours") },
          { value: "H168", label: t("1Week") },
        ]}
      />
    </AccountTableRow>
  );
};

const DefaultCountryCode = () => {
  const [accountData] = useContext(AccountContext);
  const { mutate, error, isSuccess, reset } = usePostSettings();
  const { t } = useTranslation();

  if (error) displayError(error, reset);
  if (isSuccess) displaySuccess("Settings updated", reset);

  return (
    <AccountTableRow
      title="Default Contry Code"
      subTitle="Default country code extension"
    >
      <SingleSelectForm
        defaultValue={accountData?.clinicDefaults?.country}
        onChange={(country) => mutate({ country })}
        options={countryCodes?.map((c) => ({
          value: c.code,
          label: `${c.name} ${c.dial_code}`,
        }))}
      />
    </AccountTableRow>
  );
};

const AvalibliltyProfile = () => {
  const [accountData] = useContext(AccountContext);
  const { mutate, error, isSuccess, reset } = usePostSettings();
  const { data, error: avalError } = useGetAvaliabilityProfile();
  const { t } = useTranslation();

  if (avalError) displayError(avalError);
  if (error) displayError(error, reset);
  if (isSuccess) displaySuccess("Settings updated", reset);

  return (
    <AccountTableRow
      title="Availability Profile"
      subTitle="Default profile for when you create a new clinic"
    >
      <SingleSelectForm
        defaultValue={accountData?.clinicDefaults?.availabilityProfileId}
        onChange={(availabilityProfileId) => mutate({ availabilityProfileId })}
        //@ts-ignore
        options={data?.map((p) => ({ value: p.id, label: p.name }))}
        disabled={!accountData?.timezone}
        disabledText="You need to set up your timezone first"
      />
    </AccountTableRow>
  );
};

const CheckinSettings = () => {
  const [accountData] = useContext(AccountContext);
  const { mutate, error, isSuccess, reset } = usePostSettings();
  // const { t } = useTranslation();

  if (error) displayError(error, reset);
  if (isSuccess) displaySuccess("Settings updated", reset);

  return (
    <AccountTableRow
      title="Check-in Settings"
      subTitle="Default requirements for new clinics (can be change for each clinic individually)"
    >
      <SingleSwitchForm
        onChange={(c) => mutate(c)}
        options={
          accountData?.clinicDefaults
            ? [
                {
                  label: "Waiting Room Audio",
                  name: "audioMessagesEnabled",
                  value: accountData?.clinicDefaults?.audioMessagesEnabled,
                },
                {
                  label: "Require Date of Birth",
                  name: "promptForDoB",
                  value: accountData?.clinicDefaults?.promptForDoB,
                },
                {
                  label: "Require Phone Number",
                  name: "promptForPhoneNumber",
                  value: accountData?.clinicDefaults?.promptForPhoneNumber,
                },
              ]
            : []
        }
      />
    </AccountTableRow>
  );
};

const displayError = (e: unknown, reset?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  enqueueSnackbar(e as string, { variant: "error", preventDuplicate: true });
  if (reset) reset();
};

const displaySuccess = (s: unknown, reset?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  enqueueSnackbar(s as string, { variant: "success", preventDuplicate: true });
  if (reset) reset();
};

const Account = (props: any) => (
  <Container maxWidth="md" sx={{ mt: 10 }}>
    <Typography variant="h5">General</Typography>
    <AccountTable>
      <ChangeEmail />
      <ChangePassword />
      <TwoFactorAuth />
      <Reigon />
      <TimeZone />
      <Subscription />
      <PayedAppointments />
      <EthereumKeys />
    </AccountTable>

    <Typography variant="h5" sx={{ mt: 10 }}>
      Clinic Defaults
    </Typography>
    <AccountTable>
      <DefaultExperation />
      <DefaultCountryCode />
      <AvalibliltyProfile />
      <CheckinSettings />
    </AccountTable>
  </Container>
);

export default Account;
