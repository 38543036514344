import * as signalR from "@microsoft/signalr";
import { signalRConnection, patientId, roomId } from "common/videoCallModule";

export const log = ({
  type = "log",
  text = "",
  css = "",
  params = "",
  sLog = true,
}) => {
  // const isProduction = process.env.NODE_ENV === "production";
  const isProduction = false;

  // Local log
  if (type === "log" && !isProduction) {
    console.log(`%c${text}`, css, params);
  } else if (type === "err") {
    console.error(`%c${text}`, css, params);
  }

  // Server log
  if (sLog && patientId && roomId) {
    type === "err" ? serverLogErr(text, params) : serverLogMsg(text, params);
  }
};

export const serverLogMsg = (text, params) => {
  const data = JSON.stringify({ text, params });
  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    signalRConnection.invoke("LogMsg", roomId, patientId, data).catch(() => {
      return;
    });
  }
};

export const serverLogErr = (text, params) => {
  const data = JSON.stringify({ text, params });
  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    signalRConnection.invoke("LogErr", roomId, patientId, data).catch(() => {
      return;
    });
  }
};
