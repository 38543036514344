import React, { useState, useRef } from "react";

// Components
import {
  FramerModal,
  ClinicCode,
  ClinicDelete,
  ClinicTerm,
  OpeningIntervals,
  CopyClinicLink,
  ClinicNameWithChange,
  ClinicOptions,
} from "components";
import ClinicAssignedQuestionnaires from "../ClinicAssignedQuestionnaires";

// Router
import { withRouter } from "react-router-dom";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

// API
import { apiPost, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import IconClinic from "icons/svg/IconClinic";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Icon from "@mui/material/Icon";

// I18n

const useStyles = makeStyles((theme) => ({
  root: {},
  cardContent: {
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  cardActions: {
    padding: theme.spacing(1, 2),
    minHeight: 64,
  },
  imageContainer: {
    height: 96,
    width: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  image: {
    height: 66,
    width: 66,
    color: theme.palette.primary.main,
  },
}));

const ClinicCard = (props) => {
  const {
    history,
    className,
    clinic,
    allClinics,
    handleUpdate,
    // eslint-disable-next-line
    staticContext,
    ...rest
  } = props;
  const classes = useStyles();

  // Refs
  const ref = useRef();

  const [openClinicSettings, setOpenClinicSettings] = useState(false);
  const [openIntervals, setOpenIntervals] = useState(false);

  const handleClick = (e) => {
    if (!ref.current?.contains(e.target)) {
      history.push(`/clinic/${clinic.id}`);
    }
  };

  const handleClose = () => {
    setOpenIntervals(false);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <FramerModal {...{ open: openIntervals, handleClose }}>
        <OpeningIntervals {...{ clinic }} />
      </FramerModal>

      <FramerModal
        {...{
          open: openClinicSettings,
          handleClose: () => setOpenClinicSettings(false),
        }}
      >
        <ClinicOptions
          callback={(res) =>
            apiPost(endpoints.clinic.settings, {
              clinicId: clinic.id,
              ...res,
            }).catch(console.error)
          }
          clinic={clinic}
        />
      </FramerModal>

      <CardContent className={classes.cardContent} onClick={handleClick}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
          spacing={3}
        >
          <Grid item className={classes.imageContainer}>
            <IconClinic className={classes.image} />
          </Grid>
          <Grid item ref={ref}>
            <ClinicNameWithChange
              {...{ clinicName: clinic.name, clinicId: clinic.id }}
            />
          </Grid>
          <Grid item>
            <ClinicCode {...{ clinicCode: clinic.code }} />
          </Grid>
          <Grid item>
            <ClinicTerm
              {...{
                clinicTerm: clinic.term,
                clinicExpiration: clinic.expiration,
              }}
            />
          </Grid>
          {clinic?.questionnaires?.length > 0 && (
            <Grid item>
              <ClinicAssignedQuestionnaires
                {...{ questionnaires: clinic.questionnaires }}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />

      <CardActions className={classes.cardActions}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Grid item>
            <CopyClinicLink {...{ clinicURL: clinic.clinicURL }} />
          </Grid>

          <Grid item>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Tooltip
                  title={"Use Test Bot to check your audio quality"}
                  arrow
                >
                  <IconButton
                    onClick={() =>
                      apiPost(`${endpoints.clinic.testBot}${clinic.id}`).catch(
                        console.error,
                      )
                    }
                    size="large">
                    <Icon>smart_toy</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <ClinicDelete
                  {...{
                    clinicId: clinic.id,
                    clinicName: clinic.name,
                    clinicTerm: clinic.term,
                    allClinics,
                    handleUpdate,
                  }}
                />
              </Grid>

              <Grid item>
                <Tooltip title={"Settings"} arrow>
                  <IconButton onClick={() => setOpenClinicSettings(true)} size="large">
                    <SettingsRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

ClinicCard.propTypes = {
  className: PropTypes.string,
  clinic: PropTypes.object.isRequired,
};

export default withRouter(ClinicCard);
