import React from "react";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

// Language selection
import { languageCodesAndNames } from "localisations/i18n";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Language = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  return (
    <Card {...rest} className={clsx(classes.root, className)} elevation={0}>
      <CardHeader
        title={t("MenuLanguage")}
        subheader={t("SettingsLanguageSubheader")}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={1} wrap="wrap">
          <Grid item>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="language">{"Language"}</InputLabel>
              <Select
                label={"Language"}
                labelId="language"
                id="language"
                name="language"
                value={i18n.language}
                onChange={(e) => {
                  i18n.changeLanguage(e.target.value);
                }}
              >
                {Object.keys(languageCodesAndNames).map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {languageCodesAndNames[lang]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      {/* <Divider />
      <CardActions>
        <Button color="primary" variant="outlined">
          {t("SaveChanges")}
        </Button>
      </CardActions> */}
    </Card>
  );
};

Language.propTypes = {
  className: PropTypes.string,
};

export default Language;
