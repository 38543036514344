import React, { useState, useEffect, useContext, useRef } from "react";

// API
import { apiPost, apiGet, endpoints } from "api";

// Utils
import { log } from "helpers";

// Routing
import { withRouter, Redirect } from "react-router-dom";

// Contexts
import { FullscreenContext, CallContext } from "contexts";

// Components
import { VideoCall } from "components";
import { PatientQuestionnaire } from "./components";
import makeStyles from '@mui/styles/makeStyles';

// Localisation
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    // flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // height: "100%",
    // marginTop: "64px", // see if it solves mobile horizontal view, if not -> delete
  },
  flexItemContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
}));

const PatientCall = (props) => {
  // const { history } = props;
  const { params } = props.match;

  const classes = useStyles();
  const { i18n } = useTranslation();

  // Contexts
  const [FullScreen, isFullscreen] = useContext(FullscreenContext);
  const { callStatus, setCallStatus } = useContext(CallContext);

  // Path params
  const roomId = params.room_id;
  const patientId = params.patient_id;

  // Call
  const [questionnairesToBeFilledIn, setQuestionnairesToBeFilledIn] =
    useState(null);
  const [isCallInProgress, setIsCallInProgress] = useState(null);

  // START OF AUDIO MESSAGES
  const [isAudioMsgPlaying, setIsAudioMsgPlaying] = useState(false);
  // const [audioTracks, setAudioTracks] = useState([]);
  const interval = useRef(null);
  const audioTracks = useRef([]);
  const supportedLanguages = [
    "arSA",
    "de",
    "enGB",
    "es",
    "fr",
    "it",
    "ja",
    "zhCN",
    "zhTW",
  ];

  // Check for questionnaires for the patient to fill in
  // Skip if it's third party invitee
  useEffect(() => {
    if (callStatus.isInvited || !patientId) {
      setQuestionnairesToBeFilledIn(false);
      // Reset the invited state
      setCallStatus((prevState) => ({ ...prevState, isInvited: false }));
      return;
    }

    apiGet(
      `${endpoints.patient.questionnairesThatNeedToBeFilledIn}${patientId}`,
    )
      .then((res) => {
        res.data.length > 0
          ? setQuestionnairesToBeFilledIn(res.data)
          : setQuestionnairesToBeFilledIn(false);
      })
      .catch(console.error);
  }, []);

  const showNextQuestionnaire = () => {
    const remainingQuestionnaires = [...questionnairesToBeFilledIn];
    remainingQuestionnaires.shift();
    console.log(">>> Remaining Qs:", remainingQuestionnaires);
    if (remainingQuestionnaires.length > 0) {
      setQuestionnairesToBeFilledIn(remainingQuestionnaires);
    } else {
      setQuestionnairesToBeFilledIn(false);
    }
  };

  const startAudioMessages = (currentLocale) => {
    log({
      text: "[Patient Call] PLAYING INITIAL AUDIO",
      css: "color: white; background-color: DarkCyan",
    });
    setIsAudioMsgPlaying(true);

    if (!supportedLanguages.includes(currentLocale)) currentLocale = "enGB";
    const initialSound = new Audio(
      `/audio/${currentLocale}/patient_doctor_online_initial.mp3`,
    );

    audioTracks.current.push(initialSound);
    initialSound.loop = false;
    initialSound.play().catch((err) =>
      log({
        type: "err",
        text: "[AudioMessages] Unable to play initial audio message.",
        params: err.message,
      }),
    );
    loopWaitingMessage(currentLocale);
  };

  const loopWaitingMessage = (currentLocale) => {
    log({
      text: "[Patient Call] PLAYING LOOPING AUDIO",
      css: "color: white; background-color: DarkCyan",
    });

    const loopingSound = new Audio(
      `/audio/${currentLocale}/patient_doctor_online.mp3`,
    );

    audioTracks.current.push(loopingSound);
    interval.current = setInterval(() => {
      loopingSound.play().catch((err) =>
        log({
          type: "err",
          text: "[AudioMessages] Unable to play looping audio message.",
          params: err.message,
        }),
      );
    }, 20000);
  };

  const playDingDong = () => {
    if (isCallInProgress) return;

    const dingDong = new Audio(`/audio/ding-dong.mp3`);

    stopAudioMessages();
    if (patientId)
      dingDong
        .play()
        .then(audioTracks.current.push(dingDong))
        .catch((err) =>
          log({
            type: "err",
            text: "[AudioMessages] Unable to stop audio message.",
            params: err.message,
          }),
        );
    setIsCallInProgress(true);
  };

  const stopAudioMessages = () => {
    if (!patientId && !isAudioMsgPlaying) return;

    log({
      text: "[Patient Call] STOPPING WAITING ROOM AUDIO",
      css: "color: white; background-color: DarkCyan",
    });

    setIsAudioMsgPlaying(false);

    audioTracks.current.forEach((audiTrack) => audiTrack.pause());

    clearInterval(interval.current);
  };
  // END OF AUDIO MESSAGES

  useEffect(() => {
    if (patientId && callStatus.audioMessageEnabled === true)
      startAudioMessages(i18n.language);
    // eslint-disable-next-line
  }, [callStatus?.audioMessagesEnabled]);

  useEffect(() => {
    return () => callDisconnectCallback();
    // eslint-disable-next-line
  }, []);

  const callConnectCallback = () => {
    console.log("[Call Connect Callback] Patient call connected.");
    playDingDong();
  };

  const callDisconnectCallback = () => {
    setIsCallInProgress(false);
    stopAudioMessages();

    if (!callStatus.noUserMedia) {
      // If the call ends normally
      try {
        if (window.location.hostname !== "localhost") {
          window.location.assign(`${process.env.REACT_APP_MAIN_URL}/thankyou`);
        } else {
          console.log(
            "[Call] Call ended -> Redirecting to 'Thank you' page in prod",
          );
          // window.location.assign(`https://myclinic.com/thankyou`);
        }
      } catch (err) {
        console.log("[Call] Problem redirecting to main url", err);
      }
    } else {
      // If the call fails with no detected device

      // Reset the state
      setCallStatus((prevState) => ({
        ...prevState,
        noUserMedia: false,
      }));

      window.history.back();
    }
  };

  return (
    <>
      {questionnairesToBeFilledIn === false && (
        <div className={classes.root}>
          <div className={classes.flexItemContainer}>
            <FullScreen handle={isFullscreen}>
              <VideoCall
                {...{
                  roomId,
                  patientId,
                  isFullscreen,
                  callConnectCallback,
                  callDisconnectCallback,
                }}
              />
            </FullScreen>
          </div>
        </div>
      )}

      {questionnairesToBeFilledIn && (
        <>
          <PatientQuestionnaire
            {...{
              patientId,
              questionnaires: questionnairesToBeFilledIn,
              showNextQuestionnaire,
            }}
          />
        </>
      )}
    </>
  );
};

export default withRouter(PatientCall);
