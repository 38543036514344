import React, { useContext, useEffect } from "react";

// Contexts
import { AccountContext } from "contexts";

// Routing
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout } from "./components";

// Layout
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

// Views (components)
import {
  Dashboard as DashboardView,
  ClinicsManager as ClinicsManagerView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Clinic as ClinicView,
  ForgotPassword as ForgotPasswordView,
  CheckIn as CheckInView,
  PatientCall as PatientCallView,
  Profile as ProfileView,
  ProfileEdit as ProfileEditView,
  ConfirmEmail as ConfirmEmailView,
  JoinRoom as JoinRoomView,
  Questionnaire as QuestionnaireView,
  Messaging as MessagingView,
  InviteClinicians as InviteCliniciansView,
  GetFreeUpgrade as GetFreeUpgradeView,
  AvailabilityProfiles as AvailabilityProfilesView,
  ProfileClaim as ProfileClaimView,
  ChangeEmail as ChangeEmailView,
} from "./views";

// I18n
import { useTranslation } from "react-i18next";

const DefaultRoute = ({ history }) => {
  const [accountData] = useContext(AccountContext);

  useEffect(() => {
    if (!accountData) return;
    history.push(
      accountData.landingPage ? accountData.landingPage : "/dashboard"
    );
  }, [accountData]);

  return null;
};

const Routes = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Switch>
      {/* <Redirect exact from="/" to="/dashboard" /> */}
      <Redirect exact from="/clinic" to="/clinics-manager" />
      <RouteWithLayout
        component={DefaultRoute}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        title="Dashboard"
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        title="Clinics Manager"
        component={ClinicsManagerView}
        exact
        layout={MainLayout}
        path="/clinics-manager"
      />
      <RouteWithLayout
        component={ClinicView}
        exact
        layout={MainLayout}
        path="/clinic/:clinic_id"
      />
      <RouteWithLayout
        title="Check In"
        component={CheckInView}
        exact
        layout={MinimalLayout}
        path="/j/:clinic_code"
      />
      <RouteWithLayout
        component={PatientCallView}
        exact
        layout={MinimalLayout}
        path="/c/:room_id/:patient_id?"
      />
      <RouteWithLayout
        title="Join Room"
        component={JoinRoomView}
        exact
        layout={MinimalLayout}
        path="/jc/:room_id/:invitation_id"
      />
      <RouteWithLayout
        title="Confirm Email"
        component={ConfirmEmailView}
        exact
        layout={MinimalLayout}
        path="/identity/account/:confirmemail"
      />
      <RouteWithLayout
        title="Messaging"
        component={MessagingView}
        exact
        layout={MainLayout}
        path="/messaging"
      />
      <RouteWithLayout
        title="Account"
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        title="Profile"
        component={ProfileView}
        exact
        layout={MainLayout}
        path="/profile"
      />
      <RouteWithLayout
        title="Profile Edit"
        component={ProfileEditView}
        exact
        layout={MainLayout}
        path="/profile-edit"
      />
      <RouteWithLayout
        title="Profile Claim"
        component={ProfileClaimView}
        exact
        layout={MainLayout}
        path="/profile-claim/:claim_id"
      />
      <RouteWithLayout
        title="Settings"
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        title="Sign Up"
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        title={t("LoginPageTitle")}
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in/:claim_id?"
      />
      <RouteWithLayout
        title="Forgot Password"
        component={ForgotPasswordView}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        title="Confirm Email"
        component={ChangeEmailView}
        exact
        layout={MinimalLayout}
        path="/confirm-email"
      />
      <RouteWithLayout
        title="Questionnaires"
        component={QuestionnaireView}
        exact
        layout={MainLayout}
        path="/questionnaire"
      />
      <RouteWithLayout
        title="Invite Clinicians"
        component={InviteCliniciansView}
        exact
        layout={MainLayout}
        path="/invite-clinicians"
      />
      <RouteWithLayout
        title="Get Free Upgrade"
        component={GetFreeUpgradeView}
        exact
        layout={MainLayout}
        path="/free-upgrade"
      />
      <RouteWithLayout
        title="Availability Profiles"
        component={AvailabilityProfilesView}
        exact
        layout={MainLayout}
        path="/availability-profiles"
      />
      <RouteWithLayout
        title="Not Found"
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
