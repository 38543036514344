import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from "react";
import { ICallContext } from "types";

type DefaultValue = {
  callStatus: ICallContext;
  setCallStatus: Dispatch<SetStateAction<ICallContext>>;
};

const defaultValue: DefaultValue = {
  callStatus: {
    isCallInProgress: false,
    isClickAwayAllowed: true,
    isClinician: false,
    isPatient: false,
    isInvited: false,
    invitationCode: "",
    invitationName: "",
    noUserMedia: false,
  },
  setCallStatus: () => {
    return;
  },
};

export const CallContext = createContext(defaultValue);

export const CallProvider: FC = ({ children }) => {
  const [callStatus, setCallStatus] = useState(defaultValue.callStatus);

  return (
    <CallContext.Provider value={{ callStatus, setCallStatus }}>
      {children}
    </CallContext.Provider>
  );
};
