import React, { createContext, useState } from "react";
import { Dispatch, SetStateAction, FC } from "react";
import { useCookies } from "react-cookie";
import { legacyTheme } from "theme";
import { IGlobalSettings } from "types";

/**
 * Original developer "Bhezad" created a theme switcher
 * but it was not implmented anywhere, removeing all unsued
 * themes for
 * 1. simplification
 * 2. upgrade to Mui5 created alot of legacy themes
 */

const allThemes: IGlobalSettings["allThemes"] = {
  Legacy: legacyTheme,
};

type DefaultValue = {
  globalSettings: IGlobalSettings;
  setGlobalSettings: Dispatch<SetStateAction<IGlobalSettings>>;
};

const defaultValue: DefaultValue = {
  globalSettings: {
    theme: legacyTheme,
    allThemes,
    currentClinic: null,
  },
  setGlobalSettings: () => {
    return;
  },
};

export const GlobalContext = createContext(defaultValue);

export const GlobalProvider: FC = ({ children }) => {
  const [cookies] = useCookies(["theme"]);

  const [globalSettings, setGlobalSettings] = useState<IGlobalSettings>({
    theme: cookies.theme ? allThemes[cookies.theme] : legacyTheme,
    allThemes,
    currentClinic: null,
  });

  return (
    <GlobalContext.Provider value={{ globalSettings, setGlobalSettings }}>
      {children}
    </GlobalContext.Provider>
  );
};
