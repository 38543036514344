import React from "react";

// Utils
import { formatISO } from "date-fns";

import makeStyles from "@mui/styles/makeStyles";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  Box,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import TextsmsIcon from "@mui/icons-material/Textsms";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

//  Date & time picker
import { DateTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

// I18n
import { useTranslation } from "react-i18next";

// Types

const useStyles = makeStyles((theme) => ({
  root: {},
  button: { whiteSpace: "nowrap" },
  form: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

interface IProps {
  allInvitations: any;
  setAllInvitations: any;
  invitation: any;
  index: number;
  invitationMethod: any;
  invitations: any;
  accountData: any;
  clinicExpiration: any;
  supportedSmsCountries: any;
  defaultCountryCode: any;
  handleRemoveInvitation: any;
}

const ClinicInviteForm = (props: IProps) => {
  const {
    allInvitations,
    setAllInvitations,
    invitation,
    index,
    accountData,
    clinicExpiration,
    defaultCountryCode,
    supportedSmsCountries,
    handleRemoveInvitation,
  } = props;

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<any>,
    index: number
  ) => {
    const shallowCopy = [...allInvitations];
    shallowCopy[index][e.target.name] = e.target.value;
    setAllInvitations(shallowCopy);
  };

  const handleChangeDate = (value: MaterialUiPickersDate, index: number) => {
    const shallowCopy = [...allInvitations];
    shallowCopy[index].expectedJoinDate = formatISO(value || new Date());
    setAllInvitations(shallowCopy);
  };

  const handleChangeType = (type: string, index: number) => {
    if (!type) return; // If clicked twice will be null

    const shallowCopy = [...allInvitations];
    shallowCopy[index].type = type;
    // Update the state for country code
    if (type == "phone" && !shallowCopy[index].countryCode)
      shallowCopy[index].countryCode = defaultCountryCode;
    setAllInvitations(shallowCopy);
  };

  return (
    <Grid container alignItems="flex-start" spacing={1}>
      <Grid item>
        <TextField
          fullWidth
          label={t<string>("PatientFullName")}
          name="fullName"
          type="text"
          variant="outlined"
          onChange={(e) => handleChange(e, index)}
          value={invitation.fullName || ""}
          style={{
            width: "25ch",
          }}
          required
        />
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          value={invitation.type}
          size="large"
          exclusive
          onChange={(_, value) => handleChangeType(value, index)}
          aria-label="invitation method"
        >
          <Tooltip title={t<string>("InvitePatientViaEmail")} arrow>
            <ToggleButton value="email" aria-label="email">
              <AlternateEmailIcon />
            </ToggleButton>
          </Tooltip>

          <Tooltip
            title={
              accountData?.features?.sms
                ? t<string>("InvitePatientViaSMS")
                : t<string>("AccountUpgradeToUseFeature")
            }
            arrow
          >
            {accountData?.features?.sms ? (
              <ToggleButton value="phone" aria-label="phone" disabled={false}>
                <TextsmsIcon />
              </ToggleButton>
            ) : (
              // Without surrounding div the disabled button will not trigger tooltip
              <Box>
                <ToggleButton
                  value="phone"
                  aria-label="phone"
                  disabled={true}
                  size="large"
                >
                  <TextsmsIcon />
                </ToggleButton>
              </Box>
            )}
          </Tooltip>
        </ToggleButtonGroup>
      </Grid>

      {invitation.type === "phone" ? (
        <>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="countryCode-label">
                {t<string>("InvitationCountryCode")}
              </InputLabel>
              <Select
                labelId="countryCode-label"
                id="countryCode"
                value={invitation.countryCode || defaultCountryCode || ""}
                name="countryCode"
                onChange={(e) => handleChange(e, index)}
                label={t<string>("InvitationCountryCode")}
                style={{
                  width: "15ch",
                }}
              >
                {supportedSmsCountries.map((countryCode: string) => {
                  return (
                    <MenuItem value={countryCode} key={countryCode}>
                      {countryCode}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label={t<string>("PatientPhone")}
              name="phone"
              type="text"
              variant="outlined"
              onChange={(e) => handleChange(e, index)}
              value={invitation.phone || ""}
            />
          </Grid>
        </>
      ) : (
        <Grid item>
          <TextField
            fullWidth
            label={t<string>("PatientEmail")}
            name="email"
            type="text"
            variant="outlined"
            onChange={(e) => handleChange(e, index)}
            value={invitation.email || ""}
          />
        </Grid>
      )}
      <Grid item>
        <DateTimePicker
          variant="inline"
          inputVariant="outlined"
          label={t<string>("PatientExpectedJoinTime")}
          value={invitation.expectedJoinDate}
          onChange={(value) => handleChangeDate(value, index)}
          minDate={new Date()}
          maxDate={new Date(clinicExpiration)} // Errors if clinic is expired when used in booking request
          autoOk
          // locale="fr"
        />
      </Grid>

      {/* Remove invitation */}
      <Grid>
        {index !== 0 && (
          <IconButton
            aria-label="remove invitation"
            onClick={() => handleRemoveInvitation(index)}
            size="large"
          >
            <CloseRoundedIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default ClinicInviteForm;
