import React, { useState, useEffect, useContext } from "react";

// Contexts
import { AccountContext } from "contexts";

// Router
import { Link as RouterLink, withRouter } from "react-router-dom";

// API
import { apiGet, apiPost, authMessages, endpoints } from "api";

// Utils
import PropTypes from "prop-types";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { GoogleLogin } from "react-google-login";
import { log } from "helpers";

import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
} from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";

// Validator Schema
const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.webp)",
    // backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    background: theme.palette.primary.light,
    borderRadius: "0.5rem",
    padding: theme.spacing(3, 4),
  },
  quoteText: {
    color: theme.palette.white,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 100,
    flexBasis: 700,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  gButton: {
    marginTop: theme.spacing(1),
  },
}));

const SignIn = (props) => {
  const { history, match } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Contexts
  const [, setAccountData] = useContext(AccountContext);

  const [pending, setPending] = React.useState(false);
  const [requiresMFA, setRequiresMFA] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      rememberMe: false,
    },
    touched: {},
    errors: {},
  });

  // Anytime form values change, validates them, then updates formState .isValid & errors
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  // Checks if the form values are touched and have errors, if so helperText shows formState.errors
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // Anytime email, password or checkbox changes, updates formState .values & .touched
  // We need the "event" props, that's why we don't use useEffect
  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const getAccountData = async () => {
    try {
      const response = await apiGet(endpoints.account.data);
      if (response.status === 200 && response.data) {
        setAccountData((prevState) => ({ ...prevState, ...response.data }));
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLoginFlow = async (responseStatus, responseData) => {
    const id = match.params.claim_id;

    switch (responseStatus) {
      case 200:
        try {
          enqueueSnackbar(authMessages[responseStatus].message, {
            variant: authMessages[responseStatus].variant,
          });
          setPending(false);
          getAccountData().then((res) =>
            history.push(
              id
                ? `/profile-claim/${match.params.claim_id}`
                : res?.landingPage
                ? res.landingPage
                : "/dashboard"
            )
          );
        } catch (err) {
          log({
            type: "err",
            text: "[Auth -> Logged in]",
            params: err.message,
          });
          setPending(false);
        }
        break;
      case 401:
        if (responseData.title === "MFARequired") {
          setFormState({
            isValid: false,
            values: {
              rememberMe: false,
              rememberMachine: false,
            },
            touched: {},
            errors: {},
          });
          setRequiresMFA(true);
        }
        setPending(false);
        enqueueSnackbar(
          authMessages[responseStatus][responseData.title].message,
          {
            variant: authMessages[responseStatus][responseData.title].variant,
          }
        );
        break;
      default:
        enqueueSnackbar(authMessages.default.message, {
          variant: authMessages.default.variant,
        });
        setPending(false);
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setPending(true);

      const response = await apiPost(endpoints.account.login, formState.values);
      if (!response) throw new Error("No response from the server");
      handleLoginFlow(response.status, response.data);
    } catch (err) {
      log({ type: "err", text: "[Auth]", params: err.message });
      setPending(false);
    }
  };

  const handleMFA = async (e) => {
    e.preventDefault();

    try {
      setPending(true);

      const response = await apiPost(
        endpoints.account.performMFA,
        formState.values
      );
      handleLoginFlow(response.status, response.data);
    } catch (err) {
      log({ type: "err", text: "[Auth MFA]", params: err.message });
      setPending(false);
    }
  };

  const gSignInSuccess = async (token) => {
    log({ text: "[Google Auth] Sign in successful", params: [token] });

    try {
      const response = await apiPost(endpoints.account.gAuth, {
        idToken: token.tokenId,
      });
      if (response.status === 200 && response.data) {
        log({ text: "[App] Logged in with Google" });
        handleLoginFlow(response.status, response.data);
      } else {
        throw new Error("Failed to authenticate with Google token");
      }
    } catch (err) {
      log({
        type: "err",
        text: "[Google Auth -> Sign in]",
        params: err.message,
      });
    }
  };

  const gSignInFail = () => {
    log({ type: "err", text: "[Google Auth] Failed signing in with Google" });
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            {/* <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h3">
                MyClinic
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Powered by Medicalchain
                </Typography>
              </div>
            </div> */}
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <img
                alt="Logo"
                src="/images/logos/logo_white_with_text.png"
                height="96"
                style={{
                  filter: "invert(100%)",
                }}
              />
            </div>
            <div className={classes.contentBody}>
              {/* Sign in with no MFA */}
              {!requiresMFA && (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Typography
                    className={classes.title}
                    variant="h2"
                    gutterBottom
                  >
                    {t("LoginPageTitle")}
                  </Typography>

                  {/* Google sign in */}
                  <Box mt={5} mb={5}>
                    <Typography variant="h5" gutterBottom>
                      {t("AccountSignInWithGoogleAccount")}
                    </Typography>

                    <GoogleLogin
                      className={classes.gButton}
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      buttonText={t("AccountSignInWithGoogleButton")}
                      onSuccess={(token) => gSignInSuccess(token)}
                      onFailure={gSignInFail}
                      cookiePolicy={"single_host_origin"}
                    />
                  </Box>

                  <Typography variant="h5" gutterBottom>
                    {t("AccountUseOwnEmail")}
                  </Typography>

                  {/* <input
                    type="hidden"
                    autoComplete="on"
                    value=""
                    style={{
                      display: "none",
                      opacity: 0,
                      position: "absolute",
                      left: "-100000px",
                    }}
                    readOnly={true}
                  />
 */}
                  <TextField
                    inputProps={{
                      "data-testid": "emailInput",
                    }}
                    label={t("EmailAddress")}
                    aria-label="email-input"
                    className={classes.textField}
                    error={hasError("email")}
                    fullWidth
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ""}
                    variant="outlined"
                    required
                    autoComplete="none"
                  />
                  <TextField
                    inputProps={{
                      "data-testid": "passwordInput",
                    }}
                    label={t("Password")}
                    aria-label="password-input"
                    className={classes.textField}
                    error={hasError("password")}
                    fullWidth
                    helperText={
                      hasError("password") ? formState.errors.password[0] : null
                    }
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                    variant="outlined"
                    required
                    autoComplete="none"
                  />
                  {/* <FormControlLabel
                    label={t("RememberMe")}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name="rememberMe"
                        value={formState.values.rememberMe}
                      />
                    }
                  /> */}
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || pending}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    data-testid="loginButton"
                  >
                    {pending && (
                      <Box mr={1}>
                        <CircularProgress size="1rem" />
                      </Box>
                    )}
                    {t("LoginPageButton")}
                  </Button>

                  <Typography color="textSecondary" variant="body1">
                    <Link
                      component={RouterLink}
                      to="/forgot-password"
                      variant="body1"
                    >
                      {t("ForgotPasswordLink")}
                    </Link>
                  </Typography>

                  <Typography color="textSecondary" variant="body1">
                    <Link
                      component="a"
                      href={`${process.env.REACT_APP_MAIN_URL}/register`}
                      variant="body1"
                    >
                      {t("RegisterLink")}
                    </Link>
                    {/* <Link component={RouterLink} to="/sign-up" variant="body1">
                      {t("RegisterLink")}
                    </Link> */}
                  </Typography>

                  {/* Legal bit */}
                  <Box mt={3}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      align="justify"
                      display="block"
                    >
                      Access to this computer/Solution and any information it
                      contains is limited to authorised users only. Legal action
                      can be taken against unauthorised use of, or unauthorised
                      access to, this computer/Solution and/or any information
                      it contains, including pursuant to the Computer Misuse Act
                      1990. If you are an authorised user, by proceeding to
                      access and use this computer/Solution and/or the
                      information it contains, you are accepting any terms of
                      use, notices and policies which are contained or
                      referenced within it or which have otherwise been drawn to
                      your attention as an authorised user.
                    </Typography>
                  </Box>
                </form>
              )}
              {requiresMFA && (
                <form className={classes.form} onSubmit={handleMFA}>
                  <Typography
                    className={classes.title}
                    variant="h2"
                    gutterBottom
                  >
                    {t("2FATitle")}
                  </Typography>
                  <TextField
                    name="twoFactorCode"
                    className={classes.textField}
                    label={t("AuthenticatorCode")}
                    error={hasError("twoFactorCode")}
                    fullWidth
                    helperText={
                      hasError("twoFactorCode")
                        ? formState.errors.twoFactorCode[0]
                        : null
                    }
                    onChange={handleChange}
                    type="text"
                    value={formState.values.twoFactorCode || ""}
                    variant="outlined"
                    required
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    // disabled={!formState.isValid}
                    disabled={pending}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {pending && (
                      <Box mr={1}>
                        <CircularProgress size="1rem" />
                      </Box>
                    )}
                    {t("LoginPageButton")}
                  </Button>
                </form>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
