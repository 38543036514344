import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

// Date
import { format } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const InvitationsTable = (props) => {
  const { allInvitations } = props;

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const invitationStatusLabel = {
    Queued: `${t("InvitationStatusQueued")}`,
    Sending: `${t("InvitationStatusSending")}`,
    Sent: `${t("InvitationStatusSent")}`,
    Failed: `${t("InvitationStatusFailed")}`,
    Delivered: `${t("InvitationStatusDelivered")}`,
    Undelivered: `${t("InvitationStatusUndelivered")}`,
    Receiving: `${t("InvitationStatusReceiving")}`,
    Received: `${t("InvitationStatusReceived")}`,
    Clicked: `${t("InvitationStatusClicked")}`,
    CheckedIn: `${t("InvitationStatusCheckedIn")}`,
  };

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>{t("Name")}</TableCell>
            <TableCell>{t("PatientEmailPhone")}</TableCell>
            <TableCell>{t("PatientExpectedJoinTime")}</TableCell>
            <TableCell>{t("TitleStatus")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allInvitations.map((invitation) => (
            <TableRow key={invitation.invitationId}>
              <TableCell
                component="th"
                scope="row"
                style={{ verticalAlign: "top" }}
              >
                <Typography variant="body1">{invitation.name}</Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: "top" }}>
                <Typography variant="body1">
                  {invitation.email
                    ? invitation.email
                    : `${invitation.prefix} ${invitation.phone}`}
                </Typography>
              </TableCell>
              <TableCell style={{ verticalAlign: "top" }}>
                <Typography variant="body1">
                  {format(new Date(invitation.expectedJoinDate), "PPp", {
                    locale: supportedLocales[i18n.language],
                  })}
                </Typography>
              </TableCell>

              <TableCell style={{ verticalAlign: "top" }}>
                <Typography variant="body1">
                  {invitationStatusLabel[invitation.deliverStatus]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvitationsTable;
