//@ts-nocheck
import { apiGet, apiPost, endpoints } from "api";
import { supportedLocales } from "localisations/dateFnsLocale";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const getStripeKey = async () => {
  try {
    const response = await apiGet(endpoints.stripe.getKey);
    if (response.status === 200 && response.data) {
      return response.data.publishableKey;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const createCheckoutSession = async () => {
  try {
    const key = await getStripeKey();
    if (!key) throw new Error("Couldn't get Stripe key");

    const stripe = window.Stripe(key);

    const response = await apiPost(endpoints.stripe.checkout);
    if (response.status === 200 && response.data) {
      const { sessionId } = response.data;
      stripe.redirectToCheckout({
        sessionId,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const createPortalSession = async () => {
  try {
    const response = await apiPost(endpoints.stripe.manage);
    if (response.status === 200 && response.data) {
      window.open(response.data, "_self");
    }
  } catch (err) {
    console.error(err);
  }
};

export const convertTrialString = (end: string | undefined): string => {
  const { i18n } = useTranslation();

  if (!end) return "";

  try {
    return format(new Date(end), "PP", {
      locale: supportedLocales[i18n.language],
    });
  } catch {
    return "";
  }
};
