import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactNode,
  CSSProperties,
  ChangeEvent,
} from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { Chat } from "components";
import { format, intervalToDuration, formatDuration } from "date-fns";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Tabs, Tab, Typography, Box, Link, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";
import { IClinic, IFileAttachment, IPatient } from "types";

interface ITabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
  style?: CSSProperties;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...rest } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && (
        <Box style={{ width: "100%", height: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: "#FAFAFA",
    minHeight: 70,
  },
  attachmentLink: {
    margin: theme.spacing(1, 0),
    cursor: "pointer",
  },
}));

interface IProps {
  clinicInfo: IClinic;
  roomId: string;
  selectedPatient: IPatient & { valueProp?: number };
  setSelectedAttachment: Dispatch<SetStateAction<IFileAttachment | null>>;
  setSelectedMp4Attachment: Dispatch<
    SetStateAction<{
      fileUrl: string;
    } | null>
  >;
  isChatMounted: boolean;
  setQuestionResult: any;
}

const PatientDetails = (props: IProps) => {
  const {
    clinicInfo,
    roomId,
    selectedPatient,
    setSelectedAttachment,
    setSelectedMp4Attachment,
    isChatMounted,
    setQuestionResult,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [value, setValue] = useState(
    selectedPatient.valueProp ? selectedPatient.valueProp : 0
  );
  useEffect(() => {
    if (!selectedPatient.valueProp) return;

    setValue(selectedPatient.valueProp);
  }, [selectedPatient]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
        aria-label="details tabs"
      >
        <Tab label={t("PatientInformation")} icon={<InfoIcon />} />
        <Tab label={t("Attachments")} icon={<AttachmentIcon />} />
      </Tabs>

      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "100%" }}
        slideStyle={{ height: "100%" }}
        containerStyle={{ height: "100%" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          wrap="nowrap"
          style={{ height: "100%" }}
        >
          {/* Patient details */}
          <Grid item xs={12}>
            {selectedPatient ? (
              <Box p={1}>
                <Typography variant="button" display="block" color="primary">
                  {t("PatientName")}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {selectedPatient.name}
                </Typography>

                {clinicInfo.promptForDoB && (
                  <>
                    <Typography
                      variant="button"
                      display="block"
                      color="primary"
                    >
                      {t("TitleDateOfBirth")}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {format(new Date(selectedPatient.doB), "P", {
                        // @ts-ignore
                        locale: supportedLocales[i18n.language],
                      })}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "grey" }}
                      gutterBottom
                    >
                      {`(${formatDuration(
                        intervalToDuration({
                          start: new Date(selectedPatient.doB),
                          end: new Date(),
                        }),
                        {
                          format: ["years", "months"],
                          delimiter: ", ",
                          // @ts-ignore
                          locale: supportedLocales[i18n.language],
                        }
                      )})`}
                    </Typography>
                  </>
                )}

                {clinicInfo.promptForPhoneNumber && (
                  <>
                    <Typography
                      variant="button"
                      display="block"
                      color="primary"
                    >
                      {t("PhoneNumber")}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {selectedPatient.phoneNumber}
                    </Typography>
                  </>
                )}

                {/* <Typography variant="button" display="block" color="primary">
                  {"Chat"}
                </Typography> */}
              </Box>
            ) : (
              <Typography variant="body1" gutterBottom>
                `${t("PatientNotSelected")}`
              </Typography>
            )}
          </Grid>

          {/* Chat */}
          <Grid item xs={12}>
            {selectedPatient && isChatMounted && (
              <Chat
                {...{
                  isClinician: true,
                  roomId,
                }}
              />
            )}
          </Grid>
        </Grid>

        <div>
          {selectedPatient?.attachments?.length > 0 && (
            <Box p={1}>
              <Typography variant="button" display="block" color="primary">
                {"Attached Files"}
              </Typography>

              {selectedPatient.attachments.map((attachment, index) => {
                const { name, fileUrl, fileID } = attachment;
                const fileExtension = name.split(".").pop() || "";

                return (
                  <div key={fileID}>
                    {["png", "jpg", "jpeg"].includes(fileExtension) && (
                      <Link
                        className={classes.attachmentLink}
                        component={Typography}
                        onClick={() =>
                          setSelectedAttachment({
                            fileIndex: index,
                            fileName: name,
                            fileUrl,
                            fileType: "image",
                          })
                        }
                      >
                        {name}
                      </Link>
                    )}

                    {["pdf", "mov"].includes(fileExtension) && (
                      <Typography>
                        <Link
                          className={classes.attachmentLink}
                          component="a"
                          href={fileUrl}
                          target="_blank"
                        >
                          {name}
                        </Link>
                      </Typography>
                    )}

                    {["mp4"].includes(fileExtension) && (
                      <Link
                        className={classes.attachmentLink}
                        component={Typography}
                        onClick={() =>
                          setSelectedMp4Attachment({
                            fileUrl,
                          })
                        }
                      >
                        {name}
                      </Link>
                    )}
                  </div>
                );
              })}
            </Box>
          )}

          {selectedPatient?.questionnaireResults?.length > 0 && (
            <Box p={1}>
              <Typography variant="button" display="block" color="primary">
                {"Questionnaire Responses"}
              </Typography>

              {selectedPatient.questionnaireResults.map((quest) => {
                return (
                  <div key={quest.surveyId}>
                    <Link
                      className={classes.attachmentLink}
                      component={Typography}
                      onClick={() =>
                        setQuestionResult({ open: true, selected: quest })
                      }
                    >
                      {quest.name}
                    </Link>
                  </div>
                );
              })}
            </Box>
          )}
        </div>
      </SwipeableViews>
    </>
  );
};

PatientDetails.propTypes = {
  roomId: PropTypes.string,
  selectedPatient: PropTypes.object.isRequired,
  setSelectedAttachment: PropTypes.func.isRequired,
  setSelectedMp4Attachment: PropTypes.func.isRequired,
  isChatMounted: PropTypes.bool.isRequired,
};

export default PatientDetails;
