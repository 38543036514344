import React, { useState, useEffect } from "react";

// Utils
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

// API
import { apiGet, apiPost, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3),
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "10",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  wrapper: {
    padding: theme.spacing(1),
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // borderRadius: "0.5rem",
    // overflow: "hidden",
    maxWidth: 600,
  },
}));

export const InviteToRoom = (props) => {
  const { roomId, handleClose } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [roomURL, setRoomURL] = useState(null);
  // Add the formState here to collect textField data to post to invite by sms/email endpoint

  useEffect(() => {
    getInvCodeByRoomID();
    // eslint-disable-next-line
  }, []);

  const getInvCodeByRoomID = async () => {
    try {
      const response = await apiGet(
        `${endpoints.clinic.roomURL1}${roomId}${endpoints.clinic.roomURL2}`,
      );

      if (response.status === 200 && response.data) {
        setRoomURL(`${window.location.host}/jc/${roomId}/${response.data}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // eslint-disable-next-line
  const sendInviteToRoom = async () => {
    try {
      const response = await apiPost(
        `${endpoints.clinic.roomInvite1}${roomId}${endpoints.clinic.roomInvite2}`,
      );

      if (response.status === 200 && response.data) {
        // Do stuff here
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClickedOutside = (e) => {
    if (e.target === e.currentTarget) handleClose();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(roomURL);
      enqueueSnackbar("Invitation link copied successfully", {
        variant: "info",
      });
    } catch (err) {
      enqueueSnackbar("Failed to copy", { variant: "error" });
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <Box className={classes.root} onClick={handleClickedOutside}>
      <Card className={classes.wrapper}>
        <CardHeader
          title="Room Invitation"
          subheader="Invite more participants to join"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            You can invite up to 2 other people to join the call with your
            patient either by sharing the link or sending an email or SMS
            invitation.
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Tooltip title="Copy link" arrow>
                <IconButton
                  color="secondary"
                  aria-label="delete"
                  onClick={copyToClipboard}
                  size="large">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography variant="button" color="textSecondary">
                Copy link
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="textSecondary">
            (Invite via SMS/email fields to be added)
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          <Button size="small" disabled>
            Send invitation
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

InviteToRoom.propTypes = {
  roomId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

// export default InviteToRoom;
