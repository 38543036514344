import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Container, ImageContainer } from "./ChangeEmail.styles";
import { Image, Body, BodySection, Form } from "./ChangeEmail.styles";
import { Title } from "./ChangeEmail.styles";
import { confirmEmailChange } from "../../api-new/account";
import { useSnackbar } from "notistack";

const ForgotPassword = (props: any) => {
  const { history } = props;
  const { mutate, isSuccess, error, reset } = confirmEmailChange();
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const email = queryParams.get("email");
  const code = queryParams.get("code");

  const handleBack = () => {
    history.goBack();
  };

  const onEmailChange = () => {
    reset();
    mutate({ id: id || "", code: code || "" });
  };

  if (error) {
    enqueueSnackbar(error as string, { variant: "error" });
  }

  if (isSuccess) {
    enqueueSnackbar("Email has been changed", { variant: "success" });
    handleBack();
  }

  return (
    <>
      <Container container>
        <ImageContainer item lg={5}>
          <Image />
        </ImageContainer>
        <Body flexDirection="column" item lg={7} xs={12}>
          <Body flexDirection="column" item>
            <BodySection item alignItems="center">
              <IconButton onClick={handleBack} size="large">
                <ArrowBackIcon />
              </IconButton>
            </BodySection>
            <BodySection item alignItems="center">
              <Form onSubmit={() => console.log("SUBMIT")}>
                <Title variant="h2" gutterBottom>
                  Confirm Email
                </Title>
                <Title variant="h6">Email will Be change too</Title>
                <Title variant="h6" gutterBottom>
                  {email}
                </Title>
                <Button
                  sx={{ marginTop: "20px", maxWidth: "400px" }}
                  fullWidth
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={onEmailChange}
                >
                  Confirm
                </Button>
              </Form>
            </BodySection>
          </Body>
        </Body>
      </Container>
    </>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgotPassword);
