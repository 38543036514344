import React, { useState } from "react";

// React Json Forms
import Form from "@rjsf/material-ui";

// Components
import { ConfirmDialog } from "components";

import makeStyles from '@mui/styles/makeStyles';
import { Box, Button, Grid, Paper } from "@mui/material";
import { apiPost, endpoints } from "api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 1),
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  wrapper: {
    maxWidth: 960,
  },
  paper: {
    padding: theme.spacing(1, 1),
  },
}));

const PatientQuestionnaire = (props) => {
  const { patientId, questionnaires, showNextQuestionnaire } = props;
  const classes = useStyles();

  const questionnaire = questionnaires[0];
  const schema = JSON.parse(questionnaire.formSchema);
  const uiSchema = questionnaires && JSON.parse(questionnaire.uiSchema);

  const [formData, setFormData] = useState({});
  const [showSkipConfirmation, setShowSkipConfirmation] = useState(false);
  const handleSubmitQuestionnaireAnswers = async () => {
    try {
      const response = await apiPost(
        endpoints.patient.submitQuestionnaireAnswers,
        {
          questionnaireId: questionnaire.id,
          formResults: formData,
          patientId,
        },
      );
      if (response?.status === 200) {
        console.log(">>> Answers submitted successfully", response.data);
        showNextQuestionnaire();
      } else {
        console.error(">>> Problem submitting answers");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleClickSkip = () => {
    setShowSkipConfirmation(false);
    showNextQuestionnaire();
  };

  return (
    <Box className={classes.root}>
      <ConfirmDialog
        {...{
          title: "Confirm Skipping",
          content:
            "Your clinician has requested that you complete this questionnaire before joining the video call. Are you sure you want to skip their questionnaire?",
          open: showSkipConfirmation,
          handleClose: () => setShowSkipConfirmation(false),
          handleDelete: handleClickSkip,
        }}
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        className={classes.wrapper}
      >
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowSkipConfirmation(true)}
                  >
                    {"Skip"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Paper className={classes.paper}>
            <Form
              schema={schema}
              uiSchema={uiSchema}
              onChange={(questionnaire) => setFormData(questionnaire.formData)}
              formData={formData}
              onSubmit={() => handleSubmitQuestionnaireAnswers()}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientQuestionnaire;
