import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// Time
import { format } from "date-fns";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

// Components
import { getInitials } from "helpers";

const useStyles = makeStyles((theme) => ({
  root: {},
  inline: {
    display: "inline",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export default function MessageListItem(props) {
  const { sender, topic, text, date, status } = props;
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{getInitials(sender)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={status === "Read" ? sender : <strong>{sender}</strong>}
        secondary={
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {status === "Read" ? (
                `${topic} — ${text.substring(0, 24)}...`
              ) : (
                <strong>{`${topic} — ${text.substring(0, 24)}...`}</strong>
              )}
            </Typography>
            <Typography
              component="span"
              variant="body2"
              style={{ display: "block", marginTop: "0.5rem" }}
            >
              {format(new Date(date), "PPp", {
                locale: supportedLocales[i18n.language],
              })}
            </Typography>
          </>
        }
      />
    </>
  );
}
