import React, { useState, useContext } from "react";

import makeStyles from "@mui/styles/makeStyles";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Contexts
import { ClinicsContext } from "contexts";

const transparent = "rgba(0,0,0,0)";
const useStyles = makeStyles(() => ({
  select: {
    borderColor: "white",
    "&:before": {
      borderColor: transparent,
    },
    "&:hover": {
      borderColor: transparent,
    },
    "&:after": {
      borderColor: transparent,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: transparent,
    },
  },
  iconSelect: {
    fill: "grey",
    stroke: "grey",
    strokeWidth: 2,
  },
}));

const ClinicSelectDropDown = (props) => {
  const { callback } = props;

  const classes = useStyles();

  const [selectedClinic, setSelectedClinic] = useState("");
  const [clinicsList] = useContext(ClinicsContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedClinic(value);

    callback(value);
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id="clinics" shrink={false}>
        {selectedClinic ? "" : "Select Clinic"}
      </InputLabel>
      <Select
        className={classes.select}
        inputProps={{
          classes: {
            notchedOutline: {
              border: "white",
            },
            icon: classes.iconSelect,
          },
        }}
        label={"Clinics"}
        labelId="clinics"
        id="clinics"
        name="clinics"
        value={selectedClinic || ""}
        onChange={handleChange}
        style={{ fontWeight: "500" }}
        variant="standard"
      >
        {clinicsList?.map((clinic) => (
          <MenuItem value={clinic.id} key={clinic.id}>
            {clinic.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClinicSelectDropDown;
