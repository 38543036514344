import React, { useState, useContext } from "react";

// Time
import { formatISO } from "date-fns";

// Components
import { ClinicOptions } from "components";

// Contexts
import { AccountContext } from "contexts";

// Material UI
import {
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";

//  Date & time picker
import { DateTimePicker } from "@material-ui/pickers";

// I18n
import { useTranslation } from "react-i18next";

const CreateClinicForm = ({ handleCreateClinic }) => {
  const { t } = useTranslation();

  const [accountData] = useContext(AccountContext);

  const [isAlwaysOpen, setIsAlwaysOpen] = useState(true);

  const [formState, setFormState] = useState({
    name: "",
    expirationOption: accountData?.clinicDefaults?.expiration || "H24",
    audioMessagesEnabled:
      accountData?.clinicDefaults?.audioMessagesEnabled || false,
    promptForPhoneNumber:
      accountData?.clinicDefaults?.promptForPhoneNumber || false,
    promptForDoB: accountData?.clinicDefaults?.promptForDoB || false,
    availabilityProfileId:
      accountData?.clinicDefaults?.availabilityProfileId || null,
  });

  const handleOpeningTime = (opening) => {
    setFormState((formState) => ({
      ...formState,
      openingIntervals: [
        {
          ...formState.openingIntervals[0],
          opening: formatISO(new Date(opening)),
        },
      ],
    }));
  };

  const handleClosingTime = (closing) => {
    setFormState((formState) => ({
      ...formState,
      openingIntervals: [
        {
          ...formState.openingIntervals[0],
          closing: formatISO(new Date(closing)),
        },
      ],
    }));
  };

  //  eslint-disable-next-line
  const handleOpeningIntervals = () => {
    if (isAlwaysOpen) {
      setIsAlwaysOpen(false);
      setFormState((formState) => ({
        ...formState,
        openingIntervals: [
          {
            opening: formatISO(new Date()),
            closing: formatISO(new Date()),
          },
        ],
      }));
    } else {
      setIsAlwaysOpen(true);
      setFormState((formState) => ({
        name: formState.name,
        expirationOption: formState.expirationOption,
        audioMessagesEnabled: formState.audioMessagesEnabled,
      }));
    }
  };

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateClinic(formState);
  };

  const daysToExpiration = {
    H24: 1,
    H72: 3,
    H168: 7,
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h4" gutterBottom>
          {t("CreateNewClinic")}
        </Typography>
        <Divider />
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                fullWidth
                label={t("Name")}
                name="name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="expirationOption">{t("Expiration")}</InputLabel>
                <Select
                  labelId="expirationOption"
                  id="expirationOption"
                  label={t("Expiration")}
                  name="expirationOption"
                  value={formState.expirationOption}
                  onChange={handleChange}
                >
                  <MenuItem value={"H24"}>{t("24Hours")}</MenuItem>
                  <MenuItem value={"H72"}>{t("72Hours")}</MenuItem>
                  <MenuItem value={"H168"}>{t("1Week")}</MenuItem>
                  <MenuItem value={"NoExpiration"}>{"No expiration"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <ClinicOptions
                callback={(res) =>
                  setFormState((prevState) => ({ ...prevState, ...res }))
                }
                showExtraOptions={false}
              />
            </Grid>

            {!isAlwaysOpen && (
              <>
                <Grid item>
                  <DateTimePicker
                    variant="inline"
                    inputVariant="outlined"
                    label={t("OpensAt")}
                    value={formState.openingIntervals[0].opening}
                    onChange={handleOpeningTime}
                    minDate={new Date()}
                    maxDate={new Date().setDate(
                      new Date().getDate() +
                        daysToExpiration[formState.expirationOption]
                    )}
                  />
                </Grid>
                <Grid item>
                  <DateTimePicker
                    variant="inline"
                    inputVariant="outlined"
                    label={t("ClosesAt")}
                    value={formState.openingIntervals[0].closing}
                    onChange={handleClosingTime}
                    minDate={new Date()}
                    maxDate={new Date().setDate(
                      new Date().getDate() +
                        daysToExpiration[formState.expirationOption]
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("CreateClinic")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateClinicForm;
