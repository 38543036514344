import { styled } from "@mui/system";

export const Container = styled("div")(({ theme }) => ({
  "& > p": {
    marginTop: "10px",
  },
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
}));
