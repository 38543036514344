import React, { useEffect, forwardRef } from "react";

// Utils
// import PropTypes from "prop-types";
import clsx from "clsx";

const VideoElement = (props, ref) => {
  const { className, srcObject, ...rest } = props;

  // const refVideo = React.useRef(null);

  useEffect(() => {
    if (!srcObject) return;

    if ("srcObject" in ref.current) {
      ref.current.srcObject = srcObject;
    } else {
      ref.current.src = window.URL.createObjectURL(srcObject); // for older browsers
    }
    // eslint-disable-next-line
  }, [srcObject]);

  return (
    <video
      ref={ref}
      {...rest}
      className={clsx(className)}
      playsInline
      autoPlay
    />
  );
};

// VideoElement.propTypes = {
//   srcObject: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   muted: PropTypes.bool,
//   width: PropTypes.string,
//   height: PropTypes.string,
// };

// VideoElement.defaultProps = {
//   muted: false,
//   width: "1280",
//   height: "960",
// };

export default forwardRef(VideoElement);
