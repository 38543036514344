import React, { useState, useEffect, useContext } from "react";

// Components
import { ConfirmDialog, NothingToShow } from "components";
import MessageListItem from "./components/MessageListItem";
import MessageBody from "./components/MessageBody";
import MarkunreadRoundedIcon from "@mui/icons-material/MarkunreadRounded";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";

// Contexts
import { MessagingContext } from "contexts";

// Utils
import { apiGet, apiPut, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  List,
  ListItem,
  Divider,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    height: "100%",
  },
}));

const MessagingContainer = () => {
  const classes = useStyles();

  const [selectedThread, setSelectedThread] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [, setMessageCount] = useContext(MessagingContext);

  useEffect(() => {
    getMessageList();
  }, []);

  const getMessageList = () =>
    apiGet(endpoints.clinic.messages)
      .then((res) => {
        const sortedRes = res.data
          .sort(
            (a, b) =>
              a.message[a.message.length - 1].created -
              b.message[b.message.length - 1].created,
          )
          .reverse();

        setMessageList(sortedRes);
        if (!messageList.length) setSelectedThread(sortedRes[0]);
        return sortedRes;
      })
      .catch(console.error);

  const handleMarkRead = (thread) => {
    // Update the unread message counter
    if (thread.status !== "Read")
      setMessageCount((prevState) =>
        prevState > 0 ? prevState - 1 : prevState,
      );

    setSelectedThread((prevState) => ({ ...prevState, status: "Read" }));
    setMessageList((prevState) =>
      prevState.map((i) => {
        if (i.id === thread.id) {
          i.status = "Read";
          return i;
        }
        return i;
      }),
    );

    apiPut(endpoints.clinic.messages, {
      messageIDs: [thread.id],
      changeType: "Read",
    }).catch(console.error);
  };

  const handleMarkUnread = () => {
    // Update the unread message counter
    setMessageCount((prevState) => prevState + 1);

    setSelectedThread((prevState) => ({ ...prevState, status: "Unread" }));
    setMessageList((prevState) =>
      prevState.map((i) => {
        if (i.id === selectedThread.id) {
          i.status = "Unread";
          return i;
        }
        return i;
      }),
    );

    apiPut(endpoints.clinic.messages, {
      messageIDs: [selectedThread.id],
      changeType: "Unread",
    }).catch(console.error);
  };

  const handleDelete = () => {
    apiPut(endpoints.clinic.messages, {
      messageIDs: [selectedThread.id],
      changeType: "Deleted",
    })
      .then(() => {
        setOpenConfirmDelete(false);
        getMessageList().then((res) => setSelectedThread(res[0]));
      })
      .catch(console.error);
  };

  return <>
    <Box className={classes.root}>
      <ConfirmDialog
        {...{
          title: "Delete messages",
          content:
            "Are you sure you want to delete all the messages in this thread?",
          open: openConfirmDelete,
          handleClose: () => setOpenConfirmDelete(false),
          handleDelete,
        }}
      />
      <Grid
        container
        style={{
          height: "100%",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {"Messaging"}
          </Typography>
        </Grid>

        {!messageList.length && (
          <Grid item xs={12}>
            <NothingToShow />
          </Grid>
        )}

        {messageList.length > 0 && (
          <Grid
            item
            xs={12}
            style={{
              height: "100%",
              maxHeight: "90vh",
              overflow: "hidden",
            }}
          >
            <Grid
              container
              wrap="nowrap"
              style={{
                height: "100%",
              }}
            >
              {/* Left side message list */}
              <Grid
                item
                style={{
                  minWidth: 300,
                  borderRight: "1px solid #c1c3c5",
                  width: "20%",
                  maxHeight: "90vh",
                  overflow: "auto",
                }}
              >
                <List>
                  {messageList?.map((thread) => {
                    return (
                      <div key={thread.id}>
                        <ListItem
                          alignItems="flex-start"
                          onClick={() => {
                            handleMarkRead(thread);
                            thread.status = "Read";
                            setSelectedThread(thread);
                          }}
                          style={{
                            cursor: "pointer",
                            borderLeft:
                              thread.id === selectedThread.id
                                ? "3px solid #F01B85"
                                : "3px solid rgba(0,0,0,0)",
                          }}
                        >
                          <MessageListItem
                            {...{
                              sender: thread.message[0].sender,
                              topic: thread.topic,
                              text: thread.message[0].message,
                              date: thread.message[0].created,
                              status: thread.status,
                            }}
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </div>
                    );
                  })}
                </List>
              </Grid>

              {/* Right side message content */}
              <Grid
                item
                style={{
                  width: "80%",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  style={{
                    height: "100%",
                  }}
                >
                  {/* Message content HEADER */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#e8e8e8",
                      height: 82,
                      padding: "1rem",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Typography variant="h6">
                          {selectedThread?.message?.[0]?.sender}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" wrap="nowrap">
                          <Grid item>
                            <IconButton
                              color="inherit"
                              aria-label="menu"
                              onClick={
                                selectedThread.status === "Read"
                                  ? handleMarkUnread
                                  : () => handleMarkRead(selectedThread)
                              }
                              size="large">
                              {selectedThread.status === "Read" ? (
                                <Tooltip title={"Mark Unread"} arrow>
                                  <MarkunreadRoundedIcon />
                                </Tooltip>
                              ) : (
                                <Tooltip title={"Mark Read"} arrow>
                                  <DraftsRoundedIcon />
                                </Tooltip>
                              )}
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Tooltip title={"Delete Messages"} arrow>
                              <IconButton
                                color="inherit"
                                aria-label="menu"
                                onClick={() => setOpenConfirmDelete(true)}
                                size="large">
                                <DeleteRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Message content BODY */}
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: "100%",
                      maxHeight: "82vh",
                      overflow: "auto",
                    }}
                  >
                    {selectedThread?.message?.map((message, index) => {
                      return (
                        <Box m={3} key={index}>
                          <MessageBody {...{ message }} />
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  </>;
};

export default MessagingContainer;
