import React, { useState, useEffect } from "react";

// Utils
import PropTypes from "prop-types";

// Date
import { format } from "date-fns";

// Material UI
import { Typography, Grid } from "@mui/material";

// I18n
import { useTranslation } from "react-i18next";
import { supportedLocales } from "localisations/dateFnsLocale";

const OpeningIntervals = (props) => {
  const { clinic } = props;

  const { t, i18n } = useTranslation();

  const [scheduledIntervals, setScheduledIntervals] = useState(null);

  useEffect(() => {
    if (clinic && clinic.openingIntervals) {
      const intervals = clinic.openingIntervals.filter(
        (interval) => !interval.manuallyAdded,
      );
      if (Array.isArray(intervals) && intervals.length)
        setScheduledIntervals(intervals);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      {scheduledIntervals ? (
        scheduledIntervals.map((interval, index) => (
          <Grid item key={index}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="button">{t("OpensAt")}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {format(new Date(interval.opening), "PPp", {
                    locale: supportedLocales[i18n.language],
                  })}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="button">{t("ClosesAt")}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" gutterBottom>
                  {format(new Date(interval.closing), "PPp", {
                    locale: supportedLocales[i18n.language],
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography variant="body1">{t("IntervalsNoScheduled")}</Typography>
      )}
    </Grid>
  );
};

OpeningIntervals.propTypes = {
  clinic: PropTypes.object.isRequired,
};

export default OpeningIntervals;
