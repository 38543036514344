// WebRTC
import * as signalR from "@microsoft/signalr";

// API
import { apiGet } from "api";

// Utils
import { log } from "helpers";

/***********
 *  MEDIA  *
 **********/
export const getUsersMediaStream = async (constraints) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const streamTracks = stream.getTracks();

    stream.onaddtrack = (e) => {
      log({
        text: `[Local Stream] Track added (${e.track.kind}, ${e.track.enabled})`,
        css: "color:Lime",
        params: [e],
      });
    };

    stream.onremovetrack = (e) => {
      log({
        text: `[Local Stream] Track removed (${e.track.kind}, ${e.track.enabled})`,
        css: "color:Magenta",
        params: [e],
      });
    };

    stream.oninactive = (e) => {
      log({
        text: `[Local Stream] Inactive`,
        css: "color:Magenta",
        params: [e],
      });
    };

    log({
      text: "[Local Stream] Got user's media stream",
      params: [{ stream, streamTracks }],
    });

    return stream;
  } catch (err) {
    log({
      type: "err",
      text: "[Local Stream] Couldn't get user's media stream:",
      params: err.message,
    });

    return null;
  }
};

export const monitorDevices = (monitorDevicesCallback) => {
  try {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices)
      throw new Error("enumerateDevices() not supported");

    navigator.mediaDevices.ondevicechange = () =>
      monitorDevicesCallback(getDevices());
  } catch (err) {
    log({
      type: "err",
      text: "[Media] Error getting devices:",
      params: err.message,
    });
    return null;
  }
};

export const getDevices = async () => {
  try {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices)
      throw new Error("enumerateDevices() not supported");

    const devices = await navigator.mediaDevices.enumerateDevices();

    let deviceTypes = {
      audioinput: [],
      videoinput: [],
      audiooutput: [],
    };
    devices.forEach(
      (device) =>
        (deviceTypes = {
          ...deviceTypes,
          [device.kind]: [...deviceTypes[device.kind], device],
        })
    );
    log({
      text: "[Media] Available devices:",
      css: "color:Magenta",
      params: deviceTypes,
    });

    return deviceTypes;
  } catch (err) {
    log({
      type: "err",
      text: "[Media] Error getting devices:",
      params: err.message,
    });
    return null;
  }
};

export const getScreensharingStream = () => {
  const constraints = {
    video: {
      cursor: "always",
    },
  };

  return navigator.mediaDevices
    .getDisplayMedia(constraints)
    .then((stream) => stream)
    .catch((err) => {
      log({
        type: "err",
        text: "[Media] Couldn't get user's screensharing stream:",
        params: err.message,
      });
      return null;
    });
};

export const stopVideoAndAudioTracks = (stream) => {
  if (!stream) return;

  const array = stream.getTracks();

  array.forEach((i) => {
    if (i.readyState === "live") {
      i.stop();
      log({ text: `[Media] Stopped local ${i.kind} track`, params: [i] });
    }
  });
};

export const stopVideoOnly = (stream) => {
  if (!stream) return;

  const array = stream.getTracks();

  array.forEach((i) => {
    if (i.kind === "video") {
      i.stop();
      log({ text: `[Media] Stopped local ${i.kind} track`, params: [i] });
    }
  });
};

export const stopAudioOnly = (stream) => {
  if (!stream) return;

  const array = stream.getTracks();

  array.forEach((i) => {
    if (i.kind === "audio") {
      i.stop();
      log({ text: `[Media] Stopped local ${i.kind} track`, params: [i] });
    }
  });
};

// export const toggleCamera = (stream, isCameraOn) => {
//   if (!stream) return;

//   const array = stream.getVideoTracks();

//   array.forEach((i) => {
//     if (i.readyState === "live") {
//       i.enabled = isCameraOn ? false : true;
//       log({
//         text: "[Media] Toggled cam:",
//         params: i.enabled,
//       });
//     }
//   });
// };

// export const toggleMic = (stream, isMicOn) => {
//   if (!stream) return;

//   const array = stream.getAudioTracks();

//   array.forEach((i) => {
//     if (i.readyState === "live") {
//       i.enabled = isMicOn ? false : true;
//       log({
//         text: "[Media] Toggled mic:",
//         params: i.enabled,
//       });
//     }
//   });
// };

/*************
 *  SIGNALR  *
 ************/
export let signalRConnection = null;
export let patientId = null;
export let roomId = null;
export let webRtcParameters = null;
export let audioMessagesEnabled = null;
export let ownName = null;

const getAccessToken = async ({
  rId,
  pId,
  invitationCode,
  invitationName,
  isClinician,
}) => {
  let response = null;

  try {
    if (!invitationCode && !pId && !isClinician)
      throw new Error(`Missing parameters`);
    patientId = pId;
    roomId = rId;

    const verifyRole = (role) => {
      if (response.status !== 200) {
        throw new Error("Problem getting token from the server");
      } else if (response.data.configuration !== role) {
        throw new Error("Role doesn't match");
      } else {
        log({
          text: `[Call] Name: ${response.data.name} \nRole: ${response.data.configuration}`,
        });
        webRtcParameters = response.data.webRtcParameters;
        audioMessagesEnabled = response.data.audioMessagesEnabled;
        ownName = response.data.name;
        return response.data.chatToken;
      }
    };

    if (invitationCode) {
      response = await apiGet(
        `/clinic/rooms/join?InvitationCode=${invitationCode}&ParticipantType=Invited&InvitationName=${invitationName}`
      );
      return verifyRole("Invited");
    } else if (patientId && !isClinician) {
      response = await apiGet(
        `/clinic/rooms/join?PatientId=${patientId}&ParticipantType=Patient&RoomId=${roomId}`
      );
      return verifyRole("Patient");
    } else if (isClinician) {
      response = await apiGet(
        `/clinic/rooms/join?ParticipantType=Practitioner&RoomId=${roomId}`
      );
      return verifyRole("Practitioner");
    }
  } catch (err) {
    log({ type: "err", text: "[SignalR] Access token:", params: err.message });
    return null;
  }
};

export const signalRBuildConnection = async (data) => {
  try {
    const accessToken = await getAccessToken(data);
    if (!accessToken) throw new Error(`Access token is ${accessToken}`);
    log({ text: `[SignalR] Received token`, css: "color:MediumSpringGreen" });

    signalRConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        // `${process.env.REACT_APP_SIGNALR_DOMAIN}`,
        // { accessTokenFactory: () => accessToken },
        `${process.env.REACT_APP_SIGNALR_DOMAIN}?access_token=${accessToken}`
      )
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 120000) {
            return Math.random() * 10000;
          }
          return null;
        },
      })
      .build();
  } catch (err) {
    log({ type: "err", params: err.message });
  }
};

export const signalRStartConnection = async (receivedMessageCallback) => {
  try {
    await signalRConnection.start();
    log({
      text: "[SignalR] Connected to signalling server",
      css: "color:MediumSpringGreen",
    });

    signalRConnection.on("ChatMsg", (data) => {
      log({
        text: "[SignalR] Chat message received:",
        css: "color:DarkTurquoise",
        params: data,
      });
      receivedMessageCallback(data);
    });

    // signalRConnection.onclose(async () => {
    //   try {
    //     log({
    //       text: "[SignalR] signalRConnection.onclose -> Trying to reconnect",
    //       css: "color:MediumSpringGreen",
    //     });

    //     await signalRStartConnection();
    //   } catch (err) {
    //     log({
    //       type: "err",
    //       text: "[SignalR] signalRConnection.onclose",
    //       params: err.message,
    //     });
    //   }
    // });
  } catch (err) {
    log({
      type: "err",
      text: "[SignalR] Problem connecting to SignalR:",
      params: err.message,
    });
    // if (signalRConnection?.state !== signalR.HubConnectionState.Connected) {
    //   setTimeout(() => signalRStartConnection(), 1000);
    // }
  }
};

export const signalRInitiateCall = (isClinician, patientId) => {
  if (!patientId) return;

  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    const destination = isClinician ? "ClinicianCall" : "PatientCall";
    signalRConnection
      .invoke(destination, patientId)
      .then(
        log({
          text: `[SignalR] Invoked "${destination}" \npatientId -> [${patientId}]`,
          css: "color:MediumSpringGreen",
        })
      )
      .catch((err) =>
        log({
          type: "err",
          text: "[SignalR] Initiating call problem:",
          params: err.message,
        })
      );
  }
};

export const sayHello = (roomId) => {
  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    signalRConnection.invoke("Hello", roomId).catch((err) =>
      log({
        type: "err",
        text: `[SignalR] Couldn't say "Hello" to room [${roomId}]`,
        params: err.message,
      })
    );
  }
};

export const respondToHello = async (numberOfPeers, connectionId, name) => {
  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    if (numberOfPeers < 4) {
      try {
        await signalRConnection.invoke("Ack", connectionId);
        log({
          text: `[SignalR] "Ack" sent to ${name} [${connectionId}]`,
          css: "color:MediumSpringGreen",
        });
      } catch (err) {
        log({
          type: "err",
          text: `[SignalR] Couldn't say "Ack" to ${name} [${connectionId}]`,
          params: err.message,
        });
      }
    } else {
      try {
        await signalRConnection.invoke("FullRoom", connectionId);
        log({
          text: `[SignalR] "Fullroom" sent to ${name} [${connectionId}]`,
          css: "color:MediumSpringGreen",
        });
      } catch (err) {
        log({
          type: "err",
          text: `[SignalR] Couldn't say "Fullroom" to ${name} [${connectionId}]`,
          params: err.message,
        });
      }
    }
  }
};

export const signalRForwardSignal = (connectionId, data) => {
  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    signalRConnection
      .invoke("CallInfoDynamic", connectionId, JSON.stringify(data))
      .catch((err) =>
        log({
          type: "err",
          text: "[SignalR] Forwarding peer signal to server:",
          params: err.message,
        })
      );
  }
};

export const signalREndCall = async (patientId) => {
  if (!patientId) return;

  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    try {
      await signalRConnection.invoke("EndCall", patientId);
    } catch (err) {
      // log({
      //   type: "err",
      //   text: "[SignalR] Problem while ending call:",
      //   params: err.message,
      // });
    }
  }
};

export const signalRStopConnection = () => {
  if (signalRConnection) signalRConnection.stop();
};

export const clinicianPollNow = async (clinicId) => {
  if (!clinicId) return;

  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    try {
      const response = await signalRConnection.invoke(
        "ClinicianPoll",
        clinicId
      );
      return response;
    } catch (err) {
      // log({
      //   type: "err",
      //   text: "[SignalR] Clinician poll error:",
      //   params: err.message,
      // });
      return null;
    }
  }
};

export const patientPollNow = async (patientId) => {
  if (!patientId) return;

  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    try {
      const response = await signalRConnection.invoke("PatientPoll", patientId);
      return response;
    } catch (err) {
      // log({
      //   type: "err",
      //   text: "[SignalR] Patient poll error:",
      //   params: err.message,
      // });
      return null;
    }
  }
};

/**********
 *  PEER  *
 *********/

export const getPeerStats = (peers) => {
  try {
    const array = Object.keys(peers);

    array.forEach((i) => {
      peers[i].getStats(processStats);
    });
  } catch (err) {
    log({ type: "err", text: "[Peer] Get Stats:", params: err.message });
  }
};

export const processStats = (err, stats) => {
  try {
    if (err != null) throw err;

    const data = JSON.stringify(stats);

    if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
      signalRConnection
        .invoke("Stats", roomId, patientId, data)
        .catch((err) => {
          console.error(err?.message);
        });
    }
  } catch (err) {
    log({ type: "err", text: "[Peer] Process Stats:", params: err.message });
  }
};

export const addPeerStream = (peers, stream) => {
  try {
    if (!stream) throw new Error(`Stream is ${stream}`);

    const array = Object.keys(peers);

    array.forEach((i) => {
      peers[i].addStream(stream);
      log({ text: `[Peer] Added new stream:`, params: [stream] });
    });
  } catch (err) {
    log({ type: "err", text: "[Peer] Add stream:", params: err.message });
  }
};

export const removePeerTracks = (peers, stream) => {
  try {
    if (!stream) throw new Error(`Stream is ${stream}`);

    const allTracks = stream.getTracks();

    const allPeers = Object.keys(peers);

    allPeers.forEach((peer) => {
      allTracks.forEach((track) => {
        peers[peer].removeTrack(track, stream);
        log({
          text: `[Peer] Removed ${track.kind} track for [${peer}]:`,
          params: [stream],
        });
      });
    });
  } catch (err) {
    // log({ type: "err", text: "[Peer] Remove stream:", params: err.message });
  }
};

export const removePeerStream = (peers, stream) => {
  try {
    if (!stream) throw new Error(`Stream is ${stream}`);

    const array = Object.keys(peers);

    array.forEach((i) => {
      peers[i].removeStream(stream);
      log({ text: `[Peer] Removed stream for [${i}]:`, params: [stream] });
    });
  } catch (err) {
    // log({ type: "err", text: "[Peer] Remove stream:", params: err.message });
  }
};

export const destroyAllPeers = (peers) => {
  try {
    // Closes all connections
    const array = Object.keys(peers);

    array.forEach((i) => {
      peers[i].destroy();
      log({ text: "[Peer] Destroyed peer:", params: [i] });
    });

    // Clears peers object
    peers = {};
  } catch (err) {
    log({ type: "err", text: "[Peer] Destroy peers:", params: err.message });
  }
};

export const replacePeerTrack = (peers, oldTrack, newTrack, stream) => {
  try {
    const array = Object.keys(peers);

    array.forEach((i) => {
      peers[i].replaceTrack(oldTrack, newTrack, stream);
      log({
        text: `[Peer] Replaced ${newTrack.kind} track:`,
        params: { oldTrack, newTrack, stream },
      });
    });
    return true;
  } catch (err) {
    log({ type: "err", text: "[Peer] Replace track:", params: err.message });
    return false;
  }
};

export const addPeerTrack = (peers, newTrack, stream) => {
  try {
    const array = Object.keys(peers);

    array.forEach((i) => {
      peers[i].addTrack(newTrack, stream);
      log({
        text: `[Peer] Added ${newTrack.kind} track:`,
        params: { newTrack, stream },
      });
    });
    return true;
  } catch (err) {
    log({ type: "err", text: "[Peer] Add track:", params: err.message });
    return false;
  }
};

/**********
 *  CHAT  *
 *********/
export const sendChatMessage = async (textFieldValue, roomId) => {
  if (signalRConnection?.state === signalR.HubConnectionState.Connected) {
    try {
      if (!roomId) throw new Error(`Room ID is ${roomId}`);
      if (!textFieldValue) throw new Error("Invalid text field value");

      await signalRConnection.invoke("ChatMsg", roomId, textFieldValue);
      log({
        text: `[SignalR] Chat message "${textFieldValue}" sent to room [${roomId}]`,
        css: "color:Orchid",
      });
    } catch (err) {
      log({
        type: "err",
        text: "[SignalR] Send chat message:",
        params: err.message,
      });
    }
  } else {
    setTimeout(() => sendChatMessage(textFieldValue, roomId), 500);
  }
};
