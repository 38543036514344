import React from "react";

// Utils
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    // height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const ClinicsToolbar = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {children}
      </div>
    </div>
  );
};

ClinicsToolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ClinicsToolbar;
