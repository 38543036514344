import React, { useState, useContext } from "react";

// Context
import { CallContext } from "contexts";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import { Typography, Box, Button, Grid, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    width: "100%",
    // height: "100%",
    // backgroundColor: "#FFF",
  },
  wrapper: {
    maxWidth: "500px",
    // height: "75%",
    // backgroundColor: "#BBB",
  },
}));

const JoinRoom = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const { setCallStatus } = useContext(CallContext);

  const invitationCode = props.match.params.invitation_id;
  const roomId = props.match.params.room_id;

  const classes = useStyles();

  const [invitationName, setInvitationName] = useState("");

  const handleChange = (e) => {
    setInvitationName(e.target.value);
  };

  const handleJoinCall = () => {
    setCallStatus((prevState) => ({
      ...prevState,
      isInvited: true,
      invitationCode,
      invitationName,
    }));
    history.push(`/c/${roomId}`);
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        className={classes.wrapper}
        direction="column"
        justifyContent="center"
        // alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {t("CallInviteeTitle")}
          </Typography>
          <Typography color="textSecondary">
            {t("CallInviteeSubtitle")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Grid item>
            <Typography variant="h5">
              {t("CallInviteeInputNameLable")}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label={t("CallInviteeInputName")}
              name="name"
              onChange={handleChange}
              type="text"
              value={invitationName || ""}
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Button
            disabled={!invitationName}
            variant="contained"
            color="secondary"
            onClick={handleJoinCall}
          >
            {t("CallInviteeJoinButton")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JoinRoom;
