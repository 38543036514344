import React from "react";

// Material UI
import { Popover, Typography, List, ListItem } from "@mui/material";

// Available languages
import { languageCodesAndNames } from "localisations/i18n";

// I18n
import { useTranslation } from "react-i18next";

const TopbarLanguagePopover = (props) => {
  const { showLanguageAnchorEl, setShowLanguageAnchorEl } = props;

  const { i18n } = useTranslation();

  const handleLanguageClose = () => {
    setShowLanguageAnchorEl(null);
  };

  const open = Boolean(showLanguageAnchorEl);

  return (
    <Popover
      open={open}
      anchorEl={showLanguageAnchorEl}
      onClose={handleLanguageClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List>
        {Object.keys(languageCodesAndNames).map((lang) => {
          return (
            <ListItem
              button
              onClick={() => {
                i18n.changeLanguage(lang);
                handleLanguageClose();
              }}
              key={lang}
            >
              <Typography variant="button">
                {languageCodesAndNames[lang]}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
};

export default TopbarLanguagePopover;
