import React, { useState } from "react";

// Components
import ButtonWithMenu from "./ButtonWithMenu";

// API
import { endpoints, apiPatch } from "api";

import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Box, Icon, IconButton, Button } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { grey } from "@mui/material/colors";

const boxShadow =
  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";
const neon =
  "inset 0 0 5px #fff,inset 2px 0 8px #f0f,inset 0 0 40px #0ff,inset 2px 0 60px #f0f,inset 0 0 30px #0ff,0 0 5px #fff,0 0 8px #f0f,1px 0 8px #0ff";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 240,
  },
  coverWrapper: {
    position: "relative",
    height: 200,
    backgroundColor: grey[300],
    borderRadius: "1rem 1rem 0 0",
  },
  coverActionPosition: {
    position: "absolute",
    bottom: 10,
    right: 12,
  },
  coverImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "1rem 1rem 0 0",
  },
  avatarWrapper: {
    position: "absolute",
    bottom: -38.5,
    left: 20,
  },
  avatar: {
    boxShadow,
    width: theme.spacing(20),
    height: theme.spacing(20),
    cursor: "pointer",
    "&:hover": {
      boxShadow: neon,
    },
  },
  avatarIcon: {
    width: "120%",
    height: "120%",
    color: "white",
  },
  avatarActionPosition: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  editButton: {
    backgroundColor: grey[100],
    color: grey[600],
    border: "1px solid",
    borderColor: grey[200],
    "&:hover": {
      backgroundColor: grey[200],
      transition: "ease-in-out 0.33s",
    },
  },
}));

const ProfileImageAndCover = ({ profile }) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState({
    el: null,
    type: null,
  });

  const handleSetAnchor = (el, type) => {
    setAnchor({ el, type });
  };

  return (
    <Box className={classes.container}>
      <ButtonWithMenu
        {...{
          anchor,
          setAnchor,
        }}
      />

      <Box className={`${classes.coverWrapper}`}>
        {/* Profile Cover */}
        {profile.bgImageUrl && (
          <img
            className={classes.coverImage}
            src={profile.bgImageUrl}
            alt="cover"
          />
        )}

        {/* Action */}
        <Box className={classes.coverActionPosition}>
          <Button
            aria-controls="menu"
            aria-haspopup="true"
            name="cover"
            onClick={(e) => handleSetAnchor(e.currentTarget, "cover")}
            variant="contained"
            startIcon={<Icon>photo_camera</Icon>}
          >
            Edit cover photo
          </Button>
        </Box>

        {/* Profile Avatar/Image */}
        <Box className={classes.avatarWrapper}>
          <Box>
            <Avatar
              className={classes.avatar}
              alt="profile picture"
              src={profile.profileImageUrl}
              onClick={(e) => handleSetAnchor(e.currentTarget, "photo")}
            >
              <AccountCircleRoundedIcon className={classes.avatarIcon} />
            </Avatar>

            {/* Action */}
            <Box className={classes.avatarActionPosition}>
              <IconButton
                size="small"
                className={classes.editButton}
                onClick={(e) => handleSetAnchor(e.currentTarget, "photo")}
              >
                <Icon>photo_camera</Icon>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileImageAndCover;
