import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/material";

export enum CryptoView {
  ADD_KEY = "ADD_KEY",
  DISPLAY_KEY = "DISPLAY_KEY",
  DISABLED = "DISABLED",
}

interface Props {
  onAddETHKey: () => void;
  wallet: string | undefined;
  view: CryptoView;
}

export const CryptoPaymentsButton = ({ view, wallet, onAddETHKey }: Props) => {
  return (
    <>
      {view === CryptoView.ADD_KEY && (
        <Button variant="outlined" onClick={onAddETHKey}>
          Add Wallet
        </Button>
      )}

      {view === CryptoView.DISPLAY_KEY && (
        <Typography>{wallet || ""}</Typography>
      )}

      {view === CryptoView.DISABLED && (
        <Alert severity="warning"> Paid appointments must be enabled </Alert>
      )}
    </>
  );
};
