export const asyncSettings = {
  saveUrl: `${process.env.REACT_APP_API_DOMAIN}/patient/attachment2`,
  removeUrl: `${process.env.REACT_APP_API_DOMAIN}/patient/attachment2`,
};

export const onActionComplete = (args) => {
  console.log("[UPLOADER] OnActionComplete", args);
  // $('#submitbutton').prop('disabled', false);
};

export const onUploadSuccess = (args) => {
  console.log("[UPLOADER] OnUploadSuccess", args);
  if (args.operation === "upload") {
    console.log("[UPLOADER] Success");
  } else if (args.operation === "remove") {
    console.log("[UPLOADER] Removed");
    // $('#submitbutton').prop('disabled', false);
  }
};

export const onUploading = (args, PatientID) => {
  console.log("[UPLOADER] OnUploading", args);
  // $('#submitbutton').prop('disabled', true);
  args.customFormData = [{ PatientID }];
  // args.currentRequest.setRequestHeader(
  //   "XSRF-TOKEN",
  //   document.getElementsByName("__RequestVerificationToken")[0].value,
  // );
};

export const onRemoving = (args, PatientID) => {
  console.log("[UPLOADER] OnRemoving", args);
  // $('#submitbutton').prop('disabled', true);
  args.customFormData = [{ PatientID }];
  // if (args.currentRequest != null)
  //   args.currentRequest.setRequestHeader(
  //     "XSRF-TOKEN",
  //     document.getElementsByName("__RequestVerificationToken")[0].value,
  //   );
};

export const onUploadFailure = (args) => {
  console.log("[UPLOADER] Failed", args);
};
