import React, { createContext, useState } from "react";

export const ClinicsContext = createContext();

export const ClinicsProvider = ({ children }) => {
  const [clinicsList, setClinicsList] = useState(null);

  return (
    <ClinicsContext.Provider value={[clinicsList, setClinicsList]}>
      {children}
    </ClinicsContext.Provider>
  );
};
