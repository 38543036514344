import React, { useState, useEffect } from "react";

// Utils
import PropTypes from "prop-types";
import clsx from "clsx";

// Router
import { withRouter } from "react-router-dom";

// APIs
import { apiGet, apiPost, endpoints } from "api";

// Components
import { ProfileSection, PublishToggle } from "./components";
import { GenericFormModal } from "components";

import makeStyles from '@mui/styles/makeStyles';
import {
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";

// I18n
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    padding: theme.spacing(2),
    overflow: "unset",
    maxWidth: "800px",
  },
  cardContent: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  valueContainer: {
    padding: theme.spacing(2),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  value: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
    "&::before": {
      content: `''`,
      position: "absolute",
      left: 0,
      width: ".2rem",
      height: ".75rem",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  emailAction: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  profilePhoto: {
    width: "10rem",
    objectFit: "cover",
  },
  defaultProfilePhoto: {
    width: "10rem",
    objectFit: "cover",
  },
  iconContainer: {
    width: "3rem",
    display: "flex",
    justifyContent: "center",
    height: "1.5rem",
    alignItems: "center",
  },
  loading: {
    height: "100%",
  },
  genericValue: {
    marginTop: "1rem",
  },
  bubbleItem: {
    display: "flex",
    border: "1px solid #e2e0e0",
    borderRadius: "15px",
    padding: "7px",
    alignItems: "center",
    margin: "10px 10px 0 0",
  },
  bubbleItemClear: {
    paddingLeft: "10px",
  },
  clinicAddress: {
    maxWidth: "300px",
  },
}));

const Profile = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [profileData, setProfileData] = useState({
    emails: [],
    memberships: [],
    clinicalInterests: [],
    treatments: [],
    qualifications: [],
    licenses: [],
    clinics: [],
    isPublished: false,
    profilePhoto: "",
    loaded: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [selectOptions, setSelectOptions] = useState({
    titles: [],
    treatmentsinterests: [],
    memberships: [],
    licensenames: [],
    qualifications: [],
  });

  const closeModal = () => {
    setModalOpen(false);
  };

  const setFormModal = (formContent) => {
    setModalContent(formContent);
    setModalOpen(true);
  };

  const getProfileData = async () => {
    try {
      const response = await apiGet(endpoints.profile.all);

      if (response.status === 200 && response.data) {
        setProfileData({
          loaded: true,
          ...response.data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setPublished = async (isPublished) => {
    try {
      const response = await apiPost(endpoints.profile.publish, {
        published: isPublished,
      });

      if (response.status === 200 && response.data) {
        getProfileData();
      }
    } catch (err) {
      getProfileData();
      console.error(err);
    }
  };

  const removeItem = async (type, id) => {
    try {
      const response = await apiPost(endpoints.profile.remove, {
        object: type,
        id,
      });

      if (response.status === 200 && response.data) {
        getProfileData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectOptions = async (endpoint) => {
    try {
      const response = await apiGet(endpoint);
      if (response.status === 200 && response.data) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const getAllSelectOptions = async () => {
      const titles = await getSelectOptions(endpoints.profile.list.titles);
      const treatmentsinterests = await getSelectOptions(
        endpoints.profile.list.treatmentsinterests,
      );
      const memberships = await getSelectOptions(
        endpoints.profile.list.memberships,
      );
      const licensenames = await getSelectOptions(
        endpoints.profile.list.licensenames,
      );
      const qualifications = await getSelectOptions(
        endpoints.profile.list.qualifications,
      );

      setSelectOptions({
        titles,
        treatmentsinterests,
        memberships,
        licensenames,
        qualifications,
      });
    };

    getProfileData();
    getAllSelectOptions();
  }, []);

  if (!profileData.loaded) {
    return <div className={classes.loading}></div>;
  }

  return (
    <>
      <div className={clsx(classes.root)}>
        <CardHeader
          title={t("MenuProfile")}
          subheader={t("AccountProfileSubheader")}
        />
        <CardContent className={classes.cardContent}>
          <ProfileSection
            heading="Publish"
            headingActionComponent={PublishToggle}
            headingActionComponentProps={{
              isIcon: false,
              checked: profileData.isPublished,
              value: profileData.isPublished,
              onChange: (val) => {
                // API request here
                const isPublished = val;
                setPublished(isPublished);

                setProfileData({
                  ...profileData,
                  isPublished,
                });
              },
              name: "publishSwitch",
              // size: "small",
              color: "primary",
              inputProps: {
                "aria-label": profileData.isPublished ? "Disable" : "Enable",
              },
            }}
            renderChildren={() => {
              const isPublished = profileData.isPublished;

              if (profileData?.profileURL && isPublished) {
                return (
                  <Grid container className={classes.genericValue}>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        <Link
                          component="a"
                          href={profileData?.profileURL}
                          target="_blank"
                        >
                          {profileData?.profileURL}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }
            }}
          />

          {/* <ProfileSection
            heading="Email Addresses"
            headingAction={() => {
              setFormModal({
                formTitle: "Add Email Address",
                acceptLabel: "Add",
                endpoint: endpoints.profile.email,
                successMessage: "Email Added",
                closeLabel: "Cancel",
                fieldArray: [
                  {
                    name: "emailAddress",
                    label: "Email Address",
                    type: "input",
                  },
                ],
                schema: {
                  emailAddress: {
                    presence: { allowEmpty: false, message: "is required" },
                    email: true,
                    length: {
                      maximum: 64,
                    },
                  },
                },
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() =>
              profileData?.emails.map(({ id, email }) => (
                <div
                  variant="outlined"
                  className={classes.valueContainer}
                  key={id}
                >
                  <Grid container>
                    <Grid item xs={6} className={classes.value}>
                      <Typography>{email}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={classes.emailAction}
                      onClick={() => removeItem("email", id)}
                    >
                      <div className={classes.iconContainer}>
                        <IconButton aria-label="delete" size="small">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))
            }
          /> */}

          <ProfileSection
            heading="Personal Details"
            headingAction={() => {
              setFormModal({
                formTitle: "Update Personal Details",
                acceptLabel: "Save Changes",
                endpoint: endpoints.profile.personalDetails,
                closeLabel: "Cancel",
                fieldArray: [
                  {
                    name: "title",
                    label: "Title",
                    type: "select",
                    initialValue: profileData?.title,
                    options: Object.values(
                      selectOptions.titles,
                    ).map((title) => ({ value: title, label: title })),
                  },
                  {
                    name: "firstName",
                    label: "First Name",
                    type: "input",
                    initialValue: profileData?.firstName,
                  },
                  {
                    name: "lastName",
                    label: "Last Name",
                    type: "input",
                    initialValue: profileData?.lastName,
                  },
                ],
                schema: {
                  // emailAddress: {
                  //   presence: { allowEmpty: false, message: "is required" },
                  //   email: true,
                  //   length: {
                  //     maximum: 64,
                  //   },
                  // },
                },
              });
            }}
            headingActionComponent={EditIcon}
            headingActionAriaLabel="Edit"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                <Grid item xs={12}>
                  <Typography>{`${profileData?.title} ${profileData?.firstName} ${profileData?.lastName}`}</Typography>
                </Grid>
              </Grid>
            )}
          />

          <ProfileSection
            heading="Photo"
            headingAction={() => {
              setFormModal({
                formTitle: "Update Photo",
                acceptLabel: "Save Changes",
                endpoint: endpoints.profile.attachment,
                successMessage: "Photo updated",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "uploadFile",
                    label: "Photo",
                    type: "fileUpload",
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={EditIcon}
            headingActionAriaLabel="Edit"
            renderChildren={() => (
              <>
                <div variant="outlined">
                  <Grid container>
                    <Grid item xs={6} className={classes.genericValue}>
                      <Typography>
                        <img
                          alt="profile"
                          className={
                            profileData?.profilePhoto
                              ? classes.profilePhoto
                              : classes.defaultProfilePhoto
                          }
                          src={
                            profileData?.profilePhoto ||
                            "/images/default-profile.png"
                          }
                        />
                      </Typography>
                    </Grid>

                    {profileData?.profilePhoto && (
                      <Grid
                        item
                        xs={6}
                        className={classes.emailAction}
                        onClick={() => removeItem("photo")}
                      >
                        <div className={classes.iconContainer}>
                          <IconButton aria-label="delete" size="small">
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </>
            )}
          />

          <ProfileSection
            heading="Bio"
            headingAction={() => {
              setFormModal({
                formTitle: "Update Biography",
                acceptLabel: "Save Changes",
                endpoint: endpoints.profile.biography,
                successMessage: "Biography updated",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "biography",
                    label: "Biography",
                    type: "textarea",
                    initialValue: profileData?.biography,
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={EditIcon}
            headingActionAriaLabel="Edit"
            renderChildren={() => {
              const hasBiography = profileData?.biography.length;

              if (hasBiography) {
                return (
                  <div>
                    <Grid container className={classes.genericValue}>
                      <Grid item xs={12}>
                        <Typography>{profileData?.biography}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            }}
          />

          <ProfileSection
            heading="Clinical Interests"
            headingAction={() => {
              setFormModal({
                formTitle: "Add Clinical Interest",
                acceptLabel: "Add",
                endpoint: endpoints.profile.clinicalInterest,
                successMessage: "Clinical Interests added",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "clinicalInterest",
                    label: "Clinical Interest",
                    type: "selectFreeform",
                    options: selectOptions.treatmentsinterests,
                    multipleValues: true,
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                {profileData?.clinicalInterests.map(
                  ({ id, clinicalInterest }) => (
                    <div
                      variant="outlined"
                      className={classes.bubbleItem}
                      key={id}
                    >
                      <Typography>{clinicalInterest}</Typography>
                      <div
                        className={classes.bubbleItemClear}
                        onClick={() => removeItem("clinicalInterest", id)}
                      >
                        <IconButton aria-label="delete" size="small">
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </div>
                  ),
                )}
              </Grid>
            )}
          />

          <ProfileSection
            heading="Treatments"
            headingAction={() => {
              setFormModal({
                formTitle: "Add Treatment",
                acceptLabel: "Add",
                endpoint: endpoints.profile.treatment,
                successMessage: "Treatments added",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "treatment",
                    label: "Treatment",
                    type: "selectFreeform",
                    options: selectOptions.treatmentsinterests,
                    multipleValues: true,
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                {profileData?.treatments.map(({ id, treatment }) => (
                  <div
                    variant="outlined"
                    className={classes.bubbleItem}
                    key={id}
                  >
                    <Typography>{treatment}</Typography>
                    <div
                      className={classes.bubbleItemClear}
                      onClick={() => removeItem("treatment", id)}
                    >
                      <IconButton aria-label="delete" size="small">
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </Grid>
            )}
          />

          <ProfileSection
            heading="Qualifications"
            headingAction={() => {
              setFormModal({
                formTitle: "Add Qualification",
                acceptLabel: "Add",
                endpoint: endpoints.profile.qualification,
                successMessage: "Qualifications added",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "qualification",
                    label: "Qualification",
                    type: "selectFreeform",
                    options: selectOptions.qualifications,
                    multipleValues: true,
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                {profileData?.qualifications.map(({ id, qualification }) => (
                  <div
                    variant="outlined"
                    className={classes.bubbleItem}
                    key={id}
                  >
                    <Typography>{qualification}</Typography>
                    <div
                      className={classes.bubbleItemClear}
                      onClick={() => removeItem("qualification", id)}
                    >
                      <IconButton aria-label="delete" size="small">
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </Grid>
            )}
          />

          <ProfileSection
            heading="Memberships/Fellowships"
            headingAction={() => {
              setFormModal({
                formTitle: "Add Membership",
                acceptLabel: "Add",
                endpoint: endpoints.profile.membership,
                successMessage: "Memberships/Fellowships added",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "membership",
                    label: "Membership",
                    type: "selectFreeform",
                    options: selectOptions.memberships,
                    multipleValues: true,
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                {profileData?.memberships.map(({ id, membership }) => (
                  <div
                    variant="outlined"
                    className={classes.bubbleItem}
                    key={id}
                  >
                    <Typography>{membership}</Typography>
                    <div
                      className={classes.bubbleItemClear}
                      onClick={() => removeItem("membership", id)}
                    >
                      <IconButton aria-label="delete" size="small">
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </Grid>
            )}
          />

          <ProfileSection
            heading="Licenses"
            headingAction={() => {
              setFormModal({
                formTitle: "Add License",
                acceptLabel: "Add",
                endpoint: endpoints.profile.license,
                successMessage: "License added",
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "name",
                    label: "Licence Name",
                    type: "selectFreeform",
                    options: selectOptions.licensenames,
                  },
                  {
                    name: "number",
                    label: "License Number",
                    type: "input",
                  },
                  {
                    name: "url",
                    label: "License Url",
                    type: "input",
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                {profileData?.licenses.map(({ id, name, number, url }) => (
                  <div
                    variant="outlined"
                    className={classes.bubbleItem}
                    key={id}
                  >
                    <Typography>
                      {name} - {number} - {url}
                    </Typography>
                    <div
                      className={classes.bubbleItemClear}
                      onClick={() => removeItem("license", id)}
                    >
                      <IconButton aria-label="delete" size="small">
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </Grid>
            )}
          />

          <ProfileSection
            heading="Clinics/Places of Work"
            headingAction={() => {
              setFormModal({
                formTitle: "Add Clinic/Location",
                acceptLabel: "Add",
                endpoint: endpoints.profile.clinic,
                closeLabel: "Close",
                fieldArray: [
                  {
                    name: "clinic",
                    label: "Location",
                    type: "locationSelect",
                  },
                ],
                schema: {},
              });
            }}
            headingActionComponent={AddIcon}
            headingActionAriaLabel="Add"
            renderChildren={() => (
              <Grid container className={classes.genericValue}>
                {profileData?.clinics.map(({ id, clinic }) => (
                  <div
                    variant="outlined"
                    className={clsx(classes.bubbleItem, {
                      [classes.clinicAddress]: true,
                    })}
                    key={id}
                  >
                    <Typography>
                      {`${JSON.parse(clinic).name}, ${
                        JSON.parse(clinic).formatted_address
                      }`}
                    </Typography>
                    <div
                      className={classes.bubbleItemClear}
                      onClick={() => removeItem("clinic", id)}
                    >
                      <IconButton aria-label="delete" size="small">
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </Grid>
            )}
          />
        </CardContent>
      </div>
      <GenericFormModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        formContent={modalContent}
        onSuccess={() => {
          getProfileData();
        }}
      />
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

Profile.defaultProps = {};

export default withRouter(Profile);
