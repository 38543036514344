import React, { useState } from "react";

// Utils
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

// API
import { apiPost, endpoints } from "api";

// I18n
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  Grid,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "auto",
  },
  editButton: {
    color: theme.palette.text.secondary,
  },
  saveButton: {
    color: "#49cc90",
  },
  cancelButton: {
    color: "#f93e3e",
  },
}));

const ClinicNameWithChange = (props) => {
  const { clinicName, clinicId } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [newClinicName, setNewClinicName] = useState(clinicName);

  const handleSubmitClinicNameChange = async () => {
    try {
      const response = await apiPost(endpoints.clinic.settings, {
        clinicId,
        name: newClinicName,
      });
      if (response.status === 200) {
        enqueueSnackbar("Clinic name changed successfully", {
          variant: "success",
        });
        setIsEditMode(false);
      } else {
        enqueueSnackbar("Unable to change clinic name", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("There is a problem changing clinic name", {
        variant: "error",
      });
      console.error(err);
    }
  };

  const handleCancelChange = () => {
    setIsEditMode(false);
    setNewClinicName(clinicName);
  };

  return (
    <Box className={classes.root}>
      {!isEditMode && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          onClick={() => setIsEditMode(true)}
        >
          <Grid item>
            <Typography align="center" gutterBottom variant="h4">
              {clinicName ? clinicName : "Clinic"}
            </Typography>
          </Grid>

          <Grid item>
            <Tooltip title={t("ChangeClinicName")} arrow>
              <IconButton
                aria-label={t("ChangeClinicName")}
                className={classes.editButton}
                size="large">
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}

      {isEditMode && (
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <TextField
              fullWidth
              label={t("ChangeClinicName")}
              name="newEmail"
              type="text"
              value={newClinicName}
              onChange={(e) => {
                setNewClinicName(e.target.value);
              }}
            />
          </Grid>

          <Grid item>
            <Tooltip title={t("ChangeClinicNameSave")} arrow>
              <IconButton
                aria-label={t("ChangeClinicNameSave")}
                onClick={handleSubmitClinicNameChange}
                className={classes.saveButton}
                size="large">
                <CheckCircleRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title={t("ChangeClinicNameCancel")} arrow>
              <IconButton
                aria-label={t("ChangeClinicNameCancel")}
                onClick={handleCancelChange}
                className={classes.cancelButton}
                size="large">
                <CancelRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

ClinicNameWithChange.propTypes = {
  clinicName: PropTypes.string.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default ClinicNameWithChange;
