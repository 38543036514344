import React, { useState, useEffect, useRef } from "react";

// Utils
import PropTypes from "prop-types";
import validate from "validate.js";

// Components
import { FramerModal as Modal } from "components";
import LocationInput from "./fields/LocationInput";
import ApiValidateInput from "./fields/ApiValidateInput";

// Utils
import { useSnackbar } from "notistack";

// APIs
import { apiPost } from "api";

import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { DropzoneAreaBase } from "material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  headingActionIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  cancelButton: {
    marginRight: 20,
  },
  form: {
    // marginTop: 20,
  },
  formField: {
    marginTop: 20,
  },
}));

const GenericFormModal = ({ isOpen, formContent, handleClose, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const formRef = useRef();

  const schema = {
    ...formContent?.schema,
  };

  const [images, setImages] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  // Set the initial values
  useEffect(() => {
    if (formContent) {
      formContent.fieldArray.forEach((field) => {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [field?.name]: field.initialValue,
          },
        }));
      });
    }
  }, [formContent]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    //  eslint-disable-next-line
  }, [formState.values, formContent]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }));
  };

  const resetFormValues = () => {
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
  };

  const getFieldGroup = (field) => {
    let renderField = null;

    if (field.type === "input") {
      renderField = (
        <TextField
          label={field?.label}
          error={hasError(field?.name)}
          fullWidth
          helperText={
            hasError(field?.name) ? formState.errors[field?.name][0] : null
          }
          name={field?.name}
          onChange={handleChange}
          type={field?.textType ? field.textType : "text"}
          value={formState.values[field.name] || ""}
          variant="outlined"
        />
      );
    }

    if (field.type === "textarea") {
      renderField = (
        <TextField
          label={field?.label}
          error={hasError(field?.name)}
          fullWidth
          helperText={
            hasError(field?.name) ? formState.errors[field?.name][0] : null
          }
          name={field?.name}
          onChange={handleChange}
          type="text"
          value={formState.values[field.name] || ""}
          variant="outlined"
          multiline
          rows={5}
        />
      );
    }

    if (field.type === "select") {
      renderField = (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth
        >
          <InputLabel id={`${field?.label}-label`}>{field?.label}</InputLabel>
          <Select
            labelId={`${field?.label}-label`}
            id={field?.name}
            label={field?.label}
            name={field?.name}
            value={formState.values[field.name] || ""}
            onChange={handleChange}
            fullWidth
            // helperText={
            //   hasError(field?.name) ? formState.errors[field?.name][0] : null
            // }
          >
            {field?.options.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (field.type === "selectFreeform") {
      renderField = (
        <Autocomplete
          multiple={field?.multipleValues}
          freeSolo
          id={field?.name}
          name={field?.name}
          disableClearable
          options={field?.options.map((opt) => opt)}
          onChange={(e, newValue) => {
            const customEvent = {
              target: {
                name: field?.name,
                value: newValue,
              },
            };
            handleChange(customEvent);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field?.label}
              margin="normal"
              variant="outlined"
              value={formState.values[field.name] || ""}
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
        />
      );
    }

    if (field.type === "apiValidateInput") {
      renderField = (
        <ApiValidateInput
          id={field?.name}
          onChange={(value) => {
            const customEvent = {
              target: {
                name: field?.name,
                value,
              },
            };

            handleChange(customEvent);
          }}
          prefix={field?.prefix}
        />
      );
    }

    if (field.type === "locationSelect") {
      renderField = (
        <LocationInput
          field={field}
          value={formState.values[field.name] || ""}
          onChange={(newValue) => {
            const customEvent = {
              target: {
                name: field?.name,
                value: JSON.stringify(newValue),
              },
            };

            handleChange(customEvent);
          }}
        />
      );
    }

    if (field.type === "fileUpload") {
      if (formRef.current) {
        // Make the form modal bigger for the file upload
        formRef.current.style.width = `${600}px`;
      }

      renderField = (
        <>
          <DropzoneAreaBase
            open={true}
            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
            maxFileSize={5000000}
            filesLimit={1}
            showAlerts={false}
            fileObjects={images}
            onAdd={(fileObjs) => {
              setImages(fileObjs);
              setFormState((formState) => ({
                ...formState,
                values: {
                  ...formState.values,
                  [field?.name]: fileObjs[0].data,
                },
                touched: {
                  ...formState.touched,
                  [field?.name]: true,
                },
              }));
            }}
            onDelete={(fileObjs) => {
              setImages([]);
              setFormState((formState) => ({
                ...formState,
                values: {
                  ...formState.values,
                  [field?.name]: null,
                },
              }));
            }}
          />
        </>
      );
    }

    if (field) {
      return (
        <div key={field.name} className={classes.formField}>
          {renderField}
        </div>
      );
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    console.log(formState.values);
    try {
      const response = await apiPost(formContent.endpoint, formState.values);
      if (response.status === 200) {
        if (formContent?.successMessage) {
          enqueueSnackbar(formContent.successMessage, { variant: "success" });
        }
        onSuccess(formState.values);
        handleClose();
        resetFormValues();
      } else if (response.data) {
        if (formContent?.processError) {
          formContent.processError(response.data);
        } else {
          enqueueSnackbar(
            typeof response.data.detail === "string"
              ? response.data.detail
              : "Problem performing the request",
            {
              variant: "error",
            }
          );
        }

        handleClose();
        resetFormValues();
      }
    } catch (err) {
      enqueueSnackbar("Problem performing the request", {
        variant: "error",
      });

      console.error(err);
    }
  };

  return (
    <Modal open={isOpen} handleClose={handleClose}>
      <form className={classes.root} onSubmit={submitForm} ref={formRef}>
        <div className={classes.header}>
          <Typography variant="h5">{formContent?.formTitle}</Typography>
          <IconButton aria-label="Close" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.form}>
          {formContent?.fieldArray.map((field) => {
            // return <FieldGroup key={field.name} field={field} />
            return getFieldGroup(field);
          })}
        </div>
        <div className={classes.actionButtons}>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            variant="contained"
          >
            {formContent?.closeLabel}
          </Button>
          <Button
            type="submit"
            disabled={!formState.isValid}
            variant="contained"
            color="primary"
          >
            {formContent?.acceptLabel}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

GenericFormModal.propTypes = {
  onSuccess: PropTypes.func,
};

GenericFormModal.defaultProps = {
  onSuccess: PropTypes.func,
};

export default GenericFormModal;
