import { createTheme, Theme } from "@mui/material/styles";
import { colors } from "@mui/material";

/**
 * The old developer created multiple themes that were not implmneted
 * inside the applicaiton.
 *
 * This file was updated by Material UI tool when upgrading from V4 to V5
 * npx @mui/codemod v5.0.0/preset-safe ./
 *
 * This created alot of uneccaery code (considering most of the theme was unsed)
 * This is a simplfied version of the only used theme
 */

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const white = "#FFFFFF";
const black = "#000000";

export const legacyTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
      defaultProps: {
        size: "medium",
        fullWidth: true,
      },
    },
  },
  palette: {
    primary: {
      main: "#334bfa",
      contrastText: white,
    },
    secondary: {
      contrastText: white,
      main: "#F01B85",
    },
    success: {
      contrastText: white,
      dark: colors.green[900],
      main: colors.green[600],
      light: colors.green[400],
    },
    info: {
      contrastText: white,
      dark: colors.blue[900],
      main: colors.blue[600],
      light: colors.blue[400],
    },
    warning: {
      contrastText: white,
      dark: colors.orange[900],
      main: colors.orange[600],
      light: colors.orange[400],
    },
    error: {
      contrastText: white,
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400],
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      fontSize: "14px",
      textTransform: "capitalize",
    },
    caption: {
      color: colors.grey["600"],
    },
  },
});
