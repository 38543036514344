import React, { useState, useEffect, useContext, useRef, useMemo } from "react";

// WebRTC
import Peer from "simple-peer";

// Contexts
import { FullscreenContext, ChatContext, CallContext } from "contexts";

// Components
import { Chat, InviteToRoom } from "components";
import {
  VideoElement,
  VideoCallActions,
  WaitingForClinician,
  VideoCallSettings,
  NoUserMedia,
} from "./components";
import {
  signalRConnection,
  getUsersMediaStream,
  getDevices,
  signalRBuildConnection,
  webRtcParameters,
  audioMessagesEnabled,
  ownName,
  signalRStartConnection,
  signalRInitiateCall,
  sayHello,
  respondToHello,
  patientPollNow,
  signalRForwardSignal,
  signalREndCall,
  getScreensharingStream,
  stopVideoAndAudioTracks,
  signalRStopConnection,
  addPeerStream,
  removePeerTracks,
  removePeerStream,
  destroyAllPeers,
  getPeerStats,
} from "common/videoCallModule";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { log } from "helpers";

// I18n
import { useTranslation } from "react-i18next";

// Animation
import { motion } from "framer-motion";

// Material UI
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import SignalCellularConnectedNoInternet0BarRoundedIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0BarRounded";

const selfVideoMarginX = 5;
const selfVideoMarginY = 5;
const boxShadow = "0px 3px 6px rgba(0, 0, 0, 0.5)";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  height600px: {
    height: "600px",
  },
  height75vh: {
    height: "75vh",
  },
  height100: {
    height: "100%",
  },
  flexItemScreensharing: { width: "25%", height: "25%" },
  flexItem1Col1Row: { width: "100%", height: "100%" },
  flexItem2Col1Row: { width: "50%", height: "100%" },
  flexItem2Col2Rows: {
    width: "50%",
    height: "50%",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
  selfVideoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  selfVideo: {
    // boxShadow, // Shows horizontal when video is vertical
    borderRadius: "0.5rem",
  },
  selfVideoVisibilityIcon: {
    boxShadow,
    backgroundColor: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  bottomRightCorner: {
    position: "absolute",
    bottom: selfVideoMarginX,
    right: selfVideoMarginY,
  },
  center: {
    position: "absolute",
    bottom: "50%",
    right: "50%",
  },
  containerHeight25perc: {
    height: "25%",
  },
  containerHeight50perc: {
    height: "50%",
  },
  screensharingVideoContainer: {
    position: "relative", // This is the parent of cloned local video, that's why
    width: "100%",
    height: "75%",
    overflow: "hidden",
  },
  remoteVideoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative", // This is the parent container for overlay, that's why
    overflow: "hidden", // Overlay component may overflow in smaller screens
  },
  overlay: {
    position: "absolute",
    bottom: "0",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 80%)",
    color: "#f1f1f1",
    width: "100%",
    textAlign: "center",
  },
  overlayNoSignal: {
    background: "rgba(256, 256, 256, 0.66)",
    width: "auto",
    padding: theme.spacing(0, 1),
    borderRadius: "1rem",
    fontWeight: 900,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const VideoCallWrapper = styled(Box)({
  backgroundColor: "black",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "center",
  width: "100%",
  // height: "100%",
  position: "relative",
  overflow: "hidden",
});

const FlexContainerWithWrap = styled(Box)({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const VideoCall = (props) => {
  const {
    isClinician,
    roomId,
    patientId,
    setShowCallInviteModal,
    setShowDetails,
    callConnectCallback,
    callDisconnectCallback,
    setIsChatMounted,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  // LOGS
  //  eslint-disable-next-line
  const [logs, setLogs] = useState([]);

  // PEER
  const [onStreamInactive, setOnStreamInactive] = useState(null);
  useEffect(() => {
    if (!onStreamInactive) return;
    removeInactiveStream(onStreamInactive);
    setOnStreamInactive(null);
    //  eslint-disable-next-line
  }, [onStreamInactive]);

  // REFS
  const peersRef = useRef({});
  const videoCallWrapper = useRef(null);
  const selfVideo = useRef(null);
  const remoteVideo = useRef(null);
  const callRef = useRef({});
  const patientPollRef = useRef(null);

  // SCREENSHARING
  const screensharingVideo = useRef(null);
  const localScreensharingStream = useRef(null);
  const [isSharingScreen, setIsSharingScreen] = useState(false);

  // CONTEXTS
  const [, isFullscreen] = useContext(FullscreenContext);
  const [chatState, setChatState, , setChatMessageCounter] =
    useContext(ChatContext);
  const { callStatus, setCallStatus } = useContext(CallContext);
  const { isInvited, invitationCode, invitationName } = callStatus;

  // REMOTE STREAMS
  const [remoteStreamsList, setRemoteStreamsList] = useState([]);
  const [remoteScreensharingStream, setRemoteScreensharingStream] =
    useState(null);

  // Remote streams updater
  const [activeStreams, setActiveStreams] = useState(null);
  useEffect(() => {
    const numberOfStreams = remoteStreamsList.length;
    log({
      text: `[Call] Total active streams: ${numberOfStreams}`,
      css: "color:black; background-color:white",
    });

    // Update the number of active streams to update the layout of video elements
    setActiveStreams(numberOfStreams);

    // eslint-disable-next-line
  }, [remoteStreamsList]);

  // Polling
  const [patientPollResponse, setPatientPollResponse] = useState(null);

  // In-call settings
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openCallSettings, setOpenCallSettings] = useState(false);
  const [isSelfVideoVisible, setIsSelfVideoVisible] = useState(true);
  const [isSelfVideoLocked, setIsSelfVideoLocked] = useState(false);
  const [showNoUserMediaWarning, setShowNoUserMediaWarning] = useState(false);
  const [blankScreenMessage, setBlankScreenMessage] = useState({
    clinician: t("WaitingForPatient"),
    patient: t("WaitingForClinician"),
    invited: t("WaitingForParticipants"),
  });
  const [noSignal, setNoSignal] = useState({});

  // Chat
  const [isChatOpen, setIsChatOpen] = useState(null);

  // The call startp-up sequence
  useEffect(() => {
    (async () => {
      try {
        // Start fresh
        console.clear();

        await signalRBuildConnection({
          rId: roomId,
          pId: patientId,
          invitationCode,
          invitationName,
          isClinician,
        });

        // Change document title to patient's name
        if (!isClinician) document.title = `${ownName} - ${document.title}`;

        await signalRStartConnection(receivedMessageCallback);

        await getDevices();

        let stream = await getUsersMediaStream({ audio: true, video: true });
        if (stream === null) {
          log({
            text: "[Call] User Media - Audio & Video Not Detected - Trying Audio Only",
            css: "color:Orange",
          });
          stream = await getUsersMediaStream({ audio: true, video: false });

          if (stream === null) {
            setCallStatus((prevState) => ({
              ...prevState,
              noUserMedia: true,
            }));

            setShowNoUserMediaWarning(true);

            throw new Error("User Media - No Media Detected");
          }
        }

        if ("srcObject" in selfVideo.current) {
          selfVideo.current.srcObject = stream;
        } else {
          selfVideo.current.src = window.URL.createObjectURL(stream);
        }

        setCallStatus((prevState) => ({
          ...prevState,
          audioMessagesEnabled,
        }));
        signalRInitiateCall(isClinician, patientId);
        startCall(stream);

        // Mount the chat for clinician
        if (isClinician) setIsChatMounted(true);

        // Make sure room id exists
        if (chatState[roomId] === undefined) {
          setChatState((prevState) => ({ ...prevState, [roomId]: [] }));
        }

        setCallStatus((prevState) => ({
          ...prevState,
          isCallInProgress: true,
          isClickAwayAllowed: false,
        }));
      } catch (err) {
        log({ type: "err", text: "[Call] Start call:", params: err.message });
      }
    })();

    return () => {
      document.title = "process.env.REACT_APP_WEBSITE_NAME;";
      if (!callRef.current.ended) endCall(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        getPeerStats(peersRef.current);
      } catch (err) {
        console.error("[Stats] Polling error: ", err);
      }
    }, 15000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  // Patient poll -> patient says it's online to the clinician
  useEffect(() => {
    if (!patientId || isClinician) return;

    const interval = setInterval(async () => {
      try {
        const response = await patientPollNow(patientId);
        patientPollRef.current = response;
      } catch (err) {
        console.error("[SignalR] Polling error: ", err);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  // Update the state for patient poll response only when clinician's online status changes
  useMemo(() => {
    if (patientPollRef.current) {
      console.log(
        `[Patient Poll Response] Clinician's online status: ${patientPollRef.current.online}`
      );
      setPatientPollResponse(patientPollRef.current);
    }
  }, [patientPollRef.current?.online]);

  const endCall = (isLocal) => {
    // BUG: if endCall is triggered before the connections are established, it's not going to run again
    // Hence the media and connections don't stop
    if (callRef.current.ended) return;

    // Not to trigger this twice, because if triggered locally runs again when no more streams
    callRef.current.ended = true;
    log({
      text: `[Call] End call triggered ${isLocal ? "locally" : "remotely"}`,
    });

    // Removing tracks for all peers
    removePeerTracks(peersRef.current, selfVideo.current.srcObject);

    // Removing stream for all peers
    removePeerStream(peersRef.current, selfVideo.current.srcObject);

    stopVideoAndAudioTracks(selfVideo.current.srcObject);

    turnOffScreensharing();

    // Destroying all peers
    destroyAllPeers(peersRef.current);

    // Invokes signalRConnection.on("EndCall") for everyone, then stops SignalR connection
    signalREndCall(patientId)
      .then(() => signalRStopConnection())
      .catch((err) => console.error(err));

    // CLEARING STATES

    // Removes all video elements from the DOM
    setRemoteStreamsList([]);
    // Removes all peers from the list
    peersRef.current = {};

    setChatState((prevState) => ({ ...prevState, [roomId]: [] }));
    setChatMessageCounter((prevState) => ({
      ...prevState,
      [roomId]: 0,
    }));

    setCallStatus((prevState) => ({
      ...prevState,
      isCallInProgress: false,
      isClickAwayAllowed: true,
    }));
    callDisconnectCallback();
  };

  // SCREENSHARING FUNCTIONS
  const turnOnScreensharing = () => {
    getScreensharingStream()
      .then((stream) => {
        if (stream) {
          setIsSharingScreen(true);

          // This gets triggered when you click on the browsers stop screensharing button
          stream.getVideoTracks()[0].addEventListener("ended", (e) => {
            log({
              text: `[Local Screensharing Stream] Inactive (NEW)`,
              css: "color:Magenta",
              params: [e],
            });

            turnOffScreensharing();
          });

          localScreensharingStream.current = stream;

          log({
            text: "[Media] Got user's screensharing stream:",
            params: [stream],
          });

          addPeerStream(peersRef.current, stream);
        }
      })
      .catch((err) => {
        log({
          type: "err",
          text: `[Media] Failed to get user's screensharing stream`,
          params: err.message,
        });

        setIsSharingScreen(false);
      });
  };

  const turnOffScreensharing = () => {
    if (!localScreensharingStream.current) return;
    log({ text: "[Call] Turning off screensharing" });
    setIsSharingScreen(false);

    // Remove the stream from all peers
    removePeerStream(peersRef.current, localScreensharingStream.current);

    stopVideoAndAudioTracks(localScreensharingStream.current);
    localScreensharingStream.current = null;
  };

  // CHAT FUNCTIONS
  const openChatWindow = () => {
    setIsChatOpen(true);
  };

  const handleCloseChatWindow = () => {
    setChatMessageCounter((prevState) => ({
      ...prevState,
      [roomId]: 0,
    }));

    setIsChatOpen(false);
  };

  const createTimestampForChatMsg = () => {
    // TODO: Allow different locales
    const locale = "en-GB";
    return new Date().toLocaleString(locale, {
      hour: "numeric",
      minute: "numeric",
      // hour12: true,
    });
  };

  const receivedMessageCallback = (data) => {
    // Add one to the counter badge
    setChatMessageCounter((prevState) => ({
      ...prevState,
      [roomId]: prevState[roomId] ? prevState[roomId] + 1 : 1,
    }));

    // Parse the JSON and add the message to state to be rendered
    const message = JSON.parse(data.msg);
    const { type, body } = message;

    setChatState((prevState) => ({
      ...prevState,
      [roomId]: [
        ...prevState[roomId],
        {
          type,
          author: data.name,
          body,
          time: createTimestampForChatMsg(),
        },
      ],
    }));
  };

  // INVITE TO ROOM FUNCTIONS
  const handleOpenInviteToRoom = () => {
    setOpenInviteModal(true);
  };

  const handleCloseInviteToRoom = () => {
    setOpenInviteModal(false);
  };

  // STREAM UPDATERS
  const addNewPeerStream = (stream) => {
    const { name, connectionId } = stream;
    const streamTracks = stream.getTracks();
    const streamVideoTracks = stream.getVideoTracks();

    if (streamTracks.length === 1 && streamVideoTracks.length === 1) {
      // SCREENSHARING STREAM

      // When remote screensharing track is removed
      stream.onremovetrack = (e) => {
        log({
          text: `[Peer] ${name}'s screensharing track removed (stream.onremovetrack) [${connectionId}]`,
          css: "color:Magenta",
          params: [e],
        });

        setRemoteScreensharingStream(null);
      };

      log({
        text: `[Peer.on("stream")] Screensharing stream received from ${name} [${connectionId}]`,
        css: "color: black; background-color: Orchid",
        params: [{ stream, streamTracks }],
      });

      setRemoteScreensharingStream(stream);
    } else {
      // REGULAR REMOTE STREAM
      stream.onaddtrack = (e) => {
        log({
          text: `[Peer] ${name} added ${e.track.kind} track (${
            e.track.enabled ? "enabled" : "disabled"
          }) [${connectionId}]`,
          css: "color:Lime",
          params: [e],
        });
      };

      stream.onremovetrack = (e) => {
        log({
          text: `[Peer] ${name} removed ${e.track.kind} track (${
            e.track.enabled ? "enabled" : "disabled"
          }) [${connectionId}]`,
          css: "color:Magenta",
          params: [e],
        });
      };

      log({
        text: `[Peer.on("stream")] New stream received from ${name} [${connectionId}]`,
        css: "color: black; background-color: Orchid",
        params: [{ stream, streamTracks }],
      });

      // Updating remoteStreamsList triggers a useEffect function that updates activeStream
      setRemoteStreamsList((prevState) => [...prevState, stream]);
    }
  };

  const removeInactiveStream = (connectionId) => {
    // Update the remoteStreamsList's state once a stream is inactive
    // This removes the video element that contains the inactive stream from the DOM
    const remainingRemoteStreams = remoteStreamsList.filter(
      (i) => i.connectionId !== connectionId
    );

    // Updating remoteStreamsList triggers a useEffect function that updates activeStream
    setRemoteStreamsList(remainingRemoteStreams);

    // Do something if the remote peer has no more streams
    if (remainingRemoteStreams.length === 0) {
      log({
        text: `[Call] [${connectionId}] has no more streams => destroying & deleting peer`,
        css: "color:black; background-color:yellow",
      });

      if (peersRef.current[connectionId]) {
        // 1. Trigger peer.on("close") on the remote peer
        peersRef.current[connectionId].destroy();

        // 2. Delete the peer from peers list
        delete peersRef.current[connectionId];
      }
    }
  };

  // CALL SETTINGS
  const handleOpenCallSettings = () => {
    setOpenCallSettings(true);
  };

  const handleCloseCallSettings = () => {
    setOpenCallSettings(false);
  };

  const createPeer = ({ initiator, stream, connectionId, name }) => {
    if (peersRef.current[connectionId]) return;
    let hasStream = false;

    log({ text: `[Peer] Creating new peer \nIs initiator? ${initiator}` });

    const { iceTurnServers, iceUsername, icePassword, iceStunServers } =
      webRtcParameters;

    peersRef.current[connectionId] = new window.SimplePeer({
      initiator,
      stream,
      offerOptions: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      },
      trickle: true,
      config: {
        iceServers: [
          {
            urls: iceTurnServers,
            username: iceUsername,
            credential: icePassword,
          },
          {
            urls: iceStunServers,
          },
        ],
      },
      iceCompleteTimeout: 20000,
    });

    const peer = peersRef.current[connectionId];

    // Check if new peer sends any streams
    setTimeout(() => {
      if (hasStream) return;
      try {
        log({
          text: "[Peer -> TIMED OUT] Peer didn't send any streams => destroying & deleting peer",
        });

        if (peersRef.current[connectionId]) {
          peersRef.current[connectionId].destroy();
          delete peersRef.current[connectionId];
        }
      } catch (err) {
        console.error(err);
      }
    }, 15000);

    log({
      text: "[Peer] All peer instances:",
      css: "color: black; background-color: yellow",
      params: peersRef.current,
    });

    peer.on("error", (err) => {
      log({ type: "err", text: "[Peer]", params: err });

      if (
        typeof err.message === "string" &&
        err.message.includes("Connection failed")
      )
        setBlankScreenMessage((prevState) => ({
          ...prevState,
          clinician: t("WaitingForPatientDisconnected"),
        }));
    });

    peer.on("signal", (data) => {
      log({
        text: `[Peer] Signal received from ${name} [${connectionId}] (forwarding to server)`,
        params: data,
      });

      // Forwarding the signal to the server
      signalRForwardSignal(connectionId, data);
    });

    peer.on("data", (data) => {
      log({
        text: `[Peer] Data received from ${name} [${connectionId}]: ${data}`,
      });
    });

    peer.on("stream", (stream) => {
      callConnectCallback();

      hasStream = true;
      stream.connectionId = connectionId;
      stream.name = name;

      addNewPeerStream(stream);
    });

    peer.on("track", (track, stream) => {
      const trackSettings = track.getSettings();
      const trackConstraints = track.getConstraints();
      // const trackCapabilities = track.getCapabilities();

      log({
        text: `[Peer.on("track")] New ${
          track.kind
        } track received from ${name} (${
          track.enabled ? "enabled" : "disabled"
        }) [${connectionId}]`,
        css: "color:cyan; background-color:black",
        params: [
          {
            track,
            stream,
            trackSettings,
            trackConstraints,
            // trackCapabilities
          },
        ],
      });
    });

    peer.on("connect", () => {
      log({
        text: `[Peer.on("connect")] CONNECTED to ${name} [${connectionId}]`,
        css: "color: black; background-color: MediumSpringGreen",
      });
      // peer.send("Hi!");
    });

    peer.on("close", () => {
      log({
        text: `[Peer] Connection close received from ${name} [${connectionId}]`,
        css: "color: black; background-color: Tomato",
      });

      if (!callRef.current.ended) setOnStreamInactive(connectionId);

      if (peersRef.current[connectionId]) {
        peersRef.current[connectionId].destroy();
        delete peersRef.current[connectionId];
      }
    });

    peersRef.current[connectionId]._pc.oniceconnectionstatechange = () => {
      if (
        peersRef.current[connectionId]._pc.iceConnectionState === "disconnected"
      ) {
        setNoSignal((prevState) => ({ ...prevState, [connectionId]: true }));

        log({
          text: `[Peer] Received DISCONNECTED iceConnectionState from ${name} [${connectionId}]`,
          css: "color: black; background-color: Tomato",
        });
      } else if (
        peersRef.current[connectionId]._pc.iceConnectionState === "connected"
      ) {
        setNoSignal((prevState) => ({ ...prevState, [connectionId]: false }));

        log({
          text: `[Peer] Received CONNECTED iceConnectionState from ${name} [${connectionId}]`,
          css: "color: black; background-color: MediumSpringGreen",
        });
      }
    };
  };

  const startCall = (stream) => {
    sayHello(roomId);

    signalRConnection.on("Hello", async (data) => {
      const { name, connectionId } = data;

      log({
        text: `[SignalR] "Hello" received from ${name} [${connectionId}]`,
        css: "color:MediumSpringGreen",
      });

      createPeer({ initiator: false, stream, connectionId, name });

      const numberOfPeers = Object.keys(peersRef.current).length;
      respondToHello(numberOfPeers, connectionId, name);
    });

    signalRConnection.on("Ack", (data) => {
      const { name, connectionId } = data;

      log({
        text: `[SignalR] "Ack" received from ${name} [${connectionId}]`,
        css: "color:MediumSpringGreen",
      });

      createPeer({ initiator: true, stream, connectionId, name });
    });

    signalRConnection.on("CallInfoDynamic", (data, connectionId) => {
      log({
        text: `[SignalR] "CallInfoDynamic" received ${data} [${connectionId}]`,
        css: "color:MediumSpringGreen",
      });

      const parsedData = JSON.parse(data);
      peersRef.current[connectionId].signal(parsedData);
    });

    // // Server ends call
    signalRConnection.on("EndCall", () => {
      log({
        text: "[SignalR] REMOTE ENDED CALL EXPLICITLY",
        css: "color:MediumSpringGreen",
      });
      if (!isClinician) {
        endCall(false);
      } else {
        setBlankScreenMessage((prevState) => ({
          ...prevState,
          clinician: t("WaitingForPatientCallEnded"),
        }));
      }
    });

    signalRConnection.on("FullRoom", () => {
      log({
        text: "[SignalR] Fullroom received -> ending call",
        css: "color:MediumSpringGreen",
      });

      endCall(false);
    });
  };

  const dragStarted = () => {
    setIsSelfVideoLocked(true);
  };

  const dragEnded = () => {
    setIsSelfVideoLocked(false);
  };

  // No user media detected funcs
  const handleNoUserMediaClose = () => {
    setShowNoUserMediaWarning(false);
    endCall(true);
  };

  return (
    <Box className={classes.root}>
      {/* Videos begin */}
      <VideoCallWrapper
        ref={videoCallWrapper}
        className={clsx({
          [classes.height600px]: !isFullscreen.active && !isMobile,
          [classes.height75vh]: !isFullscreen.active && isMobile,
          [classes.height100]: isFullscreen.active,
        })}
      >
        <FlexContainerWithWrap>
          {/* Screensharing stream */}
          {remoteScreensharingStream?.active && (
            <Box className={clsx(classes.screensharingVideoContainer)}>
              <VideoElement
                ref={screensharingVideo}
                className={classes.fullSize}
                srcObject={remoteScreensharingStream}
                muted={true}
              />
              <Typography variant="caption" className={classes.overlay}>
                {`${remoteScreensharingStream.name}'s screen`}
              </Typography>
            </Box>
          )}

          {/* Remote streams */}
          {remoteStreamsList.map((stream) => (
            <Box
              className={clsx(classes.remoteVideoContainer, {
                [classes.flexItem2Col2Rows]:
                  (activeStreams === 3 || activeStreams === 4) &&
                  !isMobile &&
                  !remoteScreensharingStream,
                [classes.flexItem2Col1Row]:
                  activeStreams === 2 &&
                  !isMobile &&
                  !remoteScreensharingStream,
                [classes.flexItem1Col1Row]:
                  activeStreams === 1 &&
                  !isMobile &&
                  !remoteScreensharingStream,
              })}
              style={{
                height:
                  isMobile && !remoteScreensharingStream
                    ? `${100 / activeStreams}%`
                    : remoteScreensharingStream && "25%",
                width: remoteScreensharingStream && `${100 / activeStreams}%`,
              }}
              key={stream.id}
            >
              <VideoElement
                ref={remoteVideo}
                className={clsx(classes.fullSize)}
                srcObject={stream}
              />
              {noSignal[stream.connectionId] && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                  className={clsx(classes.overlayNoSignal)}
                >
                  <Grid item>
                    <SignalCellularConnectedNoInternet0BarRoundedIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{"Signal Lost"}</Typography>
                  </Grid>
                </Grid>
              )}
              <Typography variant="caption" className={classes.overlay}>
                {stream.name}
              </Typography>
            </Box>
          ))}

          {/* Waiting for clinician component (only for patient) */}
          {!activeStreams && (
            <WaitingForClinician
              {...{
                text:
                  !isClinician && !isInvited
                    ? blankScreenMessage.patient
                    : isClinician
                    ? blankScreenMessage.clinician
                    : isInvited
                    ? blankScreenMessage.invited
                    : "",
                isClinicianOnline: patientPollResponse?.online,
              }}
            />
          )}
        </FlexContainerWithWrap>

        {/* Local video at the bottom right of the screen */}
        <motion.div
          drag
          // layout
          dragConstraints={{
            bottom: 0,
            right: 0,
            top: -(
              videoCallWrapper.current?.clientHeight -
              selfVideo.current?.clientHeight -
              selfVideoMarginY -
              5
            ),
            left: -(
              videoCallWrapper.current?.clientWidth -
              selfVideo.current?.clientWidth -
              selfVideoMarginX -
              5
            ),
          }}
          onDragStart={dragStarted}
          onDragEnd={dragEnded}
          className={clsx(classes.selfVideoWrapper, classes.bottomRightCorner)}
        >
          <VideoElement
            className={classes.selfVideo}
            ref={selfVideo}
            width={"160"}
            height={"120"}
            muted={true}
            onClick={() => {
              setIsSelfVideoVisible(false);
            }}
            style={{
              visibility: isSelfVideoVisible ? "visible" : "hidden",
              pointerEvents: isSelfVideoLocked ? "none" : "auto",
            }}
          />
          {!isSelfVideoVisible && (
            <IconButton
              className={classes.selfVideoVisibilityIcon}
              onClick={() => {
                setIsSelfVideoVisible(true);
              }}
              style={{ pointerEvents: isSelfVideoLocked ? "none" : "auto" }}
              size="large"
            >
              <VisibilityOffOutlinedIcon color="primary" />
            </IconButton>
          )}
        </motion.div>
      </VideoCallWrapper>
      {/* Videos end */}

      {/* No user device dialog box */}
      <NoUserMedia
        {...{
          open: showNoUserMediaWarning,
          handleClose: handleNoUserMediaClose,
        }}
      />

      {/* Invite component */}
      {openInviteModal && (
        <InviteToRoom {...{ roomId, handleClose: handleCloseInviteToRoom }} />
      )}

      {/* Not-clinician Chat component */}
      {!isClinician && activeStreams > 0 && (
        <Box style={{ visibility: isChatOpen ? "visible" : "hidden" }}>
          <Chat
            {...{
              roomId,
              handleCloseChatWindow,
            }}
          />
        </Box>
      )}

      {/* Video settings component */}
      {openCallSettings && (
        <VideoCallSettings
          {...{
            peers: peersRef.current,
            selfVideo,
            handleClose: handleCloseCallSettings,
          }}
        />
      )}

      {/* Action bar component */}
      <VideoCallActions
        {...{
          peers: peersRef.current,
          selfVideo,
          roomId,
          isClinician,
          isSharingScreen,
          openChatWindow,
          turnOffScreensharing,
          turnOnScreensharing,
          setShowCallInviteModal,
          setShowDetails,
          handleOpenInviteToRoom,
          handleOpenCallSettings,
          endCall,
          setLogs,
          remoteScreensharingStream,
        }}
      />

      {/* Logs */}
      {/* {logs.map((log, index) => {
        return (
          <Typography variant="caption" key={index}>
            {log}
          </Typography>
        );
      })} */}
    </Box>
  );
};

VideoCall.propTypes = {
  isClinician: PropTypes.bool,
  roomId: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  setShowDetails: PropTypes.func,
  callConnectCallback: PropTypes.func.isRequired,
  callDisconnectCallback: PropTypes.func,
  setIsChatMounted: PropTypes.func,
};

VideoCall.defaultProps = {
  isClinician: false,
  roomId: "",
  patientId: "",
  setShowDetails: PropTypes.func,
  callDisconnectCallback: PropTypes.func,
  setIsChatMounted: () => PropTypes.func,
};

export default VideoCall;
