import { Typography, Grid, Chip } from "@mui/material";
import { ClinicInvite, FramerModal } from "components";
import React from "react";

// Date
import { format } from "date-fns";

// MUI
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";

const ClinicBookingInfo = (props) => {
  const { booking, handleClose } = props;

  return (
    <div>
      <FramerModal {...{ open: !!booking, handleClose }}>
        {booking && (
          <Grid container direction="column" spacing={3}>
            <Grid item container direction="column" alignItems="center">
              <Grid item>
                <AssignmentIndRoundedIcon style={{ fontSize: "5rem" }} />
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  {booking.patient.name || "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                <strong>Gender: </strong>
                {booking.patient.gender || "-"}
              </Typography>
              <Typography>
                <strong>Address: </strong>
                {booking.patient.address || "-"}
              </Typography>
              <Typography>
                <strong>Phone Number: </strong>
                {`(+)${booking.patient.phoneNumber}` || "-"}
              </Typography>
              <Typography>
                <strong>Email: </strong>
                {booking.patient.email || "-"}
              </Typography>
              <Typography>
                <strong>Date of Birth: </strong>
                {format(new Date(booking.patient.doB), "PP") || "-"}
              </Typography>
            </Grid>

            <Grid
              item
              style={{
                border: "1px solid #cccccc",
                borderRadius: "1rem",
                margin: "1rem 0",
              }}
            >
              <Typography>
                <strong>Message: </strong>
              </Typography>
              <Typography>{booking.message || "-"}</Typography>
            </Grid>

            <Grid item>
              <Typography>
                <strong>Clinic Name: </strong>
                {booking.clinic.name || "-"}
              </Typography>

              <Typography>
                <strong>Clinic Service: </strong>
                {`${booking.clinic.service.name} (${
                  booking.clinic.service.price
                } ${booking.clinic.service.currency.toUpperCase()})`}
              </Typography>

              <Typography style={{ display: "inline" }}>
                <strong>Booking Status: </strong>
              </Typography>
              <Chip
                color={
                  booking?.bookingStatus?.toLowerCase() === "paid"
                    ? "success"
                    : "warning"
                }
                label={booking.bookingStatus || "-"}
                size="small"
              />
            </Grid>

            {/* Action buttons */}
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <ClinicInvite
                  {...{ clinic: booking.clinic, patient: booking.patient }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </FramerModal>
    </div>
  );
};

export default ClinicBookingInfo;
