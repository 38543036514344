import React, { useState } from "react";

// Location Autocomplete
import LocationInput from "components/GenericFormModal/fields/LocationInput";

// MUI
import {
  Grid,
  Button,
  Icon,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Switch,
} from "@mui/material";

const LocEditorFields = ({
  propName,
  editorData,
  handleSave,
  handleDelete,
}) => {
  const itemLabels = {
    locations: "Location",
  };

  const defaultValues = {
    map: null,
    type: null,
  };
  const [formData, setFormData] = useState(
    editorData
      ? {
          ...editorData,
          map: editorData.map ? JSON.parse(editorData.map) : null,
        }
      : defaultValues,
  );

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const handleChangeMap = (data) => {
    setFormData((prevState) => ({
      ...prevState,
      map: data,
    }));
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      spacing={2}
      style={{ maxWidth: 500 }}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>{`${
          editorData ? "Edit" : "Add"
        } a ${itemLabels[propName]}`}</Typography>
      </Grid>

      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">{"Select Location Type"}</FormLabel>
          <RadioGroup
            row
            aria-label="location type"
            name="type"
            value={formData.type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="clinic"
              control={<Radio />}
              label="Clinic"
            />
            <FormControlLabel
              value="mailing"
              control={<Radio />}
              label="Mailing"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item>
        <LocationInput
          valueProp={formData?.map?.formatted_address || ""}
          onChange={handleChangeMap}
        />
      </Grid>

      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={formData.isVisible || false}
                onChange={handleChange}
                name="isVisible"
              />
            }
            label="Visible publicly"
          />
        </FormGroup>
      </Grid>

      {/* Actions */}
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "1rem" }}
        spacing={3}
      >
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Icon>delete</Icon>}
            disabled={!editorData}
            onClick={() => handleDelete(editorData.id)}
          >
            {"Delete"}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            disabled={!formData.map}
            onClick={() => {
              handleSave(formData);
            }}
          >
            {"Save"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocEditorFields;
