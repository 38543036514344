import React, { useState, useEffect } from "react";

// Components
import { ConfirmDialog, FramerModal } from "components";
import AvailabilityProfiles from "@medicalchain/rcl-availability-profiles";

// Utils
import { useSnackbar } from "notistack";

// API
import { apiPost, apiGet, apiDelete, endpoints } from "api";

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Typography,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToPhotosRoundedIcon from "@mui/icons-material/AddToPhotosRounded";
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const AvailProfilesTable = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [profileIdToBeDeleted, setProfileIdToBeDeleted] = useState(null);
  const [showAvailProfiles, setShowAvailProfiles] = useState(false);
  const [viewProfile, setViewProfile] = useState({
    show: false,
    profile: null,
  });

  const [availabilityProfiles, setAvailabilityProfiles] = useState([]);
  useEffect(() => getAllAvailProfiles(), []);

  const getAllAvailProfiles = () => {
    apiGet(endpoints.clinic.availabilityProfile)
      .then((res) => {
        setAvailabilityProfiles(res.data);
      })
      .catch(console.error);
  };

  const handleCloseDialog = () => {
    setProfileIdToBeDeleted(null);
    setShowDeleteDialog(false);
  };

  const handleDelete = () => {
    apiDelete(`${endpoints.clinic.availabilityProfile}/${profileIdToBeDeleted}`)
      .then((res) => {
        if (res.status === 204) {
          enqueueSnackbar("Profile deleted successfully", {
            variant: "success",
          });

          getAllAvailProfiles();
        } else {
          enqueueSnackbar(res.data?.detail, {
            variant: "error",
          });
        }
      })
      .catch(console.error);

    handleCloseDialog();
  };

  const handleOpenAvailabilityProfiles = () => {
    setShowAvailProfiles(true);
  };
  const handleCloseAvailabilityProfiles = () => {
    setShowAvailProfiles(false);
  };

  const handleSaveAvailabilityProfile = async (data) => {
    console.log("Received", data);
    try {
      const response = await apiPost(
        endpoints.clinic.availabilityProfile,
        data,
      );
      if (response.status === 201) {
        enqueueSnackbar("Availability profile created successfully", {
          variant: "success",
        });
        handleCloseAvailabilityProfiles();
        getAllAvailProfiles();
      } else {
        enqueueSnackbar(
          "There is a problem creating the availability profile.",
          {
            variant: "error",
          },
        );
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to create the availability profile.", {
        variant: "error",
      });
    }
  };

  const closeViewProfile = () => {
    setViewProfile({ show: false, profile: null });
  };

  return <>
    <ConfirmDialog
      {...{
        title: `Delete Availability Profile`,
        content: `Are you sure you want to delete this availability profile?`,
        open: showDeleteDialog,
        handleClose: handleCloseDialog,
        handleDelete,
      }}
    />

    {/* Add a new profile */}
    <FramerModal
      {...{
        open: showAvailProfiles,
        handleClose: handleCloseAvailabilityProfiles,
      }}
    >
      <Box mb={4}>
        <Typography variant="h5">{"Create availability profile"}</Typography>
      </Box>
      <AvailabilityProfiles
        {...{
          handleSave: handleSaveAvailabilityProfile,
          handleClose: handleCloseAvailabilityProfiles,
        }}
      />
    </FramerModal>

    {/* View profile */}
    <FramerModal
      {...{
        open: viewProfile.show,
        handleClose: closeViewProfile,
      }}
    >
      {viewProfile.profile && (
        <>
          <Box mb={3}>
            <Typography variant="h5">{`Availability Profile Schedules for ${viewProfile.profile.name}`}</Typography>
          </Box>

          <Box>
            {Object.keys(viewProfile.profile.openingHours).map(
              (day, index) => {
                return (
                  <Box key={index} mb={3}>
                    <Typography
                      variant="h6"
                      style={{ textTransform: "capitalize" }}
                    >
                      {day}
                    </Typography>
                    {viewProfile.profile.openingHours[day].length === 0 && (
                      <Typography>{"Closed all day"}</Typography>
                    )}
                    {viewProfile.profile.openingHours[day].map(
                      (timeslot, index) => {
                        return (
                          <Box key={index} mb={2}>
                            {timeslot.opens === "00:00" &&
                            timeslot.closes === "00:00" ? (
                              <Typography>{"Open all day"}</Typography>
                            ) : (
                              <>
                                <Typography>{timeslot.opens}</Typography>
                                <Typography>{timeslot.closes}</Typography>
                                <Divider />
                              </>
                            )}
                          </Box>
                        );
                      },
                    )}
                  </Box>
                );
              },
            )}
          </Box>
        </>
      )}
    </FramerModal>

    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="h4">{"Availability Profiles"}</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAvailabilityProfiles}
          startIcon={<AddOutlinedIcon />}
        >
          {"Create availability profile"}
        </Button>
      </Grid>
    </Grid>

    <Box mt={3}>
      <Alert severity="info">
        {
          "Availability Profiles can be assigned to each Clinic under the Clinic Settings in Clinics Manager."
        }
      </Alert>
    </Box>

    <TableContainer className={classes.table}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>{"Profile Name"}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {availabilityProfiles.map((availProfile) => (
            <TableRow
              className={classes.root}
              style={{ cursor: "pointer" }}
              key={availProfile.id}
            >
              <TableCell
                component="th"
                scope="row"
                onClick={() =>
                  setViewProfile({
                    show: true,
                    profile: availProfile,
                  })
                }
              >
                <Typography>{availProfile.name}</Typography>
              </TableCell>

              <TableCell>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    {availProfile.id!='00000000-0000-0000-0000-000000000000' &&
                    <Tooltip title={"Delete"} arrow>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => {
                          setShowDeleteDialog(true);
                          setProfileIdToBeDeleted(availProfile.id);
                        }}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    }
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>;
};

export default AvailProfilesTable;
