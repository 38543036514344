import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import useDebouncedCallback from "../../../hooks/useDebounceCallback";

// APIs
import { apiPost, endpoints } from "api";

const ApiValidateInput = ({ onChange, field, value, prefix }) => {
  const [validationText, setValidationText] = useState(false);

  const validateHandle = async (handle) => {
    try {
      const response = await apiPost(`${endpoints.account.checkHandle}${handle}`);
      if (response.status === 200 && response.data) {
        switch(response.data) {
          case "InvalidHandle":
            setValidationText("Invalid Handle");
            return;
          case "HandleInUse":
            setValidationText("Handle In Use");
            return;
          case true:
            setValidationText("");
            return;
          default: 
            setValidationText(response.data);
            return;
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const debouncedFetch = useDebouncedCallback((handle) => {
    validateHandle(handle);
  }, 200);

  const handleChange = (e) => {
    setValidationText("");
    onChange(e.target.value);
    debouncedFetch.callback(e.target.value);
  }

  const isValidationError = !!validationText;

  return (
    <>
      <TextField
        label={field?.label}
        error={isValidationError}
        fullWidth
        helperText={validationText}
        name={field?.name}
        onChange={handleChange}
        type={field?.textType ? field.textType : "text"}
        value={value}
        variant="outlined"
        InputProps={{
          startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
        }}
      />
    </>
  );
};

export default ApiValidateInput;
