import React, { useState } from "react";

// Utils
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    zIndex: "10",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  wrapper: {
    padding: theme.spacing(2),
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 720,
    maxHeight: 1080,
    overflow: "auto",
  },
}));

const CustomModal = (props) => {
  const { children, handleClose } = props;

  const classes = useStyles();

  const handleClickedOutside = (e) => {
    if (e.target === e.currentTarget) handleClose();
  };

  return (
    <Box className={classes.root} onClick={handleClickedOutside}>
      <Card className={classes.wrapper}>{children}</Card>
    </Box>
  );
};

CustomModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default CustomModal;
