import React from "react";

// Material components
import { SvgIcon } from "@mui/material";

// Minifying SVGs: https://jakearchibald.github.io/svgomg/
export default function IconTourGuide(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.646 93.646">
        <path d="M67.971 49.778l-9.378-10.345c-.584-.644-1.121-1.971-1.148-2.841L57.1 25.858v-.311c0-1.654-1.346-3-3-3H41.272c-1.478 0-3.127 1.047-3.756 2.384l-12.358 26.25a2.935 2.935 0 00-.096 2.292c.28.75.84 1.342 1.575 1.666l1.821.803c.388.171.802.258 1.231.258a3.143 3.143 0 002.794-1.704l5.789-11.517v11.576c-.024.067-.059.128-.081.196l-9.783 30.638c-.407 1.276-.283 2.619.35 3.781s1.693 1.994 2.987 2.343l.654.177c.428.116.872.175 1.318.175 2.251 0 4.296-1.481 4.974-3.603l9.141-28.628 3.242 7.941c.791 1.937 1.645 5.329 1.865 7.409l1.551 14.621c.249 2.341 2.1 4.04 4.402 4.04.377 0 .76-.046 1.137-.137l.659-.16c2.624-.635 4.478-3.331 4.133-6.008l-2.297-17.828c-.292-2.265-1.269-5.812-2.178-7.907l-3.102-7.144c-.04-.093-.097-.177-.143-.267v-4.841l5.59 5.836c.556.581 1.3.901 2.094.901.803 0 1.553-.326 2.111-.918l1.034-1.098c1.106-1.175 1.125-3.101.041-4.296zM48.52 20.005c5.516 0 10.003-4.487 10.003-10.003C58.523 4.487 54.036 0 48.52 0c-5.515 0-10.001 4.487-10.001 10.002-.001 5.516 4.486 10.003 10.001 10.003z" />
      </svg>
    </SvgIcon>
  );
}
