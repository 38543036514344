import React, { createContext } from "react";

// Fullscreen
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export const FullscreenContext = createContext();

export const FullscreenProvider = ({ children }) => {
  const isFullscreen = useFullScreenHandle();

  return (
    <FullscreenContext.Provider value={[FullScreen, isFullscreen]}>
      {children}
    </FullscreenContext.Provider>
  );
};
