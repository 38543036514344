import React, { useEffect, useMemo, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import useDebouncedCallback from "../../../hooks/useDebounceCallback";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const LocationInput = ({ onChange, valueProp }) => {
  // When editing use the existing value
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBFW8ehqvDT6Dqq96YYAQz261ByQvskUOA&libraries=places",
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  //   const fetch = useMemo(
  //     () =>
  //       (request, callback) => {
  //         autocompleteService.current.getPlacePredictions(request, callback);
  //       },
  //     [],
  //   );

  const debouncedFetch = useDebouncedCallback((request, callback) => {
    autocompleteService.current.getPlacePredictions(request, callback);
  }, 200);

  const fetch = useMemo(
    () => (request, callback) => debouncedFetch.callback(request, callback),
    //  eslint-disable-next-line
    [],
  );

  const fetchPlaceDetails = useMemo(
    () => (request, callback) => {
      placesService.current.getDetails(request, callback);
    },
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div"),
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);

        if (newValue?.place_id) {
          fetchPlaceDetails({ placeId: newValue?.place_id }, (results) => {
            onChange(results);
          });
        }

        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add a location"
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default LocationInput;
