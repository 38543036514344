import React, { cloneElement } from "react";

// Utils
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    fontSize: "10rem",
    marginBottom: theme.spacing(3),
  },
}));

const NothingToShow = (props) => {
  const { text, icon } = props;
  const classes = useStyles();

  // To add custom class name to the input component
  const StyledIcon = () =>
    cloneElement(icon, {
      className: classes.icon,
    });

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <Grid item>
        <StyledIcon />
      </Grid>
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

NothingToShow.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object,
};

NothingToShow.defaultProps = {
  text: "Nothing to show yet",
  icon: <PeopleOutlineIcon color="action" />,
};

export default NothingToShow;
