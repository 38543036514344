import React, { useState } from "react";

// Utils
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";

// Components
import { ConfirmDialog } from "components";

// API
import { apiDelete, endpoints } from "api";

// I18n
import { useTranslation } from "react-i18next";

// Material UI
import { Tooltip, IconButton, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const ClinicDelete = (props) => {
  const { clinicId, clinicName, clinicTerm, allClinics, handleUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  const handleDelete = async () => {
    try {
      const response = await apiDelete(
        endpoints.clinic.delete,
        toBeDeleted.clinicId,
      );
      if (response.status === 200) {
        enqueueSnackbar("Clinic deleted successfully", {
          variant: "success",
        });
        const remainingClinics = allClinics.filter(
          (clinic) => clinic.id !== toBeDeleted.clinicId,
        );
        handleUpdate(remainingClinics);
      } else {
        enqueueSnackbar("Unable to delete the clinic", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar("There is a problem deleting the clinic", {
        variant: "error",
      });
      console.error(err);
    }
  };

  const confirmDelete = (clinicId, clinicName) => {
    setOpen(true);
    setToBeDeleted({ clinicId, clinicName });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <ConfirmDialog
      {...{
        title: `${t("EndClinic")}`,
        content: `${t("EndClinicText")}`,
        open,
        handleClose,
        handleDelete,
      }}
    />
    {clinicTerm !== "Permanent" && (
      <Tooltip title={t("EndClinic")} arrow>
        <IconButton
          aria-label={t("EndClinic")}
          onClick={() => confirmDelete(clinicId, clinicName)}
          size="large">
          <DeleteIcon color="primary" />
        </IconButton>
      </Tooltip>
    )}
  </>;
};

ClinicDelete.propTypes = {
  clinicId: PropTypes.string.isRequired,
  clinicName: PropTypes.string.isRequired,
  allClinics: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
};

export default ClinicDelete;
