import React from "react";

// Components
import { Table } from "./components";

import makeStyles from '@mui/styles/makeStyles';
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 3),
    padding: theme.spacing(2),
  },
}));

const AvailabilityProfiles = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Table />
    </Box>
  );
};

export default AvailabilityProfiles;
