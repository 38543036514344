import React, { useState, useEffect } from "react";

// Utils
import { apiGet, endpoints } from "api";

// Components
import { ClinicBookingInfo } from "components";

// Utils
import clsx from "clsx";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  Grid,
  Chip,
} from "@mui/material";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    // height: "inherit",
    position: "relative",
  },
  cardContent: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  leftBorder: {
    borderLeft: "3px solid rgba(255, 255, 255, 0)",
    transition: "ease 0.2s",
  },
  actionButtons: {
    opacity: "0",
    transition: "ease 0.2s",
  },
  hoveredListItem: {
    transition: "ease 0.2s",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    "&:hover $actionButtons": {
      opacity: "1",
    },
    "&:hover $leftBorder": {
      backgroundColor: "rgba(255, 255, 255, 0)",
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 10,
    right: 10,
  },
}));

const ClinicBookings = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [bookings, setBookings] = useState([]);
  const [booking, setBooking] = useState(null);

  // Get all bookings for this user
  useEffect(() => {
    apiGet(endpoints.bookings.get)
      .then((res) => {
        setBookings(res.data);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <ClinicBookingInfo
        {...{ booking, handleClose: () => setBooking(null) }}
      />

      <Card {...rest} className={clsx(classes.root, className)} elevation={0}>
        <CardHeader title="Clinic Bookings" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <List>
            {/* Rendering bookings */}
            {bookings && bookings.length > 0 ? (
              bookings.map((booking) => {
                return (
                  <ListItem
                    className={classes.hoveredListItem}
                    key={booking.id}
                    button
                    onClick={() => setBooking(booking)}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      wrap="nowrap"
                    >
                      {/* List item content */}
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                        >
                          <ListItem className={classes.leftBorder}>
                            <ListItemAvatar>
                              <AssignmentIndRoundedIcon />
                            </ListItemAvatar>

                            <Typography
                              variant="body1"
                              style={{ marginRight: "0.75rem" }}
                            >
                              {booking.patient.name || "-"}
                            </Typography>

                            <Chip
                              color={
                                booking?.bookingStatus?.toLowerCase() === "paid"
                                  ? "success"
                                  : "warning"
                              }
                              label={booking.bookingStatus || "-"}
                              size="small"
                            />
                          </ListItem>
                        </Grid>
                      </Grid>

                      {/* List item actions */}
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                          className={classes.actionButtons}
                          wrap="nowrap"
                        >
                          {/* <Grid item>
                        <ClinicInvite
                          {...{
                            clinic,
                            variant: 2,
                            color: "secondary",
                          }}
                        />
                      </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })
            ) : (
              <ListItem>
                <Typography variant="body1">{"No bookings yet."}</Typography>
              </ListItem>
            )}
          </List>
        </CardContent>
        <CardActions className={classes.cardActions}></CardActions>
      </Card>
    </>
  );
};

ClinicBookings.propTypes = {
  className: PropTypes.string,
};

export default ClinicBookings;
