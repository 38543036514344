import React, { useEffect, useContext, useMemo } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import { apiGet, endpoints } from "api";
import DateFnsUtils from "@date-io/date-fns";
import { supportedLocales } from "localisations/dateFnsLocale";
import { useTranslation } from "react-i18next";
import { ClinicsContext, MessagingContext } from "contexts";
import { GlobalContext, AccountContext, PatientsContext } from "contexts";
import "localisations/i18n";

const Main = (props) => {
  const { history, location } = props;
  const { i18n } = useTranslation();

  const { globalSettings } = useContext(GlobalContext);
  const [accountData, setAccountData] = useContext(AccountContext);
  const [, setClinicsList] = useContext(ClinicsContext);
  const [, setPatientsList] = useContext(PatientsContext);
  const [, setMessageCount] = useContext(MessagingContext);

  const publicPages = [
    "/sign-in",
    "/sign-up",
    "/forgot-password",
    "/confirm-email",
    "/not-found",
  ];
  const isPatientCheckin = /^\/j\/\S+$/i;
  const isPatientCall = /^\/c\/\S+$/i;
  const isInvitedJoinCall = /^\/jc\/\S+$/i;
  const isSignInWithClaimId = /^\/sign-in\/\S+$/i;

  const isAuthenticatedPage =
    !publicPages.includes(location.pathname) &&
    !isPatientCheckin.test(location.pathname) &&
    !isPatientCall.test(location.pathname) &&
    !isInvitedJoinCall.test(location.pathname) &&
    !isSignInWithClaimId.test(location.pathname)
      ? true
      : false;

  /*
   * Messaging *
   */
  // Func for fetching messages
  const getMessageList = () =>
    apiGet(endpoints.clinic.messages)
      .then((res) => {
        const unreadMessages = res.data?.filter((i) => i.status === "Unread");
        setMessageCount(unreadMessages?.length);
      })
      .catch(console.error);

  // Get messages on the first render
  useEffect(() => {
    if (isAuthenticatedPage) getMessageList();
  }, []);

  // Poll messages every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticatedPage) {
        getMessageList();
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  /*
   * Intercom *
   */
  // Change Intercom state once account data changes to null or undefined
  const accountDataChange = useMemo(() => accountData, [accountData?.fullName]);

  useEffect(() => {
    if (!accountData) {
      window.Intercom("shutdown");
      return;
    }
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      name: accountData.fullName,
      email: accountData.email,
    });
  }, [accountDataChange]);

  /*
   * Account & Clinics *
   */
  const getAccountData = async () => {
    try {
      const response = await apiGet(endpoints.account.data);
      if (response?.status === 200 && response.data) {
        setAccountData(response.data);
      } else {
        // This checks if user is NOT authenticated, then
        // If user's visiting an authenticated page, they'll be redirected to sign-in
        if (isAuthenticatedPage) history.push("/sign-in");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getClinicData = async () => {
    let list = {};

    try {
      const response = await apiGet(endpoints.clinic.data);
      if (response?.status === 200 && response.data) {
        setClinicsList(response.data);
        for (let i = 0; i < response.data.length; i++) {
          try {
            const res = await apiGet(
              endpoints.clinic.patients,
              response.data[i].id
            );
            if (res?.status === 200) {
              list = { ...list, [response.data[i].id]: res.data };
            }
          } catch (err) {
            console.error(err);
          }
        }
        // console.log("Online patients:", list);
        setPatientsList(list);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch account & clinics data on first render
  useEffect(() => {
    if (isAuthenticatedPage) {
      getAccountData();
      getClinicData();
    }
    // eslint-disable-next-line
  }, [location]);

  // Poll clinics - How fast online patients show depends on this
  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticatedPage) {
        getClinicData();
      }
    }, parseInt(process.env.REACT_APP_DATA_REFRESH_RATE));
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={globalSettings.theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MuiPickersUtilsProvider
            locale={supportedLocales[i18n.language]}
            utils={DateFnsUtils}
          >
            <Routes />
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withRouter(Main);
